import React, { useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslate } from 'react-polyglot'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import {
    MOMENT_FORMATS,
    getIsDateSameOrBefore,
    getTomorrowDate,
} from 'services/moment.service'

import ProjectFormContext from 'screens/common/singleProject/formContext/ProjectFormContext'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
    INPUT_FILED_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import REGEXP from 'constants/regex'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import InputField from 'components/formFields/InputField'
import Button from 'components/Button'
import Loader from 'components/Loader'
import DateTimeField from 'components/formFields/DateTimeField'
import TextAreaField from 'components/formFields/TextAreaField'
import SelectField from 'components/formFields/SelectField'
import Separator from 'components/Separator'
import FocusError from 'components/FocusError'
import LocationMapField from 'components/formFields/LocationMapField'
import { CheckboxField, MultiselectField } from 'components/formFields'
import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'
import AvailablePositions from './AvailablePositions'

const ProjectForm = ({ handleSubmit, initialData, id }) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)
    const { currentUser, currentCompany } = useContext(CurrentUserContext)

    const formRef = useRef()
    const [availablePositions, setAvailablePositions] = useState(
        initialData?.projectPositions ?? []
    )
    const [positionsError, setPositionsError] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const { showConfirmationModal, closeConfirmationModal, setIsConfirming } =
        useContext(ConfirmationModalContext)

    const initialValues = {
        projectIdNumber: initialData?.projectIdNumber ?? '',
        name: initialData?.name ?? '',
        clientOrganizations: initialData?.clientOrganizations ?? [],
        projectStatus: initialData?.projectStatus ?? null,
        hidden: initialData?.hidden ?? true,
        fromDate: initialData?.fromDate ?? '',
        toDate: initialData?.toDate ?? '',
        country: initialData?.country ?? null,
        region: initialData?.region ?? null,
        locationDetail: initialData?.locationDetail ?? '',
        locationType: initialData?.locationType ?? null,
        countryOfMobilization: initialData?.countryOfMobilization ?? null,
        location: {
            geoLat: initialData?.geoLat ?? '',
            geoLong: initialData?.geoLong ?? '',
        },
        bodyOfWater: initialData?.bodyOfWater ?? null,
        altitude: initialData?.altitude ?? '',
        industry: initialData?.industry ?? null,
        projectIndustryType: initialData?.projectIndustryType ?? null,
        projectScope: initialData?.projectScope ?? '',
        undisclosed: initialData?.undisclosed ?? false,
        divingMode: initialData?.divingMode ?? null,
        fromDurationDays: initialData?.fromDurationDays ?? '',
        toDurationDays: initialData?.toDurationDays ?? '',
        projectManager: initialData?.projectManager ?? '',
        divingPlatform: initialData?.divingPlatform ?? '',
        nameOfVessel: initialData?.nameOfVessel ?? '',
        fromDepth: initialData?.fromDepth ?? '',
        toDepth: initialData?.toDepth ?? '',
        projectPositions: availablePositions,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object().shape({
        projectIdNumber: Yup.string()
            .trim()
            .matches(REGEXP.ALPHA_NUMERIC, t('form.error.onlyLetterAndNumber')),
        name: Yup.string()
            .trim()
            .matches(REGEXP.ALPHA_NUMERIC, t('form.error.onlyLetterAndNumber'))
            .required(requiredMessage),
        clientOrganizations: Yup.array().notRequired(),
        projectStatus: Yup.object().required(requiredMessage),
        fromDate: Yup.date().nullable(),
        toDate: Yup.date()
            .nullable()
            .test('toDate', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBefore(
                        value,
                        parent.fromDate,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.toDateAfterFromDate'),
                        path: 'toDate',
                    })
                }
                return true
            }),
        country: Yup.object().required(requiredMessage),
        region: Yup.object().required(requiredMessage),
        locationType: Yup.object().required(requiredMessage),
        countryOfMobilization: Yup.object().required(requiredMessage),
        bodyOfWater: Yup.object().required(requiredMessage),
        altitude: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.positiveAltitude'))
            .required(requiredMessage),
        industry: Yup.object().required(requiredMessage),
        projectIndustryType: Yup.object().required(requiredMessage),
        projectScope: Yup.string().trim().required(requiredMessage),
        divingMode: Yup.object().required(requiredMessage),
        fromDurationDays: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber')),
        toDurationDays: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .test('toDurationDays', (value, { parent, createError }) => {
                if (Number(value) < Number(parent.fromDurationDays)) {
                    return createError({
                        message: t('form.error.mustBeMoreThanFrom'),
                        path: 'toDurationDays',
                    })
                }
                return true
            }),
        projectManager: Yup.string()
            .trim()
            .matches(REGEXP.ALPHA_WITH_SPACES, t('form.error.onlyLetter'))
            .required(requiredMessage),
        divingPlatform: Yup.string()
            .trim()
            .matches(REGEXP.ALPHA_WITH_SPACES, t('form.error.onlyLetter')),
        nameOfVessel: Yup.string().notRequired(),
        fromDepth: Yup.number().min(0, t('form.error.invalidNumber')),
        toDepth: Yup.number()
            .min(0, t('form.error.invalidNumber'))
            .test('toDepth', (value, { parent, createError }) => {
                if (Number(value) < Number(parent.fromDepth)) {
                    return createError({
                        message: t('form.error.mustBeMoreThanFromDepth'),
                        path: 'toDepth',
                    })
                }
                return true
            }),
    })

    const isImperialUnit =
        initialData?.unitImperial ??
        currentUser.userSetting.unitImperial ??
        true

    const handleSubmitProjectForm = async (
        formData,
        clientOrganizations,
        resetForm
    ) => {
        await handleSubmit({
            projectPositions: availablePositions,
            ...formData,
            clientOrganizations: clientOrganizations,
            unitImperial: isImperialUnit,
            geoLat: formData.location.geoLat.toString(),
            geoLong: formData.location.geoLong.toString(),
        })
        resetForm({})
        setAvailablePositions([])
    }

    const onSubmit = async (
        { projectPositions, ...formData },
        { resetForm }
    ) => {
        try {
            if (availablePositions?.length === 0) {
                setPositionsError('Please add at least one position')
                const selector = `[scroll-attribute=availablePositionsErrorScroll]`
                const errorElement = document.querySelector(selector)
                if (errorElement) {
                    errorElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    })
                }
            } else {
                setIsSubmitting(true)

                const clientOrganizations = formData.clientOrganizations.map(
                    (item) => {
                        return {
                            id: item.id,
                            entityType: ENTITIES.COMPANY,
                        }
                    }
                )

                const isEqual = (arr1, arr2) => {
                    return (
                        arr1.length === arr2.length &&
                        arr1.every((el1) =>
                            arr2.some((el2) => el1.id === el2.id)
                        )
                    )
                }

                const hasClientOrganizationsChanged = !isEqual(
                    clientOrganizations,
                    initialValues.clientOrganizations
                )

                if (hasClientOrganizationsChanged) {
                    showConfirmationModal({
                        message: 'message.clientOrganizationsDesc',
                        title: 'message.clientOrganizations',
                        handleConfirm: async () => {
                            setIsConfirming(true)
                            await handleSubmitProjectForm(
                                formData,
                                clientOrganizations,
                                resetForm
                            )
                            closeConfirmationModal()
                        },
                        handleCancel: () => {
                            closeConfirmationModal()
                        },
                    })
                } else {
                    await handleSubmitProjectForm(
                        formData,
                        clientOrganizations,
                        resetForm
                    )
                }
            }
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setIsSubmitting(false)
            setIsConfirming(false)
        }
    }

    const handleSubmitForm = () => formRef?.current?.handleSubmit()

    const handleConfirm = () => {
        closeConfirmationModal()
        navigate(-2)
    }

    const handleCancel = () => closeConfirmationModal()

    const handleClose = () => {
        showConfirmationModal({
            confirmLabel: 'general.leavePage',
            cancelLabel: 'general.dontLeavePage',
            message: 'general.areYouSureLeavingPage',
            title: 'general.leavingPage',
            handleConfirm: () => handleConfirm(),
            handleCancel,
        })
    }

    const unitOfMeasurementLabel = isImperialUnit
        ? 'form.label.ft'
        : 'form.label.m'

    return (
        <div>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validation}
            >
                {({ values: { industry, fromDate } }) => (
                    <Form>
                        <FocusError />
                        <ProjectFormContext />
                        <div className="_wr">
                            <div className="_w -mt20">
                                <div className="_12 _l4">
                                    <InputField
                                        name="projectIdNumber"
                                        type={INPUT_FILED_TYPE.TEXT}
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <InputField
                                        name="name"
                                        type={INPUT_FILED_TYPE.TEXT}
                                        label="form.label.diveProjectName"
                                        placeholder="form.placeholder.diveProjectName"
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <MultiselectField
                                        name="clientOrganizations"
                                        entityType={
                                            ENTITIES.CLIENT_ORGANIZATIONS
                                        }
                                        disabledItems={[currentCompany.id]}
                                        searchable
                                    />
                                </div>
                                <div className="_12 _s8 _l4">
                                    <SelectField
                                        name="projectStatus"
                                        placeholder="form.placeholder.projectStatus"
                                        entityType={ENTITIES.PROJECT_STATUS}
                                        required
                                    />
                                </div>
                                <div className="_12 _s4 centered-checkbox -withSelect">
                                    <CheckboxField
                                        name="hidden"
                                        label="form.label.hidden"
                                        translate
                                    />
                                    <span className="help-icon">
                                        <Tooltip
                                            tooltip={t(
                                                'form.label.hiddenInfoText'
                                            )}
                                        >
                                            <Icon
                                                name={ICONS.HELP_AND_INFO}
                                                color={COLORS.LIGHT_BLUE}
                                                size={ICON_SIZE.SIZE20}
                                            />
                                        </Tooltip>
                                    </span>
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <span className="a-bodyTextMedium _12">
                                    {t('general.startDateRange')}
                                </span>
                                <div className="_12 _l4 -mt20">
                                    <DateTimeField
                                        name="fromDate"
                                        label="form.label.startDateRangeFrom"
                                    />
                                </div>
                                <div className="_12 _l4 -mt20">
                                    <DateTimeField
                                        name="toDate"
                                        label="form.label.startDateRangeTo"
                                        minDate={
                                            fromDate
                                                ? getTomorrowDate(fromDate)
                                                : null
                                        }
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <div className="_12 _l4">
                                    <SelectField
                                        name="country"
                                        label="form.label.countryOfWork"
                                        placeholder="form.placeholder.countryOfWork"
                                        entityType={ENTITIES.COUNTRY}
                                        searchable
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="region"
                                        label="form.label.geographicalRegion"
                                        placeholder="form.placeholder.geographicalRegion"
                                        entityType={ENTITIES.REGION}
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <InputField
                                        name="locationDetail"
                                        type={INPUT_FILED_TYPE.TEXT}
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="locationType"
                                        placeholder="form.placeholder.locationType"
                                        entityType={ENTITIES.LOCATION_TYPE}
                                        searchable
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="countryOfMobilization"
                                        placeholder="form.placeholder.countryOfMobilization"
                                        entityType={ENTITIES.COUNTRY}
                                        searchable
                                        required
                                    />
                                </div>

                                {/* GOOGLE MAP */}
                                <div className="_12 -mt20">
                                    <LocationMapField
                                        name="location"
                                        label="form.label.mapLocation"
                                        description="form.label.mapLocationDesc"
                                    />
                                </div>

                                <div className="_12 _m4">
                                    <SelectField
                                        name="bodyOfWater"
                                        entityType={ENTITIES.BODY_OF_WATER}
                                        searchable
                                        required
                                    />
                                </div>
                                <div className="_12 _m4 ">
                                    <InputField
                                        name="altitude"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        required
                                        units={unitOfMeasurementLabel}
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <div className="_12 _l4">
                                    <SelectField
                                        name="industry"
                                        placeholder="form.placeholder.industryType"
                                        entityType={ENTITIES.INDUSTRY}
                                        params={{
                                            onlyUser: false,
                                        }}
                                        searchable
                                        required
                                        createNew
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="projectIndustryType"
                                        label="form.label.projectType"
                                        placeholder="form.placeholder.projectType"
                                        entityType={
                                            ENTITIES.PROJECT_INDUSTRY_TYPES
                                        }
                                        params={
                                            industry && {
                                                'industries.id': industry.id,
                                            }
                                        }
                                        searchable
                                        required
                                        disabled={!industry}
                                        reload={industry}
                                        createNew
                                        createNewParams={{
                                            related_entity: ENTITIES.INDUSTRY,
                                            related_entity_id: industry?.id,
                                        }}
                                    />
                                </div>
                                <div className="_12 -mt20">
                                    <TextAreaField
                                        name="projectScope"
                                        label="form.label.projectScopeOfWork"
                                        placeholder="form.placeholder.projectScopeOfWork"
                                        flexibleTextarea
                                        xxlTextarea
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <SelectField
                                        name="divingMode"
                                        label="form.label.modeOfDive"
                                        placeholder="form.placeholder.modeOfDive"
                                        entityType={ENTITIES.DIVING_MODE}
                                        searchable
                                        required
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <span className="a-bodyTextMedium _12">
                                    {t('general.projectDurationInDays')}
                                </span>
                                <div className="_12 _l4 -mt20">
                                    <InputField
                                        name="fromDurationDays"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        label="form.label.projectDurationInDaysFrom"
                                        placeholder="form.placeholder.projectDurationInDaysFrom"
                                    />
                                </div>
                                <div className="_12 _l4 -mt20">
                                    <InputField
                                        name="toDurationDays"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        label="form.label.projectDurationInDaysTo"
                                        placeholder="form.placeholder.projectDurationInDaysTo"
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <div className="_12 _l4">
                                    <InputField
                                        name="projectManager"
                                        type={INPUT_FILED_TYPE.TEXT}
                                        required
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <InputField
                                        name="divingPlatform"
                                        type={INPUT_FILED_TYPE.TEXT}
                                    />
                                </div>
                                <div className="_12 _l4">
                                    <InputField
                                        name="nameOfVessel"
                                        type={INPUT_FILED_TYPE.TEXT}
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            <div className="_w">
                                <span className="a-bodyTextMedium _12">
                                    {t('general.waterDepthRange')}
                                </span>
                                <div className="_12 _l4 -mt20">
                                    <InputField
                                        name="fromDepth"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        label="form.label.shallowestDepth"
                                        placeholder={
                                            isImperialUnit
                                                ? 'form.placeholder.shallowestDepthImperial'
                                                : 'form.placeholder.shallowestDepth'
                                        }
                                    />
                                </div>
                                <div className="_12 _l4 -mt20">
                                    <InputField
                                        name="toDepth"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                        label="form.label.deepestDepth"
                                        placeholder={
                                            isImperialUnit
                                                ? 'form.placeholder.deepestDepthImperial'
                                                : 'form.placeholder.deepestDepth'
                                        }
                                    />
                                </div>
                            </div>
                            <div className="_w">
                                <Separator />
                            </div>
                            {isSubmitting && <Loader />}
                        </div>
                    </Form>
                )}
            </Formik>

            <AvailablePositions
                availablePositions={availablePositions}
                setAvailablePositions={setAvailablePositions}
                projectId={id}
                positionsError={positionsError}
            />
            <div className="_wr">
                <div className="_w">
                    <div className="space-between -projectButtons -buttons fullWidth">
                        <div className="_12 _xs6 _m3 -prevButton -mt40">
                            <Button
                                btnClass={BUTTON_STATUS.SECONDARY}
                                label="button.cancel"
                                onClick={handleClose}
                                buttonSize={BUTTON_SIZE.LARGE}
                                disabled={isSubmitting}
                            />
                        </div>
                        <div className="_12 _xs6 _m3 -nextButton -mt40">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                buttonSize={BUTTON_SIZE.LARGE}
                                label={
                                    initialData
                                        ? 'button.saveChanges'
                                        : 'button.saveProject'
                                }
                                type={BUTTON_TYPE.SUBMIT}
                                onClick={handleSubmitForm}
                                disabled={isSubmitting}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectForm
