import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'

import { setLastScreenURL } from 'services/localStorage.service'

import { BUTTON_SIZE } from 'constants/enums'

import Button from 'components/Button'
import CreateDiveRecordModalForm from 'screens/diver/diveRecord/components/CreateDiveRecordModalForm'
import AddNew from 'screens/diver/DiverOnboarding/AddNew'

const CreateDiveRecordModal = ({
    route,
    positionOnCurrentProject,
    profile,
    title,
}) => {
    const { pathname } = useLocation()

    const [openCreateDiveRecordModal, setOpenCreateDiveRecordModal] = useState(
        false
    )

    const handleCreateDiveRecord = () => {
        setLastScreenURL(pathname)

        setOpenCreateDiveRecordModal(true)
    }

    return (
        <div className="fullWidth">
            {profile ? (
                <AddNew
                    label="button.addDiveRecord"
                    handleAction={handleCreateDiveRecord}
                />
            ) : (
                <Button
                    label={`${title ? title : 'button.createDiveRecord'}`}
                    onClick={handleCreateDiveRecord}
                    buttonSize={BUTTON_SIZE.MEDIUM}
                />
            )}

            {openCreateDiveRecordModal && (
                <CreateDiveRecordModalForm
                    setOpenCreateDiveRecordModal={setOpenCreateDiveRecordModal}
                    route={route}
                    positionOnCurrentProject={positionOnCurrentProject}
                    profile={profile}
                />
            )}
        </div>
    )
}

CreateDiveRecordModal.propTypes = {
    route: PropTypes.string,
    isSupervisorOnCurrentProject: PropTypes.bool,
    profile: PropTypes.bool,
    title: PropTypes.string,
}

CreateDiveRecordModal.defaultProps = {
    profile: false,
}

export default CreateDiveRecordModal
