import Breadcrumbs from 'components/Breadcrumbs'
import ROUTES from 'constants/routes'
import React, { Fragment, useState } from 'react'
import ICONS from 'constants/icons'
import AdciFormSearch from 'screens/common/adci/components/AdciFormSearch'
import AdciPhotoSearch from 'screens/common/adci/components/AdciPhotoSearch'
import ToggleButtons from 'components/ToggleButtons'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router'
import { LogoComponent } from 'components/icons'
import COLORS from 'constants/colors'

const adciImage = require('assets/images/adci.png')

const AdciSearch = ({ publicView = false }) => {
    const t = useTranslate()
    const [isBasicSearch, setIsBasicSearch] = useState(true)

    const toggleButtons = [
        {
            label: 'adci.basicSearch',
            clickHandler: () => {
                setIsBasicSearch(true)
            },
        },
        {
            label: 'adci.imageSearch',
            clickHandler: () => {
                setIsBasicSearch(false)
            },
        },
    ]

    const breadcrumbs = [
        {
            label: 'general.homepage',
            to: ROUTES.HOME,
            icon: ICONS.HOMEPAGE_BREADCRUMB,
        },
    ]

    return (
        <Fragment>
            {!publicView && (
                <div className="m-boxes__breadcrumbs">
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                </div>
            )}
            <div className={`m-boxes__main ${!publicView ? '-fullWidth' : ''}`}>
                <div className="_wr">
                    <div className="_w justify-center m-adciContainer">
                        <div className="aligned-center column -mt50 m-adciContainer__wrapper _12">
                            <img
                                className="adciImage"
                                src={adciImage}
                                alt="adci"
                            />
                            <h3 className="-mt20 -mb20">
                                {t('adci.checkCertificates')}
                            </h3>
                            <div className="-mb20">
                                <ToggleButtons buttons={toggleButtons} />
                            </div>
                            {isBasicSearch ? (
                                <AdciFormSearch />
                            ) : (
                                <AdciPhotoSearch />
                            )}
                            <div className="column aligned-center -mt20 -mb50">
                                <p className="a-bodyTextRegular a-italicText -mb5">
                                    {t('general.poweredBy')}
                                </p>
                                <Link
                                    to="https://skillndepth.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <LogoComponent
                                        color1={COLORS.DARK_BLUE}
                                        color2={COLORS.LIGHT_BLUE}
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AdciSearch
