const profileImageGuidelines = [
    {
        title: 'general.exampleOfAcceptablePhoto',
        correct: true,
        examples: [
            {
                title: 'general.correct',
                description: 'general.acceptablePhoto',
                image: 'profileImageGuidelines1',
            },
        ],
    },
    {
        title: 'general.exampleOfInappropriatePhoto',
        correct: false,
        examples: [
            {
                title: 'general.tooClose',
                description: 'general.tooCloseDescription',
                image: 'profileImageGuidelines2',
            },
            {
                title: 'general.tooFarAway',
                description: 'general.tooFarAwayDescription',
                image: 'profileImageGuidelines3',
            },
            {
                title: 'general.notCentered',
                description: 'general.notCenteredDescription',
                image: 'profileImageGuidelines4',
            },
            {
                title: 'general.poorQuality',
                description: 'general.poorQualityDescription',
                image: 'profileImageGuidelines5',
            },
            {
                title: 'general.blockedFace',
                description: 'general.blockedFaceDescription',
                image: 'profileImageGuidelines6',
            },
        ],
    },
]

export { profileImageGuidelines }
