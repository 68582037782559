import React, { useContext } from 'react'
import { Link, useLocation, useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import ROUTES from 'constants/routes'
import { ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import { ACTION } from 'constants/constants'

import Icon from 'components/Icon'

const SideBarItem = ({
    item,
    active,
    setActive,
    setOpen,
    open,
    canLeavePage,
    handleOpenModal,
    fixedSidebar,
}) => {
    const {
        key,
        label,
        mainLabel,
        to,
        icon,
        subitems,
        className,
        mainClassName = 'm-sidebar',
        notAvailableAtTheMoment = false,
    } = item

    const t = useTranslate()
    const { pathname, search } = useLocation()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)

    const handleClick = (e, to) => {
        e.stopPropagation()
        e.preventDefault()
        navigate(to)
        if (!fixedSidebar && setOpen) {
            setOpen(!open)
        }
    }

    const handleSetActive = () => {
        if (notAvailableAtTheMoment) {
            setAlert(t('alerts.messages.notAvailable'))
        } else {
            setActive(subitems && active?.key === key ? null : item)
        }

        if (!fixedSidebar && !subitems && setOpen) {
            setOpen(!open)
        }
    }

    const getIsActive = (path) => {
        if (pathname === ROUTES.HOME) return path === ROUTES.HOME
        if (pathname === ROUTES.SETTINGS) return path === ROUTES.SETTINGS
        if (path === ROUTES.HOME) {
            return pathname === ROUTES.HOME
        }
        return pathname
            .split('/')
            .some((item) => item === path?.replaceAll('/', ''))
    }

    const navigateLink = subitems ? `${pathname}${search}` : to

    if (mainLabel) {
        return (
            <div className="m-sidebar__itemContainer">
                <span className="m-sidebar__item -mainLabel a-bodyTextMedium a-lightText -opacity-60">
                    {t(label)}
                </span>
            </div>
        )
    }

    return (
        <Link
            className={`${mainClassName}__itemContainer a-bodyTextMedium ${
                active?.key === key ? ' -active' : ''
            } ${subitems ? '-withSubItems' : ''}`}
            to={canLeavePage ? navigateLink : undefined}
            onClick={() => {
                handleSetActive()
                !canLeavePage && handleOpenModal(ACTION.NAVIGATE, navigateLink)
            }}
        >
            <div
                className={`${mainClassName}__item ${
                    getIsActive(to) && !notAvailableAtTheMoment
                        ? ' -active'
                        : ''
                } ${className || ''}`}
                title={t(label)}
            >
                {icon && (
                    <Icon
                        name={icon}
                        color={COLORS.LIGHT_BLUE}
                        size={ICON_SIZE.SIZE20}
                    />
                )}
                <span
                    className={`${mainClassName}__itemLabel a-bodyTextMedium`}
                >
                    {t(label)}
                </span>
                {subitems && (
                    <span className={`${mainClassName}__subItemsIcon`}></span>
                )}
            </div>
            {subitems && (
                <div className={`${mainClassName}__subitems`}>
                    {subitems.map((item) => (
                        <div
                            className={`${mainClassName}__subitem ${
                                getIsActive(item.to) ? ' -active' : ''
                            }`}
                            key={item.key}
                            onClick={(e) => handleClick(e, item.to)}
                            title={t(item.label)}
                        >
                            {item.icon && (
                                <Icon
                                    name={item.icon}
                                    color={COLORS.DARK_BLUE}
                                    size={ICON_SIZE.SIZE24}
                                />
                            )}
                            <span className={`${mainClassName}__subitemLabel`}>
                                {t(item.label)}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </Link>
    )
}

SideBarItem.propTypes = {
    item: PropTypes.shape({
        label: PropTypes.string.isRequired,
        mainLabel: PropTypes.bool,
        to: PropTypes.string,
        icon: PropTypes.string,
        subitems: PropTypes.array,
        className: PropTypes.string,
        mainClassName: PropTypes.string,
        notAvailableAtTheMoment: PropTypes.bool,
    }),
    active: PropTypes.object,
    setActive: PropTypes.func,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    canLeavePage: PropTypes.bool,
    handleOpenModal: PropTypes.func,
    fixedSidebar: PropTypes.bool,
}

SideBarItem.defaultProps = {
    canLeavePage: true,
}

export default SideBarItem
