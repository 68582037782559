import React, { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router'

import { editEntityService } from 'services/entity.service'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import usePremiumFeature from 'hooks/usePremiumFeature'

import { DATA_ACCESS_TYPES } from 'constants/constants'
import {
    ACCESS_CONTROL_STATUSES,
    ALERT_TYPES,
    SELECT_VALUE_TYPE,
    SORT_TYPES,
} from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { FEATURES } from 'constants/features'

import RadioGroup from 'components/RadioGroup'
import { Table } from 'components/table'
import StatusCell from 'components/table/tableCells/StatusCell'
import UserWithRoleCell from 'components/table/tableCells/UserWithRoleCell'
import AccessControlActions from './AccessControlActions'
import NoData from 'components/NoData'
import Icon from 'components/Icon'
import Button from 'components/Button'
import ROUTES from 'constants/routes'
import COLORS from 'constants/colors'
import DateCell from 'components/table/tableCells/DateCell'

const TABLE_HEADER = [
    {
        key: 'requester',
        title: 'table.header.user',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        sortable: true,
        CellComponent: UserWithRoleCell,
    },
    {
        key: 'status',
        title: 'form.label.status',
        showOnSmallScreens: true,
        smallScreenHeader: true,
        sortable: true,
        statuses: ACCESS_CONTROL_STATUSES,
        CellComponent: StatusCell,
    },
    {
        key: 'dateRequestSent',
        title: 'form.label.requestSentAt',
        showOnSmallScreens: true,
        smallScreenHeader: true,
        sortable: false,
        CellComponent: DateCell,
    },
    {
        key: 'dateRequestApproved',
        title: 'form.label.requestStatusChangedAt',
        showOnSmallScreens: true,
        smallScreenHeader: true,
        sortable: false,
        CellComponent: DateCell,
    },
]

const DataAccessControl = ({
    module,
    accessTypeLabel,
    dataAccessAttribute,
    title,
    noFiles,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { search } = useLocation()

    const { hasFeatureAccess } = usePremiumFeature()
    const hasDataAccess = hasFeatureAccess(FEATURES.DATA_ACCESS)

    const { currentUser, userId, fetchCurrentUser } = useContext(
        CurrentUserContext
    )
    const { setAlert } = useContext(AlertContext)

    const [isPublicAccess, setIsPublicAccess] = useState(
        currentUser.userSetting[dataAccessAttribute]
    )

    const { data, meta, isLoading, fetchData } = useFetchDataByQueryParams(
        ENTITIES.DATA_ACCESS_REQUEST,
        {
            include: 'requester,requester.company,target',
            'target.id': userId,
            module,
            sort: 'status',
        }
    )

    const filters = { name: { type: 'text' } }

    const tableData =
        data?.map((item) => ({
            ...item,
            status:
                noFiles &&
                item.status === ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id
                    ? ACCESS_CONTROL_STATUSES.GRANTED.id
                    : item.status,
        })) || []

    const handleChangeAccess = async (value) => {
        try {
            setIsPublicAccess(value)
            await editEntityService(
                ENTITIES.USER_SETTING,
                currentUser.userSetting.id,
                { [dataAccessAttribute]: value }
            )
            fetchCurrentUser()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }
    const upgradeToPro = () => {
        navigate(ROUTES.PACKAGES)
    }

    const dataAccessOptions = DATA_ACCESS_TYPES.map((item) => ({
        ...item,
        disabled: !hasDataAccess && item.name === 'Public access',
        tooltip:
            !hasDataAccess && item.name === 'Public access'
                ? t('general.featureForProUsers')
                : '',
    }))

    return (
        <Fragment>
            <span className="-mb20 a-bodyTextMedium">
                {t(`${title ? title : 'general.dataAccessControl'}`)}
            </span>
            <div className="aligned-start">
                <RadioGroup
                    name="isPublicAccess"
                    label={accessTypeLabel}
                    defaultOptions={dataAccessOptions}
                    valueType={SELECT_VALUE_TYPE.STRING}
                    value={isPublicAccess}
                    setValue={handleChangeAccess}
                />
            </div>
            <span className="a-captionsTextRegular a-lightText -mt5">
                {t(
                    isPublicAccess
                        ? 'general.publicAddressDescription'
                        : 'general.selectiveAccessDescription'
                )}
            </span>
            <div className="m-dataAccessControl__titleContainer -mt20 -mb15">
                <div>
                    {!isPublicAccess && (
                        <span className="aligned-center a-bodyTextMedium -title -gap10">
                            {!hasDataAccess && (
                                <Icon name={ICONS.PRO} color={COLORS.YELLOW} />
                            )}
                            {t('general.requests')}
                        </span>
                    )}
                    {!hasDataAccess && (
                        <span className="aligned-start a-captionsTextRegular a-lightText -mt10">
                            {t('general.optionsForProVersion')}
                        </span>
                    )}
                </div>
                {!hasDataAccess && (
                    <Button
                        label="button.upgradeToPro"
                        icon={ICONS.PRO}
                        iconColor={COLORS.YELLOW}
                        onClick={upgradeToPro}
                    />
                )}
            </div>
            {data?.length === 0 && !isLoading && !search && (
                <div className="-mt50">
                    <NoData
                        icon={ICONS.NO_DATA}
                        title={t('general.noDataYet')}
                        description={t('general.nothingHereRightNow')}
                    />
                </div>
            )}
            {!isPublicAccess && (search || data?.length > 0) && (
                <Table
                    headerItems={TABLE_HEADER}
                    data={tableData}
                    isLoading={isLoading}
                    totalItems={meta?.totalItems}
                    rowActions={[
                        {
                            ActionComponent: AccessControlActions,
                            fetchData: fetchData,
                            noFiles: noFiles,
                            hasDataAccess: hasDataAccess,
                        },
                    ]}
                    searchable
                    fullWidthTitle
                    defaultSort={{ key: 'status', order: SORT_TYPES.ASC }}
                    filters={filters}
                    showDefaultKey={false}
                />
            )}
        </Fragment>
    )
}

DataAccessControl.propTypes = {
    module: PropTypes.number.isRequired,
    accessTypeLabel: PropTypes.string.isRequired,
    dataAccessAttribute: PropTypes.string.isRequired,
}

export default DataAccessControl
