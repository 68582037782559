import ManagementLayout from '../components/ManagementLayout'
import React, { useContext } from 'react'
import TermsAndConditionsBreadcrumbs from 'screens/admin/TermsAndConditions/components/TermsAndConditionsBreadcrumbs'
import ENTITIES from 'constants/entities'
import FILTERS from 'constants/filters'
import { TABLE_HEADER } from 'screens/admin/TermsAndConditions/util/tableHeader'
import { Table } from 'components/table'
import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import TermsAndConditionFilters from 'screens/admin/TermsAndConditions/components/TermsAndConditionsFilters'
import { formatDateRange } from 'utils/filterButtonFormatters'
import Button from 'components/Button'
import { BASE_URL } from 'utils/axiosClient'
import { getUserToken } from 'services/localStorage.service'
import moment from 'moment'
import { MOMENT_FORMATS } from 'services/moment.service'
import { useTranslate } from 'react-polyglot'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { AlertContext } from 'contexts/AlertContext'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'

const TermsAndConditions = () => {
    const { setAlert } = useContext(AlertContext)
    const token = getUserToken()
    const t = useTranslate()
    const filters = {
        firstName: { type: 'text' },
        lastName: { type: 'text' },
        timestamp: {
            type: 'dateRange',
            formatter: formatDateRange,
        },
        action: {
            displayAttribute: 'name',
            formatter: (val) => {
                const parsedVal = Boolean(parseInt(val))
                return parsedVal ? 'Accepted' : 'Declined'
            },
        },
    }

    const { activeFilters } = useFetchActiveFilters(
        {},
        FILTERS.SUPER_ADMIN_TERMS_AND_CONDITIONS
    )

    const { data: logs, meta, isLoading } = useFetchDataByQueryParams(
        ENTITIES.TERMS_AND_CONDITIONS_LOGS,
        {
            include: 'termsAndConditionsVersion',
        }
    )

    const prepareFilters = () => {
        const filters = { ...activeFilters }
        if (filters.timestamp) {
            filters.timestamp.before = filters.timestamp.before
                ? moment(filters.timestamp.before)
                      .endOf('day')
                      .format(MOMENT_FORMATS.DATE_FULL_TIMEZONE)
                : null
            filters.timestamp.after = filters.timestamp.after
                ? moment(filters.timestamp.after).format(
                      MOMENT_FORMATS.DATE_FULL_TIMEZONE
                  )
                : null
        }

        return filters
    }

    const downloadTerms = () => {
        if (!logs.length) {
            setAlert(t('message.noLogsForFilters'), ALERT_TYPES.ERROR)
            return
        }
        fetch(
            `${BASE_URL}/api/${ENTITIES.TERMS_AND_CONDITIONS_LOGS_DOWNLOAD}`,
            {
                method: 'POST',
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(prepareFilters()),
            }
        )
            .then(handleResponse)
            .catch((err) => {
                setAlert(t('message.logsDownloadError'), ALERT_TYPES.ERROR)
            })
    }

    const handleResponse = (response) => {
        response.blob().then((data) => {
            const url = URL.createObjectURL(data)
            const a = document.createElement('a')
            a.href = url
            a.download = getFileName()
            document.body.appendChild(a)
            a.click()
            a.remove()
            window.URL.revokeObjectURL(url)
        })
    }

    const getFileName = () => {
        const before =
            activeFilters.timestamp && activeFilters.timestamp.before
                ? '_' +
                  moment(activeFilters.timestamp.before).format(
                      MOMENT_FORMATS.DATE_API
                  )
                : ''
        const after =
            activeFilters.timestamp && activeFilters.timestamp.after
                ? '_' +
                  moment(activeFilters.timestamp.after).format(
                      MOMENT_FORMATS.DATE_API
                  )
                : ''

       let accepted = ''

        switch (activeFilters.action) {
            case '1' :
                accepted = `_${t('general.accepted')}`
                break
            case '0' :
                accepted = `_${t('general.declined')}`
                break
            default:
                accepted = ''
        }

        return `${t(
            'general.termsAndConditionsFileName'
        )}${after}${before}${accepted}.csv`
    }

    return (
        <ManagementLayout BreadcrumbsComponent={TermsAndConditionsBreadcrumbs}>
            <Table
                title="general.termsAndConditions"
                headerItems={TABLE_HEADER}
                data={logs || []}
                totalItems={meta?.totalItems || 0}
                searchable={true}
                searchKey="fullName"
                searchPlaceholder="form.placeholder.searchForLogs"
                isLoading={isLoading}
                fullWidthTitle
                FilterComponent={TermsAndConditionFilters}
                filters={filters}
                filtersKey={FILTERS.SUPER_ADMIN_TERMS_AND_CONDITIONS}
                actions={[
                    {
                        handleAction: downloadTerms,
                        icon: ICONS.DOWNLOAD,
                        label: 'general.downloadLogs',
                        iconColor: COLORS.WHITE,
                        iconSize: ICON_SIZE.SIZE20,
                    },
                ]}
            />
        </ManagementLayout>
    )
}

export default TermsAndConditions
