import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'

import useQueryParams from 'hooks/useQueryParams'

import { getTomorrowDate } from 'services/moment.service'

import Modal from 'components/Modal'
import { DateTimeField, InputField, SelectField } from 'components/formFields'
import { SELECT_VALUE_TYPE } from 'constants/enums'
import { STATUS_OF_TERMS_CONDITIONS_LOGS } from 'constants/constants'

const TermsAndConditionFilters = ({ open, setOpen, activeFilters }) => {
    const { addFilterParams } = useQueryParams()

    const emptyValues = {
        timestamp: { after: null, before: null },
        action: null,
    }

    const initialValues = {
        firstName: activeFilters.firstName ?? emptyValues.firstName,
        lastName: activeFilters.lastName ?? emptyValues.lastName,
        timestamp: activeFilters.timestamp ?? emptyValues.timestamp,
        action: activeFilters.action ?? emptyValues.action,
    }

    const handleFilter = (filters) => {
        addFilterParams(filters)
        setOpen(false)
    }

    const handleReset = (formFields, setFieldValue, setTouched) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, emptyValues[key])
        })
        setTouched({})
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleFilter}>
            {({ values, isSubmitting, setFieldValue, setTouched }) => (
                <Form>
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="general.filterLogs"
                        isSubmitting={isSubmitting}
                        values={values}
                        smallModalWithDropdowns
                        buttons={{
                            middleBtn: {
                                handleClick: () =>
                                    handleReset(
                                        values,
                                        setFieldValue,
                                        setTouched
                                    ),
                            },
                            nextBtn: {
                                label: 'button.filter',
                            },
                        }}
                    >
                        <div className="_wr">
                            <div className="_w">
                                <div className="_12 _m6">
                                    <InputField name="firstName" />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="lastName" />
                                </div>
                                <div className="_12 _m6">
                                    <DateTimeField
                                        name="timestamp.after"
                                        label="form.label.from"
                                        maxDate={
                                            values.timestamp.before
                                                ? new Date(
                                                      values.timestamp.before
                                                  )
                                                : null
                                        }
                                    />
                                </div>
                                <div className="_12 _m6 ">
                                    <DateTimeField
                                        name="timestamp.before"
                                        label="form.label.to"
                                        minDate={
                                            values.timestamp.after
                                                ? getTomorrowDate(
                                                      values.timestamp.after
                                                  )
                                                : null
                                        }
                                    />
                                </div>
                                <div className="_12 -mb10">
                                    <SelectField
                                        name="action"
                                        label={'form.label.logsStatus'}
                                        placeholder={
                                            'form.placeholder.logsStatus'
                                        }
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                        defaultOptions={
                                            STATUS_OF_TERMS_CONDITIONS_LOGS
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

TermsAndConditionFilters.propTypes = {
    date: PropTypes.object,
}

export default TermsAndConditionFilters
