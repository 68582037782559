import PropTypes from 'prop-types'

import { Link } from 'react-router'

const GalleryImagesPolicy = ({ galleryImagesPolicyObject }) => {
    return (
        <div className="fullWidth column">
            <span className="a-bodyTextMedium">
                {galleryImagesPolicyObject?.mainTitle}
            </span>
            <span className="a-mediumText a-lightText -mt5">
                {galleryImagesPolicyObject.mainDescription}
            </span>
            <div className="column -gap20 -mt20">
                {galleryImagesPolicyObject.sections.map((section, index) => (
                    <div key={index} className="column">
                        <span className="a-mediumTextSemiBold">
                            {section.sectionTitle}{' '}
                            {section.sectionTitleDescription && (
                                <span className="a-lightText">
                                    {section.sectionTitleDescription}
                                </span>
                            )}
                        </span>
                        <span className="a-mediumText -mt5">
                            {section.descriptionBoldText && (
                                <span className="a-mediumTextSemiBold">
                                    {section.descriptionBoldText}{' '}
                                </span>
                            )}
                            <span className="a-lightText">
                                {section.description}{' '}
                                {section.sectionLink && (
                                    <Link
                                        to={section.sectionLink}
                                        target="blank"
                                    >
                                        {section.sectionLinkText}
                                    </Link>
                                )}
                            </span>
                        </span>
                        {section.sectionList?.map((list, index) => (
                            <li className="a-mediumText -liDisc " key={index}>
                                <span className="a-mediumTextSemiBold">
                                    {list.boldText}{' '}
                                </span>
                                <span className="a-lightText">{list.text}</span>
                            </li>
                        ))}
                        <ol type="1">
                            {section.subSections?.map((subSection, index) => (
                                <div className="-mt5 column" key={index}>
                                    <li
                                        className={`a-mediumTextSemiBold ${
                                            section.numberedSections
                                                ? '-liNumber'
                                                : ''
                                        }`}
                                    >
                                        {subSection.title}
                                    </li>
                                    <ul>
                                        {subSection.description.map(
                                            (description, index) => (
                                                <li
                                                    className="-mt5 a-mediumText a-lightText -liDisc -withBiggerLeftSpace"
                                                    key={index}
                                                >
                                                    {description}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            ))}
                        </ol>
                    </div>
                ))}
            </div>
        </div>
    )
}

GalleryImagesPolicy.propTypes = {
    galleryImagesPolicyObject: PropTypes.object,
}

export default GalleryImagesPolicy
