import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { DIVE_RECORD_STEPS } from 'screens/diver/diveRecord/constants/diveRecordSteps'
import { TAB } from 'screens/diver/profile/diverInformation/constants/tabs'

export const NOTIFICATION_LEVEL = {
    INFO: 1,
    WARNING: 2,
    DANGER: 3,
}

export const NOTIFICATION_TYPE = {
    POSITION_INVITE_SENT_TO_DIVER: 1,
    POSITION_INVITE_ACCEPTED: 2,
    POSITION_INVITE_REJECTED: 3,
    POSITION_APPLICATION_SUBMITTED_BY_DIVER: 4,
    POSITION_APPLICATION_ACCEPTED: 5,
    POSITION_APPLICATION_REJECTED: 6,
    MEDICAL_RECORD_EXPIRING: 7,
    MEDICAL_RECORD_EXPIRED: 8,
    TRAVEL_DOCUMENT_EXPIRING: 9,
    TRAVEL_DOCUMENT_EXPIRED: 10,
    CERTIFICATE_EXPIRING: 11,
    CERTIFICATE_EXPIRED: 12,
    DATA_ACCESS_REQUEST_RECEIVED: 13,
    DIVE_INVITE_EMPLOYED: 14,
    DIVE_INVITE_CANDIDATE_STATUS_CHANGE: 15,
    DIVE_RECORD_SUPERVISOR_REQUEST: 16,
    DIVE_RECORD_SUPERVISOR_ACCEPTED: 17,
    DIVE_RECORD_SUPERVISOR_REJECTED: 18,
    DIVE_INVITE_REAPPLIED: 19,
    DIVE_INVITE_CANCELED: 20,
    DIVE_INVITE_WITHDRAWN: 21,
    DIVER_REMOVED_FROM_POSITION: 22,
    NEW_PROJECT_ASSOCIATED: 30,
    DATA_ACCESS_NEW_REQUEST: 31,
    DATA_ACCESS_REQUESTED_AGAIN: 32,
    DATA_ACCESS_GRANTED_WITH_FILES: 33,
    DATA_ACCESS_GRANTED_WITHOUT_FILES: 34,
    DATA_ACCESS_REJECTED: 35,
    DATA_ACCESS_REMOVED: 36,
    DATA_ACCESS_CHANGED_WITH_FILES: 37,
    DATA_ACCESS_CHANGED_WITHOUT_FILES: 38,
    DATA_ACCESS_GRANTED: 39,
    DATA_ACCESS_CHANGED: 40,
    COMPANY_NEW_INVITE: 42,
    CERTIFICATE_VERIFICATION_SUCCESSFUL: 43,
    CERTIFICATE_VERIFICATION_FAILED: 44,
    PAYMENT_INFO: 45,
    DATA_ACCESS_GRANTED_WITH_FILES_JOB_APPLICATION: 46,
    DATA_ACCESS_GRANTED_WITHOUT_FILES_JOB_APPLICATION: 47,
    USER_ACCEPTED_COMPANY_INVITE: 48,
    USER_REJECTED_COMPANY_INVITE: 49,
}

export const renderNotificationIcon = (notificationLevel) => {
    switch (notificationLevel) {
        case NOTIFICATION_LEVEL.DANGER:
            return ICONS.NOTIFICATION_DANGER
        case NOTIFICATION_LEVEL.WARNING:
            return ICONS.ALERT_WARNING
        default:
            return ICONS.ALERT_INFO
    }
}

export const notificationNavigation = (
    entityId,
    notificationType,
    userId,
    positionId,
    additionalData
) => {
    switch (notificationType) {
        case NOTIFICATION_TYPE.MEDICAL_RECORD_EXPIRED:
        case NOTIFICATION_TYPE.MEDICAL_RECORD_EXPIRING:
            return `${ROUTES.DIVER_PROFILE}/${userId}?tab=${TAB.MEDICAL_INFO}`
        case NOTIFICATION_TYPE.TRAVEL_DOCUMENT_EXPIRED:
        case NOTIFICATION_TYPE.TRAVEL_DOCUMENT_EXPIRING:
            return `${ROUTES.DIVER_PROFILE}/${userId}?tab=${TAB.TRAVEL_DOCUMENTATION}`
        case NOTIFICATION_TYPE.CERTIFICATE_EXPIRED:
        case NOTIFICATION_TYPE.CERTIFICATE_EXPIRING:
        case NOTIFICATION_TYPE.CERTIFICATE_VERIFICATION_SUCCESSFUL:
        case NOTIFICATION_TYPE.CERTIFICATE_VERIFICATION_FAILED:
            return `${ROUTES.DIVER_PROFILE}/${userId}?tab=${TAB.TRAINING_RECORD}&subTab=${additionalData.subModule}`
        case NOTIFICATION_TYPE.POSITION_APPLICATION_SUBMITTED_BY_DIVER:
            return `${ROUTES.PROJECT}/${entityId}${ROUTES.DIVE_TEAM}?projectPosition=${positionId}&status=2`
        case NOTIFICATION_TYPE.POSITION_INVITE_ACCEPTED:
            return `${ROUTES.PROJECT}/${entityId}${ROUTES.DIVE_TEAM}?projectPosition=${positionId}&status=4`
        case NOTIFICATION_TYPE.POSITION_INVITE_REJECTED:
            return `${ROUTES.PROJECT}/${entityId}${ROUTES.DIVE_TEAM}?projectPosition=${positionId}&status=3`
        case NOTIFICATION_TYPE.DATA_ACCESS_REQUEST_RECEIVED:
            return `${ROUTES.SETTINGS}/${
                ROUTES.DATA_ACCESS_CONTROL
            }/${getDataAccessModulePath(additionalData?.module)}`
        case NOTIFICATION_TYPE.DIVE_RECORD_SUPERVISOR_REQUEST:
            return `/${ROUTES.DIVE_RECORD_SUMMARY}/${entityId}/${DIVE_RECORD_STEPS[0].route}`
        case NOTIFICATION_TYPE.DIVE_RECORD_SUPERVISOR_ACCEPTED:
            return `/${ROUTES.DIVE_RECORDS}/${entityId}`
        case NOTIFICATION_TYPE.DIVE_RECORD_SUPERVISOR_REJECTED:
            return `/${ROUTES.DIVE_RECORD}/${entityId}/${DIVE_RECORD_STEPS[0].route}`
        case NOTIFICATION_TYPE.DATA_ACCESS_NEW_REQUEST:
        case NOTIFICATION_TYPE.DATA_ACCESS_REQUESTED_AGAIN:
            return `${ROUTES.SETTINGS}/${
                ROUTES.DATA_ACCESS_CONTROL
            }/${getDataAccessModulePath(additionalData?.module)}`
        case NOTIFICATION_TYPE.DATA_ACCESS_REJECTED:
        case NOTIFICATION_TYPE.DATA_ACCESS_REMOVED:
        case NOTIFICATION_TYPE.DATA_ACCESS_GRANTED_WITH_FILES:
        case NOTIFICATION_TYPE.DATA_ACCESS_GRANTED_WITHOUT_FILES:
        case NOTIFICATION_TYPE.DATA_ACCESS_CHANGED_WITH_FILES:
        case NOTIFICATION_TYPE.DATA_ACCESS_CHANGED_WITHOUT_FILES:
        case NOTIFICATION_TYPE.DATA_ACCESS_GRANTED:
        case NOTIFICATION_TYPE.DATA_ACCESS_CHANGED:
            return `${ROUTES.DIVER_PROFILE}/${
                additionalData.profileHash
            }?tab=${getProfileModulePath(additionalData?.module)}`
        case NOTIFICATION_TYPE.USER_ACCEPTED_COMPANY_INVITE:
        case NOTIFICATION_TYPE.USER_REJECTED_COMPANY_INVITE:
            return `${ROUTES.DIVER_PROFILE}/${additionalData.profileHash}`
        case NOTIFICATION_TYPE.DATA_ACCESS_GRANTED_WITH_FILES_JOB_APPLICATION:
        case NOTIFICATION_TYPE.DATA_ACCESS_GRANTED_WITHOUT_FILES_JOB_APPLICATION:
            return `${ROUTES.DIVER_PROFILE}/${additionalData.profileHash}`
        case NOTIFICATION_TYPE.PAYMENT_INFO:
            return `${ROUTES.SETTINGS}/billing`
        case NOTIFICATION_TYPE.COMPANY_NEW_INVITE:
            return `${ROUTES.HOME}`
        case NOTIFICATION_TYPE.POSITION_INVITE_SENT_TO_DIVER:
        case NOTIFICATION_TYPE.POSITION_APPLICATION_ACCEPTED:
        case NOTIFICATION_TYPE.POSITION_APPLICATION_REJECTED:
        case NOTIFICATION_TYPE.DIVE_INVITE_EMPLOYED:
        case NOTIFICATION_TYPE.DIVE_INVITE_CANDIDATE_STATUS_CHANGE:
        case NOTIFICATION_TYPE.DIVE_INVITE_REAPPLIED:
        case NOTIFICATION_TYPE.DIVE_INVITE_CANCELED:
        case NOTIFICATION_TYPE.DIVE_INVITE_WITHDRAWN:
        case NOTIFICATION_TYPE.DIVER_REMOVED_FROM_POSITION:
        case NOTIFICATION_TYPE.NEW_PROJECT_ASSOCIATED:
            return `${ROUTES.PROJECT}/${entityId}`
        default:
            return null
    }
}

export const renderImageBoxBadge = (notificationType) => {
    switch (notificationType) {
        case NOTIFICATION_TYPE.POSITION_INVITE_SENT_TO_DIVER:
        case NOTIFICATION_TYPE.POSITION_APPLICATION_SUBMITTED_BY_DIVER:
            return '-applied'
        case NOTIFICATION_TYPE.POSITION_INVITE_ACCEPTED:
            return '-accepted'
        case NOTIFICATION_TYPE.POSITION_INVITE_REJECTED:
            return '-rejected'
        default:
            return '-noBadge'
    }
}

const getDataAccessModulePath = (module) => {
    switch (module) {
        case 1:
            return ROUTES.MEDICAL_INFO
        case 2:
            return ROUTES.TRAVEL_DOCUMENTATION
        case 3:
            return ROUTES.TRAINING_RECORDS
        case 4:
            return ROUTES.PERSONAL_EQUIPMENT
        case 5:
            return ROUTES.PAST_EMPLOYMENT
        case 6:
            return ROUTES.DIVE_RECORD
        case 7:
            return ROUTES.EXPORT_CV
        case 8:
            return ROUTES.DATA_ACCESS_PROJECT
        case 9:
            return ROUTES.CONTACT_INFO
        default:
            return ROUTES.PAST_EMPLOYMENT
    }
}

const getProfileModulePath = (module) => {
    switch (module) {
        case 1:
            return TAB.MEDICAL_INFO
        case 2:
            return TAB.TRAVEL_DOCUMENTATION
        case 3:
            return TAB.TRAINING_RECORD
        case 4:
            return TAB.PERSONAL_EQUIPMENT
        case 5:
            return TAB.PAST_EMPLOYMENT
        case 6:
            return TAB.DIVE_RECORDS
        case 7:
            return TAB.EXPORT_CV
        case 8:
            return TAB.DIVE_PROJECTS
        case 9:
            return TAB.PERSONAL_INFO
        default:
            return TAB.PAST_EMPLOYMENT
    }
}

export const renderCustomNotificationIcon = (notificationType) => {
    switch (notificationType) {
        case NOTIFICATION_TYPE.CERTIFICATE_VERIFICATION_SUCCESSFUL:
            return { className: '-accepted', icon: ICONS.APPLICATION_ACCEPTED }
        case NOTIFICATION_TYPE.CERTIFICATE_VERIFICATION_FAILED:
            return { className: '-rejected', icon: ICONS.APPLICATION_REJECTED }
        case NOTIFICATION_TYPE.POSITION_APPLICATION_ACCEPTED:
        case NOTIFICATION_TYPE.DIVE_INVITE_CANDIDATE_STATUS_CHANGE:
            return { className: '-warning', icon: ICONS.APPLICATION_CANDIDATE }
        case NOTIFICATION_TYPE.POSITION_APPLICATION_REJECTED:
        case NOTIFICATION_TYPE.DIVE_INVITE_CANCELED:
        case NOTIFICATION_TYPE.DIVER_REMOVED_FROM_POSITION:
            return { className: '-rejected', icon: ICONS.APPLICATION_REJECTED }
        case NOTIFICATION_TYPE.DIVE_INVITE_EMPLOYED:
        case NOTIFICATION_TYPE.NEW_PROJECT_ASSOCIATED:
            return { className: '-accepted', icon: ICONS.APPLICATION_ACCEPTED }
        default:
            return { className: '-noBcg', icon: ICONS.APPLICATION_ACCEPTED }
    }
}

export const setImageBoxClassName = (notificationLevel, notificationType) => {
    switch (notificationLevel) {
        case NOTIFICATION_LEVEL.INFO: {
            return renderCustomNotificationIcon(notificationType).className
        }
        case NOTIFICATION_LEVEL.WARNING:
            return '-warning'
        default:
            return '-danger'
    }
}
