import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import { formatDate } from 'services/moment.service'

import { AlertContext } from 'contexts/AlertContext'

import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import { ALERT_TYPES } from 'constants/enums'

import CardHeader from 'components/card/CardHeader'
import Info from 'components/Info'
import InfoFiles from 'components/InfoFiles'

const RecordCard = ({
    record,
    index,
    records,
    setRecords,
    handleShowRecordForm,
    setEditRecord,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const {
        nameOfShip,
        shipsPort,
        shipsOfficialNumber,
        shipsGrossTonnage,
        dateOfEngagement,
        placeOfDischarge,
        rank,
        position,
        descriptionOfVoyage,
        nameOfMaster,
        files,
    } = record

    const handleEdit = () => {
        handleShowRecordForm()
        setEditRecord(record)
    }

    const handleDelete = async (index) => {
        try {
            const recordsCopy = [...records]
            recordsCopy.splice(index, 1)
            setRecords(recordsCopy)
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    return (
        <div>
            <CardHeader
                title={`${t('general.record')} ${index + 1}`}
                actions={[
                    {
                        handleAction: handleEdit,
                        icon: ICONS.EDIT,
                        iconColor: COLORS.DARK_BLUE,
                        label: 'button.editRecord',
                    },
                    {
                        handleAction: () => handleDelete(index),
                        icon: ICONS.DELETE,
                        iconColor: COLORS.RED,
                        label: 'button.deleteRecord',
                        title: 'general.deleteRecordInformation',
                        confirmationMessage:
                            'message.areYouSureDeleteRecordInfo',
                        textColor: '-redText',
                    },
                ]}
            />
            <Info label={'form.label.nameOfShip'} value={nameOfShip} />
            <Info label={'form.label.shipsPort'} value={shipsPort} />

            <Info
                label={'form.label.shipsOfficialNumber'}
                value={shipsOfficialNumber}
            />
            <Info
                label={'form.label.shipsGrossTonnageShort'}
                value={shipsGrossTonnage}
            />
            <Info
                label={'form.label.dateOfEngagement'}
                value={formatDate(dateOfEngagement)}
                textColor="orange"
            />
            <Info
                label={'form.label.placeOfDischarge'}
                value={placeOfDischarge}
            />
            <Info label={'form.label.rank'} value={rank} />
            <Info label={'form.label.position'} value={position} />
            {descriptionOfVoyage && (
                <Info
                    label={'form.label.descriptionOfVoyage'}
                    value={descriptionOfVoyage}
                />
            )}
            <Info label={'form.label.nameOfMaster'} value={nameOfMaster} />
            <InfoFiles label="general.documentsCopy" files={files} />
        </div>
    )
}

RecordCard.propTypes = {
    record: PropTypes.object,
    index: PropTypes.number,
    handleShowRecordForm: PropTypes.func,
    setRecords: PropTypes.func,
    records: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    setEditRecord: PropTypes.func,
}

export default RecordCard
