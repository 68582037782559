import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { checkExpiryDate, formatDate } from 'services/moment.service'

import {
    INFO_VALUE_TYPE,
    WALLET_CERTIFICATE_GENERATION_STATUSES,
} from 'constants/enums'

import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import InfoFiles from 'components/InfoFiles'

const CertificateCard = ({ item }) => {
    const t = useTranslate()

    const googleAvailable =
        item &&
        item.googlePassCertificate &&
        item.googlePassCertificate.status ===
            WALLET_CERTIFICATE_GENERATION_STATUSES.PASS_GENERATED

    const appleAvailable =
        item &&
        item.applePassCertificate &&
        item.applePassCertificate.applePassStatus ===
            WALLET_CERTIFICATE_GENERATION_STATUSES.PASS_GENERATED

    const walletAvailability = []
    if (googleAvailable) {
        walletAvailability.push('Available for Google Wallet')
    }
    if (appleAvailable) {
        walletAvailability.push('Available for Apple Wallet')
    }

    return (
        <div>
            <Info
                label="form.label.certificateIssuerName"
                value={item.certificateIssuer?.name}
            />
            <InfoRow>
                {item.issuedDate && (
                    <Info
                        label="form.label.issuedDate"
                        value={formatDate(item.issuedDate)}
                    />
                )}
                <Info
                    label="form.label.expiresDate"
                    value={
                        item.notExpire
                            ? t('form.label.notExpire')
                            : formatDate(item.expiresDate)
                    }
                    textColor={
                        item.notExpire
                            ? 'green'
                            : checkExpiryDate(item.expiresDate)
                    }
                />
            </InfoRow>
            {item.certificateNumber && (
                <Info
                    label="form.label.certificateNumber"
                    value={item.certificateNumber}
                />
            )}
            {item.certificateUrl && (
                <Info
                    label="form.label.certificateUrl"
                    value={item.certificateUrl}
                    valueType={INFO_VALUE_TYPE.CERTIFICATE_URL}
                />
            )}
            <InfoFiles
                label="general.trainingCertificateFiles"
                files={item.files}
            />
            {!!walletAvailability.length && (
                <Info value={walletAvailability} color="grayLight" />
            )}
        </div>
    )
}

CertificateCard.propTypes = {
    item: PropTypes.object.isRequired,
}

export default CertificateCard
