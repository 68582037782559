import { CERTIFICATE_VERIFICATION_STATUSES, ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

const { PENDING, NOT_VERIFIED, VERIFIED } = CERTIFICATE_VERIFICATION_STATUSES

const getCertificateStatusInfo = (status, t) => {
    switch (Number(status)) {
        case PENDING:
            return {
                name: t('general.inVerification'),
                color: 'grayDark',
                icon: ICONS.CLOCK_CIRCLE,
                iconColor: COLORS.DARK_BLUE_60,
            }
        case NOT_VERIFIED:
            return {
                name: t('general.unverified'),
                color: 'red',
                icon: ICONS.CLOSE_CIRCLE,
                iconColor: COLORS.RED,
            }
        case VERIFIED:
            return {
                name: t('general.verified'),
                color: 'green',
                icon: ICONS.CHECKMARK_VERIFICATION,
                iconColor: COLORS.GREEN,
            }

        default:
            return null
    }
}

const getCertificateStatus = (status, t) => {
    const statusInfo = getCertificateStatusInfo(status, t)

    return {
        type: 'status',
        size: ICON_SIZE.SIZE16,
        ...statusInfo,
    }
}

const isADCI = (certificateIssuer) => certificateIssuer?.note === 'ADCI'

const canEditCertificate = (isAdci, status) =>
    !isAdci || !status || status === NOT_VERIFIED

const canDeleteCertificate = (isAdci, status) =>
    !isAdci || !status || status !== PENDING

const canViewQRCode = (isAdci, status, hasQr) =>
    isAdci && status === VERIFIED && hasQr

export {
    getCertificateStatusInfo,
    getCertificateStatus,
    isADCI,
    canEditCertificate,
    canDeleteCertificate,
    canViewQRCode,
}
