import { translateRoleOptions } from './translateOptionsHelper'

const formatEntityCreator = (item, t) =>
    item.creator
        ? `${item.creator?.fullName} (${item.creator?.email})`
        : t('general.systemDefault')

const formatEntitySourceText = (item, t) =>
    item.creator ? t('general.userGenerated') : t('general.systemDefault')

const formatUserRole = (item, t) => translateRoleOptions(item.role, t)
const formatUserSubscription = (item, t) => t('billing.subscriptionType.'+item.userSubscription.userSubscriptionType, t)

export {
    formatEntityCreator,
    formatEntitySourceText,
    formatUserRole,
    formatUserSubscription,
}
