import PropTypes from 'prop-types'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'
import Image from 'components/Image'

const CustomUserItem = ({ item }) => {
    return (
        <div className="aligned-center -gap5 a_customOptionItem">
            <div className="a_customOptionItem__imageWrapper aligned-center justify-center">
                {item.id ? (
                    <div className="-image aligned-center justify-center">
                        <Image
                            path={item.avatarPath}
                            alt="user"
                            icon={ICONS.USER}
                            iconSize={ICON_SIZE.SIZE10}
                        />
                    </div>
                ) : (
                    <Icon
                        name={ICONS.MAIL}
                        size={ICON_SIZE.SIZE10}
                        color={COLORS.DARK_BLUE_60}
                    />
                )}
            </div>
            <span className="label a-mediumText">
                {item.id ? item.fullName : item}
            </span>
        </div>
    )
}

CustomUserItem.propTypes = {
    item: PropTypes.PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default CustomUserItem
