
const CURRENCY_SYMBOL_CODE_MAPPING = {
    usd: '$',
    eur: '€',
    chf: 'CHF'
}

const getCurrencySymbolByCode = (code) => {
    return CURRENCY_SYMBOL_CODE_MAPPING[code] ?? '';
}

export default getCurrencySymbolByCode