import React from 'react'
import ReleasePage from '../ReleasePage'

import ICONS from 'constants/icons'

const releaseObject = {
    title: 'Release 1.6.0',
    introText:
        'We’re excited to announce Version 1.6.0, delivering key enhancements to streamline your experience, boost efficiency, and improve usability. Here’s what’s new and improved:',
    items: [
        {
            title: 'Payment: Package selection and billing',
            description:
                'Introducing a seamless package selection and payment process, now available on both web and mobile platforms. The new billing section ensures clarity and ease when managing your subscriptions.',
            icon: ICONS.WALLET,
        },
        {
            title: 'Automation for certification verification',
            description:
                'Divers can now select a certificate issuer from a predefined list, triggering an automated verification process. This ensures a seamless and reliable validation of certifications, with clear verification statuses displayed.',
            icon: ICONS.ZOOM_WITH_CHECK,
        },
        {
            title: 'QR code verification for certificates',
            description:
                'Verified certificates will now include a QR code, allowing divers and third parties to quickly validate certifications digitally. This improves trust and convenience for all stakeholders.',
            icon: ICONS.QR_CODE,
        },
        {
            title: 'Add verified certificates to digital wallet',
            description:
                'Divers can now add their verified certifications to Google Wallet or Apple Wallet directly from the mobile app or email. This makes it easier to access and share certifications anytime, anywhere.',

            icon: ICONS.CARD,
        },
        {
            title: 'Dive records: enhanced organization and filtering',
            description:
                'We’ve improved the organization and filtering options for dive records, empowering you to:',
            icon: ICONS.DIVE_RECORDS,
            descriptionList: [
                {
                    description:
                        'Filter records by status, project, position, or verification level.',
                },
                {
                    description:
                        'Easily track, view, and manage records with enhanced usability.',
                },
            ],
        },
        {
            title: 'Photo guidance for registration',
            description:
                'To ensure a smooth onboarding experience, we’ve added clear guidelines for profile photo uploads. This helps you upload an appropriate image without confusion.',
            icon: ICONS.GALLERY,
        },
        {
            title: 'Project team member visibility',
            description:
                'Visibility of project team members will now depend on the diver’s job application status, ensuring clarity and relevance in team displays.',
            icon: ICONS.USERS,
        },
        {
            title: 'Dive records: feedback and rating privacy',
            description:
                'Divers can now hide feedback and rating data in their dive records after endorsement by a supervisor. This allows you to:',
            icon: ICONS.SHOW_PASSWORD,
            descriptionList: [
                {
                    description: 'Maintain privacy in your personal logbook.',
                },
                {
                    description:
                        'Keep sensitive feedback and ratings confidential.',
                },
            ],
        },
        {
            title: 'Manage user blocking in organizations',
            description:
                'Organizations can now block or unblock user accounts within their team. This provides better control and management of team membership for diving and client organizations.',
            icon: ICONS.BLOCK,
        },
        {
            title: 'Dive training school address auto-population',
            description:
                'When adding professional diver training details, the training school address will now autopopulate after selecting the school. This saves time and ensures data accuracy.',
            icon: ICONS.TRAINING_RECORD,
        },
        {
            title: 'Alphabetical dropdown sorting',
            description:
                'All dropdown field lists are now sorted alphabetically by default, making it easier to find and select options efficiently.',
            icon: ICONS.SORT_ICON,
        },
        {
            title: 'Historical dive record management',
            description:
                'Divers removed from a project can now edit their dive records in historical mode.',
            icon: ICONS.DIVE_RECORDS,
        },
        {
            title: 'Dive record levels: improved showcase',
            description:
                'Hovering over a dive record verification level now reveals:',
            icon: ICONS.VERIFICATION_OUTLINE,
            descriptionList: [
                {
                    description:
                        'A tooltip with the level name, an enlarged image, and a link to detailed information. This improvement offers clearer insights into verification levels.',
                },
            ],
        },
    ],
    upcomingSectionTitle: 'Upcoming features',
    upcomingSectionDescription: 'Here’s what’s on the horizon:',
    upcomingItems: [
        {
            title: 'Diver primary roles:',
            description:
                'Divers will be able to select up to three primary roles from their list of selected roles, ensuring they are displayed prominently on their profile for better visibility and search functionality.',
        },
        {
            title: 'Edit completed dive record:',
            description:
                'Divers will gain the ability to edit dive records marked as "Completed" if they have not yet been verified, allowing them to update or correct information before verification.',
        },
    ],
    outroText:
        'Thank you! These updates are inspired by your feedback and designed to enhance your experience. Thank you for being a part of our community, and we look forward to continuing to make the platform even better!',
}

const Release160 = () => {
    return <ReleasePage releaseObject={releaseObject} />
}

export default Release160
