import React from 'react'
import { useTranslate } from 'react-polyglot'

import { ADMIN_MENU_ITEMS } from 'hooks/useMenuItems'

import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'

import Breadcrumbs from 'components/Breadcrumbs'
import { getEntityFilters } from 'services/localStorage.service'

const EntityBreadcrumbs = ({ create, edit, entityType }) => {
    const t = useTranslate()

    const sidebarGroup = ADMIN_MENU_ITEMS.find((item) =>
        item.subitems?.some((el) => el.key === entityType)
    )

    const sidebarItem = sidebarGroup?.subitems?.find(
        (item) => item.key === entityType
    )

    return (
        <Breadcrumbs
            breadcrumbs={[
                {
                    label: 'menuItems.listManagement',
                    icon: ICONS.HOMEPAGE_BREADCRUMB,
                    to: `/${sidebarItem?.to}`,
                    queryParams: getEntityFilters(
                        `${entityType}_management_filters`
                    ),
                },
                {
                    label: sidebarGroup?.label,
                    to: `/${sidebarItem?.to}`,
                    queryParams: getEntityFilters(
                        `${entityType}_management_filters`
                    ),
                },
                {
                    label: `entityManagement.${entityType}`,
                    to: `/${sidebarItem?.to}`,
                    queryParams: getEntityFilters(
                        `${entityType}_management_filters`
                    ),
                },
                create && {
                    label: 'button.createNew',
                    to: `/${sidebarItem?.to}${ROUTES.CREATE}`,
                },
                edit && {
                    label: edit.name,
                    to: `/${sidebarItem?.to}${ROUTES.EDIT}/${edit.id}`,
                    translate: false,
                },
            ].filter((item) => item)}
        />
    )
}

export default EntityBreadcrumbs
