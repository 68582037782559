import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import ENTITIES from 'constants/entities'

import useFetchData from 'hooks/useFetchData'

import AddNew from '../../../DiverOnboarding/AddNew'
import SurfaceDecoEventList from './SurfaceDecoEventList'
import SurfaceDecoEventFormModal from './SurfaceDecoEventFormModal'
import { isChamberPressurizationOnly } from 'utils/diveRecordFunctions'

const SurfaceDecoEventWrapper = ({
    diveRecordDiveDataId,
    hideActions,
    unitImperial,
    divingMode,
    fetchDiveRecord,
    diveRecordDiveData,
}) => {
    const t = useTranslate()
    const { diveRecordId } = useParams()

    const [create, setCreate] = useState(false)
    const [editItem, setEditItem] = useState(null)

    const {
        data: surfaceDecoEventData,
        isLoading,
        fetchData: fetchDiveData,
    } = useFetchData(
        ENTITIES.SURFACE_DECOMPRESSION_EVENT,
        {
            'diveRecordDiveData.id': diveRecordDiveDataId,
            include: 'gasContents',
        },
        !!diveRecordId,
        false
    )

    const data = diveRecordId
        ? surfaceDecoEventData
        : diveRecordDiveData.surfaceDecompressionEvents

    const fetchData = () => {
        fetchDiveData()
        fetchDiveRecord()
    }

    const isChamber = isChamberPressurizationOnly(divingMode)

    const messages = {
        title: t(
            isChamber
                ? 'chamberRecompressionOnlyEvent.title'
                : 'general.surfaceDecompressionEvent'
        ),
        deleteAreYouSure: isChamber
            ? 'chamberRecompressionOnlyEvent.deleteAreYouSure'
            : 'message.areYouSureDeleteSurfaceDecompressionEvent',
        delete: isChamber
            ? 'chamberRecompressionOnlyEvent.delete'
            : 'button.deleteSurfaceDecompressionEvent',
        view: isChamber
            ? 'chamberRecompressionOnlyEvent.view'
            : 'button.viewFullSurfaceDecompressionEvent',
        add: t(
            isChamber
                ? 'chamberRecompressionOnlyEvent.add'
                : 'general.pleaseAddSurfaceDecompressionEvent'
        ),
        pleaseAdd: isChamber
            ? 'chamberRecompressionOnlyEvent.pleaseAdd'
            : 'general.addSurfaceDecompressionEvent',
    }

    if (hideActions)
        return (
            <SurfaceDecoEventList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                setEditItem={setEditItem}
                hideActions={hideActions}
                unitImperial={unitImperial}
                messages={messages}
            />
        )

    return (
        <div className="-contentElements">
            <h2 className="-mt35 _12 a-centeredText">{messages.title}</h2>

            <p className="a-bodyTextRegular -mt20 _12 a-centeredText">
                {messages.add}
            </p>

            <div className="_12">
                <AddNew
                    label={messages.pleaseAdd}
                    handleAction={() => setCreate(true)}
                />
            </div>

            <SurfaceDecoEventList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                setEditItem={setEditItem}
                unitImperial={unitImperial}
                messages={messages}
            />

            {create && (
                <SurfaceDecoEventFormModal
                    closeModal={() => setCreate(false)}
                    fetchData={fetchData}
                    isChamberPressurizationOnly={isChamber}
                    messages={messages}
                />
            )}

            {!!editItem && (
                <SurfaceDecoEventFormModal
                    closeModal={() => setEditItem(null)}
                    initialData={editItem}
                    fetchData={fetchData}
                    isChamberPressurizationOnly={isChamber}
                    messages={messages}
                />
            )}
        </div>
    )
}

SurfaceDecoEventWrapper.propTypes = {
    diveRecordDiveDataId: PropTypes.number.isRequired,
    hideActions: PropTypes.bool,
    unitImperial: PropTypes.bool,
    fetchDiveRecord: PropTypes.func,
}

export default SurfaceDecoEventWrapper
