import { ALERT_TYPES } from 'constants/enums'

export const formatAppErrorCodeError = (error, setAlert, t) => {
    if (error.response?.data?.appErrorCode) {
        setAlert(
            {
                ...error,
                customTitle: `${error.response.data.message}Title`,
            },
            ALERT_TYPES.ERROR,
            t
        )
    } else {
        setAlert(error, ALERT_TYPES.ERROR)
    }
}