import { useLocation, useNavigate, useParams } from 'react-router'
import { useTranslate } from 'react-polyglot'
import { useContext } from 'react'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useFetchDataById from 'hooks/useFetchDataById'
import useWindowDimensions from 'hooks/useWindowDimension'

import { editEntityService } from 'services/entity.service'
import { getProjectBreadcrumbs } from '../utils/getProjectBreadcrumbs'

import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'

import Loader from 'components/Loader'
import ProjectForm from './components/ProjectForm'
import Breadcrumbs from 'components/Breadcrumbs'

const EditProject = () => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { isTablet } = useWindowDimensions()

    const { id } = useParams()

    const { currentUser } = useContext(CurrentUserContext)

    const { data, isLoading } = useFetchDataById(ENTITIES.PROJECT, id, {
        include:
            'country,company,region,locationType,industry,projectIndustryType,divingMode,countryOfMobilization,projectStatus,bodyOfWater,clientOrganizations',
    })

    const handleSubmit = async (newData) => {
        await editEntityService(ENTITIES.PROJECT, id, newData, true, [
            'projectPositions',
        ])
        navigate(`${ROUTES.PROJECT}/${id}`)
    }

    const handleClose = () => {
        navigate(-1)
    }

    if (!data) return null
    if (isLoading) return <Loader />

    if (currentUser.companyId !== data.company.id) {
        navigate(ROUTES.HOME)
    }

    return (
        <div className="m-boxes">
            <div className="m-boxes__breadcrumbs">
                <Breadcrumbs
                    breadcrumbs={getProjectBreadcrumbs(
                        `${ROUTES.EDIT}/${id}`,
                        pathname
                    )}
                />
            </div>
            <div className="m-project m-boxes__main -fullWidth">
                {isTablet && (
                    <span
                        onClick={handleClose}
                        className="removeItemIcon -large"
                    ></span>
                )}
                <div className="_wr">
                    <div className="_w">
                        <h3 className="_12">{t('general.editDiveProject')}</h3>
                    </div>
                </div>
                <ProjectForm
                    handleSubmit={handleSubmit}
                    initialData={data}
                    id={id}
                />
            </div>
        </div>
    )
}

export default EditProject
