import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { ALERT_TYPES } from 'constants/enums'

import NotesAndAlertsIcon from './icons/NotesAndAlertsIcon'

const Note = ({
    note,
    children,
    translate,
    customLabel,
    bottomMargin,
    type = ALERT_TYPES.INFO,
    showTitle = true
}) => {
    const t = useTranslate()

    const label = customLabel ? t(customLabel) : t('notes.note')
    
    return (
        <div
            className={`a-note a-notesAndAlerts -note -${type} fullWidth ${
                bottomMargin ? '-mb10' : ''
            } aligned-center -gap10`}
        >
            <NotesAndAlertsIcon type={type} note={true} />
            <p className="a-note__text a-captionsTextRegular">
                <span className="a-captionsTextSemiBold">
                    {showTitle && label}{' '}
                </span>
                {children || (translate ? t(note) : note)}
            </p>
        </div>
    )
}

Note.propTypes = {
    note: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    translate: PropTypes.bool,
    customLabel: PropTypes.string,
    bottomMargin: PropTypes.bool,
    type: PropTypes.string,
}

Note.defaultProps = {
    note: 'notes.featureUnderDevelopment',
    translate: true,
    customLabel: null,
    bottomMargin: true,
}

export default Note
