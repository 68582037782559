import Stripe from 'components/stripe/Stripe'
import Breadcrumbs from 'components/Breadcrumbs'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'
import React from 'react'
import { getEntityFilters } from 'services/localStorage.service'
import FILTERS from 'constants/filters'

const Subscription = () => {
    return (
        <div className="m-boxes">
            <div className="m-boxes__breadcrumbs">
                <Breadcrumbs
                    breadcrumbs={[
                        {
                            label: 'general.homepage',
                            to: ROUTES.HOME,
                            icon: ICONS.HOMEPAGE_BREADCRUMB,
                            queryParams: getEntityFilters(
                                FILTERS.DIVE_PROJECTS
                            ),
                        },
                        {
                            label: 'general.subscription',
                            to: ROUTES.SUBSCRIPTION
                        },
                    ]}
                />
            </div>
            <div className="m-boxes__main -fullWidth">
                <Stripe />
            </div>
        </div>
    )
}

export default Subscription