import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const ProfileImageSingleExample = ({ example, correct }) => {
    const t = useTranslate()
    return (
        <div className="column aligned-center a-profileImageSingleExample">
            {example.image && (
                <div className="a-profileImageSingleExample__image">
                    <img
                        src={require(`assets/images/${example.image}.png`)}
                        alt={example.title}
                    />
                </div>
            )}
            <div className="aligned-center -gap3 -mt15">
                <Icon
                    name={
                        correct
                            ? ICONS.CHECKMARK_ICON_ROUNDED_FILL
                            : ICONS.CLOSE_CIRCLE
                    }
                    color={correct ? COLORS.GREEN_80 : COLORS.RED_80}
                    size={ICON_SIZE.SIZE20}
                />
                <span className="a-mediumTextSemiBold">{t(example.title)}</span>
            </div>
            <span className="a-mediumText a-lightText a-centeredText -mt5 a-profileImageSingleExample__description">
                {t(example.description)}
            </span>
        </div>
    )
}

ProfileImageSingleExample.propTypes = {
    example: PropTypes.object,
    correct: PropTypes.bool,
}

export default ProfileImageSingleExample
