import React, { Fragment, useContext } from 'react'
import { useParams } from 'react-router'

import { DiveRecordContext } from './contexts/DiveRecordContext'

import ENTITIES from 'constants/entities'

import useFetchDataById from 'hooks/useFetchDataById'

import StepButtons from 'components/StepButtons'
import WorkPerformedSummary from './components/workPerformed/WorkPerformedSummary'

const WorkPerformedVerification = () => {
    const { diveRecordId } = useParams()

    const { diveRecord, goPreviousStep, goToNextStep } = useContext(
        DiveRecordContext
    )

    const { data: workPerformedData } = useFetchDataById(
        ENTITIES.DIVE_RECORD,
        diveRecordId,
        {
            include: [
                'diveRecordWorkPerformed',
                'diveRecordWorkPerformed.tasks',
                'diveRecordWorkPerformed.objects',
                'diveRecordWorkPerformed.activities',
                'diveRecordWorkPerformed.tools',
                'diveRecordWorkPerformed.supervisorTasks',
            ],
        }
    )

    const data = diveRecordId ? workPerformedData : diveRecord.data

    if (!data) return null

    return (
        <Fragment>
            <div className="ofs_l2 _l8 -diveRecordContent">
                <WorkPerformedSummary
                    data={data.diveRecordWorkPerformed}
                    diveRecordId={diveRecordId ? data.id : data._id}
                    verification
                    diveRecordType={data.diveRecordType}
                />
            </div>
            <StepButtons
                handleBack={goPreviousStep}
                handleNext={goToNextStep}
                canSkip={false}
            />
        </Fragment>
    )
}

export default WorkPerformedVerification
