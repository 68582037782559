import { useContext } from 'react'
import { Outlet, useLocation } from 'react-router'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'
import { ROLES } from 'constants/enums'

import Breadcrumbs from 'components/Breadcrumbs'
import TabNavigation from 'components/tabs/TabNavigation'
import Icon from 'components/Icon'

const {
    ROLE_DIVER,
    ROLE_CLIENT_ORGANIZATION_ADMIN,
    ROLE_DIVING_CONTRACTOR,
} = ROLES
const ALL_REAL_USER_ROLES = [
    ROLE_DIVER,
    ROLE_DIVING_CONTRACTOR,
    ROLE_CLIENT_ORGANIZATION_ADMIN,
]

const TABS = [
    {
        key: 'general-preferences',
        title: 'general.generalPreferences',
        to: ROUTES.GENERAL_PREFERENCES,
        icon: ICONS.GENERAL_PREFERENCES,
        roles: ALL_REAL_USER_ROLES,
    },
    {
        key: 'sign-in-and-security',
        title: 'general.signInAndSecurity',
        to: ROUTES.SIGN_IN_AND_SECURITY,
        icon: ICONS.LOCK,
        roles: ALL_REAL_USER_ROLES,
    },
    {
        key: 'data-access-control',
        subItemsTitle: 'general.dataAccessControl',
        to: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.CONTACT_INFO}`,
        icon: ICONS.DATA_ACCESS,
        roles: [ROLE_DIVER],
        subItems: [
            {
                key: 'contact-info',
                label: 'general.contactInfo',
                to: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.CONTACT_INFO}`,
                translate: true,
            },
            {
                key: 'dive-record',
                label: 'general.diveRecords',
                to: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.DIVE_RECORD}`,
                translate: true,
            },
            {
                key: 'projects',
                label: 'general.diveProjects',
                to: `${ROUTES.DATA_ACCESS_CONTROL}${ROUTES.PROJECT}`,
                translate: true,
            },
            {
                key: 'medical-info',
                label: 'general.medicalInfo',
                to: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.MEDICAL_INFO}`,
                translate: true,
            },
            {
                key: 'travel-documentation',
                label: 'general.travelDocumentation',
                to: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.TRAVEL_DOCUMENTATION}`,
                translate: true,
            },
            {
                key: 'training-records',
                label: 'general.educationAndTraining',
                to: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.TRAINING_RECORDS}`,
                translate: true,
            },
            {
                key: 'personal-equipment',
                label: 'general.personalEquipment',
                to: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.PERSONAL_EQUIPMENT}`,
                translate: true,
            },
            {
                key: 'past-employment',
                label: 'general.employmentHistory',
                to: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.PAST_EMPLOYMENT}`,
                translate: true,
            },
            {
                key: 'export-cv',
                label: 'general.exportProfileAsCV',
                to: `${ROUTES.DATA_ACCESS_CONTROL}/${ROUTES.EXPORT_CV}`,
                translate: true,
            },
        ],
    },
    {
        key: 'units-of-measurement',
        title: 'general.unitsAndFormats',
        to: ROUTES.UNITS_OF_MEASUREMENT,
        icon: ICONS.UNITS,
        roles: [ROLE_DIVER],
    },
    {
        key: 'notifications',
        title: 'general.notifications',
        to: ROUTES.NOTIFICATIONS,
        icon: ICONS.NOTIFICATION_BELL,
        roles: [ROLE_DIVER],
    },
    {
        key: 'account-management',
        title: 'general.accountManagement',
        to: ROUTES.ACCOUNT_MANAGEMENT,
        icon: ICONS.ACCOUNT_MANAGEMENT,
        roles: ALL_REAL_USER_ROLES,
    },
    {
        key: 'billing',
        title: 'general.billing',
        to: ROUTES.BILLING,
        icon: ICONS.BILLING,
        roles: [ROLE_DIVER],
    },
    {
        key: 'packages',
        title: 'general.packages',
        to: ROUTES.PACKAGES,
        icon: ICONS.PRO,
        roles: [ROLE_DIVER],
    },
]

const Settings = () => {
    const t = useTranslate()
    const { isSmallerThanXlScreen } = useWindowDimensions()
    const { pathname } = useLocation()

    const { currentUser } = useContext(CurrentUserContext)

    const showDefaultContent = pathname === ROUTES.SETTINGS

    const tabs = TABS.filter((tab) =>
        tab.roles.some((role) => role.name === currentUser?.role?.name)
    )

    return (
        <div className="m-boxes -left -settings">
            <div className="m-boxes__breadcrumbs">
                <Breadcrumbs
                    breadcrumbs={[
                        {
                            label: 'general.settingsAndPrivacy',
                            to: ROUTES.SETTINGS,
                            icon: ICONS.HOMEPAGE_BREADCRUMB,
                            showAlways: true,
                        },
                        {
                            label: 'general.generalPreferences',
                            to: ROUTES.GENERAL_PREFERENCES,
                            showAlways: pathname === ROUTES.GENERAL_PREFERENCES,
                        },
                        {
                            label: 'general.signInAndSecurity',
                            to: ROUTES.SIGN_IN_AND_SECURITY,
                            showAlways:
                                pathname === ROUTES.SIGN_IN_AND_SECURITY,
                        },
                        {
                            label: 'general.dataAccessControl',
                            to: ROUTES.DATA_ACCESS_CONTROL,
                        },
                        {
                            label: 'general.unitsOfMeasurement',
                            to: ROUTES.UNITS_OF_MEASUREMENT,
                            showAlways:
                                pathname === ROUTES.UNITS_OF_MEASUREMENT,
                        },
                        {
                            label: 'general.notifications',
                            to: ROUTES.NOTIFICATIONS,
                            showAlways: pathname === ROUTES.NOTIFICATIONS,
                        },
                        {
                            label: 'general.accountManagement',
                            to: ROUTES.ACCOUNT_MANAGEMENT,
                            showAlways: pathname === ROUTES.ACCOUNT_MANAGEMENT,
                        },
                    ]}
                />
            </div>
            <div
                className={`m-boxes__main -withDefaultContent fullHeightBoxes ${
                    isSmallerThanXlScreen ? '-mt20' : ''
                }`}
            >
                {showDefaultContent && (
                    <div className="-defaultContent column aligned-center justify-center fullHeight">
                        <Icon name={ICONS.SETTINGS_AND_PRIVACY} />
                        <span className="a-bodyTextMedium -mb20 -mt20">
                            {t('general.settingsAndPrivacy')}
                        </span>
                        <p className="a-mediumText centered-text a-lightText -defaultContentDescription">
                            {t('general.settingsAndPrivacyDesc')}
                        </p>
                    </div>
                )}
                <Outlet />
            </div>
            <div className="m-boxes__side column fullHeightBoxes">
                <span className="a-bodyTextMedium -boxTitle -mb10">
                    {t('general.settingsAndPrivacy')}
                </span>
                <TabNavigation tabs={tabs} columnView />
            </div>
        </div>
    )
}

export default Settings
