import React, { useEffect, useMemo, useState } from 'react'

import { ALERT_POSITIONS, ALERT_TYPES } from 'constants/enums'

export const AlertContext = React.createContext()

const ALERT_DURATION = 6000

const alertInitialState = {
    message: '',
    type: ALERT_TYPES.NOTIFICATION,
    position: ALERT_POSITIONS.RIGHT,
}

const AlertContextProvider = (props) => {
    const [alert, setAlert] = useState(alertInitialState)

    useEffect(() => {
        const timer = setTimeout(
            () => setAlert(alertInitialState),
            ALERT_DURATION
        )
        return () => {
            clearTimeout(timer)
        }
    }, [alert.message])

    const handleSetAlert = (
        data,
        type = ALERT_TYPES.NOTIFICATION,
        translate = null,
        position = ALERT_POSITIONS.RIGHT
    ) => {
        setAlert({
            message:
                (data?.response?.data?.message ||
                    data?.response?.data?.transMessage) &&
                translate
                    ? translate(
                          data.response.data.transMessage ||
                              data?.response?.data?.message
                      )
                    : data?.response?.data?.message ||
                      data?.response?.data?.detail ||
                      data?.message ||
                      data,
            title: data?.customTitle,
            type,
            position,
        })
    }

    const contextValue = useMemo(() => ({ alert, setAlert: handleSetAlert }), [
        alert,
    ])

    return (
        <AlertContext.Provider value={contextValue}>
            {props.children}
        </AlertContext.Provider>
    )
}

export default AlertContextProvider
