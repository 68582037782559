import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

import { BASE_URL } from 'utils/axiosClient'

import COLORS from 'constants/colors'
import { BUTTON_SIZE, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import DropdownActions from 'components/DropdownActions'
import Icon from 'components/Icon'
import StatusActions from 'components/status/StatusActions'
import CardInfo from './CardInfo'
import Image from 'components/Image'
import ActionButton from 'components/table/ActionButton'

const CardHeader = ({
    title,
    subtitle,
    item,
    statuses,
    actions,
    statusActionsCombined,
    avatar,
    placeholderIcon,
    verifiedBadge,
    customIcon,
    sidebarCard,
    fullTitle,
    highlightedInfo,
    onlyHeader,
    smallerFontSize,
    baseUrl,
    allowOverflow,
}) => {
    const t = useTranslate()
    const { isMobile } = useWindowDimensions()

    const renderSubtitle = () => {
        return (
            <Fragment>
                {Array.isArray(subtitle) &&
                subtitle?.filter((item) => item).length > 1
                    ? subtitle?.map((item, index) => {
                          return (
                              <span key={index}>
                                  {item}
                                  {index === 0 && (
                                      <span className="separatorDisc">
                                          &#x2022;
                                      </span>
                                  )}
                              </span>
                          )
                      })
                    : subtitle}
            </Fragment>
        )
    }

    const onlySingleAction =
        statuses.length === 0 &&
        highlightedInfo.length === 0 &&
        !statusActionsCombined &&
        actions.length !== 0

    return (
        <div
            className={`a-stepCard__header space-between ${
                !onlyHeader ? '-mb5' : ''
            } ${sidebarCard ? '-headerSidebar' : ''} ${
                onlySingleAction ? '-withOnlySingleAction' : '-gap10 '
            } ${allowOverflow ? '-allowOverflow' : ''}`}
        >
            <div className="a-stepCard__header--left aligned-center -gap10 fullWidth">
                {(avatar || placeholderIcon) && (
                    <div className="-avatarImage">
                        <Image
                            path={avatar}
                            baseUrl={baseUrl}
                            alt="avatar"
                            icon={placeholderIcon || ICONS.USER}
                        />
                    </div>
                )}
                <div
                    className={`${
                        avatar || placeholderIcon ? '-withAvatarImage' : ''
                    }  -textContent aligned-center flex-wrap -gap10`}
                >
                    {customIcon}

                    {title && (
                        <div
                            className={`aligned-center -titleAndVerifiedIcon ${
                                customIcon ? '-withCustomIcon' : ''
                            }`}
                        >
                            <span
                                className={`${
                                    smallerFontSize
                                        ? 'a-mediumTextSemiBold'
                                        : 'a-bodyTextMedium'
                                } -title ${
                                    verifiedBadge ? '-verifiedBadge' : ''
                                } ${fullTitle ? '-fullTitle' : ''}`}
                            >
                                {title}
                            </span>

                            {verifiedBadge && (
                                <span className="verifiedIcon">
                                    <Icon name={ICONS.VERIFICATION_PROFILE} />
                                </span>
                            )}
                        </div>
                    )}
                    {subtitle && (
                        <div className="fullWidth">
                            <span
                                className={`${
                                    smallerFontSize
                                        ? 'a-captionsTextRegular'
                                        : 'a-mediumText'
                                } a-lightText -opacity-60 ellipsis-text`}
                            >
                                {renderSubtitle()}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            {(statuses.length !== 0 ||
                actions?.length !== 0 ||
                highlightedInfo.length !== 0) && (
                <div
                    className={`a-stepCard__header--right ${
                        isMobile ? 'aligned-start' : 'aligned-center'
                    } -gap10`}
                >
                    <div className="-status aligned-center -gap10">
                        {!statusActionsCombined &&
                            statuses.map(
                                (
                                    { name, icon, color, iconColor, size },
                                    index
                                ) =>
                                    !!name &&
                                    name[0] && (
                                        <div
                                            key={index}
                                            className={`a-status 
                                                a-captionsTextRegular
                                            }  ${
                                                color ? `-${color}` : ''
                                            } aligned-center -gap5`}
                                        >
                                            {icon && (
                                                <Icon
                                                    name={icon}
                                                    color={iconColor}
                                                    size={size}
                                                />
                                            )}
                                            {name && <span>{name}</span>}
                                        </div>
                                    )
                            )}
                        {highlightedInfo.map(({ info, infoText }, index) => (
                            <CardInfo key={index} title={infoText}>
                                {info}
                            </CardInfo>
                        ))}
                    </div>
                    {!!actions?.length && (
                        <div className="-action aligned-center -gap10">
                            {statusActionsCombined && actions?.length > 1 && (
                                <StatusActions
                                    actions={actions}
                                    statuses={statuses}
                                    item={item}
                                />
                            )}
                            {!statusActionsCombined &&
                                actions?.length === 1 && (
                                    <ActionButton
                                        {...actions[0]}
                                        item={item}
                                        iconSize={ICON_SIZE.SIZE20}
                                        buttonSize={BUTTON_SIZE.XSMALL}
                                        tooltip={actions[0].label}
                                        label={null}
                                    />
                                )}

                            {!statusActionsCombined && actions?.length > 1 && (
                                <DropdownActions
                                    actions={actions}
                                    item={item}
                                />
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

CardHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
    ]),
    statuses: PropTypes.array,
    actions: PropTypes.array,
    item: PropTypes.object,
    avatar: PropTypes.string,
    placeholderIcon: PropTypes.string,
    verifiedBadge: PropTypes.bool,
    customIcon: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    sidebarCard: PropTypes.bool,
    fullTitle: PropTypes.bool,
    highlightedInfo: PropTypes.array,
    onlyHeader: PropTypes.bool,
    smallerFontSize: PropTypes.bool,
    baseUrl: PropTypes.string,
    allowOverflow: PropTypes.bool,
    statusActionsCombined: PropTypes.bool,
}

CardHeader.defaultProps = {
    statuses: [],
    actions: [],
    item: {},
    verifiedBadge: false,
    sidebarCard: false,
    fullTitle: false,
    highlightedInfo: [],
    onlyHeader: false,
    smallerFontSize: false,
    baseUrl: BASE_URL,
    allowOverflow: false,
}

export default CardHeader
