import { useNavigate } from 'react-router'
import { Formik } from 'formik'

import { useContext, useEffect, useState } from 'react'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ContractorContext } from './context/ContractorContext'
import { AlertContext } from 'contexts/AlertContext'

import useQueryParams from 'hooks/useQueryParams'
import useWindowDimensions from 'hooks/useWindowDimension'
import useFetchFile from 'hooks/useFetchFile'

import { editEntityService } from 'services/entity.service'

import ICONS from 'constants/icons'
import { TAB } from './companyInformation/constants/tabs'

import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_SIZE,
} from 'constants/enums'
import { FILE_CATEGORIES } from 'constants/fileCategories'

import { ProjectList } from 'components/projects'
import { CompanyInformation } from './companyInformation'
import Loader from 'components/Loader'
import AboutCompany from './companyInformation/components/AboutCompany'
import FavoriteUsers from './companyInformation/components/FavoriteUsers'
import { CoverImageUploadFields } from 'components/formFields'
import Button from 'components/Button'
import ROUTES from 'constants/routes'
import { GalleryPage } from 'screens/common/gallery'
import UserManagement from './companyInformation/components/UserManagement'

const Profile = () => {
    const { isSmallScreen } = useWindowDimensions()
    const navigate = useNavigate()
    const { tab } = useQueryParams()

    const { setAlert } = useContext(AlertContext)

    const { company, isLoadingContractor, error, fetchContractor } = useContext(
        ContractorContext
    )

    const { fileUrl: coverImageUrl } = useFetchFile(company?.heroImagePath)

    const [openCoverImageModal, setOpenCoverImageModal] = useState('')
    const { currentCompany, isVerified } = useContext(CurrentUserContext)

    const coverPhotoButtonTitle = !!company?.heroImagePath
        ? 'general.editCoverPhoto'
        : 'general.addCoverPhoto'

    const initialValues = {
        heroImage: company?.heroImage ?? null,
    }

    const isMyCompany = currentCompany?.id === Number(company?.id)

    useEffect(() => {
        if (error?.response?.status === 400) {
            navigate(ROUTES.HOME)
        }
    }, [error])

    const renderTabContent = () => {
        switch (tab) {
            case TAB.DIVE_PROJECTS:
                return (
                    <ProjectList
                        companyID={company?.id}
                        profilePage
                        myProfile={isMyCompany}
                    />
                )
            case TAB.FAVORITE_USERS:
                return <FavoriteUsers currentCompany={currentCompany} />
            case TAB.GALLERY:
                return (
                    <GalleryPage
                        companyID={company.id}
                        myProfile={isMyCompany}
                    />
                )
            case TAB.USER_MANAGEMENT:
                return <UserManagement />
            case TAB.ABOUT_COMPANY:
            default:
                return <AboutCompany company={company} />
        }
    }

    const handleSubmit = async (formData, { setSubmitting }, customData) => {
        try {
            setSubmitting(true)

            const data = { ...formData, heroImage: customData }
            await editEntityService(ENTITIES.COMPANY, company.id, data)
            fetchContractor()

            setOpenCoverImageModal(false)
            setSubmitting(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleChildSubmit = async (customData, setSubmitting) => {
        handleSubmit(initialValues, { setSubmitting }, customData)
    }

    if (isLoadingContractor) return <Loader />

    if (!company) return null

    const renderCoverImageBox = () => (
        <div className="-coverImageBox -mb20">
            {isMyCompany && (
                <Button
                    onClick={() => setOpenCoverImageModal(true)}
                    icon={ICONS.EDIT}
                    iconSize={ICON_SIZE.SIZE20}
                    btnClass={BUTTON_STATUS.COVER_BUTTON}
                    buttonSize={BUTTON_SIZE.COVER}
                />
            )}

            <div
                className="m-coverImage"
                {...{
                    style: {
                        backgroundImage: `url(${coverImageUrl})`,
                    },
                }}
            />
        </div>
    )

    return (
        <div>
            <div className="_wr">
                <div className="_w">
                    <div className="m-boxes fullWidth -mt20">
                        {isSmallScreen && renderCoverImageBox()}
                        <div className="m-boxes__side -left -smallSidebar -noBackground">
                            <CompanyInformation
                                company={company}
                                myProfile={isMyCompany}
                                isVerified={isVerified}
                            />
                        </div>
                        <div className="m-boxes__main -fullHeight -tabContentBox -noBackground">
                            {!isSmallScreen && renderCoverImageBox()}
                            <div className="-box">{renderTabContent()}</div>
                        </div>
                    </div>
                </div>
                {openCoverImageModal && (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ setSubmitting }) => (
                            <CoverImageUploadFields
                                name="heroImage"
                                open={openCoverImageModal}
                                setOpen={setOpenCoverImageModal}
                                title={coverPhotoButtonTitle}
                                handleChildSubmit={handleChildSubmit}
                                setSubmitting={setSubmitting}
                                image={initialValues.heroImage}
                                fileCategory={
                                    FILE_CATEGORIES.COMPANY_HERO_IMAGE
                                }
                            />
                        )}
                    </Formik>
                )}
            </div>
        </div>
    )
}

export default Profile
