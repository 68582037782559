import React from 'react'

const StatusCell = ({ value, statuses }) => {
    if (!statuses) return null

    const statusKey = Object.keys(statuses).find(
        (key) => (statuses[key].id || statuses[key].value) === value
    )

    const status = statuses[statusKey]

    return (
        <div className={`a-status a-captionsTextRegular -${status?.color}`}>
            {status?.name}
        </div>
    )
}

export default StatusCell
