import PropTypes from 'prop-types'
import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import { deleteEntityService } from 'services/entity.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import {
    ALERT_TYPES,
    CERTIFICATE_VERIFICATION_STATUSES,
    WALLET_CERTIFICATE_GENERATION_STATUSES,
} from 'constants/enums'

import CardContainer from 'components/CardContainer'
import CardPlaceholder from 'components/CardPlaceholder'
import CertificateCard from './CertificateCard'
import EditCertificate from './EditCertificate'
import NoData from 'components/NoData'
import CardHeader from 'components/card/CardHeader'
import CertificateQrCode from './CertificateQrCode'
import AddCertificateToWalletViaEmail from './AddCertificateToWalletViaEmail'

import {
    canDeleteCertificate,
    canEditCertificate,
    canViewQRCode,
    getCertificateStatus,
    isADCI,
} from '../utils/certificateHelper'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

const CertificateList = ({
    data,
    isLoading,
    fetchData,
    showActions,
    profilePage,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { userId } = useContext(CurrentUserContext)

    const [edit, setEdit] = useState(null)
    const [certificateQR, setCertificateQR] = useState(null)
    const [sendCertificateToEmail, setSendCertificateToEmail] = useState(null)

    const handleEdit = (item) => {
        setEdit(item)
    }

    const handleDelete = async ({ id }) => {
        try {
            await deleteEntityService(ENTITIES.TRAINING_CERTIFICATE, id)
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleViewQR = (item) => {
        setCertificateQR(item)
    }

    const handleAddToWalletViaEmail = (item) => {
        setSendCertificateToEmail(item)
    }

    if (isLoading && data.length === 0) {
        return <CardPlaceholder numberOfCards={profilePage ? 1 : 2} />
    }

    return (
        <Fragment>
            {data.map((item) => {
                const status = item?.certificateVerification?.verifiedStatus
                const isAdci = isADCI(item?.certificateIssuer)
                const hasQRCode = !!item.certificateHash

                const googleAvailable =
                    item &&
                    item.googlePassCertificate &&
                    item.googlePassCertificate.status ===
                        WALLET_CERTIFICATE_GENERATION_STATUSES.PASS_GENERATED

                const appleAvailable =
                    item &&
                    item.applePassCertificate &&
                    item.applePassCertificate.applePassStatus ===
                        WALLET_CERTIFICATE_GENERATION_STATUSES.PASS_GENERATED

                return (
                    <div key={item.id} className="-mt20">
                        <CardContainer>
                            <CardHeader
                                title={`${item.name}`}
                                item={item}
                                statuses={
                                    isAdci &&
                                    (item.user.id === userId ||
                                        Number(status) ===
                                            CERTIFICATE_VERIFICATION_STATUSES.VERIFIED)
                                        ? [getCertificateStatus(status, t)]
                                        : []
                                }
                                actions={
                                    showActions
                                        ? [
                                              canEditCertificate(
                                                  isAdci,
                                                  status
                                              ) && {
                                                  handleAction: handleEdit,
                                                  icon: ICONS.EDIT,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  label: 'button.editTrainingCertificate',
                                              },
                                              canViewQRCode(
                                                  isAdci,
                                                  status,
                                                  hasQRCode
                                              ) && {
                                                  handleAction: handleViewQR,
                                                  icon: ICONS.QR,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  label: 'button.viewQrCode',
                                              },
                                              (googleAvailable ||
                                                  appleAvailable) && {
                                                  handleAction:
                                                      handleAddToWalletViaEmail,
                                                  icon: ICONS.MAIL,
                                                  iconColor: COLORS.DARK_BLUE,
                                                  label: 'button.sendCertificateToYourDevice',
                                              },
                                              canDeleteCertificate(
                                                  isAdci,
                                                  status
                                              ) && {
                                                  handleAction: handleDelete,
                                                  icon: ICONS.DELETE,
                                                  iconColor: COLORS.RED,
                                                  label: 'button.deleteTrainingCertificate',
                                                  title: 'general.deleteTrainingCertificate',
                                                  confirmationMessage:
                                                      'message.areYouSureDeleteTrainingCertificate',
                                                  textColor: '-redText',
                                              },
                                          ].filter((item) => !!item)
                                        : []
                                }
                                fullTitle
                            />
                            <CertificateCard item={item} />
                        </CardContainer>
                    </div>
                )
            })}

            {!!edit && (
                <EditCertificate
                    data={edit}
                    setOpen={setEdit}
                    fetchData={fetchData}
                />
            )}

            {!!certificateQR && (
                <CertificateQrCode
                    certificate={certificateQR}
                    setCertificate={setCertificateQR}
                />
            )}

            {!!sendCertificateToEmail && (
                <AddCertificateToWalletViaEmail
                    certificate={sendCertificateToEmail}
                    setCertificate={setSendCertificateToEmail}
                />
            )}

            {data?.length === 0 && !isLoading && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noDataYet')}
                    description={t(
                        showActions
                            ? 'general.clickThePlusButton'
                            : 'general.nothingHereRightNow'
                    )}
                />
            )}
        </Fragment>
    )
}

CertificateList.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    profilePage: PropTypes.bool,
}

CertificateList.defaultProps = {
    showActions: false,
}

export default CertificateList
