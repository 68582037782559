import { Fragment, useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'

import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import useQueryParams from 'hooks/useQueryParams'

import { formatUrl } from 'utils/jsonApiFormatters'

import ENTITIES from 'constants/entities'
import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
    DIVE_RECORD_DONE_STATUSES,
    DIVE_RECORD_TO_DO_STATUSES,
} from 'constants/enums'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { TAB } from 'screens/diver/profile/diverInformation/constants/tabs'

import SearchAndFilter from 'components/SearchAndFilter'
import DiveRecordFilters from './components/DiveRecordFilters'
import DiveRecordListCard from './components/DiveRecordListCard'
import CreateDiveRecordModal from '../CreateDiveRecordModal'
import RequestAccess from 'screens/diver/profile/RequestAccess'
import Pagination from 'components/table/Pagination'
import EmptyList from '../../../components/EmptyList'
import TabHeading from 'screens/diver/profile/diverInformation/components/TabHeading'
import TabNavigation from 'components/tabs/TabNavigation'
import {
    formatDiveRecordTypes,
    formatDiveRecordVerifiedLevel,
    formatDiveRecordStatuses,
} from 'utils/filterButtonFormatters'

const filters = {
    project: { entityType: ENTITIES.PROJECT, displayAttribute: 'name' },
    divingMode: { entityType: ENTITIES.DIVING_MODE, displayAttribute: 'name' },
    diveRecordType: {
        formatter: formatDiveRecordTypes,
    },
    diveRecordVerificationLevel: {
        formatter: formatDiveRecordVerifiedLevel,
    },
    status: {
        formatter: formatDiveRecordStatuses,
    },
    fromDate: { type: 'date' },
    toDate: { type: 'date' },
}

const DiveRecordList = ({
    projectId,
    userID,
    profilePage,
    myProfile,
    user,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { pathname, search } = useLocation()
    const { itemsPerPage, filterParams } = useQueryParams()

    const { isDiver } = useContext(CurrentUserContext)

    const showPredefinedFilters = isDiver && !profilePage

    const {
        data: diveRecords,
        meta,
        isLoading,
        fetchData,
        fetchDataAfterDeletion,
    } = useFetchDataByQueryParams(
        ENTITIES.DIVE_RECORD_ELASTIC,
        {
            'project.id': projectId,
            'creator.id': userID,
            isProjectPage: projectId ? 1 : 0,
            include:
                'diveRecordGeneralInformation,diveRecordGeneralInformation.divingMode,project,diveRecordGeneralInformation.supervisor,creator',
        },
        false,
        (showPredefinedFilters && !!search) || !showPredefinedFilters
    )

    useEffect(() => {
        if (showPredefinedFilters && !search) {
            const params = {
                itemsPerPage,
                status: DIVE_RECORD_TO_DO_STATUSES,
            }

            if (!projectId) {
                params['isCreator'] = true
            }

            const url = formatUrl(pathname, params)
            navigate(url)
        }
    }, [search])

    const status = meta?.dataAccessStatus
    const hasAccess =
        myProfile ||
        [
            ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id,
            ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id,
        ].includes(status)

    const hideFiles =
        status === ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        filters,
        FILTERS.DIVE_RECORDS
    )

    if (!hasAccess) {
        return (
            <RequestAccess
                module={ACCESS_CONTROL_MODULES.DIVE_RECORDS}
                title="general.diveRecords"
                status={status}
                canRequestAccess={
                    status === ACCESS_CONTROL_STATUSES.NOT_FOUND.id
                }
                profileId={userID}
                checkAccessControl={fetchData}
                profile={user}
                requestTime={meta.dataAccessRequestTime}
            />
        )
    }

    const getFilterByStatus = (statuses) => {
        const params = {
            itemsPerPage,
            status: statuses,
        }

        if (!projectId) {
            params['isCreator'] = filterParams.isCreator
        }

        return formatUrl(pathname, params)
    }

    const getIsActiveFilterByStatus = (statuses) => {
        return (
            statuses.length === filterParams.status?.length &&
            statuses.every((item) =>
                filterParams.status?.includes(item.toString())
            )
        )
    }

    if (!diveRecords) return null

    return (
        <div className="_wr profileWrapper">
            {profilePage ? (
                <div className="-mb20">
                    <TabHeading
                        tabTitle="general.diveRecords"
                        tab={ROUTES.DIVE_RECORD}
                        myProfile={myProfile}
                    />
                    {hideFiles && (
                        <p className="a-mediumText a-lightText -mt20">
                            {t('general.noAccessToFiles')}
                        </p>
                    )}
                    {myProfile && (
                        <div className="justify-center -mb20">
                            <CreateDiveRecordModal
                                route={`${ROUTES.DIVER_PROFILE}/${userID}?tab=${TAB.DIVE_RECORDS}`}
                                profile
                            />
                        </div>
                    )}
                </div>
            ) : (
                <h3 className="-mb20">
                    {t('general.diveRecords')} ({meta.totalItems})
                </h3>
            )}
            {showPredefinedFilters && (
                <Fragment>
                    {!projectId && (
                        <TabNavigation
                            tabs={[
                                {
                                    key: true,
                                    title: 'general.myLogbookRecords',
                                    queryParams: search,
                                },
                                {
                                    key: false,
                                    title: 'general.needMyApproval',
                                    queryParams: search,
                                },
                            ]}
                            tabQueryParam="isCreator"
                            useAsFilter={true}
                            underlined={true}
                        />
                    )}
                    <TabNavigation
                        tabs={[
                            {
                                key: 'toDo',
                                title: 'general.toDo',
                                getCustomPath: () =>
                                    getFilterByStatus(
                                        DIVE_RECORD_TO_DO_STATUSES
                                    ),
                                getIsActive: () =>
                                    getIsActiveFilterByStatus(
                                        DIVE_RECORD_TO_DO_STATUSES
                                    ),
                            },
                            {
                                key: 'done',
                                title: 'general.done',
                                getCustomPath: () =>
                                    getFilterByStatus(
                                        DIVE_RECORD_DONE_STATUSES
                                    ),
                                getIsActive: () =>
                                    getIsActiveFilterByStatus(
                                        DIVE_RECORD_DONE_STATUSES
                                    ),
                            },
                        ]}
                        useAsFilter={true}
                        useFirstTabAsDefault={false}
                    />
                </Fragment>
            )}
            {!profilePage && (
                <SearchAndFilter
                    searchPlaceholder="general.searchDiveRecords"
                    activeFilters={activeFilters}
                    filters={filters}
                    showDefaultKey={false}
                >
                    <DiveRecordFilters
                        activeFilters={activeFilters}
                        projectId={projectId}
                    />
                </SearchAndFilter>
            )}

            {diveRecords.map((diveRecord) => (
                <div className="-mb20" key={diveRecord.id}>
                    <DiveRecordListCard
                        diveRecord={diveRecord}
                        fetchData={fetchData}
                        fetchDataAfterDeletion={fetchDataAfterDeletion}
                    />
                </div>
            ))}
            {diveRecords.length === 0 && !isLoading && (
                <EmptyList
                    icon={ICONS.NO_DATA}
                    title={
                        meta?.totalItems
                            ? t('general.noDiveRecordsOnThisPage')
                            : t(
                                  myProfile
                                      ? 'general.noDataYet'
                                      : 'general.noDiveRecords'
                              )
                    }
                    description={
                        meta?.totalItems
                            ? t('general.noDiveRecordsOnThisPageDesc')
                            : t(
                                  myProfile
                                      ? 'general.clickThePlusButton'
                                      : 'general.noDiveRecordsDesc'
                              )
                    }
                    showSearchResult={
                        Object.keys(activeFilters).length !== 0 &&
                        !meta?.totalItems
                    }
                />
            )}
            <Pagination totalItems={meta?.totalItems} meta={meta} scrollToTop />
            {renderLoader(isLoading, profilePage, isLoadingFilters)}
        </div>
    )
}

DiveRecordList.propTypes = {
    projectId: PropTypes.number,
    userID: PropTypes.number,
    profilePage: PropTypes.bool,
    myProfile: PropTypes.bool,
}

DiveRecordList.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default DiveRecordList
