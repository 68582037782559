import React from 'react'
import { useTranslate } from 'react-polyglot'
import { useParams } from 'react-router'

import { editEntityService } from 'services/entity.service'

import useFetchDataById from 'hooks/useFetchDataById'

import ManagementLayout from '../components/ManagementLayout'
import EntityBreadcrumbs from './components/EntityBreadcrumbs'
import EntityForm from './components/EntityForm'
import Loader from 'components/Loader'
import { CUSTOM_FORMS } from './utils/customForms'

const EditEntity = ({ entityType, path }) => {
    const t = useTranslate()

    const { entityId } = useParams()

    const { data, isLoading } = useFetchDataById(entityType, entityId, {
        include: CUSTOM_FORMS[entityType]?.include || '',
    })

    const handleSubmit = async (formData) => {
        await editEntityService(entityType, entityId, formData, true)
    }

    const FormComponent = CUSTOM_FORMS[entityType]?.component || EntityForm

    if (isLoading) return <Loader />
    if (!data) return null

    return (
        <ManagementLayout
            BreadcrumbsComponent={EntityBreadcrumbs}
            entityType={entityType}
            edit={data}
        >
            <FormComponent
                initialData={data}
                handleSubmit={handleSubmit}
                title={`${t('general.edit')} ${t(
                    `entityManagement.${entityType}_singular`
                ).toLowerCase()}`}
                entityType={entityType}
                path={path}
            />
        </ManagementLayout>
    )
}

export default EditEntity
