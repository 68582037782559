import React, {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import ICONS from 'constants/icons'
import { useTranslate } from 'react-polyglot'
import Button from 'components/Button'
import ENTITIES from 'constants/entities'
import axios, { POST } from 'utils/axiosClient'
import { useDropzone } from 'react-dropzone'
import useWindowDimensions from 'hooks/useWindowDimension'
import Icon from 'components/Icon'
import COLORS from 'constants/colors'
import AdciDiverCard from 'screens/common/adci/components/AdciDiverCard'
import { AlertContext } from 'contexts/AlertContext'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import CardPlaceholder from 'components/CardPlaceholder'

const AdciPhotoSearch = () => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)
    const [uploadedPhoto, setUploadedPhoto] = useState(null)
    const [certificate, setCertificate] = useState(null)
    const [compareResult, setCompareResult] = useState(null)
    const [compareResultPreview, setCompareResultPreview] = useState(null)
    const [certificateNotFound, setCertificateNotFound] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [totalSteps, setTotalSteps] = useState(0)
    const [currentSteps, setCurrentSteps] = useState(0)
    const { isTablet } = useWindowDimensions()

    const IMAGE_RECOGNIZED_KEY = 'imageRecognized'

    const PROPERTIES_CHECK = [
        'nameMatch',
        'imageMatch',
        'certificateMatch',
        'expireDateMatch',
        'logoMatch',
    ]

    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            setAlert(t(`alerts.messages.onlyOneImage`), ALERT_TYPES.ERROR)
            return
        }
        resetUploadedPhoto()
        setIsLoading(true)
        const formData = new FormData()
        let acceptedData = acceptedFiles[0]
        formData.append('certificate', acceptedData, acceptedData.name)
        displayImage(acceptedData)
        axios(POST, ENTITIES.CERTIFICATES_IMAGE, formData)
            .then((response) => {
                if (response.data.code === 404) {
                    setCertificateNotFound(true)
                } else if (response.data.code === 422) {
                    setAlert(t(response.data.message), ALERT_TYPES.ERROR)
                    resetUploadedPhoto()
                } else {
                    const {
                        data: { dbCertificate, compare },
                    } = response
                    setCertificate(dbCertificate)
                    setCompareResult(compare)
                    setTotalSteps(Object.keys(compare).length)
                }
                setIsLoading(false)
            })
            .catch(function (response) {
                setAlert({ response }, ALERT_TYPES.ERROR, t)
                resetUploadedPhoto()
            })
    })

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    })

    /** Fake sequential processing */
    useEffect(() => {
        if (!compareResult) {
            return
        }
        let index = 0
        const keys = Object.keys(compareResult) // Get all object keys

        const updatePartByPart = () => {
            if (index < keys.length) {
                const key = keys[index]
                setCompareResultPreview((prevState) => ({
                    ...prevState,
                    [key]: compareResult[key],
                }))
                setCurrentSteps((prevSteps) => ++prevSteps)
                index++
                setTimeout(updatePartByPart, 200)
            }
        }
        updatePartByPart()
    }, [compareResult])

    const displayImage = (file) => {
        if (!file) {
            return
        }
        const reader = new FileReader()
        reader.onload = function (e) {
            setUploadedPhoto(e.target.result)
        }

        reader.readAsDataURL(file)
    }

    const resetUploadedPhoto = () => {
        setUploadedPhoto(null)
        setCertificate(null)
        setCompareResultPreview(null)
        setCompareResult(null)
        setCurrentSteps(0)
        setTotalSteps(0)
        setCertificateNotFound(false)
    }

    const displayProp = (key, isImage = false) => {
        if (isImage) {
            if (
                compareResultPreview[key] === false
            ) {
                return (
                    <div
                        key={key}
                        className={`compare-item animation-item aligned-center a-mediumText -gap5 'not-match'`}
                    >
                        <Icon
                            name={ICONS.CLOSE_CIRCLE}
                            color={COLORS.RED}
                            size={ICON_SIZE.SIZE16}
                        ></Icon>
                        <span>{t('adci.compare.' + key)}</span>
                    </div>
                )
            }
        }

        if(compareResult && compareResult[IMAGE_RECOGNIZED_KEY] === false) {
            return
        }
        
        return (
            <div
                key={key}
                className={`compare-item animation-item aligned-center a-mediumText -gap5 ${
                    compareResultPreview &&
                    compareResultPreview[key] === false
                        ? 'not-match'
                        : ''
                }`}
            >
                <div className="icon-wrapper column">
                    {(!compareResultPreview ||
                        compareResultPreview[key] ===
                        undefined) && (
                        <div className={'loading-icon'}>
                            <Icon
                                name={ICONS.LOADER_ICON}
                                color={COLORS.SECONDARY}
                            ></Icon>
                        </div>
                    )}
                    {compareResultPreview &&
                        compareResultPreview[key] !==
                        undefined &&
                        (compareResultPreview[key] ? (
                            <Icon
                                name={
                                    ICONS.CHECKMARK_ICON_ROUNDED_FILL
                                }
                                color={
                                    COLORS.LIGHT_BLUE
                                }
                                size={ICON_SIZE.SIZE16}
                            ></Icon>
                        ) : (
                            <Icon
                                name={
                                    ICONS.CLOSE_CIRCLE
                                }
                                color={COLORS.RED}
                                size={ICON_SIZE.SIZE16}
                            ></Icon>
                        ))}
                </div>
                <span>{t('adci.compare.' + key)}</span>
            </div>
        )
    }

    // const getPercent = () => {
    //     if(!compareResult) return 0;
    //     const percent = (currentSteps * 100) / totalSteps;
    //     if(percent > 100) {
    //         return 100;
    //     }
    //
    //     return percent
    // }

    return (
        <>
            <p className="-mt10 a-bodyTextRegular a-lightText -opacity-60 -mb20 centered-text">
                {t('adci.searchByImage')}
            </p>
            {!uploadedPhoto && (
                <div className="form-wrapper">
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div
                            className={`aligned-center justify-center column m-gallery__box`}
                        >
                            <div className="aligned-center column m-gallery__box--content">
                                {!uploadedPhoto && (
                                    <Fragment>
                                        <Icon
                                            name={ICONS.GALLERY_PHOTO}
                                            color={COLORS.LIGHT_BLUE}
                                        />
                                    </Fragment>
                                )}

                                <div className="aligned-center column">
                                    {isDragActive ? (
                                        <span className="-mt20 a-bodyTextRegular a-lightText -opacity-60">
                                            {t('general.dropFile')}
                                        </span>
                                    ) : (
                                        <>
                                            <span className="-mt20 a-bodyTextRegular a-lightText -opacity-60">
                                                {t(
                                                    isTablet
                                                        ? 'gallery.addPhotosFromDevice'
                                                        : 'gallery.dragNDrop'
                                                )}
                                            </span>
                                            {!isTablet && (
                                                <span className="-mt10 a-bodyTextRegular a-lightText -opacity-60 a-allCaps">
                                                    {t('gallery.or')}
                                                </span>
                                            )}
                                            <div className="justify-start -mt10">
                                                <Button label="gallery.chooseFromDevice" />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {uploadedPhoto && !certificateNotFound && (
                <>
                    <div className="uploaded-certificate-wrapper aligned-center -gap10">
                        <div className="uploaded-certificate-img aligned-center">
                            <img
                                className="-image"
                                src={uploadedPhoto}
                                alt=""
                            />
                        </div>
                        <span className="a-mediumText">Uploaded image</span>
                        <span
                            className={
                                'reset-icon aligned-center justify-center'
                            }
                            onClick={resetUploadedPhoto}
                        >
                            <Icon name={ICONS.CLOSE} size={ICON_SIZE.SIZE20} />
                        </span>
                    </div>
                    <div className="compare-res column -mt20">
                        <span className="a-bodyTextMedium centered-text fullWidth">
                            Verification results
                        </span>
                        {/*Remove loader for now*/}
                        {/*<div className="a-progressBar -withLabel -mb10">*/}
                        {/*    <div className="-wrapper">*/}
                        {/*        <div className="-bar">*/}
                        {/*            <div*/}
                        {/*                style={{*/}
                        {/*                    width: `${*/}
                        {/*                        getPercent()*/}
                        {/*                    }%`,*/}
                        {/*                }}*/}
                        {/*                className="-progress"*/}
                        {/*            ></div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <span className="a-captionsTextRegular a-lightText -opacity-60 -label">*/}
                        {/*            {`${getPercent()}%`}*/}
                        {/*        </span>*/}
                        {/*</div>*/}
                        <div className="compare-item-wrapper flex-wrap">
                            {compareResultPreview && compareResultPreview[IMAGE_RECOGNIZED_KEY] === false && (
                                displayProp(IMAGE_RECOGNIZED_KEY, true)
                            )}

                            {PROPERTIES_CHECK.map((key) => {
                                return displayProp(key)
                            })}
                        </div>
                    </div>
                    {compareResult && currentSteps === totalSteps && (
                        <div className="fullWidth result-wrapper">
                            <div className="_wr">
                                <div className="_w">
                                    <div className="_12">
                                        <AdciDiverCard
                                            certificate={certificate}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
            {certificateNotFound && (
                <>
                    <h3 className={'-mb20'}>{t('general.noResults')}</h3>
                    <span
                        className={
                            'a-bodyTextRegular a-lightText -opacity-60 centered-text'
                        }
                    >
                        {t('adci.notFound')}
                    </span>
                    <div className="justify-start -mt20">
                        <Button
                            label="adci.uploadAnother"
                            onClick={() => resetUploadedPhoto()}
                        />
                    </div>
                </>
            )}
            {(isLoading || currentSteps < totalSteps) && (
                <>
                    <div className="_wr">
                        <div className="_w">
                            <div className="_12">
                                <CardPlaceholder numberOfCards={1} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default AdciPhotoSearch
