import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { BUTTON_STATUS, ICON_SIZE, PACKAGE_INFO_DATA } from 'constants/enums'

import Button from 'components/Button'
import Icon from 'components/Icon'
import useWindowDimensions from 'hooks/useWindowDimension'
import { useNavigate } from 'react-router'

const PackageInfoRow = ({ bottom = false, activePackageTab, active, currentUser }) => {
    const navigate = useNavigate()
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()

    const getIsActivePackage = (item) => {
        return isTablet ? active : activePackageTab.id === item.id
    }
    return (
        <tr className={`-packageInfoRow ${bottom ? '-bottom' : ''}`}>
            {!isTablet && <td></td>}
            {(isTablet ? [activePackageTab] : PACKAGE_INFO_DATA).map((item) => {
                return (
                    <td key={item.key} colSpan={isTablet ? 2 : 0}>
                        <div className="column -pt30 -pb30 aligned-center">
                            {!bottom && (
                                <div className="-mb20 column justify-center aligned-center">
                                    <div className="justify-center -gap5">
                                        <Icon
                                            name={item.icon}
                                            size={ICON_SIZE.SIZE20}
                                            color={item.iconColor}
                                        />
                                        <span>{t(`general.${item.key}`)}</span>
                                    </div>
                                    {item.description && (
                                        <span className="a-mediumText a-lightText -opacity-60 -mt5">
                                            {t(item.description)}
                                        </span>
                                    )}
                                </div>
                            )}
                            <div className="a-mediumText centered-text column">
                                {getIsActivePackage(item) ? (
                                    <span className="-mt15">
                                        {t('general.currentlyActive')}
                                    </span>
                                ) : (
                                    !item.disabled &&
                                    item.buttonLabel &&
                                    !currentUser.companyId && (
                                        <Button
                                            btnClass={BUTTON_STATUS.PRIMARY}
                                            label={item.buttonLabel}
                                            onClick={
                                                item.link &&
                                                !getIsActivePackage(item)
                                                    ? () => navigate(item.link)
                                                    : undefined
                                            }
                                        />
                                    )
                                )}
                            </div>
                        </div>
                    </td>
                )
            })}
        </tr>
    )
}

PackageInfoRow.propTypes = {
    bottom: PropTypes.bool,
    activePackageTab: PropTypes.object,
    active: PropTypes.bool,
    currentUser: PropTypes.object,
}

export default PackageInfoRow
