import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import axios, { POST } from 'utils/axiosClient'
import { validateEmail } from 'utils/yupValidations'

import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Modal from 'components/Modal'
import FocusError from 'components/FocusError'
import { InputField } from 'components/formFields'
import Loader from 'components/Loader'

const IndividualAccountChangeEmail = ({ open, setOpen, user }) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        newEmail: '',
        confirmEmail: '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        newEmail: validateEmail(t).required(requiredMessage),
        confirmEmail: Yup.string()
            .trim()
            .required(requiredMessage)
            .oneOf(
                [Yup.ref('newEmail'), null],
                t('form.error.emailsMustMatch')
            ),
    })

    const handleSubmit = async ({ newEmail }, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await axios(
                POST,
                `/${ENTITIES.USER}/${ENTITIES.ADMIN}/${ENTITIES.CHANGE_EMAIL}/${user.id}`,
                { newEmail },
                false
            )
            setAlert(
                t('message.successfullyChangedUserEmail'),
                ALERT_TYPES.SUCCESS
            )
            setOpen(false)
        } catch (error) {
            setAlert(
                error,
                ALERT_TYPES.ERROR,
                error.response?.data?.message ? t : null
            )
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="general.changeUserEmail"
                        isSubmitting={isSubmitting}
                        smallModal
                        buttons={{
                            prevBtn: {
                                handleClick: () => setOpen(false),
                            },
                            nextBtn: {
                                label: 'button.save',
                            },
                        }}
                    >
                        <div className="_wr -modalElements">
                            <div className="_w">
                                <div className="_12 -mb5">
                                    <InputField
                                        name="newEmail"
                                        icon={ICONS.MAIL}
                                        iconColor={COLORS.DARK_BLUE_60}
                                        size={ICON_SIZE.SIZE20}
                                        autoCompleteOff
                                        required
                                    />
                                </div>
                                <div className="_12 -mb5">
                                    <InputField
                                        name="confirmEmail"
                                        icon={ICONS.MAIL}
                                        iconColor={COLORS.DARK_BLUE_60}
                                        size={ICON_SIZE.SIZE20}
                                        autoCompleteOff
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

IndividualAccountChangeEmail.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    user: PropTypes.object,
}

export default IndividualAccountChangeEmail
