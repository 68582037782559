import Note from 'components/Note'
import { SUPPORT } from 'constants/constants'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

const CertificatesVerification = ({ certificatesVerificationObject }) => {
    return (
        <div className="column m-helpAndInfo -gap20">
            <div className="column -gap10">
                <span className="a-bodyTextMedium">
                    {certificatesVerificationObject.title}
                </span>
                <p className="a-mediumText a-lightText">
                    {certificatesVerificationObject.description}
                </p>
            </div>
            <div className="column -gap20">
                {certificatesVerificationObject.sections.map(
                    (section, index) => (
                        <div className="column" key={index}>
                            <span className="a-mediumTextSemiBold">
                                {section.title}
                            </span>
                            {section.list && (
                                <ul>
                                    {section.list.map((listItem, index) => (
                                        <li
                                            className="-liDisc a-mediumText -mt5"
                                            key={index}
                                        >
                                            {listItem.item}
                                            {listItem.listItem?.map((item) => (
                                                <li
                                                    className="-liDisc a-mediumText"
                                                    key={index}
                                                >
                                                    {item?.item}
                                                </li>
                                            ))}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    )
                )}
            </div>
            <Note>
                <span>
                    {certificatesVerificationObject.note}{' '}
                    <Link
                        onClick={() =>
                            (window.location = `mailto:${SUPPORT.EMAIL}`)
                        }
                        title={SUPPORT.EMAIL}
                    >
                        {SUPPORT.EMAIL}
                    </Link>
                    .
                </span>
            </Note>
        </div>
    )
}

CertificatesVerification.propTypes = {
    certificatesVerificationObject: PropTypes.object,
}

export default CertificatesVerification
