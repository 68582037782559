import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'

import renderNoteAndAlertColor from 'utils/notesAndAlertsColorHelper'

import { AlertContext } from 'contexts/AlertContext'

import ICONS from 'constants/icons'
import { ICON_SIZE } from 'constants/enums'

import IconButton from './IconButton'
import NotesAndAlertsIcon from './icons/NotesAndAlertsIcon'

const Alert = () => {
    const t = useTranslate()

    const {
        alert: { message, title, type, position },
        setAlert,
    } = useContext(AlertContext)

    if (!message) return null

    return (
        <div
            className={`a-alert a-notesAndAlerts -${type} -${position} aligned-center space-between`}
        >
            <div className="a-alert__left aligned-center justify-center -gap10">
                <NotesAndAlertsIcon type={type} />
                <div className="-text column -gap4">
                    <span className="a-bodyTextMedium">
                        {t(title || `alerts.titles.${type}`)}
                    </span>
                    <span className="-message a-captionsTextRegular">
                        {message}
                    </span>
                </div>
            </div>
            <IconButton
                onClick={() => setAlert('')}
                icon={ICONS.CLOSE}
                iconSize={ICON_SIZE.SIZE20}
                iconColor={renderNoteAndAlertColor(type)}
            />
        </div>
    )
}

export default Alert
