import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'
import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'

import Button from './Button'
import Badge from './Badge'
import Icon from './Icon'

const IconShowcaseContent = ({
    iconShowcase,
    size,
    additionalBadgeKey,
    badgeKey,
    withBadge,
    infoText,
    route,
    id,
}) => {
    const navigate = useNavigate()

    return (
        <div className={`iconShowcase-content -badge -gap15`}>
            {withBadge ? (
                <Badge
                    key={id}
                    badgeKey={badgeKey}
                    additionalBadgeKey={additionalBadgeKey}
                    size={size}
                />
            ) : (
                <Icon name={iconShowcase.icon} size={size} />
            )}
            <div>
                <div className="a-bodyTextMedium">{iconShowcase?.title}</div>
                <div
                    className={`-mt5 a-mediumText a-lightText ${
                        infoText
                            ? 'ellipsis-text-two-lines'
                            : 'ellipsis-text-three-lines'
                    }`}
                >
                    <span>{iconShowcase?.description}</span>
                </div>
                {infoText && (
                    <div className="-mt5 a-mediumText">
                        <span className="a-lightText"> {infoText.label} </span>
                        <span
                            className={`a-mediumTextSemiBold ${
                                infoText.color || ''
                            }`}
                        >
                            {infoText.value}
                        </span>
                    </div>
                )}
                <div className="-mt15">
                    <Button
                        label="button.readMore"
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        buttonSize={BUTTON_SIZE.XSMALL}
                        icon={ICONS.ARROW_RIGHT}
                        iconColor={COLORS.LIGHT_BLUE}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() => {
                            navigate(route)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

IconShowcaseContent.propTypes = {
    iconShowcase: PropTypes.object,
    size: PropTypes.number,
    additionalBadgeKey: PropTypes.string,
    badgeKey: PropTypes.string,
    id: PropTypes.number,
    withBadge: PropTypes.bool,
    infoText: PropTypes.object,
    route: PropTypes.string,
}

IconShowcaseContent.defaultProps = {
    size: 100,
    withBadge: false,
}

export default IconShowcaseContent
