import PropTypes from 'prop-types'

import { editEntityService } from 'services/entity.service'

import { refetchProfile } from '../../utils/fetchHelper'

import ENTITIES from 'constants/entities'
import { PRIMARY_TRAINING_TYPE } from 'constants/enums'

import TrainingCourseForm from './TrainingCourseForm'

const EditTrainingCourse = ({
    data,
    open,
    setOpen,
    fetchData,
    refetchData,
    filteredData,
    showActions,
}) => {
    const handleSubmit = async (formData) => {
        await editEntityService(
            ENTITIES.TRAINING_COURSE,
            data.id,
            formData,
            true,
            ['trainingCertificates', 'contactAddress']
        )
        fetchData()

        const condition = [
            PRIMARY_TRAINING_TYPE.DIVE_SCHOOL_TRAINING,
            PRIMARY_TRAINING_TYPE.OTHER_TRAINING,
        ].includes(formData?.primaryTrainingType?.id)

        refetchData &&
            refetchProfile(condition, filteredData, refetchData, true, false)
    }

    return (
        <TrainingCourseForm
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            initialData={data}
            showActions={showActions}
        />
    )
}

EditTrainingCourse.propTypes = {
    data: PropTypes.object,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    fetchData: PropTypes.func,
    refetchData: PropTypes.func,
    filteredData: PropTypes.array,
    showActions: PropTypes.bool,
}

export default EditTrainingCourse
