import React from 'react'
import ReactDOM from 'react-dom/client'

import RecoilProvider from 'recoil/recoilRoot'

import AppContext from 'contexts/AppContext'

import App from 'App'

import 'index.scss'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <RecoilProvider>
        <AppContext>
            <App />
        </AppContext>
    </RecoilProvider>
)
