import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useFetchData from 'hooks/useFetchData'

import { isFormDisabled } from 'utils/form'

import { ICON_SIZE, SELECT_VALUE_TYPE, TOOLTIP_POSITION } from 'constants/enums'
import COLORS from 'constants/colors'

import FieldError from 'components/FieldError'
import FieldLabel from 'components/FieldLabel'
import Icon from './Icon'
import Tooltip from './Tooltip'

const CustomRadioGroup = ({
    name,
    label,
    value,
    setValue,
    entityType,
    params,
    defaultOptions,
    isJSONAPI,
    apiUrl,
    displayAttribute,
    descriptionAttribute,
    required,
    error,
    disabled,
    valueType,
    onUpdate,
    showLabel,
    reload,
    tooltip,
}) => {
    const t = useTranslate()

    const fieldRef = useRef()

    const isDisabled = disabled || isFormDisabled(fieldRef)

    const { data } = useFetchData(
        entityType,
        params,
        true,
        false,
        reload,
        defaultOptions,
        isJSONAPI,
        apiUrl
    )

    const handleChange = (item) => {
        if (isDisabled) return
        switch (valueType) {
            case SELECT_VALUE_TYPE.STRING:
                setValue(item?.id ?? item)
                if (onUpdate) onUpdate(item?.id ?? item)
                break
            case SELECT_VALUE_TYPE.OBJECT:
            default:
                setValue(item)
                if (onUpdate) onUpdate(item)
                break
        }
    }

    const getIsChecked = (item) => {
        if (
            valueType === SELECT_VALUE_TYPE.OBJECT &&
            (item.id || item.id === 0)
        ) {
            return item.id === value?.id
        }
        return (item.id ?? item) === value
    }

    return (
        <div
            scroll-attribute={name}
            className={`m-radioGroupContainer ${isDisabled ? '-disabled' : ''}`}
        >
            <div className="_wr">
                {showLabel && (
                    <div className="_w">
                        <div className="a-leftText _12">
                            <FieldLabel
                                className="m-checkboxGroup__label"
                                label={label || `form.label.${name}`}
                                required={required}
                                tooltip={tooltip}
                            />
                        </div>
                    </div>
                )}
                <div className={`_w`}>
                    {data.map((item) => {
                        const itemId = item.id ?? item
                        const itemName = `${name}${itemId}`
                        const itemIcon = item.icon

                        return (
                            <div
                                key={itemId}
                                className={`-radioGroupElements _12 _s6 _m4 -mt20 ${getIsChecked(item) ? '-checkedItem' : ''}`}
                            >
                                <div
                                    className={`m-radioGroup bg-white border -borderRadius10 fullWidth fullHeight withTransition ${getIsChecked(item) ? '-lightBlueBorder bcg-lightBlue05' : ''} relative`}
                                >
                                    <input
                                        type="radio"
                                        ref={fieldRef}
                                        name={itemName}
                                        id={itemName}
                                        value={itemId}
                                        onChange={() => handleChange(item)}
                                        checked={getIsChecked(item)}
                                        disabled={item.disabled}
                                    />
                                    <Tooltip
                                        tooltip={item[descriptionAttribute]}
                                        position={TOOLTIP_POSITION.BOTTOM}
                                    >
                                        <label
                                            htmlFor={itemName}
                                            title={item.tooltip}
                                            className={`fullWidth centered-text fullHeight -p15 ${
                                                (isDisabled || item.disabled) &&
                                                !getIsChecked(item)
                                                    ? 'pointerDefault'
                                                    : 'pointer'
                                            }`}
                                        >
                                            <div className="column -gap10 fullHeight fullWidth">
                                                <div
                                                    className={`-borderRadius10 -p15 -h80 fullWidth fullHeight aligned-center justify-center ${
                                                        (isDisabled ||
                                                            item.disabled) &&
                                                        !getIsChecked(item)
                                                            ? 'bcg-darkBlue02'
                                                            : 'bcg-lightBlue05'
                                                    }`}
                                                >
                                                    <Icon
                                                        name={itemIcon}
                                                        color={
                                                            (isDisabled ||
                                                                item.disabled) &&
                                                            !getIsChecked(item)
                                                                ? COLORS.DARK_BLUE_40
                                                                : COLORS.LIGHT_BLUE
                                                        }
                                                        secondColor={
                                                            COLORS.TRANSPARENT
                                                        }
                                                        size={ICON_SIZE.SIZE50}
                                                    />
                                                </div>
                                                <div className="fullHeight fullWidth aligned-center justify-center">
                                                    <span
                                                        className={`label a-mediumTextSemiBold ${
                                                            (isDisabled ||
                                                                item.disabled) &&
                                                            !getIsChecked(item)
                                                                ? 'a-lightText -opacity-40'
                                                                : ''
                                                        }`}
                                                    >
                                                        {displayAttribute
                                                            ? item[
                                                                  displayAttribute
                                                              ]
                                                            : item}
                                                    </span>
                                                </div>
                                            </div>
                                        </label>
                                    </Tooltip>
                                    {item.comingSoon && (
                                        <div className="-borderRadius5 bcg-primaryBlue a-allCaps a-whiteText a-captionsTextSemiBold -comingSoon">
                                            {t('general.comingSoon')}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>

                {error && <FieldError className="errorMessage" error={error} />}
            </div>
        </div>
    )
}

export const RadioMainPropTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    entityType: PropTypes.string,
    params: PropTypes.object,
    defaultOptions: PropTypes.array,
    isJSONAPI: PropTypes.bool,
    apiUrl: PropTypes.string,
    required: PropTypes.bool,
    displayAttribute: PropTypes.string,
    descriptionAttribute: PropTypes.string,
    disabled: PropTypes.bool,
    valueType: PropTypes.oneOf([
        SELECT_VALUE_TYPE.STRING,
        SELECT_VALUE_TYPE.OBJECT,
    ]),
    showLabel: PropTypes.bool,
    reload: PropTypes.any,
    tooltip: PropTypes.string,
}

CustomRadioGroup.propTypes = {
    ...RadioMainPropTypes,
    value: PropTypes.any,
    setValue: PropTypes.func.isRequired,
    onUpdate: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

CustomRadioGroup.defaultProps = {
    defaultOptions: [],
    displayAttribute: 'name',
    valueType: SELECT_VALUE_TYPE.OBJECT,
    required: false,
    disabled: false,
    showLabel: true,
}

export default CustomRadioGroup
