import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { useTranslate } from 'react-polyglot'

import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'
import TruncatedText from './TruncatedText'

const ContactInfo = ({ user }) => {
    const t = useTranslate()

    const {
        email,
        country,
        phoneNumber,
        dateOfBirth,
        nationality,
        totalExperienceYears,
        diver,
    } = user

    const originalDate = new Date(dateOfBirth)
    const convertedDate = originalDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    })

    return (
        <div className="-contactInfo aligned-center -mt20">
            {country && (
                <div className="aligned-center -gap5">
                    <Icon
                        name={ICONS.LOCATION}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60">
                        {country}
                    </span>
                </div>
            )}
            {email && (
                <div className="aligned-center -gap5">
                    <Icon
                        name={ICONS.MAIL}
                        size={ICON_SIZE.SIZE16}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <Link
                        className="a-mediumText a-lightText -opacity-60"
                        to=""
                        onClick={() => (window.location = `mailto:${email}`)}
                        title={email}
                    >
                        {email}
                    </Link>
                </div>
            )}
            {phoneNumber && (
                <div className="aligned-center -gap5">
                    <Icon
                        name={ICONS.PHONE}
                        color={COLORS.DARK_BLUE_20}
                        size={ICON_SIZE.SIZE18}
                    />
                    <Link
                        className="a-mediumText a-lightText -opacity-60"
                        to=""
                        onClick={() => (window.location = `tel:${phoneNumber}`)}
                        title={phoneNumber}
                    >
                        {phoneNumber}
                    </Link>
                </div>
            )}
            {dateOfBirth && (
                <div className="aligned-center -gap5">
                    <Icon
                        name={ICONS.CALENDAR}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60">
                        {convertedDate}
                    </span>
                </div>
            )}
            {nationality && (
                <div className="aligned-center -gap5">
                    <Icon
                        name={ICONS.GLOBE}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60">
                        {nationality}
                    </span>
                </div>
            )}

            {diver && totalExperienceYears && (
                <div className="aligned-center -gap5">
                    <Icon
                        name={ICONS.SKILLS}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60">
                        {totalExperienceYears || 0}{' '}
                        {t('general.yearsOfExperience')}
                    </span>
                </div>
            )}

            {diver && (
                <div className="aligned-center -gap5">
                    <Icon
                        name={ICONS.DIVER}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60">
                        {user.numberOfDives} {t('general.dives')}
                    </span>
                </div>
            )}

            {diver && user.isn && (
                <div className="aligned-center -gap5">
                    <Icon
                        name={ICONS.CODE}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60">
                        <TruncatedText text={user.isn} />
                    </span>
                </div>
            )}

            {diver && user.deVinciCode && (
                <div className="aligned-center -gap5">
                    <Icon
                        name={ICONS.CODE}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60 aligned-center -gap2">
                        {t('general.deVinciCode')}:{' '}
                        <TruncatedText text={user.deVinciCode} />
                    </span>
                </div>
            )}

            {diver && user.vantage && (
                <div className="aligned-center -gap5">
                    <Icon
                        name={ICONS.CODE}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.DARK_BLUE_20}
                    />
                    <span className="a-mediumText a-lightText -opacity-60 aligned-center -gap2">
                        {t('general.vantage')}:
                        <TruncatedText text={user.vantage} />
                    </span>
                </div>
            )}
        </div>
    )
}

ContactInfo.propTypes = {
    email: PropTypes.string,
    country: PropTypes.string,
    phoneNumber: PropTypes.string,
    dateOfBirth: PropTypes.string,
    nationality: PropTypes.string,
    totalExperienceYears: PropTypes.number,
    user: PropTypes.object,
}

export default ContactInfo
