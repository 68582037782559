import React, { useState, useRef, useEffect } from 'react'
import { Outlet } from 'react-router'

import useWindowDimensions from 'hooks/useWindowDimension'
import { USER_MENU_ITEMS } from 'hooks/useMenuItems'

import { SCREEN_WIDTH } from 'constants/enums'

import { Sidebar } from 'components/sidebar'
import Footer from 'components/Footer'
import Header from './Header'

const AuthLayout = ({ children }) => {
    const [openSidebar, setOpenSidebar] = useState(false)
    const hocRef = useRef(null)
    const hamBtnRef = useRef(null)

    const {
        isSmallerThanXlScreen,
        isSmallScreen,
        isMobile,
        isTablet,
    } = useWindowDimensions()

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const handleClickOutside = (event) => {
        if (
            document.body.clientWidth < SCREEN_WIDTH.MEDIUM &&
            hocRef &&
            hocRef.current &&
            !hocRef.current.contains(event.target) &&
            !hamBtnRef.current.contains(event.target)
        ) {
            setOpenSidebar(false)
        }
    }

    return (
        <div>
            {isSmallScreen && (
                <Sidebar
                    open={openSidebar}
                    setOpen={setOpenSidebar}
                    hocRef={hocRef}
                    menu={USER_MENU_ITEMS}
                    isMobile={isMobile}
                    isSmallScreen={isSmallScreen}
                    hamBtnRef={hamBtnRef}
                />
            )}
            <div
                className={`m-main ${
                    isSmallScreen && openSidebar ? '-sidebarIsOpen' : ''
                }`}
            >
                <Header
                    setOpenSidebar={setOpenSidebar}
                    hamBtnRef={hamBtnRef}
                    openSidebar={openSidebar}
                    isSmallScreen={isSmallScreen}
                    isMobile={isMobile}
                    isSmallerThanXlScreen={isSmallerThanXlScreen}
                    isTablet={isTablet}
                />
                <div className="m-main__wrapper">
                    <div className="_wr ">
                        <div className="_w">
                            <div className="m-main__content">
                                {children || <Outlet />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AuthLayout
