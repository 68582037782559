import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import useWindowDimensions from 'hooks/useWindowDimension'

import ModalTitle from './modal/ModalTitle'
import ModalButtons from './modal/ModalButtons'

const Modal = ({
    open,
    setOpen,
    closeOnClickOutside,
    customClass,
    children,
    smallModalWithDropdowns,
    title,
    buttons,
    noTranslateTitle,
    closeButton,
    formInForm,
    isSubmitting,
    values,
    hidePrevBtn,
    smallModal,
    widerSmallModal,
}) => {
    const hocRef = useRef(null)
    const { isTablet, isSmallScreen } = useWindowDimensions()

    useEffect(() => {
        if (closeOnClickOutside) {
            document.addEventListener('mousedown', handleClickOutside)
            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }
    }, [])

    const handleClickOutside = (event) => {
        if (
            hocRef?.current &&
            !hocRef.current.contains(event.target) &&
            setOpen
        ) {
            setOpen(false)
        }
    }

    const closeModal = () => {
        if (setOpen) {
            setOpen(false)
        }
    }

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden'
            return () => {
                document.body.style.overflow = 'unset'
            }
        }
    }, [open])

    useEffect(() => {
        if (!isTablet) {
            const handleScrollToFirstBottom = () => {
                const { scrollTop } = hocRef.current
                const distanceFromBottom = 155

                if (scrollTop < distanceFromBottom) {
                    hocRef.current.scrollTop = distanceFromBottom
                }
            }

            const container = hocRef.current
            handleScrollToFirstBottom()

            container.addEventListener('scroll', handleScrollToFirstBottom)

            return () => {
                container.removeEventListener(
                    'scroll',
                    handleScrollToFirstBottom
                )
            }
        }
    }, [])

    const calculateBodyHeight = (modalElement) => {
        const headerElement = modalElement.querySelector('.-header')
        const footerElement = modalElement.querySelector('.-footer')
        const bodyElement = modalElement.querySelector('.-body')

        if (bodyElement) {
            let headerHeight = 0
            let footerHeight = 0

            if (headerElement) {
                headerHeight = headerElement.offsetHeight
            }

            if (footerElement) {
                footerHeight = footerElement.offsetHeight
            }

            const modalHeight = isSmallScreen
                ? window.innerHeight
                : window.innerHeight - 100
            const newBodyHeight = modalHeight - headerHeight - footerHeight

            bodyElement.style.maxHeight = newBodyHeight + 'px'

            if (isSmallScreen) {
                bodyElement.style.minHeight = newBodyHeight + 'px'
            } else {
                bodyElement.style.minHeight = 'auto'
            }
        }
    }

    useEffect(() => {
        const smallModalWithDropdownsElem = document.body.querySelector(
            '.-smallModalWithDropdowns .-body'
        )

        if (smallModalWithDropdownsElem) {
            const resizeObserver = new ResizeObserver((entries) => {
                if (entries[0].target.clientHeight < 600) {
                    entries[0].target.style.overflow = 'visible'
                } else {
                    entries[0].target.style.overflow = 'auto'
                }
            })

            resizeObserver.observe(smallModalWithDropdownsElem)
        }
    }, [open])

    useEffect(() => {
        const modalElement = document.body.querySelector('.m-modal.-show')

        if (modalElement) {
            calculateBodyHeight(modalElement)

            window.addEventListener('resize', () =>
                calculateBodyHeight(modalElement)
            )
            return () =>
                window.removeEventListener('resize', () =>
                    calculateBodyHeight(modalElement)
                )
        }
    }, [open, isSmallScreen])

    return (
        <div
            className={`m-modal ${open ? '-show' : ''} ${
                smallModal ? '-smallModal' : ''
            } ${customClass || ''} ${
                smallModalWithDropdowns ? '-smallModalWithDropdowns' : ''
            } ${buttons ? '-withButtons' : '-withoutButtons'} ${
                title ? '-withTitle' : '-withoutTitle'
            } ${formInForm ? '-formInForm' : ''} ${
                hidePrevBtn ? '-hidePrevBtn' : ''
            } ${widerSmallModal ? '-smallModal -wider' : ''}`}
        >
            <div className="wrapper">
                <div className="m-modal__content" ref={hocRef}>
                    <div
                        className={`${
                            smallModalWithDropdowns ? '-insideContent' : ''
                        } fullWidth`}
                    >
                        {(setOpen || closeButton) && (
                            <span
                                onClick={closeButton || closeModal}
                                className="removeItemIcon"
                            ></span>
                        )}
                        <div className="_wr">
                            {title && (
                                <ModalTitle
                                    title={title}
                                    noTranslate={noTranslateTitle}
                                    smallModal={smallModal}
                                />
                            )}
                            {formInForm ? (
                                children
                            ) : (
                                <div className="-body">
                                    <div className="_w -bodyContent">
                                        {children}
                                    </div>
                                </div>
                            )}
                            {buttons && (
                                <ModalButtons
                                    buttons={buttons}
                                    isSubmitting={isSubmitting}
                                    values={values}
                                    setOpen={setOpen}
                                    hidePrevBtn={hidePrevBtn}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    open: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    setOpen: PropTypes.func,
    closeOnClickOutside: PropTypes.bool,
    customClass: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
    title: PropTypes.string,
    smallModalWithDropdowns: PropTypes.bool,
    buttons: PropTypes.object,
    noTranslateTitle: PropTypes.bool,
    formInForm: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    values: PropTypes.object,
    hidePrevBtn: PropTypes.bool,
    smallModal: PropTypes.bool,
}

Modal.defaultProps = {
    open: true,
    closeOnClickOutside: true,
    smallModalWithDropdowns: false,
    noTranslateTitle: false,
    formInForm: false,
    isSubmitting: false,
    hidePrevBtn: false,
    smallModal: false,
}

export default Modal
