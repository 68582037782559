import React from 'react'
import CertificatesVerification from './CertificatesVerification'

const certificatesVerificationObject = {
    title: 'Certificates verification',
    description:
        'The certificate verification process ensures that your certifications are validated and easily accessible for verification by employers, organizations, and other stakeholders. Verified certificates can be shared through QR codes and added to Google and Apple Wallets for convenient access.',
    sections: [
        {
            title: 'Certification verification process',
            list: [
                {
                    item: (
                        <>
                            Add your certificate details in{' '}
                            <span className="a-mediumTextSemiBold">
                                Education and training / Certificates
                            </span>
                            .
                        </>
                    ),
                },
                {
                    item: (
                        <>
                            Select the certificate issuer from a predefined list
                            (e.g.,{' '}
                            <span className="a-mediumTextSemiBold">
                                ADCI, IMCA
                            </span>
                            ).
                        </>
                    ),
                    listItem: [
                        {
                            item: (
                                <>
                                    For{' '}
                                    <span className="a-mediumTextSemiBold">
                                        ADCI
                                    </span>
                                    , the system automatically verifies the
                                    certificate.
                                </>
                            ),
                        },
                    ],
                },
                {
                    item: (
                        <>
                            Once verified, you will be notified and the
                            certificate is displayed with a{' '}
                            <span className="a-mediumTextSemiBold">
                                verification badge
                            </span>{' '}
                            in the certification list.
                        </>
                    ),
                },
            ],
        },
        {
            title: 'QR Code for certificate verification',
            list: [
                {
                    item: (
                        <>
                            Verified certificates automatically generate a{' '}
                            <span className="a-mediumTextSemiBold">
                                unique QR code
                            </span>{' '}
                            that can be used for verification.
                        </>
                    ),
                },
                {
                    item: (
                        <span className="a-mediumTextSemiBold">
                            To view the QR code:
                        </span>
                    ),
                    listItem: [
                        {
                            item: (
                                <>
                                    Click on the{' '}
                                    <span className="a-mediumTextSemiBold">
                                        actions menu (… button)
                                    </span>{' '}
                                    next to the certificate and select{' '}
                                    <span className="a-mediumTextSemiBold">
                                        “View QR Code”
                                    </span>
                                    .
                                </>
                            ),
                        },
                        {
                            item: 'The QR code is displayed in a modal, which can also be downloaded.',
                        },
                    ],
                },
                {
                    item: (
                        <span className="a-mediumTextSemiBold">
                            Scanning the QR Code:
                        </span>
                    ),
                    listItem: [
                        {
                            item: 'Anyone can scan the QR code using a mobile device to verify the certificate.',
                        },
                        {
                            item: 'The system redirects to a secure verification page displaying all details about certificate.',
                        },
                    ],
                },
            ],
        },
        {
            title: 'Adding Certificates to Google and Apple Wallets',
            list: [
                {
                    item: (
                        <>
                            Verified certificates can be added to{' '}
                            <span className="a-mediumTextSemiBold">
                                Google Wallet
                            </span>{' '}
                            and{' '}
                            <span className="a-mediumTextSemiBold">
                                Apple Wallet
                            </span>{' '}
                            for quick access.
                        </>
                    ),
                },
                {
                    item: (
                        <span className="a-mediumTextSemiBold">
                            From Mobile App:
                        </span>
                    ),
                    listItem: [
                        {
                            item: (
                                <>
                                    Open the{' '}
                                    <span className="a-mediumTextSemiBold">
                                        Certifications
                                    </span>{' '}
                                    page, select a verified certificate, and
                                    click{' '}
                                    <span className="a-mediumTextSemiBold">
                                        “Add to Wallet”
                                    </span>
                                    .
                                </>
                            ),
                        },
                    ],
                },
                {
                    item: (
                        <span className="a-mediumTextSemiBold">
                            From Web Application (via Email):
                        </span>
                    ),
                    listItem: [
                        {
                            item: (
                                <>
                                    On the Certifications page, click{' '}
                                    <span className="a-mediumTextSemiBold">
                                        {' '}
                                        “Add to wallet via email”
                                    </span>{' '}
                                    next to a verified certificate.
                                </>
                            ),
                        },
                        {
                            item: (
                                <>
                                    Enter the recipient’s email address and
                                    click{' '}
                                    <span className="a-mediumTextSemiBold">
                                        {' '}
                                        “Send”
                                    </span>
                                    .
                                </>
                            ),
                        },
                        {
                            item: (
                                <>
                                    The recipient receives an email with an{' '}
                                    <span className="a-mediumTextSemiBold">
                                        {' '}
                                        “Add to Wallet”
                                    </span>{' '}
                                    button, which saves the certificate directly
                                    to their Google or Apple Wallet.
                                </>
                            ),
                        },
                    ],
                },
                {
                    item: (
                        <span className="a-mediumTextSemiBold">
                            Viewing in Wallet:
                        </span>
                    ),
                    listItem: [
                        {
                            item: 'Once added, the wallet entry displays your verified certificate.',
                        },
                    ],
                },
            ],
        },
    ],
    note: 'If you need further assistance with certificate verification, please contact our support team at',
    noteLink: 'support@skillndepth.com',
}

const CertificatesVerificationPage = () => {
    return (
        <CertificatesVerification
            certificatesVerificationObject={certificatesVerificationObject}
        />
    )
}

export default CertificatesVerificationPage
