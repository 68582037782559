import React from 'react'
import { Navigate, Route, Routes } from 'react-router'

import ROUTES from 'constants/routes'

import { noAuthRoutes } from './NoAuthRoutes'

const NoAuthRoutes = () => {
    const renderRoutes = (routes) => {
        if (!routes?.length) return null

        return routes.map(({ path, element, index, routes }, i) => (
            <Route key={i} index={index} path={path} element={element}>
                {renderRoutes(routes)}
            </Route>
        ))
    }

    return (
        <Routes>
            {renderRoutes(noAuthRoutes)}
            <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
        </Routes>
    )
}

export default NoAuthRoutes
