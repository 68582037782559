import React, { Fragment, useContext } from 'react'
import { useNavigate } from 'react-router'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import useLogout from 'hooks/useLogout'

import ROUTES from 'constants/routes'
import { BUTTON_SIZE, BUTTON_STATUS, BUTTON_TYPE, ROLES } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Button from 'components/Button'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import OrganizationInfo from './OrganizationInfo'
import CustomRadioGroupField from 'components/formFields/CustomRadioGroupField'

export const SIGN_UP_AS = [
    {
        ...ROLES.ROLE_DIVER,
        title: 'general.diveIndustryProfessional',
        description: 'general.diveIndustryProfessionalDescription',
        icon: ICONS.POSITION_DIVER,
    },
    {
        ...ROLES.ROLE_DIVING_CONTRACTOR,
        title: 'general.divingOrganization',
        description: 'general.divingOrganizationDescription',
        icon: ICONS.SERVICE_CONTRACTOR_ORGANIZATION,
    },
    {
        ...ROLES.ROLE_CLIENT_ORGANIZATION_ADMIN,
        title: 'general.clientOrganization',
        description: 'general.clientOrganizationDescription',
        icon: ICONS.CLIENT_ORGANIZATION,
    },
    // NOTE: New roles COMING SOON!
    {
        ...ROLES.ROLE_TRADE_ASSOCIATION_AND_CERTIFICATE_ORGANIZATION,
        title: 'general.tradeAssociationAndCertificateOrganization',
        description:
            'general.tradeAssociationAndCertificateOrganizationDescription',
        icon: ICONS.TRADE_ASSOCIATION_AND_CERTIFICATE_ORGANIZATION,
        comingSoon: true,
        disabled: true,
    },
    {
        ...ROLES.ROLE_SCIENTIFIC_ACADEMIC_INSTITUTE,
        title: 'general.scientificAcademicInstitute',
        description: 'general.scientificAcademicInstituteDescription',
        icon: ICONS.SCIENTIFIC_ACADEMIC_INSTITUTE,
        comingSoon: true,
        disabled: true,
    },
    {
        ...ROLES.ROLE_TRAINING_INSTITUTION,
        title: 'general.trainingInstitution',
        description: 'general.trainingInstitutionDescription',
        icon: ICONS.TRAINING_INSTITUTION,
        comingSoon: true,
        disabled: true,
    },
    {
        ...ROLES.ROLE_PUBLIC_SAFETY_ORGANIZATION,
        title: 'general.publicSafetyOrganization',
        description: 'general.publicSafetyOrganizationDescription',
        icon: ICONS.PUBLIC_SAFETY_ORGANIZATION,
        comingSoon: true,
        disabled: true,
    },
    {
        ...ROLES.ROLE_STAFFING_AGENCY,
        title: 'general.staffingAgency',
        description: 'general.staffingAgencyDescription',
        icon: ICONS.STAFFING_AGENCY,
        comingSoon: true,
        disabled: true,
    },
    {
        ...ROLES.ROLE_OTHER,
        title: 'general.other',
        description: 'general.otherDescription',
        icon: ICONS.OTHER_ROLES,
        comingSoon: true,
        disabled: true,
    },
]

const SignUpAs = ({
    userData,
    setUserData,
    setShowOnboarding,
    companyInvites,
    haveCompanyInvitesLoaded,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { handleLogout } = useLogout()

    const initialValues = {
        role: userData.role || ROLES.ROLE_DIVER,
    }

    const validation = Yup.object({})

    const handleSubmit = ({ role }) => {
        delete role['title']
        delete role['description']
        setUserData((data) => ({ ...data, role }))
        setShowOnboarding(true)
    }

    const handleGoBack = () => {
        navigate(ROUTES.LOGIN)
        handleLogout()
    }

    if (!haveCompanyInvitesLoaded) {
        return <></>
    }

    const invitedByOrganization = companyInvites && companyInvites.length > 0

    return (
        <div className="column aligned-center -max-Width510 -signUpAs">
            <div className="-backBtn">
                <Button
                    btnClass={BUTTON_STATUS.TERTIARY}
                    buttonSize={BUTTON_SIZE.SMALL}
                    icon={ICONS.ARROW_LEFT}
                    iconColor={COLORS.LIGHT_BLUE}
                    onClick={handleGoBack}
                    label="button.back"
                />
            </div>
            <h1 className={`-title ${!invitedByOrganization ? '-mb40' : ''}`}>
                {t('noAuth.selectRoleForSignUp')}
            </h1>
            {invitedByOrganization && (
                <OrganizationInfo
                    companyInvites={companyInvites.map((item) => item.company)}
                />
            )}
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <CustomRadioGroupField
                            name="role"
                            label="form.label.signUpRole"
                            defaultOptions={SIGN_UP_AS.map((item) => ({
                                ...item,
                                title: t(item.title),
                                description: t(item.description),
                                disabled:
                                    (invitedByOrganization &&
                                        item.name !== ROLES.ROLE_DIVER.name) ||
                                    item.disabled,
                                icon: item.icon,
                                comingSoon: item.comingSoon,
                            }))}
                            displayAttribute="title"
                            descriptionAttribute="description"
                            required
                        />
                        <div className="-mt30 _12">
                            <Button
                                btnClass={BUTTON_STATUS.PRIMARY}
                                type={BUTTON_TYPE.SUBMIT}
                                label="button.next"
                                isLoading={isSubmitting}
                                disabled={isSubmitting}
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

SignUpAs.propTypes = {
    userData: PropTypes.object.isRequired,
    setUserData: PropTypes.func.isRequired,
    setShowOnboarding: PropTypes.func.isRequired,
}

export default SignUpAs
