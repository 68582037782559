import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { editEntityService } from 'services/entity.service'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { ACCESS_CONTROL_STATUSES, ALERT_TYPES, INFO_VALUE_TYPE } from 'constants/enums'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import Info from 'components/Info'
import InfoFiles from 'components/InfoFiles'
import Separator from 'components/Separator'
import CardContainer from 'components/CardContainer'
import CardHeader from 'components/card/CardHeader'
import Checkbox from 'components/Checkbox'

const FeedbackSummary = ({
    data,
    meta,
    firstLevelVerification,
    diveRecordId,
    verification,
    diveRecord,
    creatorId,
}) => {
    const t = useTranslate()

    const { userId } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const diveRecordDiveSummaryData = data?.diveRecordDiveSummary
    const diveRecordVerificationData = data?.diveRecordVerification
    const { firstLevelVerificationToken, verifiedLevel } = diveRecord
    const isUserCreated = userId === creatorId
    const dataAccessStatus = meta?.dataAccessStatus

    const handleChange = async (e) => {
        const checked = e.target.checked
        try {
            await editEntityService(
                ENTITIES.DIVE_RECORD_DIVE_SUMMARY,
                diveRecordDiveSummaryData.id,
                {
                    hideFeedback: checked,
                }
            )
            diveRecordDiveSummaryData.hideFeedback = checked
            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const hasFilesAccess = isUserCreated ||
        [ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id].includes(dataAccessStatus)

    if (!data) return null

    return (
        <div className="column">
            {isUserCreated && (
                <div className="-mb20">
                    <Checkbox
                        name="hideFeedback"
                        label="form.label.hideFeedback"
                        tooltip="general.hideFeedbackTooltip"
                        icon={ICONS.HELP_AND_INFO}
                        onChange={handleChange}
                        value={diveRecordDiveSummaryData.hideFeedback}
                        translate
                    />
                </div>
            )}
            {diveRecordVerificationData.allowedFeedbackData ? (
                <CardContainer noBorderCard>
                    <CardHeader
                        title={
                            verification
                                ? `${t(
                                      'general.diveRecord'
                                  )} No ${diveRecordId}`
                                : null
                        }
                    />
                    <span className="-mb5 a-bodyTextRegular">
                        {t('general.proficiencyScoringOfDivingAttributes')}
                    </span>
                    <span className="-mb20 a-mediumText a-lightText">
                        {t('general.ratingDescription')}
                    </span>
                    <Info
                        label="form.label.safetyMindedness"
                        value={diveRecordVerificationData.safetyMindedness}
                        withSpaceBetween
                        withBorder
                    />
                    <Info
                        label="form.label.comfortInWater"
                        value={diveRecordVerificationData.comfortInWater}
                        withSpaceBetween
                        withBorder
                    />
                    <Info
                        label="form.label.comfortInTask"
                        value={diveRecordVerificationData.comfortInTask}
                        withSpaceBetween
                        withBorder
                    />
                    <Info
                        label="form.label.taskCompletion"
                        value={diveRecordVerificationData.taskCompletion}
                        withSpaceBetween
                        withBorder
                    />
                    <Info
                        label="form.label.useOfTools"
                        value={diveRecordVerificationData.useOfTools}
                        withSpaceBetween
                        withBorder
                    />
                    <Info
                        label="form.label.useOfTechnique"
                        value={diveRecordVerificationData.useOfTechnique}
                        withSpaceBetween
                        withBorder
                    />
                    <Info
                        label="form.label.communication"
                        value={diveRecordVerificationData.communication}
                        withSpaceBetween
                        withBorder
                    />
                    <Info
                        label="form.label.takingDirection"
                        value={diveRecordVerificationData.takingDirection}
                        withSpaceBetween
                        withBorder
                    />
                    <Info
                        label="form.label.independentWork"
                        value={diveRecordVerificationData.independentWork}
                        withSpaceBetween
                        withBorder
                    />
                    {diveRecordVerificationData.comment && (
                        <Info
                            label="general.additionalRemarks"
                            value={diveRecordVerificationData.comment}
                            column
                        />
                    )}
                    {verifiedLevel === 1 && firstLevelVerification && (
                        <Fragment>
                            <Separator fullWidth />
                            <span className="-mb20 a-bodyTextRegular">
                                {t('general.verifiedBy')}
                            </span>
                            <Info
                                label="form.label.fullName"
                                value={`${firstLevelVerification.firstName} ${firstLevelVerification.lastName}`}
                                withBorder
                                withSpaceBetween
                            />
                            {firstLevelVerification.company && (
                                <Info
                                    label="form.label.company"
                                    value={firstLevelVerification.company}
                                    withBorder
                                    withSpaceBetween
                                />
                            )}
                            <Info
                                label="general.signature"
                                value={firstLevelVerification.signature}
                                valueType={INFO_VALUE_TYPE.SIGNATURE}
                                withBorder
                                withSpaceBetween
                            />
                            {hasFilesAccess && (
                                <InfoFiles
                                    label="form.label.supervisorCard"
                                    files={[firstLevelVerification.file]}
                                    fetchEntity={`${ENTITIES.FILE}/${ENTITIES.DIVE_RECORD_FIRST_VERIFICATION_UPLOAD}/${firstLevelVerificationToken}?fileId=`}
                                    withSpaceBetween
                                />
                            )}
                        </Fragment>
                    )}
                </CardContainer>
            ) : (
                <span className="a-bodyTextRegular a-lightText">
                    {t('general.restrictedFeedback')}
                </span>
            )}
        </div>
    )
}

FeedbackSummary.propTypes = {
    data: PropTypes.object,
    meta: PropTypes.object,
    firstLevelVerification: PropTypes.number,
    diveRecordId: PropTypes.number,
    verification: PropTypes.bool,
    diveRecord: PropTypes.object,
    creatorId: PropTypes.number,
}

export default FeedbackSummary
