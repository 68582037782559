import { Form, Formik } from 'formik'
import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Link, useNavigate, useParams } from 'react-router'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { loginService, registerUserService } from 'services/auth.service'
import { getEntityByIdService } from 'services/entity.service'

import {
    BUTTON_STATUS,
    BUTTON_TYPE,
    INPUT_FILED_TYPE,
} from 'constants/enums'
import REGEXP from 'constants/regex'
import ROUTES from 'constants/routes'
import ENTITIES from 'constants/entities'

import Button from 'components/Button'
import InputField from 'components/formFields/InputField'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import { formatAppErrorCodeError } from 'utils/alertHelper'

const RegisterPassword = () => {
    const t = useTranslate()

    const navigate = useNavigate()
    const { token } = useParams()
    const { setAlert } = useContext(AlertContext)
    const { setCurrentUser } = useContext(CurrentUserContext)

    const handleSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            const registerData = await registerUserService(formData, token)
            const userId = await loginService({
                identifier: registerData.data.attributes.email,
                password: formData.password,
            })

            if (userId) {
                const { data } = await getEntityByIdService(
                    ENTITIES.USER,
                    userId,
                    {
                        include:
                            'role,country,diverPositions,role,country,diverPositions,countryPhoneCode,userSetting,regions,userStatus,avatar,heroImage',
                    }
                )
                setCurrentUser(data)

                navigate(ROUTES.HOME)
            }
        } catch (error) {
            formatAppErrorCodeError(error, setAlert, t)
        } finally {
            setSubmitting(false)
        }
    }

    const initialValues = {
        password: '',
        repeatPassword: '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        password: Yup.string()
            .min(8, t('form.error.minCharacter'))
            .matches(REGEXP.PASSWORD, t('form.error.mustContain'))
            .required(requiredMessage),
        repeatPassword: Yup.string()
            .min(8, t('form.error.minCharacter'))
            .oneOf(
                [Yup.ref('password'), null],
                t('form.error.passwordsMustMatch')
            )
            .required(requiredMessage),
    })

    return (
        <div className="m-form -register -password">
            <h1 className="-title -mb40">{t('noAuth.joinTo')}</h1>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <div className="_w">
                            <div className="_12">
                                <InputField
                                    type={INPUT_FILED_TYPE.PASSWORD}
                                    name="password"
                                    required
                                />

                                <div className="a-captionsTextRegular a-lightText -descriptionText -passwordDescription">
                                    {t('noAuth.passwordDescription')}
                                </div>
                                <InputField
                                    type={INPUT_FILED_TYPE.PASSWORD}
                                    name="repeatPassword"
                                    placeholder="form.placeholder.password"
                                    required
                                />

                                <Button
                                    btnClass={BUTTON_STATUS.PRIMARY}
                                    type={BUTTON_TYPE.SUBMIT}
                                    label="button.signUp"
                                    isLoading={isSubmitting}
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
            <span className="m-form__link">
                {t('links.alreadyHave')}{' '}
                <Link to={ROUTES.LOGIN}>{t('links.signIn')}</Link>
            </span>
        </div>
    )
}

export default RegisterPassword
