import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import useFormikFieldChange from 'hooks/useFormikFieldChange'

import ENTITIES from 'constants/entities'
import { PRIMARY_TRAINING_TYPE } from 'constants/enums'

import { InputField, SelectField } from 'components/formFields'
import EntityForm from './EntityForm'

const { OTHER_TRAINING } = PRIMARY_TRAINING_TYPE

const TrainingTypeFormContext = () => {
    const primaryTrainingTypeChangedHandler = (
        values,
        setFieldValue,
        setFieldTouched
    ) => {
        setFieldValue('primaryTrainingTypeCategory', null)
        setFieldTouched('primaryTrainingTypeCategory', false)
    }

    useFormikFieldChange(
        ['primaryTrainingType'],
        primaryTrainingTypeChangedHandler
    )
}

const TrainingTypeForm = (props) => {
    const t = useTranslate()

    const initialValues = {
        name: props.initialData?.name ?? '',
        primaryTrainingType: props.initialData?.primaryTrainingType ?? null,
        primaryTrainingTypeCategory:
            props.initialData?.primaryTrainingTypeCategory ?? null,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object().shape({
        name: Yup.string().required(requiredMessage),
        primaryTrainingType: Yup.object().required(requiredMessage),
        primaryTrainingTypeCategory: Yup.object().when('primaryTrainingType', {
            is: (primaryTrainingType) =>
                primaryTrainingType?.id === OTHER_TRAINING,
            then: () => Yup.object().required(requiredMessage),
            otherwise: () => Yup.object().nullable(),
        }),
    })

    return (
        <EntityForm
            {...props}
            customInitialValues={initialValues}
            customValidation={validation}
            CustomFormFields={({ values: { primaryTrainingType } }) => (
                <Fragment>
                    <TrainingTypeFormContext />
                    <div className="_12 _l6">
                        <InputField
                            name="name"
                            label="form.label.entityName"
                            placeholder="form.placeholder.entityName"
                            required
                        />
                    </div>
                    <div className="_12 _l6">
                        <SelectField
                            name="primaryTrainingType"
                            label="form.label.primaryTrainingType"
                            placeholder="form.placeholder.primaryTrainingType"
                            entityType={ENTITIES.PRIMARY_TRAINING_TYPE}
                            required
                        />
                    </div>
                    {primaryTrainingType?.id === OTHER_TRAINING && (
                        <div className="_12 _l6">
                            <SelectField
                                name="primaryTrainingTypeCategory"
                                entityType={
                                    ENTITIES.PRIMARY_TRAINING_TYPE_CATEGORY
                                }
                                params={{
                                    'primaryTrainingType.id':
                                        primaryTrainingType?.id,
                                }}
                                reload={primaryTrainingType?.id}
                                required
                            />
                        </div>
                    )}
                </Fragment>
            )}
        />
    )
}

export default TrainingTypeForm
