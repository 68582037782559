import React from 'react'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'

import AvatarDropdown from 'components/AvatarDropdown'
import Logo from 'components/Logo'

const Header = ({
    openSidebar,
    setOpenSidebar,
    hamBtnRef,
    fixedSidebar,
    admin = false,
}) => {
    return (
        <header className="m-header">
            <div className={`-topLevel ${fixedSidebar ? '-fixedSidebar' : ''}`}>
                <div className="_wr">
                    <div className="_w">
                        <div className="m-header__wrapper fullWidth">
                            <Logo
                                className="m-header__logo"
                                color1={COLORS.DARK_BLUE}
                                color2={COLORS.LIGHT_BLUE}
                            />
                            <div></div>
                            <div className="m-header__main">
                                <AvatarDropdown admin={admin} />
                                <div
                                    className={`m-toggler ${admin ? '-admin' : ''}`}
                                    ref={hamBtnRef}
                                    onClick={() => setOpenSidebar(!openSidebar)}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    openSidebar: PropTypes.bool.isRequired,
    setOpenSidebar: PropTypes.func.isRequired,
    hamBtnRef: PropTypes.object,
    admin: PropTypes.bool,
}

export default Header
