import { ROLES } from 'constants/enums'
import ROUTES from 'constants/routes'
import ENTITIES from 'constants/entities'

const { ROLE_SUPER_ADMIN } = ROLES

export const ENTITY_MANAGEMENT_GROUPS = [
    {
        key: 'general_group',
        label: 'menuItems.general',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.COUNTRY,
                label: 'entityManagement.countries',
                to: ROUTES.COUNTRY_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
                create: false,
            },
            {
                key: ENTITIES.REGION,
                label: 'entityManagement.regions',
                to: ROUTES.REGION_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.INDUSTRY,
                label: 'entityManagement.industries',
                to: ROUTES.INDUSTRY_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
    {
        key: 'user_profile_group',
        label: 'menuItems.userProfile',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.LANGUAGE,
                label: 'entityManagement.languages',
                to: ROUTES.LANGUAGE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.LANGUAGE_PROFICIENCY,
                label: 'entityManagement.language_proficiencies',
                to: ROUTES.LANGUAGE_PROFICIENCY_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
                create: false,
            },
            {
                key: ENTITIES.DIVER_POSITION,
                label: 'entityManagement.diver_positions',
                to: ROUTES.DIVER_POSITION_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.DIVER_POSITION_CATEGORY,
                label: 'entityManagement.diver_position_categories',
                to: ROUTES.DIVER_POSITION_CATEGORY_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
                create: false,
            },
        ],
    },
    {
        key: 'medical_record_group',
        label: 'menuItems.medicalRecord',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.MEDICAL_RECORD_TYPE,
                label: 'entityManagement.medical_record_types',
                to: ROUTES.MEDICAL_RECORD_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.MEDICAL_EXAM_TYPE,
                label: 'entityManagement.medical_exams',
                to: ROUTES.MEDICAL_EXAM_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.DIVING_MEDICAL_EXAM_STANDARD,
                label: 'entityManagement.medical_exam_standards',
                to: ROUTES.MEDICAL_EXAM_STANDARD_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
    {
        key: 'education_and_training_group',
        label: 'menuItems.educationAndTraining',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.PRIMARY_TRAINING_TYPE_CATEGORY,
                label: 'entityManagement.primary_training_type_categories',
                to: ROUTES.PRIMARY_TRAINING_TYPE_CATEGORY_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.PRIMARY_TRAINING_TYPE,
                label: 'entityManagement.primary_training_types',
                to: ROUTES.PRIMARY_TRAINING_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
                create: false,
            },
            {
                key: ENTITIES.DIVE_SCHOOL,
                label: 'entityManagement.dive_schools',
                to: ROUTES.DIVE_TRAINING_SCHOOL_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.TRAINING_TYPE,
                label: 'entityManagement.training_types',
                to: ROUTES.TRAINING_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.PROFICIENCY_LEVEL,
                label: 'entityManagement.proficiency_levels',
                to: ROUTES.PROFICIENCY_LEVEL_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.BRANCH,
                label: 'entityManagement.branches',
                to: ROUTES.BRANCH_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.CERTIFICATE_ISSUER,
                label: 'entityManagement.certificate_issuers',
                to: ROUTES.CERTIFICATE_ISSUER,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
    {
        key: 'equipment_group',
        label: 'menuItems.equipment',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.EQUIPMENT_TYPE,
                label: 'entityManagement.equipment_types',
                to: ROUTES.EQUIPMENT_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.EQUIPMENT_BRAND,
                label: 'entityManagement.equipment_brands',
                to: ROUTES.EQUIPMENT_BRAND_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
    {
        key: 'past_employment_group',
        label: 'menuItems.pastEmployment',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.EMPLOYER,
                label: 'entityManagement.employers',
                to: ROUTES.EMPLOYER_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.PAST_EMPLOYMENT_POSITION,
                label: 'entityManagement.past_employment_positions',
                to: ROUTES.PAST_EMPLOYMENT_POSITION_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
    {
        key: 'skill_group',
        label: 'menuItems.skill',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.SKILL_CATEGORY,
                label: 'entityManagement.skill_categories',
                to: ROUTES.SKILL_CATEGORY_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.SKILL,
                label: 'entityManagement.skills',
                to: ROUTES.SKILL_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
    {
        key: 'dive_record_general_information_group',
        label: 'menuItems.diveRecordGeneralInformation',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.DIVING_PLATFORM,
                label: 'entityManagement.diving_platforms',
                to: ROUTES.DIVING_PLATFORM_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.DIVE_PHYSICAL_CHARACTERISTIC,
                label: 'entityManagement.dive_physical_characteristics',
                to: ROUTES.DIVE_PHYSICAL_CHARACTERISTIC_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.WATER_QUALITY,
                label: 'entityManagement.water_qualities',
                to: ROUTES.WATER_QUALITY_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.BOTTOM_CONDITION,
                label: 'entityManagement.bottom_conditions',
                to: ROUTES.BOTTOM_CONDITION_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
    {
        key: 'dive_record_equipment_group',
        label: 'menuItems.diveRecordEquipment',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.BREATHING_APPARATUS_TYPE,
                label: 'entityManagement.breathing_apparatus_types',
                to: ROUTES.BREATHING_APPARATUS_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.DIVING_APPAREL_PROTECTION,
                label: 'entityManagement.diving_apparel_protections',
                to: ROUTES.DIVING_APPAREL_PROTECTION_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.OTHER_DIVING_APPAREL_PROTECTION,
                label: 'entityManagement.other_diving_apparel_protections',
                to: ROUTES.OTHER_DIVING_APPAREL_PROTECTION_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.BUOYANCY_FLOTATION,
                label: 'entityManagement.buoyancy_flotations',
                to: ROUTES.BUOYANCY_FLOTATION_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.OTHER_EQUIPMENT_OUTFITTING,
                label: 'entityManagement.other_equipment_outfittings',
                to: ROUTES.OTHER_EQUIPMENT_OUTFITTING_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.GAS_CYLINDER_PURPOSE,
                label: 'entityManagement.gas_cylinder_purposes',
                to: ROUTES.GAS_CYLINDER_PURPOSE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.GAS_CONTENTS,
                label: 'entityManagement.gas_contents',
                to: ROUTES.GAS_CONTENT_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
                create: false,
            },
        ],
    },
    {
        key: 'dive_event_group',
        label: 'menuItems.diveEvent',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.DECOMPRESSION_TYPE,
                label: 'entityManagement.decompression_types',
                to: ROUTES.DECOMPRESSION_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.DECOMPRESSION_TABLE_TYPE,
                label: 'entityManagement.decompression_table_types',
                to: ROUTES.DECOMPRESSION_TABLE_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
    {
        key: 'work_performed_group',
        label: 'menuItems.workPerformed',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.DIVE_RECORD_WORK_TASK,
                label: 'entityManagement.dive_record_work_tasks',
                to: ROUTES.DIVE_RECORD_WORK_TASK_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.DIVE_RECORD_SUPERVISOR_TASK,
                label: 'entityManagement.dive_record_supervisor_tasks',
                to: ROUTES.DIVE_RECORD_SUPERVISOR_TASK_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.DIVE_RECORD_WORK_OBJECT,
                label: 'entityManagement.dive_record_work_objects',
                to: ROUTES.DIVE_RECORD_WORK_OBJECT_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.DIVE_RECORD_WORK_ACTIVITY,
                label: 'entityManagement.dive_record_work_activities',
                to: ROUTES.DIVE_RECORD_WORK_ACTIVITY_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.DIVE_RECORD_WORK_TOOL,
                label: 'entityManagement.dive_record_work_tools',
                to: ROUTES.DIVE_RECORD_WORK_TOOL_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
    {
        key: 'dive_performance_group',
        label: 'menuItems.divePerformance',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.TRAUMA_INJURY_LEVEL,
                label: 'entityManagement.trauma_injury_levels',
                to: ROUTES.TRAUMA_INJURY_LEVEL_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.MENTAL_WELLNESS_ELEMENTS,
                label: 'entityManagement.mental_wellness_elements',
                to: ROUTES.MENTAL_WELLNESS_ELEMENT_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.DECOMPRESSION_ILLNESS,
                label: 'entityManagement.decompression_illnesses',
                to: ROUTES.DECOMPRESSION_ILLNESS_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.PRESSURE_INJURY_TYPE,
                label: 'entityManagement.pressure_injury_types',
                to: ROUTES.PRESSURE_INJURY_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.EQUIPMENT_FAILURE_TYPE,
                label: 'entityManagement.equipment_failure_types',
                to: ROUTES.EQUIPMENT_FAILURE_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.CLOSE_CALL_TYPE,
                label: 'entityManagement.close_call_types',
                to: ROUTES.CLOSE_CALL_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
    {
        key: 'project_group',
        label: 'menuItems.project',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.PROJECT_STATUS,
                label: 'entityManagement.project_statuses',
                to: ROUTES.PROJECT_STATUS_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.PROJECT_INDUSTRY_TYPES,
                label: 'entityManagement.project_industry_types',
                to: ROUTES.PROJECT_INDUSTRY_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.LOCATION_TYPE,
                label: 'entityManagement.location_types',
                to: ROUTES.LOCATION_TYPE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.DIVING_MODE,
                label: 'entityManagement.diving_modes',
                to: ROUTES.DIVING_MODE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
                create: false,
            },
            {
                key: ENTITIES.BODY_OF_WATER,
                label: 'entityManagement.body_of_waters',
                to: ROUTES.BODY_OF_WATER_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
    {
        key: 'company_group',
        label: 'menuItems.company',
        roles: [ROLE_SUPER_ADMIN],
        subitems: [
            {
                key: ENTITIES.SERVICE,
                label: 'entityManagement.services',
                to: ROUTES.SERVICE_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.ORGANIZATION_TYPES,
                label: 'entityManagement.organization_types',
                to: ROUTES.ORGANIZATION_TYPES_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.VESSELS,
                label: 'entityManagement.vessels',
                to: ROUTES.VESSELS_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
            {
                key: ENTITIES.CLIENTS,
                label: 'entityManagement.clients',
                to: ROUTES.CLIENTS_MANAGEMENT,
                roles: [ROLE_SUPER_ADMIN],
            },
        ],
    },
]
