import React, { Fragment, useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslate } from 'react-polyglot'

import axios, { GET } from 'utils/axiosClient'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'

import useFetchDataById from 'hooks/useFetchDataById'

import { PROFILE_STEPS } from 'constants/constants'
import { ALERT_TYPES, BUTTON_SIZE } from 'constants/enums'
import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'

import Button from './Button'
import IdentityVerificationModal from 'screens/diver/Homepage/components/IdentityVerificationModal'
import Loader from './Loader'

const UnverifiedDiverCardOverlay = () => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { userId, currentUser, isDiver } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const [verificationUrl, setVerificationUrl] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const { data: diverProfile } = useFetchDataById(
        ENTITIES.DIVER_PROFILE,
        userId,
        {},
        isDiver,
        isDiver
    )

    const shouldCompleteProfile =
        PROFILE_STEPS.filter(
            ({ key }) => diverProfile?.completionProgress[key] === 0
        ).length > 0

    const handleCompleteProfile = () => {
        navigate(`${ROUTES.DIVER_PROFILE}/${currentUser.profileHash}`)
    }

    const openVerificationIdentityModal = () => {
        setIsLoading(true)
        axios(GET, `/identity-verification/get-link`, {})
            .then(async ({ data }) => {
                if (data && data.url) {
                    setVerificationUrl(data.url)
                }
                setIsLoading(false)
            })
            .catch((error) => {
                setAlert(error, ALERT_TYPES.ERROR, t)
                setIsLoading(false)
            })
    }

    if (!isDiver || !diverProfile) return null

    return (
        <Fragment>
            <div className="column aligned-center overlayContainer">
                <h3>
                    {shouldCompleteProfile
                        ? t('general.completeYourProfile')
                        : t('identityVerification.modalTitle')}
                </h3>
                <p className="a-bodyTextRegular a-lightText -opacity-60 text-align-center  -mt10 -mb30">
                    {shouldCompleteProfile
                        ? t(
                              'general.completeProfileToAccessCompleteInformation'
                          )
                        : t(
                              'identityVerification.oneStepFromAccessingCompleteInformation'
                          )}
                </p>
                <Button
                    label={
                        shouldCompleteProfile
                            ? 'button.completeProfile'
                            : 'identityVerification.startButton'
                    }
                    buttonSize={BUTTON_SIZE.MEDIUM}
                    onClick={
                        shouldCompleteProfile
                            ? handleCompleteProfile
                            : openVerificationIdentityModal
                    }
                />
            </div>
            {verificationUrl && (
                <IdentityVerificationModal
                    verificationUrl={verificationUrl}
                    setVerificationUrl={setVerificationUrl}
                />
            )}
            {isLoading && <Loader />}
        </Fragment>
    )
}

export default UnverifiedDiverCardOverlay
