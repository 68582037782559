import { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router'

import axios, { POST } from 'utils/axiosClient'
import { formatDateRange, formatRoleOptions, formatUserSubscriptionFilter } from 'utils/filterButtonFormatters'

import { AlertContext } from 'contexts/AlertContext'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'

import { formatUserRole, formatUserSubscription } from 'utils/tableCellFormatters'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES, BUTTON_STATUS } from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import FILTERS from 'constants/filters'

import { Table } from 'components/table'
import ManagementLayout from '../components/ManagementLayout'
import IndividualAccountBreadcrumbs from './components/IndividualAccountBreadcrumbs'
import IndividualAccountFilters from './components/IndividualAccountFilters'
import IndividualAccountChangePassword from './components/IndividualAccountChangePassword'
import IndividualAccountChangeEmail from './components/IndividualAccountChangeEmail'
import IndividualAccountChangePhoneNumber from './components/IndividualAccountChangePhoneNumber'
import IndividualAccountChangeSubscriptionType from './components/IndividualAccountChangeSubscriptionType'
import DateCell from '../../../components/table/tableCells/DateCell'

const TABLE_HEADER = [
    {
        key: 'id',
        title: 'table.header.id',
        showOnSmallScreens: true,
    },
    {
        key: 'firstName',
        title: 'table.header.firstName',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        smallScreenHeader: true,
        sortable: true,
    },
    {
        key: 'lastName',
        title: 'table.header.lastName',
        showOnSmallScreens: true,
        sortable: true,
    },
    {
        key: 'email',
        title: 'table.header.email',
        showOnSmallScreens: true,
        sortable: true,
    },
    {
        key: 'registeredAt',
        title: 'table.header.registeredAt',
        showOnSmallScreens: true,
        sortable: true,
        CellComponent: DateCell,
    },
    {
        key: 'currentTitle',
        title: 'table.header.currentTitle',
        showOnSmallScreens: true,
        sortable: true,
    },
    // {
    //     key: 'userStatus.name',
    //     title: 'table.header.availability',
    //     showOnSmallScreens: true,
    //     sortable: true,
    // },
    {
        key: 'role.name',
        title: 'table.header.role',
        showOnSmallScreens: true,
        sortable: true,
        formatter: formatUserRole,
    },
    {
        key: 'userSubscription.userSubscriptionType',
        title: 'table.header.subscriptionType',
        showOnSmallScreens: true,
        sortable: false,
        formatter: formatUserSubscription
    },
]

const filters = {
    firstName: { type: 'text' },
    lastName: { type: 'text' },
    currentTitle: { type: 'text' },
    email: { type: 'text' },
    userStatus: {
        entityType: ENTITIES.USER_STATUSES,
        displayAttribute: 'name',
    },
    userSubscription: {
        displayAttribute: 'name',
        formatter: formatUserSubscriptionFilter,
    },
    role: {
        entityType: ENTITIES.ROLE,
        displayAttribute: 'name',
        formatter: formatRoleOptions,
    },
    registeredAt: { type: 'dateRange', formatter: formatDateRange },
}

const IndividualAccountManagement = () => {
    const navigate = useNavigate()

    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const [changeUserPassword, setChangeUserPassword] = useState(null)
    const [changeSubscriptionType, setChangeSubscriptionType] = useState(null)
    const [changeEmail, setChangeEmail] = useState(null)
    const [changePhoneNumber, setChangePhoneNumber] = useState(null)

    const {
        data: usersData,
        meta,
        isLoading,
        fetchData,
        fetchDataAfterDeletion,
    } = useFetchDataByQueryParams(ENTITIES.USER, {
        include: 'role,userStatus,countryPhoneCode,userSubscription',
    })

    const handleEdit = ({ id }) => {
        navigate(`/${ROUTES.INDIVIDUAL_ACCOUNT_MANAGEMENT}${ROUTES.EDIT}/${id}`)
    }

    const handleDelete = async ({ id }) => {
        try {
            await axios(
                POST,
                `/${ENTITIES.USER}/${ENTITIES.ADMIN}/${ENTITIES.DELETE_USER}/${id}`
            )
            setAlert(t('message.successfullyDeletedUser'), ALERT_TYPES.SUCCESS)
            fetchDataAfterDeletion()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleChangePassword = (user) => {
        setChangeUserPassword(user)
    }

    const handleChangeSubscriptionType = (user) => {
        setChangeSubscriptionType(user)
    }

    const handleChangeEmail = (user) => {
        setChangeEmail(user)
    }

    const handleChangePhoneNumber = (user) => {
        setChangePhoneNumber(user)
    }

    return (
        <ManagementLayout BreadcrumbsComponent={IndividualAccountBreadcrumbs}>
            <Table
                title="general.individualAccounts"
                fullWidthTitle
                headerItems={TABLE_HEADER}
                data={usersData}
                totalItems={meta?.totalItems}
                isLoading={isLoading}
                searchable={true}
                searchKey="fullName"
                rowDropdownActions={[
                    {
                        handleAction: handleEdit,
                        icon: ICONS.EDIT,
                        label: 'button.edit',
                        btnClass: BUTTON_STATUS.TERTIARY,
                    },
                    {
                        handleAction: handleChangePassword,
                        icon: ICONS.DATA_ACCESS,
                        label: 'button.changePassword',
                        btnClass: BUTTON_STATUS.TERTIARY,
                    },
                    {
                        handleAction: handleChangeEmail,
                        icon: ICONS.MAIL,
                        label: 'button.changeEmail',
                        btnClass: BUTTON_STATUS.TERTIARY,
                    },
                    {
                        handleAction: handleChangePhoneNumber,
                        icon: ICONS.PHONE,
                        label: 'button.changePhoneNumber',
                        btnClass: BUTTON_STATUS.TERTIARY,
                    },
                    {
                        handleAction: handleChangeSubscriptionType,
                        icon: ICONS.PRO,
                        label: 'button.changeSubscriptionType',
                        btnClass: BUTTON_STATUS.TERTIARY,
                    },
                    {
                        handleAction: handleDelete,
                        icon: ICONS.DELETE,
                        iconColor: 'red',
                        label: 'button.delete',
                        title: 'general.deleteUser',
                        customConfirmationMessage: ({ fullName }) =>
                            t('message.areYouSureDeleteUser', {user: fullName}),
                        btnClass: BUTTON_STATUS.TERTIARY,
                        textColor: '-redText',
                    },
                ]}
                FilterComponent={IndividualAccountFilters}
                filters={filters}
                filtersKey={FILTERS.SUPER_ADMIN_INDIVIDUAL_FILTERS}
            />
            {changeUserPassword && (
                <IndividualAccountChangePassword
                    open={!!changeUserPassword}
                    setOpen={setChangeUserPassword}
                    user={changeUserPassword}
                />
            )}
            {changeEmail && (
                <IndividualAccountChangeEmail
                    open={!!changeEmail}
                    setOpen={setChangeEmail}
                    user={changeEmail}
                />
            )}
            {changePhoneNumber && (
                <IndividualAccountChangePhoneNumber
                    open={!!changePhoneNumber}
                    setOpen={setChangePhoneNumber}
                    user={changePhoneNumber}
                    fetchData={fetchData}
                />
            )}
            {changeSubscriptionType && (
                <IndividualAccountChangeSubscriptionType
                    open={!!changeSubscriptionType}
                    setOpen={setChangeSubscriptionType}
                    user={changeSubscriptionType}
                    fetchData={fetchData}
                />
            )}
        </ManagementLayout>
    )
}

export default IndividualAccountManagement
