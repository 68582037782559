import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { Link } from 'react-router'

const DownloadMobileAppSingleBox = ({
    storeName,
    qrCodeSrc,
    storeLink,
    storeImgSrc,
    storeImgAlt,
    qrCodeAlt,
}) => {
    const t = useTranslate()
    return (
        <div className="column -gap20 fullWidth centered-text aligned-center justify-center bg-darkBlue02 -p20 -borderRadius10">
            <div className="column fullWidth">
                <span className="a-mediumTextSemiBold fullWidth">
                    {t('general.scanQrCodeFor')}
                </span>
                <h3 className="fullWidth">{storeName}</h3>
            </div>
            <img src={qrCodeSrc} alt={qrCodeAlt} />
            <div className="column -gap5 aligned-center justify-center">
                <span className="a-mediumTextSemiBold fullWidth">
                    {t('general.orDownloadDirectly')}:
                </span>
                <Link to={storeLink} target="_blank">
                    <img src={storeImgSrc} alt={storeImgAlt} />
                </Link>
            </div>
        </div>
    )
}

DownloadMobileAppSingleBox.propTypes = {
    storeName: PropTypes.string.isRequired,
    qrCodeSrc: PropTypes.string.isRequired,
    storeLink: PropTypes.string.isRequired,
    storeImgSrc: PropTypes.string.isRequired,
    storeImgAlt: PropTypes.string.isRequired,
    qrCodeAlt: PropTypes.string.isRequired,
}

export default DownloadMobileAppSingleBox
