import { useState } from 'react'
import PropTypes from 'prop-types'

import { BUTTON_SIZE, BUTTON_STATUS } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Button from 'components/Button'
import PersonalInfo from './PersonalInfo'
import DiverCountry from './DiverCountry'
import DiverPhoto from './DiverPhoto'
import DiverPositions from './DiverPositions'

const SIGNUP_STEPS = [PersonalInfo, DiverCountry, DiverPositions, DiverPhoto]

const DiverOnboarding = ({ role, setShowOnboarding, companyInvites }) => {
    const [step, setStep] = useState(0)
    const [userData, setUserData] = useState({ role })

    const renderCurrentStep = () => {
        const StepContent = SIGNUP_STEPS[step]
        return (
            <StepContent
                userData={userData}
                setUserData={setUserData}
                setStep={setStep}
                companyInvites={companyInvites}
            />
        )
    }

    const handleGoBack = () => {
        if (!step) {
            setShowOnboarding(false)
        } else {
            setStep(step - 1)
        }
    }

    return (
        <div className="m-form -register -mb20">
            <div className="-backBtn">
                <Button
                    btnClass={BUTTON_STATUS.TERTIARY}
                    buttonSize={BUTTON_SIZE.SMALL}
                    icon={ICONS.ARROW_LEFT}
                    iconColor={COLORS.LIGHT_BLUE}
                    onClick={() => handleGoBack()}
                    label="button.back"
                />
            </div>
            {renderCurrentStep()}
        </div>
    )
}

DiverOnboarding.propTypes = {
    role: PropTypes.object.isRequired,
    setShowOnboarding: PropTypes.func.isRequired,
}

export default DiverOnboarding
