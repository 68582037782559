import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import { InputField } from 'components/formFields'
import EntityForm from './EntityForm'

const CertificateIssuerForm = (props) => {
    const t = useTranslate()

    const initialValues = {
        name: props.initialData?.name ?? '',
        note: props.initialData?.note ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object().shape({
        name: Yup.string().required(requiredMessage),
        note: Yup.string(),
    })

    return (
        <EntityForm
            {...props}
            customInitialValues={initialValues}
            customValidation={validation}
            CustomFormFields={() => (
                <Fragment>
                    <div className="_12 _l6">
                        <InputField
                            name="name"
                            label="form.label.entityName"
                            placeholder="form.placeholder.entityName"
                            required
                        />
                    </div>
                    <div className="_12 _l6">
                        <InputField
                            name="note"
                            label="form.label.code"
                            placeholder="form.placeholder.code"
                        />
                    </div>
                </Fragment>
            )}
        />
    )
}

export default CertificateIssuerForm
