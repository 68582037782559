import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useChat from 'hooks/useChat'
import useWindowDimensions from 'hooks/useWindowDimension'

import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import Icon from 'components/Icon'
import ChatComponent from './ChatComponent'

const ChatButton = () => {
    const { isTablet } = useWindowDimensions()
    const { pathname, search } = useLocation()

    const { unreadMessages } = useContext(CurrentUserContext)

    const [chatOpen, setChatOpen] = useState(false)
    const [connected, setConnected] = useState(true)

    const { client, connectChatClient, disconnectChatClient } = useChat()

    const handleChangeState = (setState, value) => {
        setState(value)
    }

    useEffect(() => {
        if (!client) return

        let timeoutId

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'hidden') {
                timeoutId = setTimeout(() => {
                    disconnectChatClient()
                    handleChangeState(setConnected, false)
                }, 5000)
            } else {
                clearTimeout(timeoutId)
                if (!client?.user) {
                    connectChatClient()
                    handleChangeState(setConnected, true)
                }
            }
        }

        document.addEventListener('visibilitychange', handleVisibilityChange)

        return () => {
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            )
        }
    }, [client])

    useEffect(() => {
        setChatOpen(false)
    }, [pathname, search])

    const buttonHideConditions = [
        isTablet,
        !client?.user,
        pathname === ROUTES.CHAT,
        pathname.includes(`${ROUTES.NOT_FOUND_PAGE}`),
        pathname.includes(`${ROUTES.ACCESS_DENIED}`),
        pathname.includes(`/${ROUTES.DIVE_RECORD}/`),
        pathname.includes(`/${ROUTES.DIVE_RECORD_SUMMARY}/`),
        pathname.includes(`/${ROUTES.DIVE_RECORD_FIRST_LEVEL_VERIFICATION}/`),
        pathname.includes(`${ROUTES.CERTIFICATE_QR}`),
    ]

    if (buttonHideConditions.some((condition) => condition === true)) {
        return null
    }

    return (
        <div>
            {!chatOpen ? (
                <div className="m-floatingIcon">
                    <div
                        onClick={() => setChatOpen(true)}
                        className="floating-icon-chat"
                    >
                        <Icon name={ICONS.CHAT} color={COLORS.WHITE} />
                    </div>
                    <div
                        className={`unread-message ${
                            unreadMessages !== 0 ? '-active' : ''
                        }`}
                    >
                        <span className="a-captionsTextRegular">
                            {unreadMessages}
                        </span>
                    </div>
                </div>
            ) : (
                <ChatComponent setChatOpen={setChatOpen} />
            )}
        </div>
    )
}

export default ChatButton
