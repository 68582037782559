import React from 'react'
import { useTranslate } from 'react-polyglot'

import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'

import Breadcrumbs from 'components/Breadcrumbs'
import { getEntityFilters } from 'services/localStorage.service'
import FILTERS from 'constants/filters'

const TermsAndConditionsBreadcrumbs = () => {
    const t = useTranslate()
    return (
        <Breadcrumbs
            breadcrumbs={[
                {
                    label: 'menuItems.termsAndConditions',
                    to: `/${ROUTES.TERMS_AND_CONDITIONS}`,
                    icon: ICONS.HOMEPAGE_BREADCRUMB,
                    queryParams: getEntityFilters(
                        FILTERS.SUPER_ADMIN_TERMS_AND_CONDITIONS
                    )
                },
            ].filter((item) => item)}
        />
    )
}

export default TermsAndConditionsBreadcrumbs
