import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { getVerificationLevels } from 'data/diveRecordVerificationLevels'

import ROUTES from 'constants/routes'
import { TOOLTIP_POSITION } from 'constants/enums'

import IconShowcase from './IconShowcase'
import IconShowcaseContent from './IconShowcaseContent'

const VerificationLevelShowcase = ({
    children,
    levelOfVerification,
    badgesRef,
}) => {
    const t = useTranslate()

    const currentLevelData = getVerificationLevels(t).find(
        (item) => item.id == levelOfVerification
    )

    return (
        <IconShowcase
            position={TOOLTIP_POSITION.RIGHT}
            badgesRef={badgesRef}
            tooltip={
                <IconShowcaseContent
                    iconShowcase={currentLevelData}
                    infoText={{
                        label: t('general.riskLevel'),
                        color: currentLevelData.color,
                        value: currentLevelData.riskLevel,
                    }}
                    route={`/${ROUTES.HELP_AND_INFO}/${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}?levelId=level-${currentLevelData.id}`}
                />
            }
        >
            {children}
        </IconShowcase>
    )
}

VerificationLevelShowcase.propTypes = {
    levelOfVerification: PropTypes.number,
    badgesRef: PropTypes.object,
}

export default VerificationLevelShowcase
