import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router'

import COLORS from 'constants/colors'
import { SUPPORT } from 'constants/constants'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const Support = () => {
    const t = useTranslate()

    return (
        <div className="-defaultContent column aligned-center justify-center fullHeight">
            <Icon name={ICONS.SUPPORT_PAGE_ICON} color={COLORS.LIGHT_BLUE} />
            <span className="a-bodyTextMedium -mb20 -mt20">
                {t('general.wereHereToHelp')}
            </span>
            <p className="a-mediumText centered-text a-lightText -defaultContentDescription">
                {t('general.welcomeSupportDescription')}
            </p>
            <p className="a-mediumText centered-text a-lightText -mt30">
                {t('general.forImmediate')}
            </p>
            <div className="justify-center aligned-center -gap10 -mt10">
                <Icon
                    name={ICONS.PHONE}
                    color={COLORS.LIGHT_BLUE}
                    size={ICON_SIZE.SIZE20}
                />
                <Link
                    className="a-bodyTextRegular"
                    onClick={() => (window.location = `tel:${SUPPORT.NUMBER}`)}
                    title={SUPPORT.NUMBER}
                >
                    {SUPPORT.NUMBER}
                </Link>
            </div>
            <p className="a-mediumText centered-text a-lightText -mt30 -defaultContentDescription">
                {t('general.helpSupportDescription1')}{' '}
                <Link
                    onClick={() =>
                        (window.location = `mailto:${SUPPORT.EMAIL}`)
                    }
                    title={SUPPORT.EMAIL}
                >
                    {SUPPORT.EMAIL}
                </Link>{' '}
                {t('general.helpSupportDescription2')}
            </p>
        </div>
    )
}

export default Support
