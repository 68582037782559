import { useEffect, useRef, useState } from 'react'

const VerificationCode = ({ code, setCode, onSubmit }) => {
    const [isSubmitted, setIsSubmitted] = useState(false) // State to track submission
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)]

    const handleChange = (index, value) => {
        if (!isNaN(value) && value !== '') {
            const newCode = [...code]
            newCode[index] = value
            setCode(newCode)

            if (index < 3 && value !== '') {
                inputRefs[index + 1].current.focus()
            } else if (index === 3 && value !== '') {
                setIsSubmitted(true)
            }
        } else if (value === '') {
            const newCode = [...code]
            newCode[index] = ''
            setCode(newCode)

            if (index > 0) {
                inputRefs[index - 1].current.focus()
            }
        }
    }

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && !code[index]) {
            const newCode = [...code]
            newCode[index - 1] = ''
            setCode(newCode)
            inputRefs[index - 1].current.focus()
        } else if (e.key === 'ArrowLeft' && index > 0) {
            inputRefs[index - 1].current.focus()
        } else if (e.key === 'ArrowRight' && index < 3) {
            inputRefs[index + 1].current.focus()
        }
    }

    const handleKeyPress = (index, e) => {
        if (
            (e.key === 'ArrowLeft' || e.key === 'ArrowRight') &&
            index >= 0 &&
            index <= 3
        ) {
            e.preventDefault() // Prevent browser default behavior
        } else if (!isNaN(e.key) && index >= 0 && index <= 3) {
            handleChange(index, e.key)
        }
    }

    const handlePaste = (e) => {
        const pastedCode = e.clipboardData.getData('Text').slice(0, 4)
        if (pastedCode.length === 4) {
            const newCode = pastedCode.split('')
            setCode(newCode)
            setIsSubmitted(true)
        }
        e.preventDefault()
    }

    useEffect(() => {
        if (code[3] && isSubmitted) {
            onSubmit(code.join(''))
            setIsSubmitted(false)
            // Set flag to true after submitting
        }
    }, [code, isSubmitted])

    return (
        <div className="a-verificationCode justify-center">
            {code.map((digit, index) => (
                <input
                    key={index}
                    ref={inputRefs[index]}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    onKeyPress={(e) => handleKeyPress(index, e)}
                    onPaste={handlePaste}
                />
            ))}
        </div>
    )
}

export default VerificationCode
