import React, { Fragment, useContext, useState, useRef } from 'react'
import { useParams } from 'react-router'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { DiveRecordContext } from './contexts/DiveRecordContext'
import RecordVerificationFormContext from './formContext/RecordVerificationFormContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'

import useFetchDataById from 'hooks/useFetchDataById'
import useSaveByStep from './hooks/useSaveByStep'

import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    DIVE_RECORD_STATUS,
    ICON_SIZE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import { RATE_OPTIONS, YES_NO_BOOLEAN_OPTIONS } from 'constants/constants'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { FILE_CATEGORIES } from 'constants/fileCategories'

import {
    createEntityService,
    persistEntityService,
} from 'services/entity.service'

import {
    FileUploadField,
    InputField,
    RadioGroupField,
    TextAreaField,
} from 'components/formFields'
import Separator from 'components/Separator'
import StepButtons from 'components/StepButtons'
import Loader from 'components/Loader'
import Button from 'components/Button'
import RecordVerificationRejectModal from './components/RecordVerificationRejectModal'
import FocusError from '../../../components/FocusError'
import SignatureField from 'components/formFields/SignatureField'

const RecordVerification = () => {
    const t = useTranslate()
    const { diveRecordId, verificationToken } = useParams()
    const formRef = useRef()

    const isFirstLevelVerification = !diveRecordId && verificationToken

    const { setAlert } = useContext(AlertContext)
    const { diveRecord, handlePostSaveAction } = useContext(DiveRecordContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const [open, setOpen] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const { data: diveRecordData, isLoading } = useFetchDataById(
        ENTITIES.DIVE_RECORD,
        diveRecordId,
        {
            include: ['diveRecordVerification'],
        }
    )

    const data = diveRecordId ? diveRecordData : diveRecord.data

    // If the dive record has the first level of verification and is sent to the second level of verification,
    // during the second level of verification it is necessary to hide the feedback that was left during the first level of verification
    const hideVerificationFeedback =
        data?.verifiedLevel === 1 &&
        (data?.status === DIVE_RECORD_STATUS.SUBMITTED.id ||
            data?.status === DIVE_RECORD_STATUS.RE_SUBMITTED.id)

    const initialData = hideVerificationFeedback
        ? null
        : data?.diveRecordVerification ?? null

    const handleConfirmVerificationMessage = (goBack, goToStep) => {
        closeConfirmationModal()
        handlePostSaveAction(false, goBack, goToStep)
    }

    const handleSave = async (
        formData,
        formActions,
        complete = true,
        goBack = false,
        goToStep
    ) => {
        try {
            if (goToStep) {
                handlePostSaveAction(false, goBack, goToStep)
                return
            }

            const {
                firstName,
                lastName,
                company,
                signature,
                file,
                ...verificationData
            } = formData

            await persistEntityService(
                `${verificationToken ? 'first_level/' : ''}${
                    ENTITIES.DIVE_RECORD_VERIFICATION
                }`,
                {
                    ...verificationData,
                    diveRecord: { ...diveRecord.data, id: diveRecord.data._id },
                },
                diveRecord.data?.diveRecordVerification?.id
            )

            if (isFirstLevelVerification) {
                await createEntityService(
                    `${ENTITIES.DIVE_RECORD}/${ENTITIES.FIRST_LEVEL_VERIFICATION}/${verificationToken}`,
                    { firstName, lastName, company, signature, file }
                )

                showConfirmationModal({
                    title: 'general.success',
                    message: 'message.successfullyVerifiedDiveRecord',
                    icon: ICONS.CHECKMARK_CIRCLE,
                    iconColor: COLORS.GREEN_60,
                    iconSize: ICON_SIZE.SIZE80,
                    confirmLabel: 'button.okClose',
                    handleConfirm: () =>
                        handleConfirmVerificationMessage(goBack, goToStep),
                    hideCancel: true,
                })
            } else {
                handlePostSaveAction(false, goBack, goToStep)
                setAlert(t('message.diveRecordAccepted'), ALERT_TYPES.SUCCESS)
            }
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    useSaveByStep(formRef, handleSave)

    if (!data) return null

    const initialValues = {
        accurate: initialData?.accurate ?? null,
        safetyMindedness: initialData?.safetyMindedness ?? null,
        comfortInWater: initialData?.comfortInWater ?? null,
        comfortInTask: initialData?.comfortInTask ?? null,
        taskCompletion: initialData?.taskCompletion ?? null,
        useOfTools: initialData?.useOfTools ?? null,
        useOfTechnique: initialData?.useOfTechnique ?? null,
        communication: initialData?.communication ?? null,
        takingDirection: initialData?.takingDirection ?? null,
        independentWork: initialData?.independentWork ?? null,
        comment: initialData?.comment ?? '',
        firstName: initialData?.firstName ?? '',
        lastName: initialData?.lastName ?? '',
        company: initialData?.company ?? '',
        signature: initialData?.signature ?? '',
        file: initialData?.file ?? null,
    }

    const requiredMessage = t('form.error.required')
    const maximumCharacters = `${t('form.error.maximumAllowed')} 100 ${t(
        'form.error.characters'
    )}`

    const validation = Yup.object().shape({
        accurate: Yup.boolean().required(requiredMessage),
        safetyMindedness: Yup.number().required(requiredMessage),
        comfortInWater: Yup.number().required(requiredMessage),
        comfortInTask: Yup.number().required(requiredMessage),
        taskCompletion: Yup.number().required(requiredMessage),
        useOfTools: Yup.number().required(requiredMessage),
        useOfTechnique: Yup.number().required(requiredMessage),
        communication: Yup.number().required(requiredMessage),
        takingDirection: Yup.number().required(requiredMessage),
        independentWork: Yup.number().required(requiredMessage),
        comment: Yup.string(),
        firstName: diveRecordId
            ? Yup.string()
            : Yup.string()
                  .trim()
                  .max(100, maximumCharacters)
                  .required(requiredMessage),
        lastName: diveRecordId
            ? Yup.string()
            : Yup.string()
                  .trim()
                  .max(100, maximumCharacters)
                  .required(requiredMessage),
        company: diveRecordId
            ? Yup.string()
            : Yup.string().trim().max(100, maximumCharacters),
        signature: diveRecordId
            ? Yup.string()
            : Yup.string().required(requiredMessage),
        file: diveRecordId
            ? Yup.object().nullable()
            : Yup.object().required(requiredMessage),
    })

    const handleReject = () => {
        setOpen(true)
    }

    return (
        <Fragment>
            {open && (
                <RecordVerificationRejectModal
                    setOpen={setOpen}
                    submitted={submitted}
                    setSubmitted={setSubmitted}
                />
            )}

            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSave}
            >
                {({
                    handleSubmit,
                    isSubmitting,
                    values: { accurate },
                    errors,
                }) => (
                    <Form>
                        <FocusError />
                        <RecordVerificationFormContext />
                        <div className="_wr -contentElements -diveRecordContent">
                            <div className="_w">
                                <span className="-mb20 _12 a-bodyTextRegular">
                                    {t('general.diveSupervisor')}
                                </span>
                            </div>
                            <div className="_w">
                                <div className="_12 _l4">
                                    <RadioGroupField
                                        name="accurate"
                                        defaultOptions={YES_NO_BOOLEAN_OPTIONS}
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                        required
                                    />
                                </div>
                            </div>
                            {accurate && (
                                <div>
                                    <Separator />

                                    <div className="_w">
                                        <span className="-mb20 _12 a-bodyTextRegular">
                                            {t(
                                                'general.proficiencyScoringOfDivingAttributes'
                                            )}
                                        </span>
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _l4">
                                            <RadioGroupField
                                                name="safetyMindedness"
                                                defaultOptions={RATE_OPTIONS}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                displayAttribute={null}
                                                required
                                            />
                                        </div>
                                        <div className="_12 _l4">
                                            <RadioGroupField
                                                name="comfortInWater"
                                                defaultOptions={RATE_OPTIONS}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                displayAttribute={null}
                                                required
                                            />
                                        </div>
                                        <div className="_12 _l4">
                                            <RadioGroupField
                                                name="comfortInTask"
                                                defaultOptions={RATE_OPTIONS}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                displayAttribute={null}
                                                required
                                            />
                                        </div>
                                        <div className="_12 _l4">
                                            <RadioGroupField
                                                name="taskCompletion"
                                                defaultOptions={RATE_OPTIONS}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                displayAttribute={null}
                                                required
                                            />
                                        </div>
                                        <div className="_12 _l4">
                                            <RadioGroupField
                                                name="useOfTools"
                                                defaultOptions={RATE_OPTIONS}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                displayAttribute={null}
                                                required
                                            />
                                        </div>
                                        <div className="_12 _l4">
                                            <RadioGroupField
                                                name="useOfTechnique"
                                                defaultOptions={RATE_OPTIONS}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                displayAttribute={null}
                                                required
                                            />
                                        </div>
                                        <div className="_12 _l4">
                                            <RadioGroupField
                                                name="communication"
                                                defaultOptions={RATE_OPTIONS}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                displayAttribute={null}
                                                required
                                            />
                                        </div>
                                        <div className="_12 _l4">
                                            <RadioGroupField
                                                name="takingDirection"
                                                defaultOptions={RATE_OPTIONS}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                displayAttribute={null}
                                                required
                                            />
                                        </div>
                                        <div className="_12 _l4">
                                            <RadioGroupField
                                                name="independentWork"
                                                defaultOptions={RATE_OPTIONS}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                displayAttribute={null}
                                                required
                                            />
                                        </div>
                                        <div className="_12">
                                            <TextAreaField
                                                name="comment"
                                                label="form.label.additionalRemarks"
                                                placeholder="form.placeholder.additionalRemark"
                                                largeTextarea
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {isFirstLevelVerification && (
                                <div>
                                    <Separator />

                                    <div className="_w">
                                        <span className="-mb20 _12 a-bodyTextRegular">
                                            {t('general.verification')}
                                        </span>
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _l4">
                                            <InputField
                                                name="firstName"
                                                placeholder="form.placeholder.writeFirstName"
                                                required
                                            />
                                        </div>
                                        <div className="_12 _l4">
                                            <InputField
                                                name="lastName"
                                                placeholder="form.placeholder.writeLastName"
                                                required
                                            />
                                        </div>
                                        <div className="_12 _l4">
                                            <InputField
                                                name="company"
                                                placeholder="form.placeholder.writeCompanyName"
                                            />
                                        </div>
                                        <div className="_12">
                                            <SignatureField
                                                name="signature"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="_w file-upload">
                                        <FileUploadField
                                            name="file"
                                            label="form.label.supervisorCardPhoto"
                                            uploadEntity={`${ENTITIES.DIVE_RECORD_FIRST_VERIFICATION_UPLOAD}/${verificationToken}`}
                                            fetchEntity={`${ENTITIES.FILE}/${ENTITIES.DIVE_RECORD_FIRST_VERIFICATION_UPLOAD}/${verificationToken}?fileId=`}
                                            canDelete={true}
                                            multipleUpload={false}
                                            fileCategory={
                                                FILE_CATEGORIES.DIVE_RECORD_FIRST_LEVEL_VERIFICATION
                                            }
                                            required
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div
                            className={` ${
                                diveRecordId ? 'confirmAndReject' : ''
                            }`}
                        >
                            <StepButtons
                                nextlabel={
                                    diveRecordId
                                        ? 'button.confirmAndAccept'
                                        : 'button.verify'
                                }
                                backlabel="button.back"
                                handleNext={handleSubmit}
                                disableNext={
                                    !accurate || isSubmitting || submitted
                                }
                                handleBack={() =>
                                    handlePostSaveAction(false, true)
                                }
                            >
                                {diveRecordId && (
                                    <div className="-reject">
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            type={BUTTON_TYPE.BUTTON}
                                            label="button.rejectDiveRecord"
                                            onClick={handleReject}
                                            buttonSize={BUTTON_SIZE.LARGE}
                                            disabled={submitted || isSubmitting}
                                        />
                                    </div>
                                )}
                            </StepButtons>
                        </div>
                        {(isSubmitting || isLoading || submitted) && <Loader />}
                    </Form>
                )}
            </Formik>
        </Fragment>
    )
}

export default RecordVerification
