import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'

import { Link } from 'react-router'
import Icon from './Icon'

const LinkBox = ({ icon, link, text, linkText, linkType, translateText }) => {
    const t = useTranslate()

    const to = link || linkText

    const handleClick = () => {
        if (linkType === 'phone') {
            window.location = `tel:${to}`
        }
        if (linkType === 'email') {
            window.location = `mailto:${to}`
        }
    }
    return (
        <Link
            to={linkType ? undefined : to}
            className="aligned-center -gap10 a-darkBlueText a-linkBox fullWidth"
            onClick={handleClick}
        >
            <Icon
                name={icon}
                color={COLORS.LIGHT_BLUE}
                size={ICON_SIZE.SIZE20}
            />
            <span className="a-mediumTextSemiBold">
                {translateText[0] ? t(text) : text}{' '}
                <span className="-blueText">
                    {translateText[1] ? t(linkText) : linkText}
                </span>
            </span>
        </Link>
    )
}

LinkBox.propTypes = {
    icon: PropTypes.string.isRequired,
    link: PropTypes.string,
    text: PropTypes.string.isRequired,
    linkText: PropTypes.string,
    linkType: PropTypes.oneOf(['phone', 'email']),
    translateText: PropTypes.arrayOf(PropTypes.bool),
}

LinkBox.defaultProps = {
    translateText: [true, true],
}

export default LinkBox
