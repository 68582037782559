import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { validateEmail } from 'utils/yupValidations'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { ALERT_TYPES } from 'constants/enums'

import Loader from 'components/Loader'
import Modal from 'components/Modal'
import { InputField } from 'components/formFields'
import { createEntityService } from 'services/entity.service'
import ENTITIES from 'constants/entities'

const AddCertificateToWalletViaEmail = ({ certificate, setCertificate }) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)
    const { currentUser } = useContext(CurrentUserContext)

    const initialValues = {
        email: currentUser?.email ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        email: validateEmail(t).required(requiredMessage),
    })

    const onSubmit = async ({ email }, { setSubmitting }) => {
        try {
            setSubmitting(true)

            await createEntityService(
                ENTITIES.SEND_TRAINING_CERTIFICATE_TO_WALLET,
                {
                    certificateId: certificate.id,
                    email,
                },
                false
            ).finally(() => {
                setAlert(
                    `${t('message.emailSuccessfullySentTo')} ${email}`,
                    ALERT_TYPES.SUCCESS
                )
                handleClose()
            })
        } catch (error) {
        } finally {
            setSubmitting(false)
        }
    }

    const handleClose = () => {
        setCertificate(null)
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validation}
        >
            {({ isSubmitting }) => (
                <Form>
                    <Modal
                        open={true}
                        setOpen={setCertificate}
                        title="general.addCertificateToWalletViaEmail"
                        isSubmitting={isSubmitting}
                        smallModal
                        buttons={{
                            prevBtn: {
                                handleClick: handleClose,
                            },
                            nextBtn: {
                                label: 'button.send',
                            },
                        }}
                    >
                        <div className="_12">
                            <InputField
                                name="email"
                                label="form.label.recipientEmailAddress"
                                placeholder="form.placeholder.recipientEmailAddress"
                                required
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default AddCertificateToWalletViaEmail
