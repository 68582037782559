import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import useQueryParams from 'hooks/useQueryParams'

import { SORT_TYPES, ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

export const renderSortIcon = (sortValue, isCurrentSortColumn) => {
    if (!isCurrentSortColumn) {
        return <Icon name={ICONS.SORT_ICON} size={ICON_SIZE.SIZE12} />
    }

    switch (sortValue) {
        case SORT_TYPES.ASC:
            return <Icon name={ICONS.ARROW_UP} size={ICON_SIZE.SIZE12} />
        case SORT_TYPES.DESC:
            return <Icon name={ICONS.ARROW_DOWN} size={ICON_SIZE.SIZE12} />
        default:
            return <Icon name={ICONS.SORT_ICON} size={ICON_SIZE.SIZE12} />
    }
}

const TableHeader = ({
    headerItems,
    rowActions,
    sortCallback,
    defaultSort,
}) => {
    const t = useTranslate()

    const { sort, addFilterParams } = useQueryParams()

    const { ASC, DESC } = SORT_TYPES

    let querySortKey
    let querySortValue

    if (sort && sort[0] === '-') {
        querySortKey = sort.substring(1)
        querySortValue = DESC
    } else if (sort) {
        querySortKey = sort
        querySortValue = ASC
    }

    const [sortKey, setSortKey] = useState(querySortKey || defaultSort?.key)
    const [sortValue, setSortValue] = useState(
        querySortValue || defaultSort?.order
    )

    const setSort = (sort, val) => {
        if (sortCallback) {
            sortCallback(sortKey, val)
        }
        addFilterParams({ sort })
    }

    /** If sortCallback is defined do initial sort based on query params*/
    useEffect(() => {
        if (sortCallback) {
            setSort(defaultSort.key, defaultSort.order)
        }
    }, [])

    const handleSort = (key) => {
        if (!sortKey || sortKey === key) {
            switch (sortValue) {
                case ASC:
                    setSort(`-${key}`, DESC)
                    setSortValue(DESC)
                    break
                case DESC:
                    setSort(
                        null || defaultSort?.key,
                        null || defaultSort?.order
                    )
                    setSortValue(null || defaultSort?.order)
                    break
                default:
                    setSort(key, ASC)
                    setSortValue(ASC)
                    break
            }
        } else {
            setSort(key, ASC)
            setSortValue(ASC)
        }
        setSortKey(key)
    }

    return (
        <thead>
            <tr>
                {headerItems.map(
                    ({ key, title, sortKey: keySort, sortable = false }) => (
                        <th
                            key={key}
                            style={{ cursor: sortable ? 'pointer' : 'default' }}
                            onClick={
                                sortable
                                    ? () => handleSort(keySort || key)
                                    : undefined
                            }
                            className="a-captionsTextRegular"
                        >
                            <div className="-sorting">
                                {sortable && (
                                    <span>
                                        {renderSortIcon(
                                            sortValue,
                                            key === sortKey
                                        )}
                                    </span>
                                )}
                                {title && t(title)}
                            </div>
                        </th>
                    )
                )}
                {rowActions.length > 0 && (
                    <th className="a-captionsTextRegular justify-end">
                        <div>{t('general.actions')}</div>
                    </th>
                )}
            </tr>
        </thead>
    )
}

TableHeader.propTypes = {
    headerItems: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            title: PropTypes.string,
            sortable: PropTypes.bool,
        })
    ).isRequired,
    rowActions: PropTypes.array,
    sortCallback: PropTypes.func,
    defaultSort: PropTypes.object,
}

TableHeader.defaultProps = {
    rowActions: [],
    sortCallback: null,
    defaultSort: null,
}

export default TableHeader
