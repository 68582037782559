import { useNavigate } from 'react-router'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import { formatDateRange } from 'utils/filterButtonFormatters'

import { ACTIVE_INACTIVE_STATUSES, BUTTON_STATUS } from 'constants/enums'
import ENTITIES from 'constants/entities'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import { Table } from 'components/table'
import ManagementLayout from '../components/ManagementLayout'
import CompanyAccountBreadcrumbs from './components/CompanyAccountBreadcrumbs'
import CompanyAccountFilters from './components/CompanyAccountFilters'
import DateCell from 'components/table/tableCells/DateCell'
import StatusCell from 'components/table/tableCells/StatusCell'

const TABLE_HEADER = [
    {
        key: 'id',
        title: 'table.header.id',
        showOnSmallScreens: true,
    },
    {
        key: 'name',
        title: 'table.header.companyName',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        smallScreenHeader: true,
        sortable: true,
    },
    {
        key: 'email',
        title: 'table.header.email',
        showOnSmallScreens: true,
        sortable: true,
    },
    {
        key: 'registeredAt',
        title: 'table.header.registeredAt',
        showOnSmallScreens: true,
        sortable: true,
        CellComponent: DateCell,
    },
    {
        key: 'size.name',
        title: 'table.header.companySize',
        showOnSmallScreens: true,
        sortable: true,
        sortKey: 'size.value',
    },
    {
        key: 'tinVat',
        title: 'table.header.tinVat',
        showOnSmallScreens: true,
        sortable: true,
    },
    {
        key: 'approvedAccess',
        title: 'table.header.active',
        showOnSmallScreens: true,
        sortable: true,
        CellComponent: StatusCell,
        statuses: ACTIVE_INACTIVE_STATUSES,
    },
]

const filters = {
    size: {
        entityType: ENTITIES.COMPANY_SIZE,
        displayAttribute: 'name',
    },
    tinVat: { type: 'text' },
    email: { type: 'text' },
    registeredAt: { type: 'dateRange', formatter: formatDateRange },
}

const CompanyAccountManagement = () => {
    const navigate = useNavigate()

    const { data: companiesData, meta, isLoading } = useFetchDataByQueryParams(
        ENTITIES.COMPANY,
        {
            include: 'size',
        }
    )

    const handleEdit = ({ id }) => {
        navigate(`/${ROUTES.COMPANY_ACCOUNT_MANAGEMENT}${ROUTES.EDIT}/${id}`)
    }

    return (
        <ManagementLayout BreadcrumbsComponent={CompanyAccountBreadcrumbs}>
            <Table
                title="general.companyAccounts"
                fullWidthTitle
                headerItems={TABLE_HEADER}
                data={companiesData}
                totalItems={meta?.totalItems}
                isLoading={isLoading}
                searchable={true}
                searchKey="searchField"
                rowActions={[
                    {
                        handleAction: handleEdit,
                        icon: ICONS.EDIT,
                        tooltip: 'button.edit',
                        btnClass: BUTTON_STATUS.TERTIARY,
                    },
                ]}
                FilterComponent={CompanyAccountFilters}
                filters={filters}
                filtersKey={FILTERS.SUPER_ADMIN_COMPANY_FILTERS}
            />
        </ManagementLayout>
    )
}

export default CompanyAccountManagement
