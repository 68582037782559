import React, { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'

import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
    INPUT_FILED_TYPE,
} from 'constants/enums'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'

import { forgotPasswordService } from 'services/auth.service'

import { validateEmail } from 'utils/yupValidations'

import { AlertContext } from 'contexts/AlertContext'

import InputField from 'components/formFields/InputField'
import Button from 'components/Button'
import CheckEmail from './CheckEmail'
import COLORS from 'constants/colors'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'

const ForgotPassword = () => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)
    const [checkEmail, setCheckEmail] = useState(null)

    const handleSubmit = async (formData) => {
        try {
            await forgotPasswordService(formData)
            setCheckEmail(formData.email)
            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR, t)
        }
    }

    const initialValues = {
        email: '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        email: validateEmail(t).required(requiredMessage),
    })

    if (checkEmail)
        return <CheckEmail email={checkEmail} setEmail={setCheckEmail} />

    return (
        <div className="m-form -register -password">
            <div className="-backBtn">
                <Button
                    btnClass={BUTTON_STATUS.TERTIARY}
                    type={BUTTON_TYPE.BUTTON}
                    icon={ICONS.ARROW_LEFT}
                    iconColor={COLORS.LIGHT_BLUE}
                    label="links.back"
                    onClick={() => navigate(ROUTES.LOGIN)}
                />
            </div>
            <h1 className="-title -mb40">{t('noAuth.resetPasswordTitle')}</h1>
            <span className="-descriptionText a-mediumText a-lightText -mb30">
                {t('noAuth.resetPasswordText')}
            </span>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FocusError />
                        <InputField
                            name="email"
                            type={INPUT_FILED_TYPE.EMAIL}
                            icon={ICONS.MAIL}
                            size={ICON_SIZE.SIZE18}
                            iconColor={COLORS.DARK_BLUE_60}
                            required
                        />
                        <Button
                            btnClass={BUTTON_STATUS.PRIMARY}
                            type={BUTTON_TYPE.SUBMIT}
                            label="button.resetPassword"
                            disabled={isSubmitting}
                        />
                        {isSubmitting && <Loader />}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ForgotPassword
