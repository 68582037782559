import React, { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useWindowDimensions from 'hooks/useWindowDimension'
import useQueryParams from 'hooks/useQueryParams'

import { updateTableStyles } from 'utils/packageStyleHelper'

import ICONS from 'constants/icons'
import { PACKAGE_INFO_DATA, USER_SUBSCRIPTION_TYPE } from 'constants/enums'
import COLORS from 'constants/colors'

import PackageTableData from './packagesPage/components/PackageTableData'
import PackageInfoRow from './packagesPage/components/PackageInfoRow'
import TabNavigation from 'components/tabs/TabNavigation'
import SupportNoteRow from './packagesPage/components/SupportNoteRow'

const PackagesPage = () => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()
    const { tab } = useQueryParams()

    const { currentUser } = useContext(CurrentUserContext)

    const [active, setActive] = useState()

    const tabData = [
        {
            key: USER_SUBSCRIPTION_TYPE.FREE,
            title: 'general.free',
            icon: ICONS.CHECKMARK_ICON,
            iconColor: COLORS.GREEN,
        },
        {
            key: USER_SUBSCRIPTION_TYPE.PRO,
            title: 'general.pro',
            icon: ICONS.PRO,
            iconColor: COLORS.YELLOW,
        },
        {
            key: USER_SUBSCRIPTION_TYPE.ENTERPRISE,
            title: 'general.enterprise',
            icon: ICONS.PRO,
            iconColor: COLORS.YELLOW,
        },
    ]

    const activePackage = currentUser?.userSubscription?.userSubscriptionType

    useEffect(() => {
        setActive(tab === activePackage.toString())
    }, [activePackage, tab])

    const activePackageTab =
        PACKAGE_INFO_DATA.find(
            isTablet
                ? (item) => item.id.toString() === tab?.toString()
                : (item) => item.id === activePackage
        ) || PACKAGE_INFO_DATA[0]

    useEffect(() => {
        updateTableStyles(activePackage, isTablet, active)
    }, [isTablet, active])

    return (
        <div className="m-packagesPage">
            <div className="m-boxes -mt15">
                <div className="m-boxes__main -fullWidth -noPadding">
                    <div className="m-packagesPage__heading aligned-center column  centered-text -pt100">
                        <h1>{t('general.packagesTitle')}</h1>
                        <p className="a-mediumText a-lightText -opacity-60 -mt20 -description">
                            {t('general.packagesDescription')}
                        </p>
                    </div>
                    {isTablet && (
                        <div className="m-packagesPage__navigation -mt30">
                            <TabNavigation tabs={tabData} tabQueryParam="tab" />
                        </div>
                    )}
                    <div
                        className={`m-packagesPage__table ${
                            isTablet ? '-mt5' : '-mt50'
                        } -pb50  ${active ? '-active' : ''}`}
                    >
                        <table className="fullWidth">
                            <tbody>
                                <PackageInfoRow
                                    activePackageTab={activePackageTab}
                                    active={active}
                                    currentUser={currentUser}
                                />
                                <PackageTableData
                                    activePackageTab={activePackageTab}
                                />
                                <PackageInfoRow
                                    activePackageTab={activePackageTab}
                                    active={active}
                                    currentUser={currentUser}
                                    bottom
                                />
                                {!active && <SupportNoteRow />}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackagesPage
