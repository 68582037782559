import React from 'react'
import PropTypes from 'prop-types'

import Image from './Image'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

const Avatar = ({ firstName, lastName, avatar, companyAvatar }) => {
    return (
        <div className="m-avatar__imgInfo">
            <div className="m-avatar__img">
                <span className="m-avatar__icon">
                    <Image
                        path={avatar}
                        alt="avatar"
                        icon={companyAvatar ? ICONS.BUILDING : ICONS.USER}
                        iconColor={COLORS.WHITE}
                    />
                </span>
            </div>
            <div className="m-avatar__info">
                <span className="m-avatar__name">
                    <span className="-name" title={firstName + ' ' + lastName}>
                        {firstName} {lastName}
                    </span>
                </span>
            </div>
        </div>
    )
}

Avatar.propTypes = {
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyAvatar: PropTypes.bool,
}

export default Avatar
