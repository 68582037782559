const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    REGISTER_PASSWORD: '/register-password',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
    CREATE: '/create',
    EDIT: '/edit',
    GALLERY: '/gallery',
    CHAT: '/chat',
    CALENDAR: '#',
    NOTIFICATION: '/notification',
    LEGAL: '/legal',
    NOT_FOUND_PAGE: '/page-not-found',
    DIVER_ONBOARDING: '/diver-onboarding',
    PROJECT: '/project',
    DATA_ACCESS_PROJECT: 'project',
    TERMS: '/terms-and-conditions',
    PRIVACY: '/privacy-policy',
    RECORD: '/record',
    DIVING_CONTRACTOR_PROFILE: '/company',
    DIVER_PROFILE: '/user',
    DIVE_TEAM: '/dive-team',
    MY_DIVE_PROJECTS: '/my-dive-projects',
    DIVE_RECORD: 'dive-record',
    DIVE_RECORDS: 'dive-records',
    ANALYTICS: 'analytics',
    ADCI: 'adci',
    CHECK_CERTIFICATES: 'check-certificates',
    GENERAL_INFORMATION: 'general-information',
    EQUIPMENT: 'equipment',
    DIVE_PROFILE: 'dive-profile',
    WORK_PERFORMED: 'work-performed',
    DIVE_SUMMARY: 'dive-summary',
    VERIFICATION: 'verification',
    SETTINGS: '/settings',
    DIVE_RECORD_SUMMARY: 'dive-record-summary',
    UNITS_OF_MEASUREMENT: 'units-of-measurement',
    DATA_ACCESS_CONTROL: 'data-access-control',
    MEDICAL_INFO: 'medical-info',
    TRAVEL_DOCUMENTATION: 'travel-documentation',
    TRAINING_RECORDS: 'training-records',
    PERSONAL_EQUIPMENT: 'personal-equipment',
    PAST_EMPLOYMENT: 'past-employment',
    ALL_USERS: '/all-users',
    MY_PROJECT_RECORDS: 'my-project-records',
    HELP_AND_INFO: 'help-and-info',
    EXPLORE_SKILL_AND_DEPTH: 'explore-skill-and-depth',
    VERIFICATION_LEVELS: 'verification-levels',
    CREATE_A_PROJECT: 'create-a-project',
    CREATE_HISTORICAL_DIVE_RECORD: 'create-historical-dive-record',
    CREATE_PERSONAL_ACCOUNT: 'create-personal-account',
    SEND_DIVE_RECORD_FOR_VERIFICATION_LEVEL_ONE:
        'send-dive-record-for-verification-level-one',
    SIGN_UP_AS_ORGANIZATION: 'sing-up-as-organization',
    UPDATE_ORGANIZATION_PROFILE: 'update-organization-profile',
    UPDATE_PERSONAL_INFORMATION: 'update-personal-information',
    VERIFY_DIVE_RECORD_AS_A_SUPERVISOR_LEVEL_ONE:
        'verify-dive-record-as-a-supervisor-level-one',
    VERIFY_IDENTITY_USING_PERSONA: 'verify-identity-using-persona',
    RELEASE_NOTES: 'release-notes',
    RELEASE_0_9_0: 'release-0-9-0',
    RELEASE_0_9_1: 'release-0-9-1',
    RELEASE_1_0_0: 'release-1-0-0',
    RELEASE_1_1_0: 'release-1-1-0',
    RELEASE_1_2_0: 'release-1-2-0',
    RELEASE_1_3_0: 'release-1-3-0',
    RELEASE_1_4_0: 'release-1-4-0',
    RELEASE_1_5_0: 'release-1-5-0',
    RELEASE_1_6_0: 'release-1-6-0',
    EXPORT_CV: 'export-cv',
    ACCOUNT_MANAGEMENT: 'account-management',
    BADGES: 'badges',
    GENERAL_PREFERENCES: 'general-preferences',
    SIGN_IN_AND_SECURITY: 'sign-in-and-security',
    NOTIFICATIONS: 'notifications',
    DECLINE_EMAIL_CHANGE: '/decline_email_change',
    DIVE_RECORD_FIRST_LEVEL_VERIFICATION:
        'dive-record-first-level-verification',
    ACCESS_DENIED: '/access-denied',
    HOW_TO: 'how-to',
    SUBSCRIPTION: 'subscription',
    BILLING: 'billing',
    PACKAGES: '/packages',
    SUPPORT: 'support',
    INDIVIDUAL_ACCOUNT_MANAGEMENT: 'individual-account-management',
    COMPANY_ACCOUNT_MANAGEMENT: 'company-account-management',
    CALL_CENTER: 'call-center',
    GALLERY_IMAGES_POLICY: 'gallery-images-policy',
    CHECKN_DEPTH: 'chekn-depth',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
    CHEKN_DEPTH: 'chekn-depth',
    DIVE_CERTIFICATION_VERIFICATION: 'dive_certification_verification',
    DIVE_RECORD_STATUSES: 'dive_record_statuses',
    FAQ: 'faq',
    OFFLINE_MODE: 'offline_mode',
    CONTACT_INFO: 'contact-info',
    COUNTRY_MANAGEMENT: 'country-management',
    REGION_MANAGEMENT: 'region-management',
    INDUSTRY_MANAGEMENT: 'industry-management',
    LANGUAGE_MANAGEMENT: 'language-management',
    LANGUAGE_PROFICIENCY_MANAGEMENT: 'language-proficiency-management',
    DIVER_POSITION_MANAGEMENT: 'diver-position-management',
    DIVER_POSITION_CATEGORY_MANAGEMENT: 'diver-position-category-management',
    MEDICAL_RECORD_TYPE_MANAGEMENT: 'medical-record-type-management',
    MEDICAL_EXAM_MANAGEMENT: 'medical-exam-management',
    MEDICAL_EXAM_STANDARD_MANAGEMENT: 'medical-exam-standard-management',
    PRIMARY_TRAINING_TYPE_CATEGORY_MANAGEMENT:
        'primary-training-type-category-management',
    PRIMARY_TRAINING_TYPE_MANAGEMENT: 'primary-training-type-management',
    DIVE_TRAINING_SCHOOL_MANAGEMENT: 'dive-training-school-management',
    TRAINING_TYPE_MANAGEMENT: 'training-type-management',
    PROFICIENCY_LEVEL_MANAGEMENT: 'proficiency-level-management',
    BRANCH_MANAGEMENT: 'branch-management',
    CERTIFICATE_ISSUER: 'certificate-issuer',
    EQUIPMENT_TYPE_MANAGEMENT: 'equipment-type-management',
    EQUIPMENT_BRAND_MANAGEMENT: 'equipment-brand-management',
    EMPLOYER_MANAGEMENT: 'employer-management',
    PAST_EMPLOYMENT_POSITION_MANAGEMENT: 'past-employment-position-management',
    SKILL_CATEGORY_MANAGEMENT: 'skill-category-management',
    SKILL_MANAGEMENT: 'skill-management',
    DIVING_PLATFORM_MANAGEMENT: 'diving-platform-management',
    DIVE_PHYSICAL_CHARACTERISTIC_MANAGEMENT:
        'dive-physical-characteristic-management',
    WATER_QUALITY_MANAGEMENT: 'water-quality-management',
    BOTTOM_CONDITION_MANAGEMENT: 'bottom-condition-management',
    BREATHING_APPARATUS_TYPE_MANAGEMENT: 'breathing-apparatus-type-management',
    DIVING_APPAREL_PROTECTION_MANAGEMENT:
        'diving-apparel-protection-management',
    OTHER_DIVING_APPAREL_PROTECTION_MANAGEMENT:
        'other-diving-apparel-protection-management',
    BUOYANCY_FLOTATION_MANAGEMENT: 'buoyancy-flotation-management',
    OTHER_EQUIPMENT_OUTFITTING_MANAGEMENT:
        'other-equipment-outfitting-management',
    GAS_CYLINDER_PURPOSE_MANAGEMENT: 'gas-cylinder-purpose-management',
    GAS_CONTENT_MANAGEMENT: 'gas-content-management',
    DECOMPRESSION_TYPE_MANAGEMENT: 'decompression-type-management',
    DECOMPRESSION_TABLE_TYPE_MANAGEMENT: 'decompression-table-type-management',
    DIVE_RECORD_WORK_TASK_MANAGEMENT: 'dive-record-work-task-management',
    DIVE_RECORD_SUPERVISOR_TASK_MANAGEMENT:
        'dive-record-supervisor-task-management',
    DIVE_RECORD_WORK_TOOL_MANAGEMENT: 'dive-record-work-tool-management',
    DIVE_RECORD_WORK_OBJECT_MANAGEMENT: 'dive-record-work-object-management',
    DIVE_RECORD_WORK_ACTIVITY_MANAGEMENT:
        'dive-record-work-activity-management',
    TRAUMA_INJURY_LEVEL_MANAGEMENT: 'trauma-injury-level-management',
    MENTAL_WELLNESS_ELEMENT_MANAGEMENT: 'mental-wellness-element-management',
    DECOMPRESSION_ILLNESS_MANAGEMENT: 'decompression-illness-management',
    PRESSURE_INJURY_TYPE_MANAGEMENT: 'pressure-injury-type-management',
    EQUIPMENT_FAILURE_TYPE_MANAGEMENT: 'equipment-failure-type-management',
    CLOSE_CALL_TYPE_MANAGEMENT: 'close-call-type-management',
    LOCATION_TYPE_MANAGEMENT: 'location-type-management',
    PROJECT_INDUSTRY_TYPE_MANAGEMENT: 'project-industry-type-management',
    PROJECT_STATUS_MANAGEMENT: 'project-status-management',
    DIVING_MODE_MANAGEMENT: 'diving-mode-management',
    BODY_OF_WATER_MANAGEMENT: 'body-of-water-management',
    SERVICE_MANAGEMENT: 'service-management',
    ORGANIZATION_TYPES_MANAGEMENT: 'category-management',
    VESSELS_MANAGEMENT: 'vessel-management',
    CLIENTS_MANAGEMENT: 'client-management',
    PROFILE_IMAGE_GUIDELINES: 'profile_image_guidelines',
    CERTIFICATE_QR: '/cert-qr',
    CERTIFICATE_PUBLIC: '/certificate-check',
    CERTIFICATES_VERIFICATION: 'certificates-verification',
    DOWNLOAD_MOBILE_APP: 'download-mobile-app',
}

export default ROUTES
