// NOTE: It is better to keep comments
// Helper function to apply styles
const applyStyles = (elements, styles) => {
    elements.forEach((element) => {
        Object.assign(element.style, styles)
    })
}

// Helper function to get the table class
const getTableClass = (isTablet) =>
    isTablet ? '.m-packagesPage__table.-active' : '.m-packagesPage__table'

// Helper function to select table cells based on the package number and type
const getTableCells = (isTablet, activePackageNumber, selectorType) => {
    const tableClass = getTableClass(isTablet)
    const cellSelector = `${tableClass} table ${selectorType}:nth-child(${activePackageNumber}) > div`
    return document.querySelectorAll(cellSelector)
}

// Main function
export const updateTableStyles = (activePackage, isTablet) => {
    const borderThinner = '1px solid #0177a9'
    const borderThicker = '8px solid #0177a9'
    const activePackageNumber = isTablet ? 1 : activePackage + 1

    // Reset borders of all cells
    const allCells = document.querySelectorAll(
        '.m-packagesPage__table table td > div'
    )
    const allTableHeadCells = document.querySelectorAll(
        '.m-packagesPage__table table th > div'
    )
    applyStyles(allCells, {
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
    })
    applyStyles(allTableHeadCells, {
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
    })

    // Select active cells
    const activePackageInfoCells = getTableCells(
        isTablet,
        activePackageNumber,
        '.-packageInfoRow td'
    )
    const activeCells = getTableCells(isTablet, activePackageNumber, 'td')
    const activeSmallCells = isTablet
        ? document.querySelectorAll(
              `${getTableClass(isTablet)} table td:nth-child(2) > div`
          )
        : [] // Only select small cells for tablet view
    const activeTableHeadCells = getTableCells(
        isTablet,
        activePackageNumber,
        'th'
    )

    // Style active small cells (for tablet only)
    if (activeSmallCells.length) {
        activeSmallCells.forEach((cell) => {
            if (isTablet) {
                cell.style.borderRight = borderThinner
            }
        })
    }

    // Style active cells
    if (activeCells.length) {
        activeCells.forEach((cell) => {
            cell.style.borderLeft = borderThinner
            if (!isTablet) {
                cell.style.borderRight = borderThinner
            }
        })
    }

    // Style active table head cells
    if (activeTableHeadCells.length) {
        applyStyles(activeTableHeadCells, {
            borderLeft: borderThinner,
            borderRight: borderThinner,
        })
    }

    // Style package info cells
    if (activePackageInfoCells.length) {
        const [firstCell, secondCell] = activePackageInfoCells
        firstCell.style.borderTop = borderThicker
        if (isTablet) {
            firstCell.style.borderRight = borderThinner
        }
        firstCell.style.marginTop = '0'
        secondCell.style.borderBottom = borderThicker
        if (isTablet) {
            secondCell.style.borderRight = borderThinner
        }
    }
}
