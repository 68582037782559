import { Form, Formik } from 'formik'
import { InputField } from 'components/formFields'
import {
    BUTTON_TYPE,
    INPUT_FILED_TYPE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import SelectField from 'components/formFields/SelectField'
import Button from 'components/Button'
import React, { useState } from 'react'
import { getEntityService } from 'services/entity.service'
import ENTITIES from 'constants/entities'
import { useTranslate } from 'react-polyglot'
import { initialValues } from 'screens/common/adci/constants/adciInitialValues'
import * as Yup from 'yup'
import AdciDiverCard from 'screens/common/adci/components/AdciDiverCard'
import { providers } from 'screens/common/adci/constants/adciProviders'
import { POST, RESPONSE_TYPE } from 'utils/axiosClient'

const AdciFormSearch = () => {
    const t = useTranslate()
    const [certificates, setCertificates] = useState(null)

    const handleFilter = async (values) => {
        const { data } = await getEntityService(
            ENTITIES.CERTIFICATES,
            values,
            false,
            false,
            process.env.REACT_APP_API_HOST,
            RESPONSE_TYPE.JSON,
            POST
        )

        setCertificates(data)
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        firstName: Yup.string().trim().required(requiredMessage),
        lastName: Yup.string().trim().required(requiredMessage),
        certificateNumber: Yup.string().trim().required(requiredMessage),
        certificateIssuer: Yup.string().trim().required(requiredMessage),
    })

    return (
        <>
            <p className="-mt10 a-bodyTextRegular a-lightText -opacity-60 -mb20 centered-text">
                {t('adci.searchBy')}
            </p>
            <div className="form-wrapper">
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleFilter}
                    validationSchema={validation}
                >
                    {({ values, setFieldValue, isSubmitting }) => (
                        <Form className="fullWidth">
                            <div className="_wr">
                                <div className="_w">
                                    <div className="_12 _m6">
                                        <InputField
                                            name="firstName"
                                            type={INPUT_FILED_TYPE.TEXT}
                                            required
                                        />
                                    </div>
                                    <div className="_12 _m6">
                                        <InputField
                                            name="lastName"
                                            type={INPUT_FILED_TYPE.TEXT}
                                            required
                                        />
                                    </div>
                                    <div className="_12 _m6">
                                        <InputField
                                            name="certificateNumber"
                                            type={INPUT_FILED_TYPE.TEXT}
                                            required
                                        />
                                    </div>
                                    <div className="_12 _m6">
                                        <SelectField
                                            name="certificateIssuer"
                                            defaultOptions={providers}
                                            valueType={SELECT_VALUE_TYPE.STRING}
                                            required
                                        />
                                    </div>
                                    <div className="_12 ofs_m3 _m6 -searchButton -mt10">
                                        <Button
                                            type={BUTTON_TYPE.SUBMIT}
                                            label={'adci.search'}
                                        ></Button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
            {certificates && (
                <div className="-mt30 fullWidth result-wrapper">
                    <div className="_wr">
                        <div className="_w">
                            <div className="_12">
                                {certificates.map((certificate) => {
                                    return (
                                        <div
                                            key={certificate.certNumber}
                                            className="-mb10"
                                        >
                                            <AdciDiverCard
                                                certificate={certificate}
                                            ></AdciDiverCard>
                                        </div>
                                    )
                                })}
                                {certificates.length === 0 && (
                                    <div className="justify-center aligned-center column">
                                        <span className="a-lightText -mb10">
                                            {t('general.certificatesNotFound')}
                                        </span>
                                        <span className="a-lightText">
                                            {t('general.certificatesMakeSure')}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default AdciFormSearch
