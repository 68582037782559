import React, { useContext } from 'react'
import { Formik, Form } from 'formik'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES } from 'constants/enums'
import ENTITIES from 'constants/entities'

import { createEntityService } from 'services/entity.service'

import RadioGroupField from 'components/formFields/RadioGroupField'
import Modal from 'components/Modal'
import Note from 'components/Note'
import Loader from 'components/Loader'
import FocusError from 'components/FocusError'

const DataAccessShareModal = ({
    dataAccessModalData: {
        position,
        message,
        closeDataAccessModalData
    }
}) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const denyAccess = {
        id: 1,
        name: t('general.denyAccess')
    }

    const accessWithoutFiles = {
        id: 2,
        name: t('general.accessWithoutFiles')
    }

    const accessWithFiles = {
        id: 3,
        name: t('general.accessWithFiles')
    }

    const allOptions = [denyAccess, accessWithoutFiles, accessWithFiles];

    const initialValues = {
        config: accessWithFiles
    }

    const handleClose = () => {
        closeDataAccessModalData()
    }

    const onSubmit = async ({ config }, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await createEntityService(
                ENTITIES.DATA_ACCESS_SHARING_BULK,
                {
                    config,
                    projectPositionId: position.id,
                },
                false
            )
            setAlert(t('general.proceedWithoutAdjustments'), ALERT_TYPES.SUCCESS)
            handleClose()
        } catch (error) {
            setAlert(
                error,
                ALERT_TYPES.ERROR,
                error.response?.data?.message ? t : null
            )
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={true}
                        setOpen={closeDataAccessModalData}
                        closeOnClickOutside={false}
                        title="general.shareDataAccess"
                        isSubmitting={isSubmitting}
                        buttons={{
                            prevBtn: {
                                handleClick: handleClose,
                                label: 'button.proceedWithoutAdjustments',
                            },
                            nextBtn: {
                                label: 'button.adjustDataAccessSettings',
                            },
                        }}
                    >
                        <Note bottomMargin={false}
                              type={ALERT_TYPES.SUCCESS}
                              showTitle={false}
                        >
                            <span>
                                {`${t(message)}`}
                                <span className='a-captionsTextBold'> {position.diverPositionName}</span>
                                {' '}
                                 {`${t(
                                    'general.positionOn',
                                )}`}{' '}
                                <span className='a-captionsTextBold'>{position.projectName}</span>
                                {' '}
                                {`${t('general.theProject')}`}
                            </span>
                        </Note>
                        <div className="a-separator -after -pt20 -fullWidth -mt20"></div>

                        <span className="a-bodyTextRegular">
                            {t('general.shareDataAccessDesc')}
                        </span>

                        <div className="a-separator -after -pt20 -fullWidth -mt20"></div>

                        <Note note="notes.shareDataAccessNote" />

                        <div className="fullWidth">
                            <span>{t('general.shareDataAccessSelect')}</span>
                        </div>

                        <div className="-mt5">
                            <RadioGroupField
                                name={'config'}
                                defaultOptions={allOptions}
                                showLabel={false}
                            />
                        </div>

                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

DataAccessShareModal.propTypes = {
    dataAccessModalData: PropTypes.object.isRequired,
}

export default DataAccessShareModal
