import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { useContext, useRef } from 'react'
import { useNavigate } from 'react-router'

import useWindowDimensions from 'hooks/useWindowDimension'
import useFetchData from 'hooks/useFetchData'

import ENTITIES from 'constants/entities'
import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
    BUTTON_SIZE,
    TOOLTIP_POSITION,
} from 'constants/enums'
import ROUTES from 'constants/routes'

import About from './About'
import ContactInfo from './ContactInfo'
import Experience from './Experience'
import Badge from 'components/Badge'
import VerificationLevels from './VerificationLevels'
import IconShowcase from 'components/IconShowcase'
import IconShowcaseContent from 'components/IconShowcaseContent'
import useBadgeData from 'screens/common/helpAndInfo/data/Badges'
import TabHeading from './TabHeading'
import { ProfileContext } from '../../context/ProfileContext'
import Note from '../../../../../components/Note'
import RequestAccess from '../../RequestAccess'

const PersonalInfoCard = ({ user, myProfile }) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { meta, fetchProfile } = useContext(ProfileContext)

    const badgesRef = useRef(null)

    const { isSmallScreen, isTablet } = useWindowDimensions()

    const { id, about, professionalSummary, diver } = user

    const {
        BADGES,
        JOB_QUALIFICATION_BADGES,
        SPECIALIZED_DIVING_SKILLS_BADGES,
    } = useBadgeData()

    const allBadges = [
        ...BADGES,
        ...JOB_QUALIFICATION_BADGES,
        ...SPECIALIZED_DIVING_SKILLS_BADGES,
    ]

    const { data: userBadges, isReady } = useFetchData(
        ENTITIES.USER_BADGES,
        {
            'user.id': id,
        },
        diver,
        false,
        id
    )

    const handleFindOutMore = (badge) => {
        const badgeCategory = badge.category

        navigate(
            `/${ROUTES.HELP_AND_INFO}/${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${
                ROUTES.BADGES
            }?subTab=${badgeCategory}&badgeId=${`badge-${badge.key}`}`
        )
    }

    const status = meta?.dataAccessStatus
    const hasAccess =
        myProfile ||
        [
            ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id,
            ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id,
        ].includes(status)

    if (diver && !isReady) return null

    return (
        <div className="m-userMainInfo column">
            <div className="m-userMainInfo__mainInfo fullWidth">
                <div className="m-userMainInfo__mainInfo--contactInfo fullWidth">
                    <div className="aligned-center a-tabName space-between">
                        <TabHeading
                            tabTitle="general.personalInfo"
                            tab={ROUTES.CONTACT_INFO}
                            myProfile={myProfile}
                        />
                        {!hasAccess && (
                            <RequestAccess
                                module={ACCESS_CONTROL_MODULES.CONTACT_INFO}
                                status={status}
                                canRequestAccess={
                                    status ===
                                    ACCESS_CONTROL_STATUSES.PROTECTED_PROPERTIES
                                        .id
                                }
                                profile={user}
                                checkAccessControl={fetchProfile}
                                onlyButton={true}
                                buttonSize={BUTTON_SIZE.SMALL}
                            />
                        )}
                    </div>
                    {!hasAccess && (
                        <div className="-mt10">
                            <RequestAccess
                                onlyNoteStatus={true}
                                status={status}
                                requestTime={meta.dataAccessRequestTime}
                            />
                        </div>
                    )}
                    {!hasAccess &&
                        status ===
                            ACCESS_CONTROL_STATUSES.PROTECTED_PROPERTIES.id && (
                            <div className="fullWidth">
                                <Note
                                    note="message.contactInfoPrivate"
                                    bottomMargin={false}
                                />
                            </div>
                        )}
                    <ContactInfo user={user} isSmallScreen={isSmallScreen} />
                </div>
            </div>

            {about && (
                <div className="column">
                    <span className="a-mediumText -mt15 -mb10">
                        {t('form.label.aboutMe')}
                    </span>
                    <p className="a-mediumText a-lightText -opacity-60 -about">
                        {about}
                    </p>
                </div>
            )}

            {professionalSummary && (
                <div className="column">
                    <span className="a-mediumText -mt15 -mb10">
                        {t('general.professionalSummary')}
                    </span>
                    <p className="a-mediumText a-lightText -opacity-60 -about">
                        {professionalSummary}
                    </p>
                </div>
            )}
            {!!userBadges.length && (
                <div className="-badges -mt20 aligned-center" ref={badgesRef}>
                    {userBadges.map(({ id, additionalBadgeKey, badgeKey }) => {
                        const currentBadgeData = allBadges.find(
                            (item) =>
                                item.key === (additionalBadgeKey || badgeKey)
                        )
                        return (
                            <div key={id}>
                                <IconShowcase
                                    position={TOOLTIP_POSITION.RIGHT}
                                    badgesRef={badgesRef}
                                    tooltip={
                                        <IconShowcaseContent
                                            iconShowcase={currentBadgeData}
                                            size={100}
                                            additionalBadgeKey={
                                                additionalBadgeKey
                                            }
                                            badgeKey={badgeKey}
                                            id={id}
                                            withBadge={true}
                                            route={`/${ROUTES.HELP_AND_INFO}/${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.BADGES}?subTab=${currentBadgeData.category}&badgeId=badge-${currentBadgeData.key}`}
                                        />
                                    }
                                >
                                    <div
                                        onClick={() =>
                                            isTablet
                                                ? handleFindOutMore(
                                                      allBadges.find(
                                                          (item) =>
                                                              item.key ===
                                                              (additionalBadgeKey ||
                                                                  badgeKey)
                                                      )
                                                  )
                                                : () => {}
                                        }
                                    >
                                        <Badge
                                            key={id}
                                            badgeKey={badgeKey}
                                            additionalBadgeKey={
                                                additionalBadgeKey
                                            }
                                        />
                                    </div>
                                </IconShowcase>
                            </div>
                        )
                    })}
                </div>
            )}
            {diver && <VerificationLevels user={user} />}
            <About user={user} isSmallScreen={isSmallScreen} />
            {diver && <Experience user={user} isSmallScreen={isSmallScreen} />}
        </div>
    )
}

PersonalInfoCard.propTypes = {
    user: PropTypes.object,
    myProfile: PropTypes.bool,
    setOpen: PropTypes.func,
}

PersonalInfoCard.defaultProps = {
    myProfile: false,
}

export default PersonalInfoCard
