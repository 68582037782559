import { PropTypes } from 'prop-types'
import { Link } from 'react-router'

import useCanLeavePage from 'hooks/useCanLeavePage'

import COLORS from 'constants/colors'
import ROUTES from 'constants/routes'

import LogoComponent from 'components/icons/LogoComponent'

const Logo = ({ color1, color2, showSearchInput, isTablet }) => {
    const { canLeavePage, handleShowModal } = useCanLeavePage()

    const handleOpenModal = () => {
        if (!canLeavePage) {
            handleShowModal(ROUTES.HOME)
        }
    }

    return (
        <div
            className={`-mainLogo aligned-center ${
                !showSearchInput && isTablet ? '-movedLeft' : ''
            }`}
            onClick={() => handleOpenModal()}
        >
            <Link
                className="aligned-center"
                to={canLeavePage ? ROUTES.HOME : undefined}
            >
                <LogoComponent color1={color1} color2={color2} />
            </Link>
        </div>
    )
}

Logo.propTypes = {
    color1: PropTypes.string,
    color2: PropTypes.string,
    color3: PropTypes.string,
    showSearchInput: PropTypes.bool,
    isTablet: PropTypes.bool,
}

Logo.defaultProps = {
    color1: COLORS.DARK_BLUE,
    color2: COLORS.DARK_BLUE,
    color3: COLORS.DARK_BLUE,
    showSearchInput: false,
    isTablet: false,
}

export default Logo
