import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import EntityForm from './EntityForm'
import { InputField } from 'components/formFields'

const LanguageForm = (props) => {
    const t = useTranslate()

    const initialValues = {
        name: props.initialData?.name ?? '',
        code: props.initialData?.code ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object().shape({
        name: Yup.string().required(requiredMessage),
        code: Yup.string().required(requiredMessage),
    })

    return (
        <EntityForm
            {...props}
            customInitialValues={initialValues}
            customValidation={validation}
            CustomFormFields={() => (
                <Fragment>
                    <div className="_12 _l6">
                        <InputField
                            name="name"
                            label="form.label.entityName"
                            placeholder="form.placeholder.entityName"
                            required
                        />
                    </div>
                    <div className="_12 _l6">
                        <InputField
                            name="code"
                            label="form.label.languageCode"
                            placeholder="form.placeholder.languageCode"
                            required
                        />
                    </div>
                </Fragment>
            )}
        />
    )
}

export default LanguageForm
