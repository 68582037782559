import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTranslate } from 'react-polyglot'

import useFetchDataById from 'hooks/useFetchDataById'

import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'
import RESPONSE_CODES from 'constants/responseCodes'
import { ALERT_TYPES } from 'constants/enums'
import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

export const ProfileContext = React.createContext()

const ProfileContextProvider = ({ children }) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)
    const { currentUser, isVerified } = useContext(CurrentUserContext)

    const [profile, setProfile] = useState(null)

    const { profileHash } = useParams()
    const navigate = useNavigate()
    const { id } = useParams()

    // If the current user is not verified, he can only fetch his profile
    const canFetchProfile =
        isVerified || (!isVerified && profileHash === currentUser.profileHash)

    const { data, meta, isLoading, fetchData, error } = useFetchDataById(
        ENTITIES.DIVER_PROFILE_BY_HASH,
        profileHash,
        { profileHash: profileHash, include: 'heroImage' },
        !!profileHash && canFetchProfile,
        profileHash
    )

    useEffect(() => {
        if (
            error?.response?.status === RESPONSE_CODES.FORBIDDEN ||
            !canFetchProfile
        ) {
            const msg = error?.response?.data?.message
            if (msg) {
                setAlert(t(msg), ALERT_TYPES.ERROR)
            }
            navigate(ROUTES.HOME)
        }
    }, [error])

    useEffect(() => {
        setProfile(null)
    }, [profileHash])

    useEffect(() => {
        setProfile(data)
    }, [data])

    const contextValue = useMemo(
        () => ({
            profile,
            meta,
            setProfile,
            fetchProfile: fetchData,
            isLoadingProfile: isLoading,
            error,
        }),
        [profile, isLoading]
    )

    return (
        <ProfileContext.Provider value={contextValue}>
            {children}
        </ProfileContext.Provider>
    )
}

export default ProfileContextProvider
