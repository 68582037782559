import { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useTranslate } from 'react-polyglot'

import useFetchDataById from 'hooks/useFetchDataById'
import useFetchData from 'hooks/useFetchData'
import useWindowDimensions from 'hooks/useWindowDimension'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import useEmptyValueMessage from 'hooks/useEmptyValueMessage'
import useProjectAccess from 'hooks/useProjectAccess'

import { deleteEntityService } from 'services/entity.service'
import { getEntityFilters } from 'services/localStorage.service'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'

import ENTITIES from 'constants/entities'
import ROUTES from 'constants/routes'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    DIVING_PERSONNEL_POSITIONS,
    ICON_SIZE,
    PROJECT_POSITION_ACTIONS,
    PROJECT_WORKER_STATUS,
} from 'constants/enums'
import ICONS from 'constants/icons'
import FILTERS from 'constants/filters'
import COLORS from 'constants/colors'

import Loader from 'components/Loader'
import Breadcrumbs from 'components/Breadcrumbs'
import Button from 'components/Button'
import ProjectContainer from './components/ProjectContainer'
import DiverPositionList from './components/DiverPositionList'
import ContractorPositionList from './components/ContractorPositionList'
import DiveTeamMembersList from '../../../components/diveTeamMembers/DiveTeamMembersList'
import LatestDiveRecordsList from 'components/latestDiveRecords/LatestDiveRecordsList'
import RESPONSE_CODES from 'constants/responseCodes'
import ProjectClientRepresentatives from 'components/projectClientRepresentatives/ProjectClientRepresentatives'

const SingleProject = () => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { id } = useParams()
    const location = useLocation()
    const { isSmallScreen } = useWindowDimensions()

    const firstRender = useRef(true)
    const positionListRef = useRef(null)

    const [position, setPosition] = useState(null)

    const containsInUrl = location.pathname.includes(ROUTES.MY_DIVE_PROJECTS)

    const { setAlert } = useContext(AlertContext)
    const { isDiver, isContractor, userId } = useContext(CurrentUserContext)

    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const { data: project, isLoading, error } = useFetchDataById(
        ENTITIES.PROJECT,
        id,
        {
            include:
                'country,company,region,locationType,projectType,divingMode,countryOfMobilization,projectStatus,positions,creator,bodyOfWater,projectIndustryType,industry',
        },
        true,
        id
    )

    const {
        data: diveTeam,
        meta: diveTeamMeta,
        fetchData: fetchDiveTeam,
        isLoading: isLoadingProjectWorkers,
    } = useFetchData(
        ENTITIES.PROJECT_WORKER_ELASTIC,
        {
            project: parseInt(id),
            status: PROJECT_WORKER_STATUS.EMPLOYED,
        },
        true,
        false,
        id
    )

    const diverProjectPositions = useFetchDataByQueryParams(
        ENTITIES.DIVER_PROJECT_POSITIONS,
        {
            project: id,
            include: 'diverPosition,project,project.creator',
        },
        false,
        isDiver
    )

    const contractorProjectPositions = useFetchDataByQueryParams(
        ENTITIES.PROJECT_POSITIONS,
        {
            'project.id': id,
            include:
                'diverPosition,diverPosition.diverPositionCategory,project,project.creator',
        },
        false,
        isContractor
    )

    const {
        data: diveRecords,
        isLoading: isLoadingDiveRecords,
        fetchData,
    } = useFetchData(
        ENTITIES.DIVE_RECORD_ELASTIC,
        {
            'project.id': id,
            isProjectPage: 1,
            include:
                'diveRecordGeneralInformation,diveRecordGeneralInformation.divingMode,project,diveRecordGeneralInformation.supervisor,creator',
        },
        true,
        true,
        id
    )

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }
        if (!firstRender.current) {
            diverProjectPositions.fetchData()
            contractorProjectPositions.fetchData()
        }
    }, [id])

    const { isProjectOwner, isProjectClient } = useProjectAccess(project)

    /** Handle forbidden  */
    useEffect(() => {
        if (error?.response?.status === RESPONSE_CODES.FORBIDDEN) {
            const msg = error?.response?.data?.message
            if (msg) {
                setAlert(t(msg), ALERT_TYPES.ERROR)
            }
            navigate(`${ROUTES.HOME}`)
        }
    }, [error])

    const canCreateDiveRecord = diveTeam.some(
        (item) =>
            [
                DIVING_PERSONNEL_POSITIONS.SURFACE_DIVER.code,
                DIVING_PERSONNEL_POSITIONS.BELL_SAT_DIVER.code,
                DIVING_PERSONNEL_POSITIONS.DIVE_SUPERVISOR.code,
            ].includes(item.positionTitleCode) && item.workerId === userId
    )

    const positionOnCurrentProject = diverProjectPositions.data.find((obj) =>
        obj?.availableActions.some(
            (item) => item === PROJECT_POSITION_ACTIONS.PERSON_EMPLOYED
        )
    )?.diverPositionCode

    const handleConfirm = async (id) => {
        try {
            await deleteEntityService(ENTITIES.PROJECT, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            navigate(ROUTES.HOME)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleCancel = () => closeConfirmationModal()

    const handleDelete = ({ id }) =>
        showConfirmationModal({
            message: 'message.areYouSureDeleteProject',
            title: 'general.deleteProject',
            handleConfirm: () => handleConfirm(id),
            handleCancel,
        })

    const handleEditProject = () => {
        if (containsInUrl) {
            navigate(`${ROUTES.MY_DIVE_PROJECTS}${ROUTES.EDIT}/${id}`)
        } else {
            navigate(`${ROUTES.PROJECT}${ROUTES.EDIT}/${id}`)
        }
    }

    const condition = diveRecords.length === 0 && !isLoading
    const { showEmptyValueMessage } = useEmptyValueMessage(condition)

    const getPositionFromTop = () => {
        if (positionListRef.current) {
            const positionListRect = positionListRef.current.getBoundingClientRect()
            const headerHeight = document.querySelector('.m-header')
                .clientHeight

            //Note: To scroll to the container with positions, the value should be
            // adjusted by the height of the header element, and an additional 10px
            // should be added for visible space between the box and the header.
            const distanceFromTop =
                positionListRect.top + window.scrollY - headerHeight - 10

            setPosition(distanceFromTop)
        }
    }

    useEffect(() => {
        getPositionFromTop()

        window.addEventListener('scroll', getPositionFromTop)

        return () => {
            window.removeEventListener('scroll', getPositionFromTop)
        }
    }, [])

    if (!project) return null

    return (
        <div>
            <div className="m-boxes__breadcrumbs">
                {containsInUrl ? (
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                label: 'general.myDiveProjects',
                                to: ROUTES.MY_DIVE_PROJECTS,
                                icon: ICONS.HOMEPAGE_BREADCRUMB,
                                queryParams: getEntityFilters(
                                    FILTERS.DIVE_PROJECTS
                                ),
                            },
                            {
                                label: project.name,
                                to: `${ROUTES.MY_DIVE_PROJECTS}/${id}`,
                                translate: false,
                            },
                        ]}
                    />
                ) : (
                    <Breadcrumbs
                        breadcrumbs={[
                            {
                                label: 'general.homepage',
                                to: ROUTES.HOME,
                                icon: ICONS.HOMEPAGE_BREADCRUMB,
                                queryParams: getEntityFilters(
                                    FILTERS.DIVE_PROJECTS
                                ),
                            },
                            {
                                label: 'general.diveProjects',
                                to: ROUTES.HOME,
                                queryParams: getEntityFilters(
                                    FILTERS.DIVE_PROJECTS
                                ),
                            },
                            {
                                label: project.name,
                                to: `${ROUTES.PROJECT}/${id}`,
                                translate: false,
                            },
                        ]}
                    />
                )}
            </div>
            <div className="m-boxes -singleProject">
                {isProjectOwner && isSmallScreen && (
                    <div className="m-boxes__main -mb20">
                        <Button
                            label="button.editProject"
                            btnClass={BUTTON_STATUS.TERTIARY}
                            buttonSize={BUTTON_SIZE.XSMALL}
                            icon={ICONS.EDIT}
                            iconSize={ICON_SIZE.SIZE20}
                            iconColor={COLORS.LIGHT_BLUE}
                            onClick={handleEditProject}
                        />
                    </div>
                )}
                <div className="m-boxes__main -noBackground">
                    <div className="m-boxes__main -projectDetails fullWidth">
                        <ProjectContainer
                            data={project}
                            handleDelete={handleDelete}
                            canCreateDiveRecord={canCreateDiveRecord}
                            positionOnCurrentProject={positionOnCurrentProject}
                        />
                    </div>
                    <div
                        className="m-boxes__main -mt20 -openPositions fullWidth"
                        ref={positionListRef}
                    >
                        <h3 className="-mb20">
                            {t('general.projectPositions')}
                        </h3>

                        {isDiver ? (
                            <DiverPositionList
                                data={diverProjectPositions}
                                project={project}
                                fetchDiveTeam={fetchDiveTeam}
                                topPosition={position}
                            />
                        ) : (
                            <ContractorPositionList
                                projectPositions={contractorProjectPositions}
                                topPosition={position}
                            />
                        )}
                    </div>
                </div>

                <div
                    className={`m-boxes__side -button ${
                        isSmallScreen ? '-mt0' : ''
                    }`}
                >
                    {isProjectOwner && !isSmallScreen && (
                        <div className="m-boxes__side -textContent -mb20">
                            <Button
                                label="button.editThisProject"
                                btnClass={BUTTON_STATUS.TERTIARY}
                                buttonSize={BUTTON_SIZE.XSMALL}
                                icon={ICONS.EDIT}
                                iconSize={ICON_SIZE.SIZE20}
                                iconColor={COLORS.LIGHT_BLUE}
                                onClick={handleEditProject}
                            />
                        </div>
                    )}
                    <div
                        className={`m-boxes__side -textContent ${
                            isSmallScreen ? '-mt20' : ''
                        }`}
                    >
                        <LatestDiveRecordsList
                            data={diveRecords}
                            fetchData={fetchData}
                            canCreateDiveRecord={canCreateDiveRecord}
                            positionOnCurrentProject={positionOnCurrentProject}
                            showEmptyValueMessage={showEmptyValueMessage}
                            isProjectOwner={isProjectOwner}
                            isProjectClient={isProjectClient}
                        />
                    </div>

                    {(isProjectOwner || isProjectClient) && (
                        <div className="m-boxes__side -textContent -mt20">
                            <ProjectClientRepresentatives
                                projectId={parseInt(id)}
                                isProjectClient={isProjectClient}
                            />
                        </div>
                    )}

                    <div className="m-boxes__side -textContent -mt20">
                        <DiveTeamMembersList
                            projectId={parseInt(id)}
                            diveTeam={diveTeam}
                            meta={diveTeamMeta}
                            isLoading={isLoadingProjectWorkers}
                            isProjectOwner={isProjectOwner}
                            isProjectClient={isProjectClient}
                        />
                    </div>
                </div>
                {(isLoading ||
                    isLoadingProjectWorkers ||
                    isLoadingDiveRecords) && <Loader />}
            </div>
        </div>
    )
}

export default SingleProject
