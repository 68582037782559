import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useRecoilValue } from 'recoil'
import { imageAtom } from 'recoil/atoms'
import {
    Chat,
    Channel,
    ChannelHeader,
    ChannelList,
    MessageInput,
    MessageList,
    Thread,
    Window,
    useMessageContext,
    MessageSimple,
    useChannelActionContext,
    Avatar,
    ReactionsList,
    useChannelStateContext,
} from 'stream-chat-react'
import { EmojiPicker } from 'stream-chat-react/emojis'
import 'stream-chat-react/dist/css/v2/index.css'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { AlertContext } from 'contexts/AlertContext'

import useWindowDimensions from 'hooks/useWindowDimension'
import useChannelUsers from '../../hooks/useChannelUsers'

import { getFileUrlService } from 'services/file.service'

import { getChannelFilters, getDMFilters } from 'utils/chatFilters'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ALERT_TYPES, ICON_SIZE } from 'constants/enums'
import { CHANNEL_TYPE_DM, SEARCH_CHANNELS_LIMIT } from 'constants/chat'
import { DEFAULT_TEAM_CHAT_IMAGE } from 'constants/constants'

import AddChannel from 'components/chat/AddChannel'
import Icon from 'components/Icon'
import AddDirectMessage from 'components/chat/AddDirectMessage'
import Input from 'components/Input'
import Dropdown from 'components/Dropdown'
import ChatAvatar from './ChatAvatar'
import PinnedMessages from './PinnedMessages'
import EditChannel from './EditChannel'
import EmptyChannels from './EmptyChannels'
import EmptyDMs from './EmptyDMs'
import CustomListItem from './CustomListItem'
import CustomUserListItem from './CustomUserListItem'
import Image from 'components/Image'

export const getChannelUserId = (channel, currentUser) => {
    if (!channel) return

    return Object.keys(channel.state.members).filter(
        (key) => key.toString() !== currentUser.id.toString()
    )[0]
}

const CustomMessageUi = () => {
    const { editing } = useMessageContext()

    const header = document.querySelector('.m-header')

    header && (header.style.zIndex = editing ? '2' : '5')

    return <MessageSimple />
}

export const getIsOnline = (userId, client, channelUsers) => {
    if (!userId) return

    const user = channelUsers.find(
        (user) => user.id.toString() === userId.toString()
    )

    return user ? user.online : client.state.users[userId].online
}

const CustomReactionList = (props) => {
    const { channel } = useChannelStateContext()
    const { message } = useMessageContext()

    const images = useRecoilValue(imageAtom)

    const fetchReactions = async (reactionType) => {
        const { reactions } = await channel.getReactions(message.id)

        const messageReactionsByType = reactions.filter(
            (item) => item.type === reactionType
        )
        const messageReactions = []
        for (const reaction of messageReactionsByType) {
            const existingUserImage = images.find(
                (image) => image.path === reaction.user.image
            )
            const userImage = existingUserImage
                ? existingUserImage.fileUrl
                : await getFileUrlService(reaction.user.image)

            messageReactions.push({
                ...reaction,
                user: {
                    ...reaction.user,
                    image: userImage,
                },
            })
        }
        return messageReactions
    }

    return <ReactionsList {...props} handleFetchReactions={fetchReactions} />
}

const ChatComponent = ({ fullScreen = false, setChatOpen }) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()
    // Main search
    const [allChannels, setAllChannels] = useState([])
    const [teamChannels, setTeamChannels] = useState([])
    const [directChannels, setDirectChannels] = useState([])
    const [query, setQuery] = useState('')

    const [addChannel, setAddChannel] = useState(false)
    const [addDM, setAddDM] = useState(false)
    const [activeChannel, setActiveChannel] = useState(null)
    const [editChannel, setEditChannel] = useState(false)

    const { setAlert } = useContext(AlertContext)
    const { chatClient: client, currentUser } = useContext(CurrentUserContext)

    const { channelUsers } = useChannelUsers()

    useEffect(() => {
        if (!client) return

        const handleDeleteChannel = (event) => {
            const eventTypes = ['channel.deleted']
            if (eventTypes.includes(event.type)) {
                setActiveChannel(null)
            }
        }

        const messageEventListener = client.on((event) =>
            handleDeleteChannel(event)
        )

        return () => {
            messageEventListener.unsubscribe()
        }
    }, [client])

    const handleEditChannel = () => {
        if (editChannel === true) return
        setEditChannel(true)
        setAddDM(false)
        setAddChannel(false)
    }

    const renderChatAvatar = useCallback(
        (props) => {
            return (
                <ChatAvatar
                    {...props}
                    client={client}
                    activeChannel={activeChannel}
                    channelUsers={channelUsers}
                    currentUser={currentUser}
                    setActiveChannel={setActiveChannel}
                    handleEditChannel={handleEditChannel}
                    createdByMe={createdByMe}
                    handleDeleteChannel={handleDeleteChannel}
                />
            )
        },
        [activeChannel?.id, channelUsers]
    )

    const renderMessageAvatar = useCallback(
        (props) => {
            const { image, user, name, className } = props

            const isStatusAvatar =
                className === 'str-chat__avatar--message-status'

            if (user?.id === currentUser?.id?.toString()) return null

            if (image)
                return (
                    <div className="chatAvatar" title={name}>
                        <div
                            className={`-image ${
                                isStatusAvatar
                                    ? '-messageStatusAvatar'
                                    : '-messageAvatar'
                            }`}
                        >
                            <Image
                                alt=""
                                path={image}
                                type="MessageAvatar"
                                icon={ICONS.USER}
                                iconSize={ICON_SIZE.SIZE18}
                            />
                        </div>
                    </div>
                )

            return <Avatar {...props} />
        },
        [activeChannel?.id]
    )

    if (!client) return <div>Loading...</div>
    if (!currentUser) return

    let createdByMe =
        currentUser?.id === Number(activeChannel?.data?.created_by?.id) &&
        activeChannel?.type === 'team'

    const channelFilters = {
        members: { $in: [currentUser.id.toString()] },
        type: 'team',
        channelType: 'channel',
    }
    const userFilters = {
        members: { $in: [currentUser.id.toString()] },
        type: 'messaging',
        channelType: 'dm',
    }

    const onCancelCreateChannel = () => {
        setAddChannel(false)
        setEditChannel(false)
    }

    const onCancelCreateDM = () => {
        setAddDM(false)
    }

    const handleAddChannel = () => {
        if (addChannel === true) return
        setAddChannel(true)
        setAddDM(false)
        setEditChannel(false)
    }

    const activateChannel = (channel) => {
        setActiveChannel(channel)
        channel.show()
        setAddChannel(false)
        setAddDM(false)
        setEditChannel(false)
        setQuery('')
    }

    const activateChannelByUser = async (user) => {
        if (user.channel && user.channel.cid && !user.id) {
            activateChannel(user.channel)
            return
        }

        setQuery('')
        setAddChannel(false)
        setEditChannel(false)
        setAddDM(false)

        const filters = {
            type: 'messaging',
            member_count: 2,
            members: { $eq: [user.id, currentUser.id.toString() || ''] },
        }

        const [existingChannel] = await client.queryChannels(filters)

        if (existingChannel) {
            return setActiveChannel(existingChannel)
        }

        const newChannel = await client.channel('messaging', {
            members: [user.id, currentUser.id.toString() || ''],
            channelType: CHANNEL_TYPE_DM,
        })
        await newChannel.watch()

        return setActiveChannel(newChannel)
    }

    const handleAddDM = () => {
        setAddDM(true)
        setAddChannel(false)
        setEditChannel(false)
    }

    const handleDeleteChannel = async (e, channel) => {
        try {
            e.stopPropagation()
            e.preventDefault()
            if (activeChannel?.id === channel.id) {
                setActiveChannel(null)
            }
            await channel.delete()
            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            console.log(error)
        }
    }

    const renderCustomListItem = (props) => {
        return (
            <CustomListItem
                {...props}
                activeChannel={activeChannel}
                activateChannel={activateChannel}
            />
        )
    }

    const renderCustomUserListItem = (props) => {
        return (
            <CustomUserListItem
                {...props}
                currentUser={currentUser}
                client={client}
                channelUsers={channelUsers}
                activeChannel={activeChannel}
                activateChannelByUser={activateChannelByUser}
                handleDeleteChannel={handleDeleteChannel}
            />
        )
    }

    const getChannels = async (searchTerm) => {
        try {
            const channelResponse = client.queryChannels(
                getChannelFilters(searchTerm, client.userID),
                {},
                SEARCH_CHANNELS_LIMIT
            )

            const userResponse = client.queryUsers(
                getDMFilters(searchTerm, client.userID),
                SEARCH_CHANNELS_LIMIT
            )

            const [channels, { users }] = await Promise.all([
                channelResponse,
                userResponse,
            ])

            setTeamChannels(channels.length ? channels : [])
            setDirectChannels(users.length ? users : [])
            setAllChannels(channels.concat(users))
        } catch (e) {}
    }

    const onSearch = (value) => {
        setQuery(value)
        if (!value) return

        getChannels(value)
    }

    const addChannelBtn = () => {
        return (
            <div className={'new-channel'}>
                <span className="a-mediumText a-lightText -opacity-60">
                    {t('chat.channels')}
                </span>
                <span className={'icon-holder'} onClick={handleAddChannel}>
                    <Icon name={ICONS.PLUS} color={COLORS.LIGHT_BLUE} />
                </span>
            </div>
        )
    }

    const addDmBtn = () => {
        return (
            <div className={'new-channel'}>
                <span className="a-mediumText a-lightText -opacity-60">
                    {t('chat.directMessages')}
                </span>
                <span className={'icon-holder'} onClick={handleAddDM}>
                    <Icon name={ICONS.PLUS} color={COLORS.LIGHT_BLUE} />
                </span>
            </div>
        )
    }

    const renderMainContent = () => {
        if (addChannel) {
            return (
                <AddChannel
                    onCancelCreateChannel={onCancelCreateChannel}
                    onCreateChannel={activateChannel}
                    setActiveChannel={setActiveChannel}
                />
            )
        }

        if (editChannel) {
            return (
                <EditChannel
                    activeChannel={activeChannel}
                    setEditChannel={setEditChannel}
                    createdByMe={createdByMe}
                />
            )
        }

        if (addDM) {
            return (
                <AddDirectMessage
                    onCreateChannel={activateChannel}
                    onCancelCreateChannel={onCancelCreateDM}
                    setActiveChannel={setActiveChannel}
                />
            )
        }

        if (!activeChannel)
            return (
                <div className="chat-welcome-screen">
                    <div className="-content">
                        <Icon
                            name={ICONS.CHAT}
                            color={COLORS.LIGHT_BLUE}
                            size={ICON_SIZE.SIZE80}
                        />
                        <p className="a-bodyTextRegular a-lightText -opacity-40">
                            {t('general.noActiveChannelText')}
                        </p>
                    </div>
                </div>
            )

        return (
            <Channel
                channel={activeChannel}
                EmojiPicker={EmojiPicker}
                Message={CustomMessageUi}
                Avatar={renderMessageAvatar}
                ReactionsList={CustomReactionList}
            >
                <ChannelInner
                    renderChatAvatar={renderChatAvatar}
                    activeChannel={activeChannel}
                    currentUser={currentUser}
                />
            </Channel>
        )
    }

    const renderDmList = () => {
        return (
            directChannels.length > 0 &&
            directChannels.map((user, key) => {
                return (
                    <div key={key}>
                        <CustomUserListItem
                            channel={user}
                            id={user.id}
                            displayImage={user.image}
                            displayTitle={user.name}
                            unread={0}
                            activateChannelByUser={activateChannelByUser}
                            currentUser={currentUser}
                            client={client}
                            channelUsers={channelUsers}
                            activeChannel={activeChannel}
                            handleDeleteChannel={handleDeleteChannel}
                        />
                    </div>
                )
            })
        )
    }

    const renderChannelList = () => {
        return (
            teamChannels.length > 0 &&
            teamChannels.map((channel) => {
                return (
                    <div key={channel.id}>
                        <CustomListItem
                            channel={channel}
                            showActions={false}
                            activeChannel={activeChannel}
                            activateChannel={activateChannel}
                        />
                    </div>
                )
            })
        )
    }

    const renderHeading = () => {
        return (
            <div className={'header-wrapper -mb20'}>
                <h3>{t('chat.messages')}</h3>
                {!fullScreen && setChatOpen && (
                    <span
                        className={'close-chat'}
                        onClick={() => setChatOpen(false)}
                    >
                        <Icon name={ICONS.CLOSE} color={COLORS.DARK_BLUE} />
                    </span>
                )}
            </div>
        )
    }

    const renderSidebar = () => {
        return (
            <div className="sidebar-content">
                {addChannelBtn()}
                <div className={'channels-sidebar -mb20'}>
                    <ChannelList
                        filters={channelFilters}
                        Preview={renderCustomListItem}
                        EmptyStateIndicator={EmptyChannels}
                    />
                </div>

                {addDmBtn()}
                <div className={'users-sidebar'}>
                    <ChannelList
                        filters={userFilters}
                        Preview={renderCustomUserListItem}
                        EmptyStateIndicator={EmptyDMs}
                    />
                </div>
            </div>
        )
    }

    const renderSidebarContent = () => {
        if (addChannel && isTablet) {
            return (
                <AddChannel
                    onCancelCreateChannel={onCancelCreateChannel}
                    onCreateChannel={activateChannel}
                    setActiveChannel={setActiveChannel}
                />
            )
        }
        if (addDM && isTablet) {
            return (
                <AddDirectMessage
                    onCreateChannel={activateChannel}
                    onCancelCreateChannel={onCancelCreateDM}
                    setActiveChannel={setActiveChannel}
                />
            )
        }
        return (
            <div className="-sidebarContent">
                {renderHeading()}
                <div className={'search-wrapper -mb20'}>
                    <div className="m-searchField">
                        <Input
                            name="searchUserField"
                            placeholder="form.placeholder.search"
                            onChange={(e) => onSearch(e.target.value)}
                            icon={ICONS.SEARCH}
                            iconColor={COLORS.DARK_BLUE_40}
                            size={ICON_SIZE.SIZE16}
                            showLabel={false}
                            value={query}
                        />
                    </div>
                    <Dropdown open={query}>
                        {allChannels.length === 0 && (
                            <div className="-result a-lightText a-captionsTextRegular -noResults">
                                {t('general.noSearchResults')}
                            </div>
                        )}
                        {renderChannelList()}
                        {renderDmList()}
                    </Dropdown>
                </div>
                {renderSidebar()}
            </div>
        )
    }

    const renderSidebarContentSmallerDevices = () => {
        return activeChannel ? renderMainContent() : renderSidebarContent()
    }

    return (
        <div className={fullScreen ? 'fullscreen-chat' : ''}>
            <div className={'chat-cmp'}>
                {client && (
                    <Chat client={client}>
                        <div className={'chat-sidebar'}>
                            {!isTablet
                                ? renderSidebarContent()
                                : renderSidebarContentSmallerDevices()}
                        </div>
                        {!isTablet && (
                            <div className={'main-chat-content'}>
                                {renderMainContent()}
                            </div>
                        )}
                    </Chat>
                )}
            </div>
        </div>
    )
}

const ChannelInner = ({ renderChatAvatar, activeChannel, currentUser }) => {
    const t = useTranslate()
    const { sendMessage, addNotification } = useChannelActionContext()

    const overrideSubmitHandler = (message) => {
        const isDisabled =
            activeChannel.type === 'messaging' &&
            Object.values(activeChannel.state.members).some(
                (member) =>
                    parseInt(member.user_id) !== currentUser.id &&
                    !!member.user.hidden
            )

        if (isDisabled) {
            addNotification(t('message.userHiddenError'), 'error')
            return
        }

        sendMessage(message)
    }

    return (
        <>
            <Window>
                <ChannelHeader
                    Avatar={renderChatAvatar}
                    image={
                        activeChannel?.type === 'team'
                            ? DEFAULT_TEAM_CHAT_IMAGE
                            : activeChannel?.data?.image
                    }
                />
                <PinnedMessages activeChannel={activeChannel} />
                <MessageList />
                <MessageInput overrideSubmitHandler={overrideSubmitHandler} />
            </Window>
            <Thread />
        </>
    )
}

export default ChatComponent
