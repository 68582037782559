import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { imageAtom } from 'recoil/atoms'

import {
    getFileUrlService,
    isPrivatePath,
    isUrlPath,
} from 'services/file.service'

import { BASE_URL } from 'utils/axiosClient'

const useFetchFile = (path, baseUrl = BASE_URL) => {
    const [images, setImages] = useRecoilState(imageAtom)

    const existingImage = images.find((item) => item.path === path)

    const [fileUrl, setFileUrl] = useState(existingImage?.fileUrl || '')

    useEffect(() => {
        if (!!existingImage) return

        if (!path) {
            setFileUrl('')
        } else if (isPrivatePath(path)) {
            fetchFile()
        } else if (isUrlPath(path)) {
            setFileUrl(path)
        } else {
            // Public file /uploads/...
            setFileUrl(`${baseUrl}${path}`)
        }
    }, [path])

    const fetchFile = async () => {
        try {
            const url = await getFileUrlService(path, baseUrl)
            setFileUrl(url)
            setImages((state) => {
                if (state.some((item) => item.path === path)) {
                    return state
                }
                return [...state, { path, fileUrl: url }]
            })
        } catch (error) {
            console.error('Error fetching file:', error)
        }
    }

    return { fileUrl }
}

export default useFetchFile
