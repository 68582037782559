import DateCell from 'components/table/tableCells/DateCell'
import StatusCell from 'components/table/tableCells/StatusCell'
import { CALL_CENTER_STATUSES } from 'constants/enums'

export const TABLE_HEADER = [
    {
        key: 'from',
        title: 'table.header.fromNumber',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        smallScreenHeader: true,
        sortable: false,
    },
    {
        key: 'to',
        title: 'table.header.toNumber',
        showOnSmallScreens: false,
        sortable: false,
    },
    {
        key: 'startTime',
        title: 'table.header.date',
        showOnSmallScreens: true,
        sortable: false,
        CellComponent: DateCell,
    },
    {
        key: 'duration',
        title: 'table.header.duration',
        showOnSmallScreens: true,
        sortable: false,
    },
    {
        key: 'conversationStatus',
        title: 'table.header.status',
        showOnSmallScreens: true,
        sortable: false,
        CellComponent: StatusCell,
        statuses: CALL_CENTER_STATUSES,
    },
]
