import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { BUTTON_SIZE, BUTTON_STATUS, ICON_POSITION } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Button from 'components/Button'
import ENTITIES from '../../constants/entities'
import useFetchDataByQueryParams from '../../hooks/useFetchDataByQueryParams'
import InviteColleagueForm from './components/InviteColleagueForm'
import CardContainer from '../CardContainer'
import CardHeader from '../card/CardHeader'

const ProjectClientRepresentatives = ({ projectId, isProjectClient }) => {
    const t = useTranslate()
    const [addModalOpened, setAddModalOpened] = useState(false)

    const { data: projectClientReps, fetchData: fetchReps } =
        useFetchDataByQueryParams(ENTITIES.PROJECT_CLIENT_REPRESENTATIVES, {
            'project.id': projectId,
            include: 'user,user.companyUser',
        })

    return (
        <Fragment>
            <div className="column fullWidth">
                <span className="a-bodyTextMedium">
                    {t('general.clientRepresentatives')}
                </span>

                {projectClientReps.length === 0 && (
                    <span className="a-mediumText a-lightText fullWidth -mt10">
                        {t('general.noClientRepresentatives')}
                    </span>
                )}

                {projectClientReps.length > 0 &&
                    projectClientReps.map((rep) => {
                        const item = rep.user

                        return (
                            <div className="-mt15 fullWidth" key={item.id}>
                                <CardContainer
                                    noBorderCard
                                    link={`${ROUTES.DIVER_PROFILE}/${item.profileHash}`}
                                >
                                    <CardHeader
                                        title={item.fullName}
                                        avatar={item.avatarPath}
                                        placeholderIcon={ICONS.USER}
                                        onlyHeader={true}
                                    />
                                </CardContainer>
                            </div>
                        )
                    })}
            </div>

            <div>
                {isProjectClient && (
                    <div className="-mt20">
                        <Button
                            btnClass={BUTTON_STATUS.TERTIARY}
                            buttonSize={BUTTON_SIZE.XSMALL}
                            label="button.addClientRepresentatives"
                            onClick={() => {
                                setAddModalOpened(true)
                            }}
                            icon={ICONS.ARROW_RIGHT}
                            iconColor={COLORS.LIGHT_BLUE}
                            iconPosition={ICON_POSITION.RIGHT}
                        />
                    </div>
                )}
            </div>

            {addModalOpened && (
                <InviteColleagueForm
                    setModalOpened={setAddModalOpened}
                    refetchData={async () => {
                        await fetchReps()
                    }}
                    projectId={projectId}
                    projectClientReps={projectClientReps}
                />
            )}
        </Fragment>
    )
}

ProjectClientRepresentatives.propTypes = {
    projectId: PropTypes.number.isRequired,
    isProjectClient: PropTypes.bool,
}

export default ProjectClientRepresentatives
