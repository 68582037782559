import { Fragment, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import useFetchData from 'hooks/useFetchData'
import useEmptyValueMessage from 'hooks/useEmptyValueMessage'

import ENTITIES from 'constants/entities'
import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Loader from 'components/Loader'
import StepButtons from 'components/StepButtons'
import AddNew from '../AddNew'
import CreateTravelDocument from './components/CreateTravelDocument'
import TravelDocuments from './components/TravelDocuments'
import FieldError from '../../../../components/FieldError'
import RequestAccess from 'screens/diver/profile/RequestAccess'
import NoData from 'components/NoData'
import TabHeading from 'screens/diver/profile/diverInformation/components/TabHeading'

const TravelDocumentsOnboarding = ({
    goToNextStep,
    goPreviousStep,
    userID,
    myProfile,
    profilePage,
    refetchData,
    user,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()

    const [create, setCreate] = useState(
        location.state?.travelDocument || false
    )

    //NOTE: When navigated from profile page update button to the same tab you are already
    useEffect(() => {
        location.state?.travelDocument &&
            setCreate(location.state?.travelDocument)
    }, [location.state?.travelDocument])

    const [error, setError] = useState('')

    const showActions = myProfile || !profilePage

    const { data, meta, isLoading, fetchData } = useFetchData(
        ENTITIES.TRAVEL_DOCUMENTATION,
        {
            include:
                'files,travelDocumentationType,countryIssue,visas,recordInformations,visas.country,visas.files,recordInformations.files',
            'user.id': userID,
        },
        true,
        false
    )

    const status = meta?.dataAccessStatus
    const hasAccess =
        myProfile ||
        [
            ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id,
            ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id,
        ].includes(status)

    const hideFiles =
        status === ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id
    // It is necessary to clear the location state
    // (that is sent from the homepage due to completing the profile) after the component is rendered
    useEffect(() => {
        navigate(`${location.pathname}${location.search}`, { replace: true })
    }, [])

    const { showEmptyValueMessage } = useEmptyValueMessage(!showActions)

    const handleOpenModal = () => setCreate(true)

    const hasRequiredDocuments = useMemo(() => {
        return data.some(
            (item) =>
                item.travelDocumentationType?.id === 1 ||
                item.travelDocumentationType?.id === 2
        )
    }, [data])

    useEffect(() => {
        hasRequiredDocuments && setError('')
    }, [hasRequiredDocuments])

    const handleGoToNext = () => {
        if (hasRequiredDocuments) {
            setError('')
            goToNextStep()
        } else {
            setError(t('message.noRequiredDocuments'))
        }
    }

    if (!hasAccess) {
        return (
            <RequestAccess
                module={ACCESS_CONTROL_MODULES.TRAVEL_DOCUMENTS}
                title="general.travelDocumentation"
                status={status}
                canRequestAccess={
                    status === ACCESS_CONTROL_STATUSES.NOT_FOUND.id
                }
                profile={user}
                checkAccessControl={fetchData}
                requestTime={meta.dataAccessRequestTime}
            />
        )
    }

    return (
        <Fragment>
            <div className={profilePage ? 'm-profileTabs' : '-contentElements'}>
                <TabHeading
                    tabTitle="general.travelDocumentation"
                    tab={ROUTES.TRAVEL_DOCUMENTATION}
                    myProfile={myProfile}
                />
                {hideFiles && (
                    <p className="a-mediumText a-lightText -mt20">
                        {t('general.noAccessToFiles')}
                    </p>
                )}
                {showActions && (
                    <div className={!profilePage ? '_12' : ''}>
                        <AddNew
                            handleAction={handleOpenModal}
                            label="general.addDocument"
                        />

                        {error && <FieldError error={error} />}
                    </div>
                )}

                {create && (
                    <CreateTravelDocument
                        open={create}
                        setOpen={setCreate}
                        fetchData={fetchData}
                        showActions={showActions}
                        refetchData={refetchData}
                    />
                )}

                <TravelDocuments
                    data={data}
                    isLoading={isLoading}
                    fetchData={fetchData}
                    showActions={showActions}
                    profilePage={profilePage}
                    refetchData={refetchData}
                />
            </div>

            {!profilePage && (
                <StepButtons
                    handleBack={goPreviousStep}
                    handleNext={handleGoToNext}
                    noBorder
                />
            )}

            {data?.length === 0 && !isLoading && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noDataYet')}
                    description={t(
                        myProfile
                            ? 'general.clickThePlusButton'
                            : 'general.nothingHereRightNow'
                    )}
                />
            )}

            {isLoading && data.length > 0 && <Loader />}
        </Fragment>
    )
}

TravelDocumentsOnboarding.propTypes = {
    goToNextStep: PropTypes.func,
    goPreviousStep: PropTypes.func,
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
    refetchData: PropTypes.func,
}

TravelDocumentsOnboarding.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default TravelDocumentsOnboarding
