import { getUserToken } from './localStorage.service'

import { BASE_URL } from 'utils/axiosClient'

import ENTITIES from 'constants/entities'

// Private file path is /api/files/get/:id
export const isPrivatePath = (path) =>
    path?.startsWith(`/api/${ENTITIES.FILES_GET}`)

// Url path is saved with the domain e.g. chat avatar image
export const isUrlPath = (path) =>
    path?.startsWith('http://') || path?.startsWith('https://')

export const getFileUrlService = async (path, baseUrl = BASE_URL) => {
    try {
        if (isPrivatePath(path)) {
            const token = getUserToken()

            let response = await fetch(`${BASE_URL}${path}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            const blobData = await response.blob()
            return URL.createObjectURL(blobData)
        }

        if (isUrlPath(path)) {
            return path
        }

        // Public file /uploads/...
        return path ? `${baseUrl}${path}` : ''
    } catch (error) {
        throw error
    }
}
