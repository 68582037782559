import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import COLORS from 'constants/colors'
import { ICON_SIZE, USER_SUBSCRIPTION_TYPE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const BillingPlanStatus = ({ userSubscriptionType }) => {
    const t = useTranslate()

    const getPlanByType = () => {
        switch (userSubscriptionType) {
            case USER_SUBSCRIPTION_TYPE.ENTERPRISE:
                return 'general.enterprise'
            case USER_SUBSCRIPTION_TYPE.PRO:
                return 'general.pro'
            default:
                return 'general.free'
        }
    }

    return (
        <div className="aligned-center -gap5">
            {!userSubscriptionType ||
            userSubscriptionType === USER_SUBSCRIPTION_TYPE.FREE ? (
                <Icon
                    name={ICONS.CHECKMARK_ICON}
                    size={ICON_SIZE.SIZE20}
                    color={COLORS.GREEN}
                />
            ) : (
                <Icon
                    name={ICONS.PRO}
                    size={ICON_SIZE.SIZE20}
                    color={COLORS.YELLOW}
                />
            )}
            <span className="a-mediumTextSemiBold a-lightText -opacity-60">
                {t(getPlanByType())}
            </span>
        </div>
    )
}

BillingPlanStatus.propTypes = {
    userSubscriptionType: PropTypes.number,
}

export default BillingPlanStatus
