const FILTERS = {
    DIVERS: 'snd_diver_filters',
    DIVE_PROJECTS: 'snd_dive_project_filters',
    HISTORICAL_DIVE_PROJECTS: 'snd_historical_dive_project_filters',
    DIVE_TEAM: 'snd_project_dive_team',
    CONTRACTOR_HOMEPAGE: 'snd_contractor_homepage',
    COMPANIES: 'company_filters',
    USER: 'user',
    JOBS: 'job_filters',
    DIVE_RECORDS: 'snd_dive_record_filters',
    SUPER_ADMIN_INDIVIDUAL_FILTERS: 'super_admin_individual_filters',
    SUPER_ADMIN_COMPANY_FILTERS: 'super_admin_company_filters',
    SUPER_ADMIN_CALLS_FILTERS: 'super_admin_calls_filters',
    SUPER_ADMIN_TERMS_AND_CONDITIONS: 'super_admin_terms_and_conditions',
    COMPANY_EMPLOYEES: 'company_employees_filters',
    COMPANY_INVITED_USERS: 'company_invited_users_filters',
}

export default FILTERS
