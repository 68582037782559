import React, { useContext } from 'react'
import { Outlet } from 'react-router'
import { useTranslate } from 'react-polyglot'

import { formatDate } from 'services/moment.service'

import useFixedOnScroll from 'hooks/useFixedOnScroll'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { DiveRecordContext } from './contexts/DiveRecordContext'

import UnitOfMeasurementForm from './components/UnitOfMeasurementForm'
import DiveRecordHeader from './components/DiveRecordHeader'

const DiveRecordSteps = () => {
    const t = useTranslate()
    const { currentUser } = useContext(CurrentUserContext)

    const { fixedComponents } = useFixedOnScroll()

    const {
        diveRecord,
        isSupervisor,
        activeStepIndex,
        activeStep,
        allSteps,
        formRef,
        goToStepRef,
        goBack,
        handleGoToSpecificStep,
        diveRecordId,
    } = useContext(DiveRecordContext)

    const currentStep = activeStep || 1
    const allowedStep = isSupervisor ? 6 : diveRecord.data?.currentStep

    const handleClick = (id) => {
        if (id === currentStep || id > allowedStep) return

        if (isSupervisor) {
            handleGoToSpecificStep(id)
        } else if (formRef.current) {
            goToStepRef.current = id
            formRef.current.handleSave(id)
        }
    }

    const diveRecordName = () => {
        if (
            diveRecord?.data?.diveRecordGeneralInformation?.nameOfProject !==
                undefined &&
            diveRecord?.data?.recordNumber !== undefined
        ) {
            return `${
                diveRecord?.data?.diveRecordGeneralInformation?.nameOfProject
            } ${formatDate(
                diveRecord?.data?.diveRecordGeneralInformation?.dateOfDive
            )} #${diveRecord?.data?.recordNumber}`
        } else {
            return t('general.newDiveRecord')
        }
    }

    const renderDiveRecordHeader = (fixedHeader, activeFixedHeader) => (
        <DiveRecordHeader
            goBack={goBack}
            diveRecordName={diveRecordName}
            allSteps={allSteps}
            activeStepIndex={activeStepIndex}
            currentStep={currentStep}
            allowedStep={allowedStep}
            handleClick={handleClick}
            fixedHeader={fixedHeader}
            activeFixedHeader={activeFixedHeader}
        />
    )

    if (!allSteps.length) return null

    return (
        <div className="m-step">
            {/* There is double render for better transition of dive record header */}
            {renderDiveRecordHeader(false)}
            {renderDiveRecordHeader(true, fixedComponents)}
            {currentUser?.userSetting.unitImperial === undefined &&
                !isSupervisor && <UnitOfMeasurementForm />}
            <Outlet />
        </div>
    )
}

export default DiveRecordSteps
