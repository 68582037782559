import React, { useContext, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { ALL_ROLES } from 'constants/enums'
import ROUTES from 'constants/routes'

import { authRoutes } from './AuthRoutes'

const AuthRoutes = () => {
    const navigate = useNavigate()
    const { pathname, search } = useLocation()

    const {
        userId,
        currentUser,
        currentCompany,
        isDiver,
        isContractor,
        isVerified,
    } = useContext(CurrentUserContext)

    // Removing query params passed by Persona after verification
    useEffect(() => {
        if (search.includes('inquiry-id')) {
            navigate(pathname)
        }
    }, [])

    if (userId && !currentUser) return null
    if (isContractor && !currentCompany) return null // Waiting for isVerified value

    const mustCompleteSignUp =
        (isDiver || isContractor) && !currentUser.finishedSignUp

    const unverifiedUser = (isDiver || isContractor) && !isVerified

    const getAllowedRoutes = (routes) => {
        if (!routes?.length) return null

        // Return only the routes that are allowed for the role of the current user,
        // if roles are not defined it is assumed that the route is allowed for all roles.
        // If the user has to complete the sign up, only the layout and onboarding routes need to be returned,
        // otherwise the user cannot access the onboarding route
        let allowedRoutes = routes.filter(
            ({
                layout,
                onboarding = false,
                roles = ALL_ROLES,
                customCondition = (currentUser) => true,
            }) =>
                roles.some((role) => role.name === currentUser?.role.name) &&
                (layout || onboarding === mustCompleteSignUp) &&
                customCondition(currentUser)
        )

        // If the user is not verified, return only routes that do not require verification
        if (unverifiedUser) {
            allowedRoutes = allowedRoutes.filter(
                (route) => !route.requireVerification
            )
        }

        return allowedRoutes.map(({ routes, ...route }) => ({
            ...route,
            routes: getAllowedRoutes(routes),
        }))
    }

    const renderRoutes = (routes) => {
        if (!routes?.length) return null

        return routes.map(({ path, element, index, routes }, i) => (
            <Route key={i} index={index} path={path} element={element}>
                {renderRoutes(routes)}
            </Route>
        ))
    }

    const allowedRoutes = getAllowedRoutes(authRoutes)

    return (
        <Routes>
            {renderRoutes(allowedRoutes)}
            <Route
                path="*"
                element={
                    <Navigate
                        to={mustCompleteSignUp ? ROUTES.REGISTER : ROUTES.HOME}
                    />
                }
            />
        </Routes>
    )
}

export default AuthRoutes
