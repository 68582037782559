import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router'

import { AlertContext } from 'contexts/AlertContext'

import { getEntityService } from 'services/entity.service'

import { ALERT_TYPES } from 'constants/enums'

import useQueryParams from './useQueryParams'

const responseInitialState = {
    data: [],
    meta: { totalItems: 0 },
    isLoading: false,
    error: null,
}

const useFetchDataByQueryParams = (
    entity,
    params = {},
    allowLoadingMore = false,
    condition = true,
    isJSONAPI = true,
    apiUrl = process.env.REACT_APP_API_HOST
) => {
    const {
        page,
        itemsPerPage,
        sort,
        filterParams,
        addFilterParams,
    } = useQueryParams()

    const { setAlert } = useContext(AlertContext)

    const [response, setResponse] = useState(responseInitialState)

    const queryParams = useLocation().search

    const loadMore =
        allowLoadingMore && response.data.length < response.meta.totalItems
    const allowedFetch = entity && condition

    useEffect(() => {
        if (allowedFetch) {
            fetchData()
        }
    }, [queryParams, entity])

    // Clean response on entity change
    useEffect(() => {
        return () => setResponse(responseInitialState)
    }, [entity])

    const fetchData = async (fetchParams) => {
        try {
            if (!allowedFetch) return

            setResponse((res) => ({ ...res, isLoading: true }))

            const requestParams = {
                ...params,
                ...filterParams,
                page,
                itemsPerPage: itemsPerPage || params.itemsPerPage,
                sort: sort || params.sort,
                ...fetchParams,
            }

            const { data, meta } = await getEntityService(
                entity,
                requestParams,
                isJSONAPI,
                false,
                apiUrl
            )

            setResponse((res) => ({
                data:
                    allowLoadingMore && meta?.currentPage !== 1
                        ? [...res.data, ...data]
                        : data,
                meta: isJSONAPI ? meta : { totalItems: data.length },
                isLoading: false,
                error: null,
            }))
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
            setResponse({ ...responseInitialState, error })
        }
    }

    const fetchDataAfterDeletion = () => {
        if (response.data.length === 1 && response.meta.currentPage > 1) {
            addFilterParams({ page: response.meta.currentPage - 1 })
        } else {
            fetchData()
        }
    }

    return { ...response, loadMore, fetchData, fetchDataAfterDeletion }
}

export default useFetchDataByQueryParams
