const imageFileTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/octet-stream',
    'image/webp',
]

const wordFileTypes = [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
]

const pdfFileType = 'application/pdf'

export { imageFileTypes, wordFileTypes, pdfFileType }
