import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import diveRecordFlowChartImg from 'assets/images/Dive-record-flow-chart.png'
import historicalDiveRecordFlowChartImg from 'assets/images/Historical-dive-record-flow-chart.png'

const DiveRecordStatuses = ({ diveRecordStatusesObject }) => {
    const t = useTranslate()

    return (
        <div className="column m-helpAndInfo">
            <span className="a-bodyTextMedium">
                {diveRecordStatusesObject.title}
            </span>
            <p className="a-mediumText a-lightText -mt10">
                {diveRecordStatusesObject.description}
            </p>
            <div className="column -mt20 -gap20">
                {diveRecordStatusesObject.statuses?.map((status, index) => (
                    <div key={index}>
                        <span className="a-mediumTextSemiBold">
                            {status.title}
                        </span>
                        <p className="a-mediumText a-lightText -mt5">
                            {status.description}
                        </p>
                        <div className="aligned-center -gap10 a-mediumText a-lightText -mt10">
                            <span>{t('general.statusBadge')}: </span>
                            <span
                                className={`a-status a-captionsTextRegular -${status.color}`}
                            >
                                {status.title}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
            <div className="aligned-start flex-wrap -mt20 -diveRecordStatusesFlow">
                <div className="column">
                    <span className="a-mediumTextSemiBold">
                        {t('general.historicalDiveRecord')}
                    </span>
                    <img
                        src={historicalDiveRecordFlowChartImg}
                        alt="historicalDiveRecordFlow"
                    />
                </div>
                <div className="column">
                    <span className="a-mediumTextSemiBold">
                        {t('general.diveRecord')}
                    </span>
                    <img src={diveRecordFlowChartImg} alt="diveRecordFlow" />
                </div>
            </div>
        </div>
    )
}

DiveRecordStatuses.propTypes = {
    diveRecordStatusesObject: PropTypes.object,
}

export default DiveRecordStatuses
