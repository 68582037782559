import { useTranslate } from 'react-polyglot'
import { useLocation } from 'react-router'
import { useContext, useEffect, useState } from 'react'

import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import { useRemoveFromList } from 'hooks/useRemoveUser'

import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'
import { getProfileUserStatusColor } from 'utils/profileUserStatusColors'
import { userFilters } from 'utils/userFiltersHelper'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import SearchAndFilter from 'components/SearchAndFilter'
import { DiverFilters } from 'components/diverList'
import CardHeader from 'components/card/CardHeader'
import NoData from 'components/NoData'

const FavoriteUsers = () => {
    const t = useTranslate()
    const removeUser = useRemoveFromList()

    const [favoriteList, setFavorites] = useState([])

    const { search } = useLocation()

    const { isVerified } = useContext(CurrentUserContext)

    const {
        data: favorites,
        meta,
        isLoading: isLoadingFavorites,
        fetchData: fetchFavorites,
        loadMore,
    } = useFetchDataByQueryParams(
        ENTITIES.FAVORITE_USER_ELASTIC,
        {},
        true,
        isVerified
    )

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        userFilters,
        FILTERS.DIVERS
    )

    useEffect(() => {
        setFavorites([
            ...favoriteList,
            ...favorites.filter(
                (item) => !favoriteList.some((el) => el.id === item.id)
            ),
        ])
    }, [favorites])

    useEffect(() => {
        setFavorites([])
    }, [search])

    const handleLoadMore = () => {
        if (!isLoadingFavorites && loadMore) {
            fetchFavorites({
                page: meta.currentPage + 1,
            })
        }
    }

    const formatWorkerLanguages = (workerLanguages) => {
        if (!workerLanguages?.length) return 'N\\A'

        return workerLanguages?.map(
            ({ languageName, languageProficiencyName }) =>
                `${languageName} (${languageProficiencyName.toLowerCase()})`
        )
    }

    const formatExperiences = (experiences) => {
        if (!experiences || !experiences.length) {
            return 'N\\A'
        }

        return experiences.map(
            ({ dives, days, bellRuns, diveModeName }) =>
                `${diveModeName} ${
                    dives !== undefined && dives
                        ? dives + ' ' + t('general.dives')
                        : ''
                } ${
                    days !== undefined && days
                        ? days + ' ' + t('general.days')
                        : ''
                } ${
                    bellRuns !== undefined && bellRuns
                        ? bellRuns + ' ' + t('general.bellRuns')
                        : ''
                }`
        )
    }

    return (
        <div>
            <SearchAndFilter
                searchPlaceholder="general.searchDiver"
                activeFilters={activeFilters}
                filters={userFilters}
            >
                <DiverFilters activeFilters={activeFilters} />
            </SearchAndFilter>

            {favorites.map((item, index) => {
                return (
                    <div key={index} className="-mb10">
                        <CardContainer
                            link={`${ROUTES.DIVER_PROFILE}/${item?.profileHash}`}
                        >
                            <CardHeader
                                avatar={item.avatarPath}
                                title={item.fullName}
                                item={item}
                                statuses={[
                                    {
                                        type: 'status',
                                        name: item.status,
                                        color: getProfileUserStatusColor(
                                            item?.statusCode
                                        ),
                                    },
                                ]}
                                verifiedBadge={!!item.identityVerified}
                            />
                            <Info
                                label="form.label.country"
                                value={item.countryName}
                            />
                            <Info
                                label="form.label.languages"
                                value={formatWorkerLanguages(item.language)}
                                color="pink"
                            />
                            <Info
                                label="form.label.experiencedInModes"
                                value={formatExperiences(item.experiences)}
                                color="orange"
                                spaceBetween={true}
                            />
                            <InfoRow
                                withButtons
                                alignRight={!item?.totalExperienceYears}
                            >
                                {item?.totalExperienceYears ? (
                                    <Info
                                        label="form.label.totalYearsOfExperience"
                                        value={item?.totalExperienceYears?.toString()}
                                    />
                                ) : (
                                    <div></div>
                                )}
                                <Button
                                    type={BUTTON_TYPE.BUTTON}
                                    label="general.removeFromFavorites"
                                    btnClass={BUTTON_STATUS.TERTIARY}
                                    icon={ICONS.FAVORITES}
                                    iconColor={COLORS.LIGHT_BLUE}
                                    onClick={() =>
                                        removeUser(
                                            item,
                                            favorites,
                                            setFavorites,
                                            fetchFavorites
                                        )
                                    }
                                    buttonSize={BUTTON_SIZE.SMALL}
                                />
                            </InfoRow>
                        </CardContainer>
                    </div>
                )
            })}
            {favorites.length === 0 &&
                search.includes('name') &&
                !isLoadingFavorites && (
                    <NoData
                        icon={ICONS.SEARCH_ICON}
                        title={t('general.noResults')}
                        description={t('general.noSearchResultsDesc')}
                        isSmallWindow
                    />
                )}
            {favorites.length === 0 &&
                !search.includes('name') &&
                !isLoadingFavorites && (
                    <NoData
                        icon={ICONS.USERS}
                        title={t('general.noFavoriteUsersYet')}
                        description={t('general.noFavoriteUsersDesc')}
                    />
                )}
            {loadMore && (
                <div className="justify-center">
                    <Button
                        label="general.loadMore"
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.PLUS}
                        iconColor={COLORS.LIGHT_BLUE}
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={handleLoadMore}
                    />
                </div>
            )}
            {renderLoader(isLoadingFavorites, null, isLoadingFilters)}
        </div>
    )
}

export default FavoriteUsers
