import PropTypes from 'prop-types'

import useWindowDimensions from 'hooks/useWindowDimension'

import { TOOLTIP_POSITION } from 'constants/enums'

const Tooltip = ({ children, tooltip, position }) => {
    const { isMobile } = useWindowDimensions()

    const renderClassName = () => {
        return isMobile ? '-bottom' : `-${position || ''}`
    }

    return (
        <span
            className={`a-tooltipInfo ${renderClassName()} fullWidth fullHeight`}
            data-text={tooltip}
        >
            {children}
        </span>
    )
}

Tooltip.propTypes = {
    position: PropTypes.oneOf([
        TOOLTIP_POSITION.RIGHT,
        TOOLTIP_POSITION.LEFT,
        TOOLTIP_POSITION.TOP,
        TOOLTIP_POSITION.BOTTOM,
    ]),
}

Tooltip.defaultProps = {
    position: TOOLTIP_POSITION.RIGHT,
}

export default Tooltip
