import { useFormikContext } from 'formik'
import { useEffect, useRef } from 'react'

const useFormikFieldChange = (dependencies, changeHandler) => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const firstRender = useRef(true)
    const deps = dependencies.map((item) => values[item])
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }

        if (typeof changeHandler !== 'function') {
            throw new Error('Change handler not provided')
        }

        changeHandler(values, setFieldValue, setFieldTouched)
    }, deps)

    return {
        values,
        setFieldValue,
        setFieldTouched,
    }
}

export default useFormikFieldChange
