import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'

import useWindowDimensions from 'hooks/useWindowDimension'

import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { ICON_SIZE } from 'constants/enums'

import IconButton from 'components/IconButton'
import { getConvertToOrdinals } from 'utils/ordinalNumber'
import VerificationLevelShowcase from './VerificationLevelShowcase'

const VerificationLevelIcon = ({
    levelOfVerification,
    sidebarList,
    badgesRef,
}) => {
    const navigate = useNavigate()
    const { isTablet } = useWindowDimensions()

    const icons = [
        ICONS.LEVEL_0,
        ICONS.LEVEL_1,
        ICONS.LEVEL_2,
        ICONS.LEVEL_3,
        ICONS.LEVEL_4,
        ICONS.LEVEL_5,
    ]

    return (
        <VerificationLevelShowcase
            badgesRef={badgesRef}
            levelOfVerification={levelOfVerification}
        >
            <IconButton
                icon={icons[levelOfVerification]}
                onClick={() =>
                    isTablet
                        ? navigate(
                              `/${ROUTES.HELP_AND_INFO}/${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`
                          )
                        : undefined
                }
                tooltip={
                    isTablet
                        ? `${getConvertToOrdinals(
                              levelOfVerification
                          )} level of verification ${
                              !sidebarList ? '- Learn more' : ''
                          }`
                        : ''
                }
                translate={false}
                iconSize={ICON_SIZE.SIZE32}
            />
        </VerificationLevelShowcase>
    )
}

VerificationLevelIcon.propTypes = {
    levelOfVerification: PropTypes.number,
    sidebarList: PropTypes.bool,
    badgesRef: PropTypes.object,
}

export default VerificationLevelIcon
