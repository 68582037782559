import React from 'react'
import DiveRecordStatuses from './DiveRecordStatuses'

const diveRecordStatusesObject = {
    title: 'Dive record statuses',
    description:
        'Each dive record progresses through specific statuses, reflecting its journey from initial creation to final verification. Below is a detailed explanation of each status to help you understand what they represent and the actions associated with them.',
    statuses: [
        {
            title: 'Draft',
            description:
                'The initial stage when a diving professional creates a dive record. At this point, the record is still in preparation and may require additional details or edits before it’s ready for submission.',
            color: 'gray',
        },
        {
            title: 'Submitted',
            description:
                'The dive record is officially submitted by the diving professional to a supervisor or company for verification. It is now under review, awaiting approval or feedback.',
            color: 'blue',
        },
        {
            title: 'Re-submitted',
            description:
                'If a dive record was previously rejected, the diving professional can make the required corrections and submit it again for verification. The review process begins anew upon re-submission.',
            color: 'purple',
        },
        {
            title: 'Rejected',
            description:
                'The submitted dive record did not meet the required standards or criteria and has been returned to the diving professional for revisions. The feedback provided should guide the necessary adjustments.',
            color: 'red',
        },
        {
            title: 'Completed',
            description:
                'This status signifies that the dive record is finalized by diver but only for historical dive records.',
            color: 'green',
        },
        {
            title: 'Verified',
            description:
                'The dive record has undergone verification by the supervisor or company. This status confirms that the record meets all necessary criteria and any required level of verification has been applied.',

            color: 'green',
        },
    ],
}

const DiveRecordStatusesPage = () => {
    return (
        <DiveRecordStatuses
            diveRecordStatusesObject={diveRecordStatusesObject}
        />
    )
}

export default DiveRecordStatusesPage
