import React, { useState } from 'react'
import { useParams } from 'react-router'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import ENTITIES from 'constants/entities'

import useFetchData from 'hooks/useFetchData'

import AddNew from '../../../DiverOnboarding/AddNew'
import ChamberEventFormModal from '../chamberEvent/ChamberEventFormModal'
import ChamberEventList from '../chamberEvent/ChamberEventList'

const ChamberDecompressionEventWrapper = ({
    diveRecordDiveDataId,
    hideActions,
    fetchDiveRecord,
    diveRecordDiveData,
}) => {
    const t = useTranslate()
    const { diveRecordId } = useParams()

    const [create, setCreate] = useState(false)
    const [editItem, setEditItem] = useState(null)

    const {
        data: chamberDecompressionEventData,
        isLoading,
        fetchData: fetchDiveData,
    } = useFetchData(
        ENTITIES.CHAMBER_DECOMPRESSION_EVENT,
        {
            'diveRecordDiveData.id': diveRecordDiveDataId,
        },
        !!diveRecordId,
        false
    )

    const data = diveRecordId
        ? chamberDecompressionEventData
        : diveRecordDiveData.chamberDecompressionEvents

    const fetchData = () => {
        fetchDiveData()
        fetchDiveRecord()
    }

    if (!data) {
        return null
    }

    const labels = {
        timeStartLabel: 'form.label.timeStartDecompressionHours',
        timeEndLabel: 'form.label.timeStopDecompressionHours',
        holdDeptsTimeLabel: 'decompressionHold',
        areYouSureLabel: 'saturationChamberDecompressionEvent.deleteAreYouSure',
        deleteLabel: 'saturationChamberDecompressionEvent.delete',
        cardTitle: 'saturationChamberDecompressionEvent.title',
        viewFullLabel: 'saturationChamberDecompressionEvent.view',
    }

    const modalData = {
        entity: ENTITIES.CHAMBER_DECOMPRESSION_EVENT,
        fetchData,
        ...labels,
    }

    if (hideActions)
        return (
            <ChamberEventList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                setEditItem={setEditItem}
                entity={ENTITIES.CHAMBER_DECOMPRESSION_EVENT}
                labels={labels}
                hideActions={hideActions}
            />
        )

    return (
        <div className="-contentElements">
            <h2 className="-mt35 _12 a-centeredText">
                {t('saturationChamberDecompressionEvent.title')}
            </h2>

            <p className="a-bodyTextRegular -mt20 _12 a-centeredText">
                {t('saturationChamberDecompressionEvent.pleaseAdd')}
            </p>

            <div className="_12">
                <AddNew
                    label={'saturationChamberDecompressionEvent.add'}
                    handleAction={() => setCreate(true)}
                />
            </div>

            <ChamberEventList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                setEditItem={setEditItem}
                entity={ENTITIES.CHAMBER_DECOMPRESSION_EVENT}
                labels={labels}
            />

            {create && (
                <ChamberEventFormModal
                    closeModal={() => setCreate(false)}
                    modalData={modalData}
                />
            )}

            {!!editItem && (
                <ChamberEventFormModal
                    closeModal={() => setEditItem(null)}
                    initialData={editItem}
                    modalData={modalData}
                />
            )}
        </div>
    )
}

ChamberDecompressionEventWrapper.propTypes = {
    diveRecordDiveDataId: PropTypes.number.isRequired,
    hideActions: PropTypes.bool,
    fetchDiveRecord: PropTypes.func,
}

export default ChamberDecompressionEventWrapper
