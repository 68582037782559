import React, { useContext, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import ENTITIES from 'constants/entities'
import useFetchData from 'hooks/useFetchData'
import { Table } from 'components/table'
import Loader from 'components/Loader'
import Button from 'components/Button'
import { deleteEntityService } from 'services/entity.service'
import {
    ALERT_TYPES,
    BILLING_LISTING_STATUSES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    PAYPAL_PAYMENT_STATUS,
    USER_SUBSCRIPTION_TYPE,
} from 'constants/enums'
import { AlertContext } from 'contexts/AlertContext'
import StatusCell from 'components/table/tableCells/StatusCell'
import {
    capitalizeTableFormatter,
    dateTableFormatter,
    moneyFormatter,
    uppercaseTableFormatter,
} from 'components/table/formatters/tableFormatters'
import moment from 'moment/moment'
import { MOMENT_FORMATS } from 'services/moment.service'
import Separator from 'components/Separator'
import ICONS from 'constants/icons'
import { useNavigate } from 'react-router'
import ROUTES from 'constants/routes'
import { LeftIcon, RightIcon } from 'components/table/Pagination'
import PageSelector from 'components/table/PageSelector'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import useQueryParams from 'hooks/useQueryParams'
import useWindowDimensions from 'hooks/useWindowDimension'
import BillingPlanStatus from 'components/status/BillingPlanStatus'
import Note from 'components/Note'

const CancelSubscriptionModalContent = ({ currentSubscriptionData }) => {
    const t = useTranslate()
    if (!currentSubscriptionData) return null
    return (
        <div
            className={
                'column aligned-center justify-center fullWidth a-lightText'
            }
        >
            <p className="centered-text">
                {t('billing.aboutToCancelPlan', {
                    plan: currentSubscriptionData.planName,
                })}
            </p>
            <ul
                className={
                    'billing-modal-list column aligned-center justify-center -mb20'
                }
            >
                <li className={'a-mediumText'}>
                    {t('billing.billingFrequency')}:{' '}
                    <b className={'a-capitalize'}>
                        {currentSubscriptionData.billingFrequency}
                    </b>
                </li>
                <li className={'a-mediumText'}>
                    {t('billing.nextRenewalDate')}:{' '}
                    <b>
                        {moment(
                            currentSubscriptionData.subscriptionEnd.date
                        ).format(MOMENT_FORMATS.DATE)}
                    </b>
                </li>
            </ul>
            <p>{t('billing.cancelingYourSubscriptionMeans')}</p>
            <ul
                className={
                    'billing-modal-list column aligned-center justify-center'
                }
            >
                <li className={'centered-text a-mediumText'}>
                    {t('billing.revertToFree')}
                </li>
                <li className={'centered-text a-mediumText'}>
                    {t('billing.looseAccessToFeatures', {
                        plan: currentSubscriptionData.planName,
                    })}
                </li>
            </ul>
        </div>
    )
}

const BillingListing = () => {
    const PAGINATION_OPTIONS = [10, 20, 30]

    const { isSmallScreen } = useWindowDimensions()

    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)
    const { currentUser, fetchCurrentUser } = useContext(CurrentUserContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const {
        filterParams: { redirect_status },
    } = useQueryParams()

    const {
        userSubscription: { userSubscriptionType },
    } = currentUser

    const [after, setAfter] = useState(null)
    const [totalCount, setTotalCount] = useState()
    const [offset, setOffset] = useState(PAGINATION_OPTIONS[0])
    const [currentPage, setCurrentPage] = useState(1)
    const [paginationDisabled, setPaginationDisabled] = useState({
        prev: true,
        next: true,
    })
    const navigate = useNavigate()

    let { data, fetchData } = useFetchData(
        ENTITIES.LIST_INVOICES,
        {
            after: after,
            invoiceId: null,
            offset: offset,
        },
        true,
        false,
        false,
        [],
        false
    )

    const {
        data: currentSubscriptionData,
        fetchData: fetchCurrentSubscription,
    } = useFetchData(
        ENTITIES.CURRENT_SUBSCRIPTION,
        {},
        true,
        false,
        false,
        [],
        true
    )

    useEffect(() => {
        if (after !== null) {
            after
                ? setPaginationDisabled({
                      prev: false,
                      next: !data.metadata.hasMore,
                  })
                : setPaginationDisabled({
                      prev: !data.metadata.hasMore,
                      next: false,
                  })
        } else if (data?.metadata) {
            setPaginationDisabled({ prev: true, next: !data.metadata.hasMore })
        }

        if (data?.metadata) {
            setTotalCount(data.metadata.totalCount)
        }
    }, [data, after])

    const goToPackages = () => {
        navigate(ROUTES.PACKAGES)
    }

    const resetParams = () => {
        setAfter(null)
        setCurrentPage(1)
        setPaginationDisabled({ prev: true, next: false })
    }

    const handleOffsetChange = async (perPage) => {
        resetParams()
        setOffset(perPage)
        await fetchNewData(null, null, perPage, true)
    }

    const openCancelModal = () => {
        showConfirmationModal({
            title: 'billing.cancelSubscription',
            handleConfirm: cancelSubscription,
            handleCancel: closeConfirmationModal,
            customText: (
                <CancelSubscriptionModalContent
                    currentSubscriptionData={currentSubscriptionData}
                />
            ),
            confirmLabel: 'billing.confirmCancellation',
            cancelLabel: 'billing.keepSubscription',
        })
    }

    const cancelSubscription = async () => {
        try {
            closeConfirmationModal()
            await deleteEntityService(
                ENTITIES.CANCEL_SUBSCRIPTION,
                currentSubscriptionData.id,
                {}
            )
            setAlert(
                t('message.successfullyCanceledSubscription'),
                ALERT_TYPES.SUCCESS
            )
            await fetchData()
            await fetchCurrentSubscription()
            await fetchCurrentUser()
        } catch (error) {
            setAlert(t('message.errorCancelSubscription'), ALERT_TYPES.ERROR)
            closeConfirmationModal()
        }
    }

    const goToNextPage = async () => {
        if (currentPage === Math.ceil(data.metadata.totalCount / offset)) return
        const lastId = data.data[data.data.length - 1].invoiceId
        setAfter(true)
        await fetchNewData(true, lastId)
    }

    const goToPrevPage = async () => {
        if (currentPage === 1) return
        const firstId = data.data[0].invoiceId
        setAfter(false)
        await fetchNewData(false, firstId)
    }

    const fetchNewData = async (
        after,
        invoiceId,
        perPage = offset,
        resetParams
    ) => {
        await fetchData({
            after: after,
            invoiceId: invoiceId,
            offset: perPage,
        })

        if (!resetParams) {
            setCurrentPage((page) => (after === true ? ++page : --page))
        }
    }

    const TABLE_HEADER = [
        {
            key: 'plan',
            title: 'table.header.plan',
            showOnSmallScreens: true,
            smallScreenFirstItem: true,
            smallScreenHeader: true,
            formatter: uppercaseTableFormatter,
        },
        {
            key: 'amount',
            title: 'table.header.price',
            showOnSmallScreens: true,
            formatter: moneyFormatter,
        },
        {
            key: 'startDate',
            title: 'table.header.startDate',
            showOnSmallScreens: true,
            formatter: dateTableFormatter,
        },
        {
            key: 'endDate',
            title: 'table.header.endDate',
            showOnSmallScreens: true,
            formatter: dateTableFormatter,
        },
        {
            key: 'interval',
            title: 'table.header.billingPeriod',
            showOnSmallScreens: true,
            formatter: capitalizeTableFormatter,
        },
        {
            key: 'status',
            title: 'table.header.status',
            showOnSmallScreens: true,
            CellComponent: (props) => (
                <StatusCell {...props} statuses={BILLING_LISTING_STATUSES} />
            ),
        },
    ]

    /** Handle PayPal redirect param */
    useEffect(() => {
        if (!redirect_status) {
            return
        }

        if (redirect_status === PAYPAL_PAYMENT_STATUS.SUCCESS) {
            setAlert(t('billing.successfullyPayed'), ALERT_TYPES.SUCCESS)
        } else {
            setAlert(t('billing.errorWithPayment'), ALERT_TYPES.ERROR)
        }
    }, [])

    const handlePreview = (item) => {
        window.open(`${item.invoicePreviewUrl}`, '_blank')
    }

    const handleDownload = (item) => {
        window.open(`${item.invoiceDownloadUrl}`, '_blank')
    }

    if (
        currentSubscriptionData !== null &&
        currentSubscriptionData.length === 0
    ) {
        return <Loader />
    }

    return (
        <div
            className={`billing-listing column ${isSmallScreen ? '-gap10' : '-gap20'}`}
        >
            <span className="a-bodyTextBold">{t('general.billing')}</span>
            <div className="_w">
                <div
                    className={`_12 row ${isSmallScreen ? '-gap10' : '-gap20'} flex-wrap`}
                >
                    <div className="plan-description-wrapper">
                        <span className="a-mediumTextSemiBold -mr10">
                            {t('billing.activePlan')}:
                        </span>
                        <BillingPlanStatus
                            userSubscriptionType={userSubscriptionType}
                        />
                    </div>
                    {currentSubscriptionData.id && (
                        <>
                            <div className="plan-description-wrapper">
                                <span className="a-mediumTextSemiBold -mr10">
                                    {t('billing.billingFrequency')}:
                                </span>
                                <p className="a-mediumText a-lightText -opacity-60 -about a-capitalize">
                                    {currentSubscriptionData.billingFrequency}
                                </p>
                            </div>
                            <div className="plan-description-wrapper">
                                <span className="a-mediumTextSemiBold -mr10">
                                    {t('billing.activeUntil')}:
                                </span>
                                <p className="a-mediumText a-lightText -opacity-60 -about">
                                    {moment(
                                        currentSubscriptionData.subscriptionEnd
                                            .date
                                    ).format(MOMENT_FORMATS.DATE)}
                                </p>
                            </div>
                            {currentSubscriptionData.subscriptionCanceledAt && (
                                <div className="plan-description-wrapper">
                                    <span className="a-mediumTextSemiBold -mr10">
                                        {t('billing.canceledAt')}:
                                    </span>
                                    <p className="a-mediumText a-lightText -opacity-60 -about">
                                        {moment(
                                            currentSubscriptionData
                                                .subscriptionCanceledAt.date
                                        ).format(MOMENT_FORMATS.DATE)}
                                    </p>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

            {(!userSubscriptionType ||
                userSubscriptionType === USER_SUBSCRIPTION_TYPE.FREE) &&
                !currentUser.companyId && (
                    <div className="_w">
                        <div className="_12 row -gap20">
                            <Note
                                customLabel={t('general.unlockProLabel')}
                                note={t('general.unlockProText')}
                                type={ALERT_TYPES.NOTIFICATION}
                                bottomMargin={false}
                            />
                        </div>
                    </div>
                )}

            <div className="_w">
                <div className="_12 row -gap20">
                    {!currentUser.companyId && (
                        <Button
                            label={'general.changeSubscription'}
                            btnClass={BUTTON_STATUS.PRIMARY}
                            buttonSize={BUTTON_SIZE.SMALL}
                            onClick={() => goToPackages()}
                        />
                    )}
                    {currentSubscriptionData.id &&
                        !currentSubscriptionData.subscriptionCanceledAt && (
                            <Button
                                label={'general.cancelSubscription'}
                                btnClass={BUTTON_STATUS.DANGER_OUTLINE}
                                buttonSize={BUTTON_SIZE.SMALL}
                                onClick={openCancelModal}
                            />
                        )}
                </div>
            </div>

            {currentSubscriptionData.updatedByAdmin && (
                <div className="_w">
                    <div className="_12 row -gap20">
                        {t('billing.updatedByAdmin')}
                    </div>
                    <div className="_12 row -gap20">
                        <Separator fullWidth={true} marginTop={20} />
                    </div>
                </div>
            )}
            {!currentSubscriptionData.updatedByAdmin && (
                <>
                    <div className="_w">
                        <div className="_12 row -gap20">
                            <Note note={'billing.invoicesForPayPalAndMobile'} />
                        </div>
                    </div>

                    <div className="column fullWidth">
                        {data.data && (
                            <Table
                                title={'billing.billingPaymentHistory'}
                                headerItems={TABLE_HEADER}
                                data={data.data}
                                totalItems={data.data.length}
                                fullWidthTitle
                                paginate={false}
                                rowDropdownActions={[
                                    {
                                        handleAction: handlePreview,
                                        icon: ICONS.PREVIEW,
                                        label: 'button.view',
                                        btnClass: BUTTON_STATUS.TERTIARY,
                                    },
                                    {
                                        handleAction: handleDownload,
                                        icon: ICONS.DOWNLOAD_SQUARE,
                                        label: 'button.download',
                                        btnClass: BUTTON_STATUS.TERTIARY,
                                    },
                                ]}
                            />
                        )}
                        <div className={`m-pagination`}>
                            {data && data.metadata && (
                                <>
                                    <PageSelector
                                        itemsPerPage={offset}
                                        setItemsPerPage={handleOffsetChange}
                                        totalItems={totalCount}
                                        options={PAGINATION_OPTIONS}
                                    />
                                </>
                            )}
                            {data?.metadata && (
                                <div className="pagination">
                                    <LeftIcon
                                        handleClick={goToPrevPage}
                                        icon={ICONS.CHEVRON_LEFT}
                                        disabled={paginationDisabled.prev}
                                    />
                                    {data?.metadata?.totalCount && (
                                        <p
                                            className={
                                                'a-mediumText customPaginationPager'
                                            }
                                        >
                                            {currentPage}{' '}
                                            {`${t('table.pagination.of')} `}{' '}
                                            {Math.ceil(
                                                data?.metadata?.totalCount /
                                                    offset
                                            )}
                                        </p>
                                    )}
                                    <RightIcon
                                        handleClick={goToNextPage}
                                        icon={ICONS.CHEVRON_LEFT}
                                        disabled={paginationDisabled.next}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    {!data && <Loader />}
                </>
            )}
        </div>
    )
}

export default BillingListing
