import React, { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'

import { createEntityService } from 'services/entity.service'

import { validateEmail } from 'utils/yupValidations'

import { ALERT_TYPES, ICON_SIZE, INPUT_FILED_TYPE } from 'constants/enums'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'

import Modal from 'components/Modal'
import FocusError from 'components/FocusError'
import { InputField } from 'components/formFields'
import Loader from 'components/Loader'
import VerifyEmail from './VerifyEmail'

const ChangeEmailModal = ({ open, setOpen }) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const [newEmailAddress, setNewEmailAddress] = useState('')

    const initialValues = {
        newEmail: '',
        confirmEmail: '',
        password: '',
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        newEmail: validateEmail(t).required(requiredMessage),
        confirmEmail: Yup.string()
            .trim()
            .required(requiredMessage)
            .oneOf(
                [Yup.ref('newEmail'), null],
                t('form.error.emailsMustMatch')
            ),
        password: Yup.string().trim().required(requiredMessage),
    })

    const handleSubmit = async (
        { confirmEmail, ...data },
        { setSubmitting }
    ) => {
        try {
            setSubmitting(true)
            await createEntityService(
                `${ENTITIES.USER}/${ENTITIES.CHANGE_EMAIL}`,
                data,
                false
            )
            setNewEmailAddress(data.newEmail)
        } catch (error) {
            setAlert(
                error,
                ALERT_TYPES.ERROR,
                error.response?.data?.message ? t : null
            )
        } finally {
            setSubmitting(false)
        }
    }

    const handleClose = () => {
        setNewEmailAddress('')
        setOpen(false)
    }

    if (newEmailAddress)
        return (
            <VerifyEmail newEmail={newEmailAddress} handleClose={handleClose} />
        )

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="button.changeEmailAddress"
                        isSubmitting={isSubmitting}
                        smallModal
                        buttons={{
                            prevBtn: {
                                handleClick: () => setOpen(false),
                            },
                            nextBtn: {
                                label: 'button.save',
                            },
                        }}
                    >
                        <div className="_wr -modalElements">
                            <div className="_w">
                                <p className="_12 -mb20 a-centeredText a-bodyTextRegular">
                                    {t('general.changeEmailAddressDescription')}
                                </p>
                                <div className="_12 -mb5">
                                    <InputField
                                        name="newEmail"
                                        label="form.label.yourNewEmailAddress"
                                        placeholder="form.placeholder.yourNewEmailAddress"
                                        icon={ICONS.MAIL}
                                        iconColor={COLORS.DARK_BLUE_60}
                                        size={ICON_SIZE.SIZE20}
                                        autoCompleteOff
                                        required
                                    />
                                </div>
                                <div className="_12 -mb5">
                                    <InputField
                                        name="confirmEmail"
                                        label="form.label.retypeYourNewEmailAddress"
                                        placeholder="form.placeholder.retypeYourNewEmailAddress"
                                        icon={ICONS.MAIL}
                                        iconColor={COLORS.DARK_BLUE_60}
                                        size={ICON_SIZE.SIZE20}
                                        autoCompleteOff
                                        required
                                    />
                                </div>
                                <div className="_12 -mb5">
                                    <InputField
                                        name="password"
                                        label="form.label.yourPassword"
                                        type={INPUT_FILED_TYPE.PASSWORD}
                                        autoCompleteOff
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

ChangeEmailModal.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default ChangeEmailModal
