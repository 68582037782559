import React, { useContext, useState } from 'react'
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
} from '@stripe/react-stripe-js'
import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { BASE_URL } from 'utils/axiosClient'
import ENTITIES from 'constants/entities'
import { getUserToken } from 'services/localStorage.service'
import Button from 'components/Button'
import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_SIZE,
    TOOLTIP_POSITION,
} from 'constants/enums'
import { AlertContext } from 'contexts/AlertContext'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router'
import ROUTES from 'constants/routes'
import Icon from 'components/Icon'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import Tooltip from 'components/Tooltip'
import useWindowDimensions from 'hooks/useWindowDimension'

const CheckoutForm = ({ selectedInterval, packagesList }) => {
    const stripe = useStripe()
    const navigate = useNavigate()
    const elements = useElements()
    const t = useTranslate()
    const { isSmallScreen, isTablet } = useWindowDimensions()
    const { setAlert } = useContext(AlertContext)
    const { currentUser, fetchCurrentUser } = useContext(CurrentUserContext)
    const [isLoading, setIsLoading] = useState(false)

    const tooltipPositionSmallerScreens = isTablet
        ? TOOLTIP_POSITION.BOTTOM
        : TOOLTIP_POSITION.LEFT

    const token = getUserToken()

    const elementStyles = {}

    const handleSubscription = async (paymentMethodId) => {
        try {
            const response = await fetch(
                `${BASE_URL}/api/${ENTITIES.CREATE_SUBSCRIPTION}?XDEBUG_SESSION_START=PHPSTORM`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        email: currentUser.email,
                        paymentMethodId: paymentMethodId,
                        priceId: selectedInterval.id, // Use the Price ID for the subscription
                    }),
                }
            )

            const rsp = await response.json()
            if (rsp.message) {
                setAlert(t(rsp.message), ALERT_TYPES.ERROR)
            } else {
                setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
                navigate(`${ROUTES.SETTINGS}/${ROUTES.BILLING}`, {})
            }
            fetchCurrentUser()
        } catch (error) {}
    }

    const handleSubmit = async (event) => {
        if (!stripe || !elements || !selectedInterval.id) {
            return
        }
        event.preventDefault()
        setIsLoading(true)
        // Create PaymentMethod

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            // card: elements.getElement(CardElement),
            card: elements.getElement(CardNumberElement),
            type: 'card',
            billing_details: { email: currentUser.email },
        })

        if (error) {
            setAlert(error.message, ALERT_TYPES.ERROR)
            setIsLoading(false)
            return
        }

        // Call backend to create a subscription
        await handleSubscription(paymentMethod.id)
        setIsLoading(false)
    }

    return (
        <div>
            {packagesList && !!packagesList.length && (
                <form onSubmit={handleSubmit}>
                    <div className="column aligned-inherit -mt20">
                        <div className="_wr">
                            <div className="_w">
                                <div className="_12 -mb10">
                                    <span className="a-mediumTextSemiBold -mr10">
                                        {t('billing.cardInfo')}:
                                    </span>
                                </div>
                                <div className="_12 _m6 -mb10">
                                    <label
                                        className={
                                            'a-mediumText a-lightText flex'
                                        }
                                    >
                                        {t('form.label.cardNumber')}
                                    </label>
                                    <div className="card-element-wrapper">
                                        <CardNumberElement
                                            options={{
                                                style: elementStyles,
                                                placeholder: t(
                                                    'form.placeholder.cardNumber'
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="_12 _m3 -mb10">
                                    <label
                                        className={'a-mediumText a-lightText'}
                                    >
                                        {t('form.label.cardExpire')}
                                    </label>
                                    <div className="card-element-wrapper">
                                        <CardExpiryElement
                                            options={{
                                                style: elementStyles,
                                                placeholder: t(
                                                    'form.placeholder.cardExpire'
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="_12 _m3">
                                    <label
                                        className={
                                            'a-mediumText a-lightText row'
                                        }
                                    >
                                        {t('form.label.cvc')}
                                        <span className="help-icon">
                                            <Tooltip
                                                tooltip={t(
                                                    'billing.cvcTooltip'
                                                )}
                                                position={
                                                    isSmallScreen
                                                        ? tooltipPositionSmallerScreens
                                                        : TOOLTIP_POSITION.BOTTOM
                                                }
                                            >
                                                <Icon
                                                    name={ICONS.HELP_AND_INFO}
                                                    color={COLORS.LIGHT_BLUE}
                                                    size={ICON_SIZE.SIZE20}
                                                />
                                            </Tooltip>
                                        </span>
                                    </label>

                                    <div className="card-element-wrapper">
                                        <CardCvcElement
                                            options={{
                                                style: elementStyles,
                                                placeholder: t(
                                                    'form.placeholder.cvc'
                                                ),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="_w">
                                <div className="_6 _m3 ofs_m3 -mb10">
                                    <Button
                                        type={BUTTON_TYPE.BUTTON}
                                        label="button.back"
                                        additionalClasses={
                                            '-mt20 payment-buttons'
                                        }
                                        btnClass={BUTTON_STATUS.SECONDARY}
                                        onClick={() =>
                                            navigate(ROUTES.PACKAGES)
                                        }
                                    ></Button>
                                </div>
                                <div className="_6 _m3 -mb10">
                                    <Button
                                        type={BUTTON_TYPE.SUBMIT}
                                        label={
                                            isLoading
                                                ? 'form.label.processing'
                                                : 'form.label.pay'
                                        }
                                        additionalClasses={
                                            '-mt20 payment-buttons'
                                        }
                                        disabled={!stripe || isLoading}
                                    ></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </div>
    )
}

export default CheckoutForm
