import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { useContext } from 'react'

import { checkExpiryDate, formatDate } from 'services/moment.service'

import { AlertContext } from 'contexts/AlertContext'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { ALERT_TYPES } from 'constants/enums'

import CardHeader from 'components/card/CardHeader'
import Info from 'components/Info'
import InfoFiles from 'components/InfoFiles'

const VisaCard = ({
    visa,
    index,
    handleShowVisaForm,
    setVisas,
    visas,
    setEditVisa,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)

    const {
        id,
        visaNumber,
        dateOfExpiatory,
        country,
        visaType,
        visaName,
        files,
    } = visa

    const handleEdit = () => {
        handleShowVisaForm()
        setEditVisa(visa)
    }

    const handleDelete = async (index) => {
        try {
            const visasCopy = [...visas]
            visasCopy.splice(index, 1)
            setVisas(visasCopy)
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    if (!!visa.length) return null

    return (
        <div>
            <CardHeader
                title={`${t('general.visa')} ${index + 1}`}
                actions={[
                    {
                        handleAction: handleEdit,
                        icon: ICONS.EDIT,
                        iconColor: COLORS.DARK_BLUE,
                        label: 'button.editVisa',
                    },
                    {
                        handleAction: () => handleDelete(index),
                        icon: ICONS.DELETE,
                        iconColor: COLORS.RED,
                        label: 'button.deleteVisa',
                        title: 'general.deleteVisa',
                        confirmationMessage: 'message.areYouSureDeleteVisaInfo',
                        textColor: '-redText',
                    },
                ]}
            />
            <Info label="form.label.visaNumber" value={visaNumber} />
            <Info
                label="form.label.dateOfExpiatory"
                value={formatDate(dateOfExpiatory)}
                textColor={checkExpiryDate(dateOfExpiatory)}
            />
            <Info label="form.label.countryOfIssue" value={country.name} />
            <Info label="form.label.visaType" value={visaType} />
            <Info label="form.label.visaName" value={visaName} />
            <InfoFiles label="general.documentsCopy" files={files} />
        </div>
    )
}

VisaCard.propTypes = {
    visa: PropTypes.object,
    index: PropTypes.number,
    handleShowVisaForm: PropTypes.func,
    setVisas: PropTypes.func,
    visas: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    setEditVisa: PropTypes.func,
}

export default VisaCard
