import DateCell from 'components/table/tableCells/DateCell'
import StatusCell from 'components/table/tableCells/StatusCell'
import { TERMS_AND_CONDITIONS_STATUSES } from 'constants/enums'

export const TABLE_HEADER = [
    {
        key: 'firstName',
        title: 'table.header.firstName',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        smallScreenHeader: true,
        sortable: false,
    },
    {
        key: 'lastName',
        title: 'table.header.lastName',
        showOnSmallScreens: true,
        sortable: false,
    },
    {
        key: 'email',
        title: 'table.header.email',
        showOnSmallScreens: true,
        sortable: false,
    },
    {
        key: 'timestamp',
        title: 'table.header.date',
        showOnSmallScreens: true,
        sortable: false,
        CellComponent: DateCell,
    },
    {
        key: 'action',
        title: 'table.header.status',
        showOnSmallScreens: true,
        sortable: false,
        CellComponent: StatusCell,
        statuses: TERMS_AND_CONDITIONS_STATUSES,
    },
]
