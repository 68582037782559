import React, { Fragment } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import ENTITIES from 'constants/entities'

import { InputField, SelectField } from 'components/formFields'
import EntityForm from './EntityForm'

const DiverPositionForm = (props) => {
    const t = useTranslate()

    const initialValues = {
        name: props.initialData?.name ?? '',
        diverPositionCategory: props.initialData?.diverPositionCategory ?? null,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object().shape({
        name: Yup.string().required(requiredMessage),
        diverPositionCategory: Yup.object().required(requiredMessage),
    })

    return (
        <EntityForm
            {...props}
            customInitialValues={initialValues}
            customValidation={validation}
            CustomFormFields={() => (
                <Fragment>
                    <div className="_12 _l6">
                        <InputField
                            name="name"
                            label="form.label.entityName"
                            placeholder="form.placeholder.entityName"
                            required
                        />
                    </div>
                    <div className="_12 _l6">
                        <SelectField
                            name="diverPositionCategory"
                            label="form.label.diverPositionCategory"
                            placeholder="form.placeholder.diverPositionCategory"
                            entityType={ENTITIES.DIVER_POSITION_CATEGORY}
                            searchable
                            required
                        />
                    </div>
                </Fragment>
            )}
        />
    )
}

export default DiverPositionForm
