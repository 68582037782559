import {
    DIVER_POSITION_CATEGORIES,
    DIVING_PERSONNEL_POSITIONS,
} from 'constants/enums'
import ICONS from 'constants/icons'

const getDivingPersonnelImage = (position) => {
    switch (position) {
        case DIVING_PERSONNEL_POSITIONS.SURFACE_DIVER.code:
        case DIVING_PERSONNEL_POSITIONS.SURFACE_DIVER_MEDIC.code:
        case DIVING_PERSONNEL_POSITIONS.SURFACE_DIVER_CONSTRUCTION.code:
        case DIVING_PERSONNEL_POSITIONS.BELL_SAT_DIVER.code:
        case DIVING_PERSONNEL_POSITIONS.BELL_DIVER_MEDIC.code:
        case DIVING_PERSONNEL_POSITIONS.BELL_DIVER_INSPECTION.code:
        case DIVING_PERSONNEL_POSITIONS.BELL_DIVER_CONSTRUCTION.code:
            return ICONS.POSITION_DIVER
        case DIVING_PERSONNEL_POSITIONS.MEDIC.code:
        case DIVING_PERSONNEL_POSITIONS.DIVER_MEDIC_TECH_NON_DIVER.code:
            return ICONS.POSITION_DIVING_MEDIC
        case DIVING_PERSONNEL_POSITIONS.DIVE_SUPERVISOR.code:
            return ICONS.POSITION_DIVING_SUPERVISOR
        case DIVING_PERSONNEL_POSITIONS.DIVE_EQUIPMENT_TECHNICIAN.code:
        case DIVING_PERSONNEL_POSITIONS.DIVE_TECHNICIAN_ELECTRICIAN.code:
        case DIVING_PERSONNEL_POSITIONS.DIVE_TECHNICIAN_MECHANICAL.code:
        case DIVING_PERSONNEL_POSITIONS.DIVE_TECHNICIAN_SUPERVISOR.code:
            return ICONS.POSITION_EQUIPMENT_TECHNICIAN
        case DIVING_PERSONNEL_POSITIONS.LIFE_SUPPORT_SUPERVISOR.code:
        case DIVING_PERSONNEL_POSITIONS.LIFE_SUPPORT_TECHNICIAN.code:
        case DIVING_PERSONNEL_POSITIONS.ASSISTANT_LIFE_SUPPORT_TECHNICIAN.code:
            return ICONS.POSITION_LIFE_SUPPORT
        case DIVING_PERSONNEL_POSITIONS.DIVE_SUPERINTENDENT.code:
            return ICONS.POSITION_DIVE_SUPERINTENDENT
        case DIVING_PERSONNEL_POSITIONS.OFFSHORE_MANAGER_DIVING.code:
            return ICONS.POSITION_OFFSHORE_MANAGER_DIVING
        case DIVING_PERSONNEL_POSITIONS.DIVER_TENDER.code:
        case DIVING_PERSONNEL_POSITIONS.LEAD_TENDER.code:
            return ICONS.MARINE_MOBILE_OFFSHORE_UNIT_PERSONNEL
        default:
            break
    }
}

const getPositionImage = (positionCategory, position) => {
    const marineCategoryCustomImage =
        positionCategory ===
            DIVER_POSITION_CATEGORIES.MARINE_MOU_PERSONNEL.code &&
        position ===
            (DIVING_PERSONNEL_POSITIONS.MEDIC.code ||
                DIVING_PERSONNEL_POSITIONS.DIVER_TENDER.code ||
                DIVING_PERSONNEL_POSITIONS.LEAD_TENDER.code)
    if (
        positionCategory === DIVER_POSITION_CATEGORIES.DIVING_PERSONNEL.code ||
        marineCategoryCustomImage
    ) {
        return getDivingPersonnelImage(position)
    }

    switch (positionCategory) {
        case DIVER_POSITION_CATEGORIES.MARINE_MOU_PERSONNEL.code:
            return ICONS.MARINE_MOBILE_OFFSHORE_UNIT_PERSONNEL
        case DIVER_POSITION_CATEGORIES.ROV_PERSONNEL.code:
            return ICONS.ROV_PERSONNEL
        case DIVER_POSITION_CATEGORIES.SURVEY_PERSONNEL.code:
            return ICONS.OFFSHORE_SURVEY_PERSONNEL
        case DIVER_POSITION_CATEGORIES.PROJECT_MISSION_PERSONNEL.code:
            return ICONS.PROJECT_MISSION_PERSONNEL
        case DIVER_POSITION_CATEGORIES.PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL
            .code:
            return ICONS.PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL
        default:
            break
    }
}

export { getPositionImage }
