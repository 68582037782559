import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useTranslate } from 'react-polyglot'

import { getEntityByIdService } from 'services/entity.service'

import {
    checkExpiryDate,
    checkIfExpired,
    formatDate,
} from 'services/moment.service'

import ROUTES from 'constants/routes'
import ENTITIES from 'constants/entities'
import { CERTIFICATE_VERIFICATION_STATUSES } from 'constants/enums'

import CertificateStatus from './CertificateStatus'

const { NOT_VERIFIED } = CERTIFICATE_VERIFICATION_STATUSES

const CertificateQrDetails = () => {
    const t = useTranslate()
    const navigate = useNavigate()
    const { hash } = useParams()

    const [certificate, setCertificate] = useState()
    const [error, setError] = useState()

    useEffect(() => {
        const getCertificateDetails = async () => {
            const { data, error } = await getEntityByIdService(
                ENTITIES.VERIFY_TRAINING_CERTIFICATE,
                hash,
                {
                    include: 'certificateIssuer,user',
                },
                true,
                true
            )
            setCertificate(data)
            setError(error)
        }
        getCertificateDetails()
    }, [])

    if (!certificate && !error) return null

    const goToApp = () => {
        navigate(ROUTES.HOME)
    }

    const renderCertificateDetail = (label, value, color, image) => {
        if (!value) return null

        return (
            <Fragment>
                <span className="a-bodyTextRegular a-lightText a-centeredText -mb5">
                    {t(label).toUpperCase()}
                </span>
                {image && (
                    <div className="column aligned-center -mb5">
                        <img src={image} />
                    </div>
                )}
                <span
                    className={`a-bodyTextRegular a-boldText a-centeredText -mb20 ${
                        color ? `-${color}` : ''
                    }`}
                >
                    {value}
                </span>
            </Fragment>
        )
    }

    return (
        <div className="m-emailLink">
            <div
                className={`m-emailLink__background ${
                    !!certificate ? '-certQr' : ''
                }`}
            >
                <div className="m-emailLink__logo" onClick={goToApp}></div>
                <div
                    className={`m-emailLink__content -certQrWidth ${
                        !!certificate ? '-certQr' : ''
                    }`}
                >
                    <div className="column aligned-center">
                        <h1 className="a-centeredText a-boldText -mb40">
                            {t('general.certificateDetails')}
                        </h1>

                        <h2 className="a-centeredText -mb20">
                            {t('general.verificationStatus')}
                        </h2>

                        <CertificateStatus
                            verifiedStatus={
                                certificate?.verificationStatus || NOT_VERIFIED
                            }
                        />

                        {!certificate && (
                            <span className="a-bodyTextRegular a-lightText a-centeredText -mt20">
                                {t('general.certificateNotVerifiedOrNotExist')}
                            </span>
                        )}

                        {!!certificate &&
                            checkIfExpired(certificate?.expiresDate) && (
                                <span className="a-bodyTextRegular a-lightText -mb20">
                                    {t('general.certificateExpired')}
                                </span>
                            )}
                        {!!certificate && (
                            <div className="column aligned-center -mt20">
                                {renderCertificateDetail(
                                    'general.certificateHoldersName',
                                    certificate?.certificateHoldersName,
                                    null,
                                    certificate.profileImage
                                )}
                                {renderCertificateDetail(
                                    'form.label.certificateIssuerName',
                                    certificate?.certificateIssuerName
                                )}
                                {renderCertificateDetail(
                                    'form.label.certificateName',
                                    certificate?.certificateName
                                )}
                                {renderCertificateDetail(
                                    'form.label.certificateNumber',
                                    certificate?.certificateNumber
                                )}
                                {renderCertificateDetail(
                                    'form.label.expiresDate',
                                    formatDate(certificate?.expiresDate),
                                    checkExpiryDate(certificate?.expiresDate)
                                )}
                                {certificate?.verificationDate &&
                                    renderCertificateDetail(
                                        'general.verificationDate',
                                        formatDate(
                                            certificate?.verificationDate
                                        )
                                    )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CertificateQrDetails
