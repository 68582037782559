import React, { Fragment, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    DIVE_RECORD_STATUS,
    DIVE_RECORD_TYPE,
    DIVE_RECORD_VERIFICATION_LEVEL,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import ENTITIES from 'constants/entities'

import useQueryParams from 'hooks/useQueryParams'

import Modal from 'components/Modal'
import {
    DateTimeField,
    MultiselectField,
    SelectField,
} from 'components/formFields'
import Separator from 'components/Separator'
import {
    MOMENT_FORMATS,
    getIsDateSameOrBefore,
    getTomorrowDate,
} from 'services/moment.service'
import Loader from 'components/Loader'
import FilterIconButton from 'components/FilterIconButton'

const DiveRecordFilters = ({ activeFilters, projectId }) => {
    const t = useTranslate()
    const { addFilterParams } = useQueryParams()

    const { isVerified } = useContext(CurrentUserContext)

    const [open, setOpen] = useState(false)

    const emptyValues = {
        divingMode: [],
        diveRecordType: [],
        diveRecordVerificationLevel: [],
        status: [],
        project: null,
        fromDate: null,
        toDate: null,
    }

    const initialValues = {
        divingMode: activeFilters.divingMode ?? emptyValues.divingMode,
        diveRecordType: activeFilters.diveRecordType ?? emptyValues.diveRecordType,
        diveRecordVerificationLevel: activeFilters.diveRecordVerificationLevel ?? emptyValues.diveRecordVerificationLevel,
        status: activeFilters.status ?? emptyValues.status,
        project: activeFilters.project ?? emptyValues.project,
        fromDate: activeFilters.fromDate ?? emptyValues.fromDate,
        toDate: activeFilters.toDate ?? emptyValues.toDate,
    }

    const validation = Yup.object({
        fromDate: Yup.date().nullable(),
        toDate: Yup.date()
            .nullable()
            .test('toDate', (value, { parent, createError }) => {
                if (
                    getIsDateSameOrBefore(
                        value,
                        parent.fromDate,
                        MOMENT_FORMATS.DATE
                    )
                ) {
                    return createError({
                        message: t('form.error.toDateAfterFromDate'),
                        path: 'toDate',
                    })
                }
                return true
            }),
    })

    const handleFilter = (filters, { setSubmitting }) => {
        setSubmitting(true)
        addFilterParams(filters)
        setSubmitting(false)
        setOpen(false)
    }

    const handleReset = (formFields, setFieldValue) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, emptyValues[key])
        })
    }

    return (
        <Fragment>
            <FilterIconButton setOpen={setOpen} />
            {open && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={handleFilter}
                >
                    {({ values, setFieldValue, isSubmitting }) => (
                        <Form>
                            <Modal
                                open={open}
                                setOpen={setOpen}
                                closeOnClickOutside={false}
                                title="general.filterDiveRecords"
                                isSubmitting={isSubmitting}
                                values={values}
                                buttons={{
                                    middleBtn: {
                                        handleClick: () =>
                                            handleReset(values, setFieldValue),
                                    },
                                    nextBtn: {
                                        label: 'button.filter',
                                    },
                                }}
                            >
                                <div className="_wr">
                                    <div className="_w">
                                        <h6 className="-mb20 _12">
                                            {t('general.diveRecordInfo')}
                                        </h6>

                                        {!projectId && (
                                            <div className="_12 _m6">
                                                <SelectField
                                                    name="project"
                                                    entityType={
                                                        isVerified
                                                            ? ENTITIES.PROJECT
                                                            : null
                                                    }
                                                    params={{
                                                        myDiveProjects: true,
                                                    }}
                                                    disabled={!isVerified}
                                                />
                                            </div>
                                        )}

                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="status"
                                                defaultOptions={Object.values(
                                                    DIVE_RECORD_STATUS
                                                )}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                            />
                                        </div>

                                        <Separator />

                                        <h6 className="-mb20 _12">
                                            {t('form.label.dateOfDive')}
                                        </h6>

                                        <div className="_12 _m6">
                                            <DateTimeField
                                                name="fromDate"
                                                label="form.label.from"
                                            />
                                        </div>

                                        <div className="_12 _m6 calendarMovedToRight">
                                            <DateTimeField
                                                name="toDate"
                                                label="form.label.to"
                                                minDate={
                                                    values.fromDate
                                                        ? getTomorrowDate(
                                                              values.fromDate
                                                          )
                                                        : null
                                                }
                                            />
                                        </div>

                                        <Separator />

                                        <h6 className="-mb20 _12">
                                            {t('general.diveInfo')}
                                        </h6>
                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="divingMode"
                                                label="form.label.modeOfDive"
                                                placeholder="form.placeholder.selectModeOfDive"
                                                entityType={
                                                    ENTITIES.DIVING_MODE
                                                }
                                                searchable
                                                dropup
                                            />
                                        </div>

                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="diveRecordType"
                                                label="form.label.divingRole"
                                                placeholder="form.placeholder.selectDivingRole"
                                                defaultOptions={Object.values(
                                                    DIVE_RECORD_TYPE
                                                )}
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                dropup
                                            />
                                        </div>

                                        <div className="_12 _m6">
                                            <MultiselectField
                                                name="diveRecordVerificationLevel"
                                                label="form.label.verificationLevel"
                                                placeholder="form.placeholder.selectVerificationLevel"
                                                defaultOptions={Object.values(
                                                    DIVE_RECORD_VERIFICATION_LEVEL,
                                                )}
                                                disabledItems={
                                                    Object.values(
                                                        DIVE_RECORD_VERIFICATION_LEVEL,
                                                    ).filter(a => a.disabled)
                                                }
                                                valueType={SELECT_VALUE_TYPE.STRING}
                                                dropup
                                            />
                                        </div>
                                    </div>
                                </div>
                                {isSubmitting && <Loader />}
                            </Modal>
                        </Form>
                    )}
                </Formik>
            )}
        </Fragment>
    )
}

DiveRecordFilters.propTypes = {
    activeFilters: PropTypes.object,
}

export default DiveRecordFilters
