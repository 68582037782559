import { Form, Formik } from 'formik'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { useNavigate } from 'react-router'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'

import {
    MOMENT_FORMATS,
    formatDate,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
} from 'services/moment.service'

import { MAX_CHARS_ABOUT } from 'utils/validationConstants'
import {
    validateEmail,
    validatePhoneNumberNotRequired,
} from 'utils/yupValidations'

import { getEntityFilters } from 'services/localStorage.service'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    COMPANY_TYPE,
    ICON_SIZE,
} from 'constants/enums'
import ICONS from 'constants/icons'
import REGEXP from 'constants/regex'
import ROUTES from 'constants/routes'
import FILTERS from 'constants/filters'
import { FILE_CATEGORIES } from 'constants/fileCategories'

import FocusError from 'components/FocusError'
import {
    CheckboxField,
    DateTimeField,
    MultiselectField,
    ProfilePictureUploadField,
    TextAreaField,
} from 'components/formFields'
import InputField from 'components/formFields/InputField'
import SelectField from 'components/formFields/SelectField'
import Loader from 'components/Loader'
import Separator from 'components/Separator'
import Button from 'components/Button'

const CompanyAccountForm = ({ initialData, handleSubmit }) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)

    const isClientOrganization =
        initialData?.companyType === COMPANY_TYPE.CLIENT_COMPANY

    const initialValues = {
        name: initialData?.name ?? '',
        hqCountry: initialData?.hqCountry ?? null,
        hqAddress: initialData?.hqAddress ?? '',
        size: initialData?.size ?? null,
        founded: initialData?.founded ?? '',
        tinVat: initialData?.tinVat ?? '',
        email: initialData?.email ?? '',
        countryPhoneCode: initialData?.countryPhoneCode ?? null,
        phoneNumber: initialData?.phoneNumber ?? '',
        website: initialData?.website ?? '',
        contactPersonFirstName: initialData?.contactPersonFirstName ?? '',
        contactPersonLastName: initialData?.contactPersonLastName ?? '',
        about: initialData?.about ?? '',
        industries: initialData?.industries ?? [],
        organizationTypes: initialData?.organizationTypes ?? [],
        services: initialData?.services ?? [],
        locationOfServices: initialData?.locationOfServices ?? [],
        vessels: initialData?.vessels ?? [],
        clients: initialData?.clients ?? [],
        assetsAndEquipment: initialData?.assetsAndEquipment ?? '',
        logo: initialData?.logo ?? null,
        approvedAccess: initialData?.approvedAccess ?? false,
    }

    const requiredMessage = t('form.error.required')
    const maximumCharacters = `${t(
        'form.error.maximumAllowed'
    )} ${MAX_CHARS_ABOUT} ${t('form.error.characters')}`

    const validation = Yup.object({
        name: Yup.string().trim().required(requiredMessage),
        hqCountry: Yup.object().notRequired(),
        hqAddress: Yup.string().trim().required(requiredMessage),
        size: Yup.object().required(requiredMessage),
        founded: Yup.date().test('founded', (value, { createError }) => {
            if (getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)) {
                return true
            }

            return createError({
                message: t('form.error.foundedDate'),
                path: 'founded',
            })
        }),
        tinVat: Yup.string().trim(),
        email: validateEmail(t).required(requiredMessage),
        countryPhoneCode: Yup.object().notRequired(),
        phoneNumber: validatePhoneNumberNotRequired(t),
        website: Yup.string().matches(
            REGEXP.REGEXP_URL,
            t('form.error.websiteURL')
        ),
        contactPersonFirstName: Yup.string()
            .max(125, t('form.error.maximumCharLimit'))
            .trim(),
        contactPersonLastName: Yup.string()
            .max(125, t('form.error.maximumCharLimit'))
            .trim(),
        about: Yup.string().max(MAX_CHARS_ABOUT, maximumCharacters),
        industries: Yup.array()
            .min(1, requiredMessage)
            .required(requiredMessage),
        organizationTypes: Yup.array()
            .min(1, requiredMessage)
            .required(requiredMessage),
        services: Yup.array(),
        locationOfServices: Yup.array(),
        vessels: Yup.array(),
        clients: Yup.array(),
        assetsAndEquipment: Yup.string().max(500, maximumCharacters),
    })

    const onSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await handleSubmit(formData)
            handleBack()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    const handleBack = () =>
        navigate(
            `/${ROUTES.COMPANY_ACCOUNT_MANAGEMENT}${getEntityFilters(
                FILTERS.SUPER_ADMIN_COMPANY_FILTERS
            )}`
        )

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({ isSubmitting }) => (
                <Form className="personalInfoModal">
                    <FocusError />
                    <div className="-contentElements -companyManagement">
                        <div className="_w">
                            <ProfilePictureUploadField
                                name="logo"
                                profilePage={true}
                                companyProfile={true}
                                fileCategory={FILE_CATEGORIES.COMPANY_LOGO}
                            />
                            <div className="_12 _xl9 -mainInfo">
                                <div className="_w">
                                    <div className="_12 _m6">
                                        <InputField name="name" required />
                                    </div>
                                    <div className="_12 _m6">
                                        <SelectField
                                            name="size"
                                            label="form.label.companySize"
                                            placeholder="form.placeholder.companySize"
                                            entityType={ENTITIES.COMPANY_SIZE}
                                            searchable
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="_w">
                                    <div className="_12 _m6">
                                        <SelectField
                                            name="hqCountry"
                                            label="form.label.hqFullAddress"
                                            placeholder="form.placeholder.country"
                                            entityType={ENTITIES.COUNTRY}
                                            searchable
                                        />
                                    </div>
                                    <div className="_12 _m6 fakeLabel">
                                        <InputField
                                            name="hqAddress"
                                            label="general.fakeLabel"
                                            required
                                        />
                                    </div>
                                    <div className="_12 _m6">
                                        <DateTimeField
                                            name="founded"
                                            maxDate={getTodaysDate()}
                                        />
                                    </div>
                                    <div className="_12 _m6">
                                        <InputField
                                            name="tinVat"
                                            tooltip="general.tinVatTooltip"
                                        />
                                    </div>
                                </div>
                                <div className="_w">
                                    <div className="_12 _m3 centered-checkbox -mb20">
                                        <CheckboxField
                                            name="approvedAccess"
                                            label="form.label.activeAccount"
                                            title="general.accountActivity"
                                            translate
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="_w">
                            <Separator />
                            <div className="_12 _l6">
                                <InputField
                                    name="email"
                                    label="form.label.email"
                                    placeholder="form.placeholder.email"
                                    required
                                />
                            </div>
                            <div className="_12 _l6">
                                <div className="a-phoneNumberField aligned-baseline fakeLabel -notFirst">
                                    <SelectField
                                        name="countryPhoneCode"
                                        label="form.label.phone"
                                        entityType={ENTITIES.COUNTRY}
                                        displayAttribute="callingCode"
                                        searchAttribute="callingCode"
                                        iconAttribute="flag"
                                        showPlaceholder={false}
                                        params={{ sort: 'callingCode' }}
                                        tooltip="general.corporatePhoneNumberTooltip"
                                        searchable
                                    />
                                    <InputField
                                        name="phoneNumber"
                                        label="general.fakeLabel"
                                        placeholder="form.placeholder.phone"
                                        icon={ICONS.PHONE}
                                        iconColor={COLORS.DARK_BLUE_60}
                                        size={ICON_SIZE.SIZE20}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="_w ">
                            <Separator />
                            <div className="_12 _m6">
                                <InputField name="website" />
                            </div>
                        </div>
                        <div className="_w ">
                            <div className="_12 _m6">
                                <InputField name="contactPersonFirstName" />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="contactPersonLastName" />
                            </div>
                        </div>
                        <div className="_w ">
                            <Separator />
                            <div className="_12">
                                <TextAreaField name="about" largeTextarea />
                            </div>
                        </div>
                        <div className="_w">
                            <Separator />
                            <div className="_12 _m6">
                                <MultiselectField
                                    name="industries"
                                    label="form.label.industriesYourOrganisationServes"
                                    placeholder="form.placeholder.selectIndustriesYourOrganisationServes"
                                    entityType={ENTITIES.INDUSTRY}
                                    searchable
                                    createNew
                                    required
                                />
                            </div>
                            {!isClientOrganization && (
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="services"
                                        label="form.label.familyOfProvidedServices"
                                        placeholder="form.placeholder.familyOfProvidedServices"
                                        entityType={ENTITIES.SERVICE}
                                        searchable
                                        createNew
                                    />
                                </div>
                            )}
                        </div>
                        <div className="_w">
                            <Separator />
                            <div className="_12 _m6">
                                <MultiselectField
                                    name="locationOfServices"
                                    label={
                                        isClientOrganization
                                            ? 'form.label.locationsOfOperations'
                                            : 'form.label.locationsOfServices'
                                    }
                                    placeholder={
                                        isClientOrganization
                                            ? 'form.placeholder.locationsOfOperations'
                                            : 'form.placeholder.locationsOfServices'
                                    }
                                    entityType={ENTITIES.LOCATIONS_OF_SERVICES}
                                    searchable
                                    idKey="entityId"
                                />
                            </div>

                            <div className="_12 _m6">
                                <MultiselectField
                                    name="organizationTypes"
                                    label="form.label.categories"
                                    placeholder="form.placeholder.categories"
                                    entityType={ENTITIES.ORGANIZATION_TYPES}
                                    searchable
                                    required
                                />
                            </div>
                        </div>

                        {!isClientOrganization && (
                            <div className="_w">
                                <Separator />
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="vessels"
                                        label="form.label.vessels"
                                        entityType={ENTITIES.VESSELS}
                                        searchable
                                        createNew
                                        dropup
                                    />
                                </div>
                            </div>
                        )}

                        {!isClientOrganization && (
                            <div className="_w">
                                <Separator />
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="clients"
                                        label="form.label.clients"
                                        entityType={ENTITIES.CLIENTS}
                                        searchable
                                        createNew
                                        dropup
                                    />
                                </div>
                            </div>
                        )}

                        <div className="_w ">
                            <Separator />
                            <div className="_12">
                                <TextAreaField
                                    name="assetsAndEquipment"
                                    largeTextarea
                                />
                            </div>
                        </div>
                        <div className="_w">
                            <Separator />
                            {!!initialData && (
                                <div className="_12 space-between flex-wrap -gap10">
                                    <p className="a-mediumText a-lightText">
                                        <span className="a-mediumTextSemiBold">
                                            {t('general.registered')}:
                                        </span>
                                        {initialData.registeredAt
                                            ? ` ${formatDate(
                                                  initialData.registeredAt
                                              )} ${t(
                                                  'general.at'
                                              )} ${formatDate(
                                                  initialData.registeredAt,
                                                  MOMENT_FORMATS.TIME
                                              )}.`
                                            : ` /`}
                                    </p>
                                </div>
                            )}
                            {!!initialData && <Separator />}
                        </div>
                        <div className="_w spaceBetweenTwoButtons">
                            <div className="_12 _s6 _m5 -backButton">
                                <Button
                                    label="button.cancel"
                                    onClick={handleBack}
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                />
                            </div>
                            <div className="_12 _s6 _m5 -nextButton">
                                <Button
                                    label="button.saveCompanyInformation"
                                    type={BUTTON_TYPE.SUBMIT}
                                />
                            </div>
                        </div>
                    </div>
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    )
}

export default CompanyAccountForm
