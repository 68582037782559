import React from 'react'

import useFetchFile from 'hooks/useFetchFile'

import { ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Icon from './Icon'

const Image = ({
    path,
    alt,
    imgClass,
    baseUrl,
    icon = ICONS.GALLERY,
    iconSize = ICON_SIZE.SIZE20,
    iconColor = COLORS.DARK_BLUE_60,
}) => {
    const { fileUrl } = useFetchFile(path, baseUrl)

    return fileUrl ? (
        <img className={imgClass || ''} alt={alt ?? 'img'} src={fileUrl} />
    ) : (
        <Icon name={icon} size={iconSize} color={iconColor} />
    )
}

export default Image
