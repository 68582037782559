import ROUTES from 'constants/routes'
import { ENTITY_MANAGEMENT_GROUPS } from 'constants/managementEntities'

const {
    EntityManagement,
    CreateEntity,
    EditEntity,
} = require('screens/admin/EntityManagement')

const getEntityManagementRoutes = () => {
    const routes = []

    ENTITY_MANAGEMENT_GROUPS.forEach(({ subitems }) => {
        subitems.forEach(
            ({ key: entityType, to: path, roles, create = true }) => {
                routes.push({
                    path,
                    element: (
                        <EntityManagement
                            entityType={entityType}
                            create={create}
                        />
                    ),
                    roles,
                })
                create &&
                    routes.push({
                        path: `${path}${ROUTES.CREATE}`,
                        element: (
                            <CreateEntity entityType={entityType} path={path} />
                        ),
                        roles,
                    })

                routes.push({
                    path: `${path}${ROUTES.EDIT}/:entityId`,
                    element: <EditEntity entityType={entityType} path={path} />,
                    roles,
                })
            }
        )
    })

    return routes
}

const entityManagementRoutes = getEntityManagementRoutes()

export { entityManagementRoutes }
