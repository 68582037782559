import React from 'react'

const ManagementLayout = ({
    children,
    BreadcrumbsComponent,
    create,
    edit,
    entityType,
}) => {
    return (
        <div className="m-boxes">
            <div className="m-boxes__breadcrumbs">
                <BreadcrumbsComponent
                    create={create}
                    edit={edit}
                    entityType={entityType}
                />
            </div>
            <div
                className={`m-boxes__main -fullWidth ${
                    !create && !edit ? '-table' : ''
                }`}
            >
                {children}
            </div>
        </div>
    )
}

export default ManagementLayout
