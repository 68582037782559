import React from 'react'
import { useTranslate } from 'react-polyglot'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import { formatGteLteRange } from 'utils/filterButtonFormatters'

import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'

import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import {
    JOBS_INDUSTRY_TYPE_KEY,
    JOBS_SKILL_CATEGORY_TYPE_KEY,
    PROJECT_TYPE_KEY,
    SKILL_TYPE_KEY,
} from 'constants/projectFilterKeys'
import { JOBS_SEARCH_FILED } from 'constants/constants'
import FILTERS from 'constants/filters'

import JobCard from 'components/jobList/JobCard'
import SearchAndFilter from 'components/SearchAndFilter'
import JobsFilters from './JobsFilters'
import Pagination from '../table/Pagination'
import EmptyList from '../EmptyList'

const filters = {
    company: { entityType: ENTITIES.COMPANY, displayAttribute: 'name' },
    countryOfWork: {
        entityType: ENTITIES.COUNTRY,
        icon: ICONS.LOCATION,
        displayAttribute: 'name',
    },
    industry: { entityType: ENTITIES.INDUSTRY, displayAttribute: 'name' },
    projectType: {
        entityType: ENTITIES.PROJECT_INDUSTRY_TYPES,
        displayAttribute: 'name',
    },
    modeOfDive: { entityType: ENTITIES.DIVING_MODE, displayAttribute: 'name' },
    geographicalRegion: {
        entityType: ENTITIES.REGION,
        displayAttribute: 'name',
    },
    locationType: {
        entityType: ENTITIES.LOCATION_TYPE,
        displayAttribute: 'name',
    },
    countryOfMobilization: {
        entityType: ENTITIES.COUNTRY,
        icon: ICONS.LOCATION,
        displayAttribute: 'name',
    },
    bodyOfWater: {
        entityType: ENTITIES.BODY_OF_WATER,
        displayAttribute: 'name',
    },
    positionType: {
        entityType: ENTITIES.DIVER_POSITION,
        displayAttribute: 'name',
    },
    projectName: { type: 'text' },
    toDurationDays: {
        type: 'range',
        unit: 'days',
        formatter: formatGteLteRange,
    },
    fromPayRate: {
        unit: '$',
    },
    skillCategory: {
        entityType: ENTITIES.SKILL_CATEGORY,
        displayAttribute: 'name',
    },
    skill: {
        entityType: ENTITIES.SKILL,
        displayAttribute: 'name',
    },
}

const JobList = () => {
    const t = useTranslate()

    const { data: jobs, meta, isLoading } = useFetchDataByQueryParams(
        ENTITIES.JOBS_ELASTIC
    )

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        filters,
        FILTERS.JOBS
    )

    const handleFilterItemRemoved = (key, filters) => {
        if (
            key === JOBS_INDUSTRY_TYPE_KEY &&
            filters.hasOwnProperty(PROJECT_TYPE_KEY)
        ) {
            delete filters[PROJECT_TYPE_KEY]
        }
        if (
            key === JOBS_SKILL_CATEGORY_TYPE_KEY &&
            filters.hasOwnProperty(SKILL_TYPE_KEY)
        ) {
            delete filters[SKILL_TYPE_KEY]
        }

        return filters
    }

    return (
        <div>
            <SearchAndFilter
                searchPlaceholder="general.searchJob"
                activeFilters={activeFilters}
                filters={filters}
                showDefaultKey={false}
                searchKey={JOBS_SEARCH_FILED}
                modifyFiltersOnRemove={handleFilterItemRemoved}
            >
                <JobsFilters activeFilters={activeFilters} />
            </SearchAndFilter>
            {jobs.map((item) => {
                return (
                    <div key={item.id} className="-mb10 a-jobCard">
                        <JobCard item={item}></JobCard>
                    </div>
                )
            })}
            {jobs.length === 0 && !isLoading && (
                <EmptyList
                    icon={ICONS.NO_DATA}
                    title={
                        meta?.totalItems
                            ? t('general.noJobResultsOnThisPage')
                            : t('general.noJobResults')
                    }
                    description={
                        meta?.totalItems
                            ? t('general.noJobResultsOnThisPageDesc')
                            : t('general.noJobResultsDesc')
                    }
                    showSearchResult={
                        Object.keys(activeFilters).length !== 0 &&
                        !meta?.totalItems
                    }
                />
            )}
            <Pagination totalItems={meta?.totalItems} meta={meta} scrollToTop />
            {renderLoader(isLoading, null, isLoadingFilters)}
        </div>
    )
}

export default JobList
