import React from 'react'

import useWindowDimensions from 'hooks/useWindowDimension'

import appStore from '../../../../assets/images/icons/appStore.svg'
import googlePlayStore from '../../../../assets/images/icons/googlePlayStore.svg'
import appStoreQRCode from './images/appStoreQRcode.png'
import googlePlayStoreQRCode from './images/playStoreQRcode.png'

import DownloadMobileAppSingleBox from './DownloadMobileAppSingleBox'

const DownloadMobileAppBoxes = () => {
    const { isMobile } = useWindowDimensions()
    return (
        <div
            className={`${isMobile ? 'column -gap20' : 'aligned-center -gap40'} fullWidth`}
        >
            <DownloadMobileAppSingleBox
                storeName="Apple App Store"
                qrCodeSrc={appStoreQRCode}
                storeLink="https://apps.apple.com/rs/app/skill-n-depth/id6578446583"
                storeImgSrc={appStore}
                storeImgAlt="Apple App Store"
                qrCodeAlt="Apple App Store QR code"
            />
            <DownloadMobileAppSingleBox
                storeName="Google Play Store"
                qrCodeSrc={googlePlayStoreQRCode}
                storeLink="https://play.google.com/store/apps/details?id=com.skillndepth.mobile"
                storeImgSrc={googlePlayStore}
                storeImgAlt="Google Play Store"
                qrCodeAlt="Google Play Store QR code"
            />
        </div>
    )
}

export default DownloadMobileAppBoxes
