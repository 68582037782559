import React from 'react'
import { useTranslate } from 'react-polyglot'

import CellItem from '../CellItem'
import { getValueWithKey } from 'utils/dataFormatters'

const CellHeader = ({ item, headerKey, title, headerItem }) => {
    const t = useTranslate()

    return (
        <div className="m-accordion__card--header">
            {title && (
                <span className="a-captionsTextBold -title">
                    {t(title)}
                </span>
            )}
            <div className="-cellItem">
                <CellItem
                    headerKey={headerKey}
                    headerItem={headerItem}
                    item={item}
                />
            </div>
        </div>
    )
}

export default CellHeader
