const REGEXP = {
    PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+={}[\]|\\:;"'<>,.?/~`-])[A-Za-z\d!@#$%^&*()_+={}[\]|\\:;"'<>,.?/~`-]{8,}$/,
    ALPHA: /^[A-Za-zÀ-ŸžŽ\s]+$/,
    NUMERIC: /\D+/,
    ALPHA_NUMERIC: /^[a-z\d\-_\s]+$/i,
    EMAIL: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,18}$/g,
    ALPHA_WITH_SPACES: /^[a-zA-Z ]*$/,
    ALPHA_NUMERIC_NO_SPECIAL_CHARACTERS: /^[0-9a-zA-Z ]*$/,
    DIGITS: /^\d+$/,
    REGEXP_URL: /^(?:(?:https?:\/\/(?:www\.[^.]+\.[^.]+|[^\s]+\.[^.]+))|(?:www\.[^.]+\.[^.]+))(?:\/[^\s]*)?$/i,
}
export default REGEXP
