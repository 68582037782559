import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { Link } from 'react-router'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import IconButton from '../IconButton'
import Icon from 'components/Icon'

const ScrollableTabs = ({
    tabs,
    getPath,
    activeTab,
    translate,
    underlined,
}) => {
    const t = useTranslate()

    const [scroll, setScroll] = useState({
        isScrollable: false,
        leftArrowActive: false,
        rightArrowActive: false,
    })

    const [isDragging, setIsDragging] = useState(false)

    const scrollableTabsRef = useRef(null)
    const tabsListRef = useRef(null)
    const activeTabRef = useRef(null)
    const tabsList = tabsListRef.current

    const handleScroll = () => {
        const tabsList = tabsListRef.current
        const scrollableTab = scrollableTabsRef.current
        const maxScrollValue =
            tabsList?.scrollWidth - tabsList?.clientWidth - 20

        const isScrollableValue =
            scrollableTab.scrollWidth < tabsList?.scrollWidth

        setScroll({
            isScrollable: isScrollableValue,
            leftArrowActive: tabsList?.scrollLeft >= 20,
            rightArrowActive: tabsList?.scrollLeft < maxScrollValue,
        })
    }

    const handleArrowClick = (direction) => {
        tabsList.scrollLeft += direction
        handleScroll()
    }

    const handleMouseDown = () => {
        setIsDragging(true)
    }

    const handleMouseMove = (e) => {
        if (!isDragging) return
        tabsList.scrollLeft -= e.movementX
    }

    const handleMouseUp = () => {
        setIsDragging(false)
    }

    const handleActiveTabVisibility = () => {
        const tabsList = tabsListRef.current
        const active = document.querySelectorAll(
            '.m-scrollableTabs__tab.-active'
        )

        const parentWidth = tabsList?.offsetWidth
        const childWidth = active[0]?.offsetWidth

        const scrollLeft =
            active[0]?.offsetLeft + childWidth / 2 - parentWidth / 2

        tabsList && (tabsList.scrollLeft = scrollLeft)
    }

    useEffect(() => {
        handleScroll()
        window.addEventListener('resize', handleScroll)
        return () => {
            window.removeEventListener('resize', handleScroll)
        }
    }, [])

    useEffect(() => {
        handleActiveTabVisibility()
    }, [activeTab?.key])

    return (
        <div
            className={`m-tab ${underlined ? '-underlined' : ''}`}
            ref={scrollableTabsRef}
        >
            <div
                className={`m-scrollableTabs ${
                    !scroll.leftArrowActive ? '-noLeftArrow' : '-withLeftArrow'
                } ${
                    !scroll.rightArrowActive
                        ? '-noRightArrow'
                        : '-withRightArrow'
                } ${scroll.isScrollable ? '-isScrollable' : ''}`}
            >
                <IconButton
                    icon={ICONS.CHEVRON_LEFT}
                    iconColor={
                        scroll.leftArrowActive
                            ? COLORS.DARK_BLUE
                            : COLORS.DARK_BLUE_40
                    }
                    iconSize={ICON_SIZE.SIZE20}
                    className={`leftArrow ${scroll.leftArrowActive} ? '-active' : ''
                    }`}
                    onClick={() => handleArrowClick(-200)}
                />
                <div
                    ref={tabsListRef}
                    className="-wrapper"
                    onScroll={handleScroll}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                >
                    {tabs
                        .filter((tab) => !tab.hidden)
                        .map(
                            ({
                                key,
                                title,
                                queryParams,
                                badge,
                                disabled,
                                icon,
                                iconColor,
                                getCustomPath,
                            }) => (
                                <Link
                                    key={key}
                                    className={`${
                                        key === activeTab?.key ? '-active' : ''
                                    } m-scrollableTabs__tab a-mediumTextSemiBold ${
                                        disabled ? '-disabled' : ''
                                    } ${icon ? '-gap10' : ''}`}
                                    to={
                                        getCustomPath
                                            ? getCustomPath()
                                            : getPath(key, queryParams)
                                    }
                                    ref={
                                        key === activeTab?.key
                                            ? activeTabRef
                                            : null
                                    }
                                    onClick={handleActiveTabVisibility}
                                >
                                    {badge && (
                                        <span className="-incomplete"></span>
                                    )}
                                    {icon && (
                                        <Icon
                                            name={icon}
                                            color={iconColor}
                                            size={ICON_SIZE.SIZE20}
                                        />
                                    )}
                                    {translate ? t(title) : title}
                                </Link>
                            )
                        )}
                </div>
                <IconButton
                    icon={ICONS.CHEVRON_RIGHT}
                    iconColor={
                        scroll.rightArrowActive
                            ? COLORS.DARK_BLUE
                            : COLORS.DARK_BLUE_40
                    }
                    iconSize={ICON_SIZE.SIZE20}
                    className={`rightArrow ${
                        scroll.rightArrowActive ? '-active' : ''
                    }`}
                    onClick={() => handleArrowClick(200)}
                />
            </div>
        </div>
    )
}

ScrollableTabs.propTypes = {
    tabs: PropTypes.array,
    getPath: PropTypes.func,
    activeTab: PropTypes.object,
    translate: PropTypes.bool,
}

export default ScrollableTabs
