import { Fragment, useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import moment from 'moment'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useWindowDimensions from 'hooks/useWindowDimension'

import ICONS from 'constants/icons'
import COLORS from 'constants/colors'
import { CHANNEL_TYPE_CHANNEL } from 'constants/chat'
import Icon from 'components/Icon'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_SIZE,
} from 'constants/enums'

import Input from 'components/Input'
import ChatUserSearch from 'components/chat/ChatUserSearch'
import IconButton from 'components/IconButton'
import Button from 'components/Button'
import NoData from 'components/NoData'
import Image from 'components/Image'

const AddChannel = ({ onCancelCreateChannel, onCreateChannel }) => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()
    const { setAlert } = useContext(AlertContext)
    const { chatClient: client, currentUser } = useContext(CurrentUserContext)

    const [searchingUsers, setSearchingUsers] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [channelName, setChannelName] = useState('')
    const [loadSearchedUsers, setLoadSearchedUsers] = useState(false)

    const handleChannelName = async ({ target: { value } }) => {
        setChannelName(value)
    }

    const handleSearch = async (value) => {
        setLoadSearchedUsers(true)
        setSearchValue(value)

        if (!value) {
            setSearchingUsers([])
            return
        }

        const { users } = await client.queryUsers({
            name: { $autocomplete: value },
            hidden: false,
        })
        setSearchingUsers(users)
        setLoadSearchedUsers(false)
    }

    const handleAddUser = (user) => {
        const canAdd = canAddUserToChannel(user)
        if (!canAdd) return

        setSelectedUsers((users) => {
            return [...users, user]
        })
        setSearchValue('')
        setSearchingUsers([])
    }

    const canAddUserToChannel = (user) => {
        if (user.id === currentUser.id.toString()) {
            setAlert(
                t('message.currentUserIsAlwaysSelected'),
                ALERT_TYPES.WARNING
            )
            return false
        }

        const exists = selectedUsers.some((usr) => {
            return usr.id === user.id
        })

        if (exists) {
            setAlert(t('message.userAlreadySelected'), ALERT_TYPES.WARNING)
            return false
        }

        return true
    }

    const handleRemoveFromSelected = (user) => {
        setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id))
    }

    const cancelCreateChannel = () => {
        setSelectedUsers([])
        setSearchingUsers([])
        setSearchValue('')
        onCancelCreateChannel()
    }

    const createChannel = async () => {
        const filters = {
            type: 'team',
            members: { $in: [currentUser.id.toString() || ''] },
        }
        const existingChannel = await client.queryChannels(filters)

        let existingChannelArray = []

        existingChannelArray = existingChannel.filter(
            (item) => item.data.name == channelName
        )

        if (!!existingChannelArray.length) {
            setAlert(t('chat.channelNameExist'), ALERT_TYPES.WARNING)
            return
        }

        const channelId =
            channelName?.replaceAll(' ', '_') +
            currentUser.id.toString() +
            moment().format('DDMMyyyyHHmm')

        const newChannel = await client.channel('team', channelId, {
            name: channelName,
            members: [
                currentUser.id.toString(),
                ...selectedUsers.map((u) => u.id.toString()),
            ],
            channelType: CHANNEL_TYPE_CHANNEL,
        })

        await newChannel.watch()
        onCreateChannel(newChannel)
    }

    return (
        <Fragment>
            <div className="str-chat__channel-header -channels aligned-center">
                {isTablet && (
                    <div className="-backBtn">
                        <IconButton
                            icon={ICONS.ARROW_LEFT}
                            iconColor={COLORS.DARK_BLUE}
                            onClick={cancelCreateChannel}
                        />
                    </div>
                )}
                <span className="a-bodyTextMedium">
                    {t('chat.createNewChannel')}
                </span>
            </div>
            <div className={'add-chanel-wrapper -edit'}>
                <div>
                    <div>
                        <Input
                            name="channelName"
                            placeholder="form.placeholder.channelName"
                            onChange={handleChannelName}
                            required={true}
                        />
                    </div>
                    <div className="m-searchField -withLabel">
                        <Input
                            name="searchUserField"
                            label="form.label.addMembers"
                            placeholder="form.placeholder.searchUsers"
                            onChange={(e) => handleSearch(e.target.value)}
                            icon={ICONS.SEARCH}
                            iconColor={COLORS.DARK_BLUE_40}
                            size={ICON_SIZE.SIZE16}
                            showLabel={true}
                            value={searchValue}
                        />
                        {searchingUsers.length !== 0 && (
                            <div className={'channel-users-search'}>
                                <ul>
                                    {searchingUsers.map((user) => (
                                        <ChatUserSearch
                                            key={user.id}
                                            user={{ ...user, online: false }}
                                            handleClick={handleAddUser}
                                        />
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div>
                        {selectedUsers.length > 0 &&
                            selectedUsers.map((user) => (
                                <div
                                    className="chat-users-search-result"
                                    key={user.id}
                                >
                                    <div className={'result-wrapper'}>
                                        <div className="-resultImg">
                                            <span className="m-avatar__icon">
                                                <Image
                                                    path={user.image}
                                                    alt=""
                                                    icon={ICONS.USER}
                                                    iconSize={ICON_SIZE.SIZE18}
                                                    iconColor={
                                                        COLORS.DARK_BLUE_40
                                                    }
                                                />
                                            </span>
                                        </div>
                                        <div className="column -resultText">
                                            <div className="aligned-center -nameWrapper">
                                                <span className="a-mediumTextSemiBold -name">
                                                    {user.name}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <span
                                        onClick={() =>
                                            handleRemoveFromSelected(user)
                                        }
                                        className="removeItemIcon"
                                    ></span>
                                </div>
                            ))}
                    </div>
                </div>
                {!loadSearchedUsers &&
                    searchingUsers.length === 0 &&
                    searchValue && (
                        <NoData
                            icon={ICONS.SEARCH_ICON}
                            title={t('general.noResults')}
                            description={t('general.noSearchResultsDesc')}
                            isSmallWindow
                        />
                    )}
                <div className={'create-channel-btn-wrapper'}>
                    <Button
                        label="chat.cancel"
                        onClick={cancelCreateChannel}
                        btnClass={BUTTON_STATUS.SECONDARY}
                        size={BUTTON_SIZE.SMALL}
                    />
                    <Button
                        label="chat.createChannel"
                        onClick={createChannel}
                        size={BUTTON_SIZE.SMALL}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default AddChannel
