import { ENTITY_SOURCE } from 'constants/constants'
import { translateRoleOptions } from './translateOptionsHelper'
import { DIVE_RECORD_STATUS, DIVE_RECORD_TYPE, DIVE_RECORD_VERIFICATION_LEVEL } from '../constants/enums'

const { formatDateApi } = require('services/moment.service')

const formatDateRange = (value) =>
    `${value.after ? formatDateApi(value.after) : '*'} - ${
        value.before ? formatDateApi(value.before) : '*'
    }`

const formatGteLteRange = (value, filter) => `
${value.gte ? `${filter.sign ?? ''}${value.gte}` : '*'} - 
${value.lte ? `${filter.sign ?? ''}${value.lte}` : '*'}
`

const formatPayRateRange = (value, filter) =>
    `${value.position.name} | ${value.fromPayRate || 0}${filter.sign} - ${
        value.toPayRate || ''
    }${value.toPayRate ? filter.sign : '/'}`

const formatCheckboxMessage = (value, filter, t) =>
    value ? t(filter.message) : ''

const formatFromToRange = (value) =>
    `${value.from || 0} - ${value.to ? value.to : '/'} `

const hasValue = (value) => value || value === 0

const formatDivingModeExperience = (value) => {
    const result = [`${value.divingMode.name}`]
    if (hasValue(value.fromDives) || hasValue(value.toDives))
        result.push(`${value.fromDives || 0}-${value.toDives || '/'} dives`)
    if (hasValue(value.fromDays) || hasValue(value.toDays))
        result.push(`${value.fromDays || 0}-${value.toDays || '/'} days`)
    if (hasValue(value.fromBellRuns) || hasValue(value.toBellRuns))
        result.push(
            `${value.fromBellRuns || 0}-${value.toBellRuns || '/'} bell runs`
        )

    return result.join(' | ')
}

const formatSkills = (value) =>
    `${value.skillCategory.name + '-' + value.skill.name}`

const formatLanguages = (value) =>
    `${value.language.name + '-' + value.languageProficiency.name}`

const formatRoleOptions = (value, filter, t) => translateRoleOptions(value, t)

const formatEntitySource = (value) =>
    ENTITY_SOURCE.find((item) => item.id.toString() === value.toString())?.name

const formatDiveRecordTypes = (value) => formatObject(value, DIVE_RECORD_TYPE)

const formatDiveRecordVerifiedLevel = (value) => formatObject(value, DIVE_RECORD_VERIFICATION_LEVEL)

const formatDiveRecordStatuses = (value) => formatObject(value, DIVE_RECORD_STATUS)

const formatUserSubscriptionFilter = (data, filter, t) => t('billing.subscriptionType.'+data.id)

const formatObject = (value, object) => {
    const selected = Object.values(object).find(
        (item) => item.id === parseInt(value)
    )

    return selected ? selected.name : ''
}

export {
    formatDateRange,
    formatGteLteRange,
    formatPayRateRange,
    formatCheckboxMessage,
    formatFromToRange,
    formatDivingModeExperience,
    formatSkills,
    formatLanguages,
    formatRoleOptions,
    formatEntitySource,
    formatDiveRecordTypes,
    formatDiveRecordVerifiedLevel,
    formatDiveRecordStatuses,
    formatUserSubscriptionFilter
}
