import React, { Fragment, useContext, useRef } from 'react'
import { useTranslate } from 'react-polyglot'
import { useParams } from 'react-router'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { AlertContext } from 'contexts/AlertContext'
import { DiveRecordContext } from './contexts/DiveRecordContext'

import { YES_NO_BOOLEAN_OPTIONS } from 'constants/constants'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    DIVE_RECORD_STATUS,
    INPUT_FILED_TYPE,
    SELECT_VALUE_TYPE,
} from 'constants/enums'
import { FILE_CATEGORIES } from 'constants/fileCategories'

import useFetchDataById from 'hooks/useFetchDataById'
import useSaveByStep from './hooks/useSaveByStep'

import { persistEntityService } from 'services/entity.service'

import StepButtons from 'components/StepButtons'
import Loader from 'components/Loader'
import Separator from 'components/Separator'
import {
    FileUploadField,
    MultiselectField,
    TextAreaField,
} from 'components/formFields'
import InputField from 'components/formFields/InputField'
import RadioGroupField from 'components/formFields/RadioGroupField'
import SelectField from 'components/formFields/SelectField'

import DiveSummaryFormContext from './formContext/DiveSummaryFormContext'
import FocusError from '../../../components/FocusError'
import useWindowDimensions from '../../../hooks/useWindowDimension'

const DiveSummaryRecord = () => {
    const t = useTranslate()
    const { isTablet } = useWindowDimensions()
    const { projectId, diveRecordId } = useParams()
    const formRef = useRef()

    const { setAlert } = useContext(AlertContext)
    const {
        diveRecord,
        isSupervisorDiveRecord,
        handlePostSaveAction,
        goToStepRef,
    } = useContext(DiveRecordContext)

    const { data, isLoading } = useFetchDataById(
        ENTITIES.DIVE_RECORD,
        diveRecordId,
        {
            include: [
                'diveRecordDiveSummary',
                'diveRecordDiveSummary.mentalWellnessElements',
                'diveRecordDiveSummary.traumaInjuryLevel',
                'diveRecordDiveSummary.decompressionIllness',
                'diveRecordDiveSummary.pressureInjuries',
                'diveRecordDiveSummary.equipmentFailures',
                'diveRecordDiveSummary.closeCall',
                'diveRecordDiveSummary.hideIncident',
                'diveRecordDiveSummary.files',
                'diveRecordDiveSummary.hideFeedback',
            ],
        }
    )

    const initialData = data?.diveRecordDiveSummary ?? null
    const isRejected = data?.status === DIVE_RECORD_STATUS.REJECTED.id

    const handleSave = async (
        formData,
        formActions,
        complete = true,
        goBack = false
    ) => {
        try {
            await formRef.current.validateForm()
            const isValid = !Object.keys(formRef.current.errors).length

            await persistEntityService(
                ENTITIES.DIVE_RECORD_DIVE_SUMMARY,
                {
                    ...formData,
                    diveRecord: diveRecord.data,
                    complete: initialData?.complete ? isValid : complete,
                    submitted: complete,
                },
                diveRecord.data?.diveRecordDiveSummary?.id
            )

            handlePostSaveAction(false, goBack, goToStepRef.current)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    useSaveByStep(formRef, handleSave)

    if (!data) return null

    const initialValues = {
        rest: initialData?.rest ?? '',
        numberOfMeals: initialData?.numberOfMeals ?? '',
        typeOfFood: initialData?.typeOfFood ?? '',
        calorieIntake: initialData?.calorieIntake ?? '',
        litersOfWater: initialData?.litersOfWater ?? '',
        litersOfOtherLiquids: initialData?.litersOfOtherLiquids ?? '',
        prescribedMedication: initialData?.prescribedMedication ?? '',
        nonPrescribedMedication: initialData?.nonPrescribedMedication ?? '',
        supplementsConsumed: initialData?.supplementsConsumed ?? '',
        physicalActivityAnomalies: initialData?.physicalActivityAnomalies ?? '',
        lightCardio: initialData?.lightCardio ?? '',
        moderateCardio: initialData?.moderateCardio ?? '',
        strenuousCardio: initialData?.strenuousCardio ?? '',
        mentalWellnessInfluenced: !!initialData?.mentalWellnessInfluenced,
        mentalWellnessDescription: initialData?.mentalWellnessDescription ?? '',
        mentalWellnessElements: initialData?.mentalWellnessElements ?? [],
        personalHygiene: initialData?.personalHygiene ?? '',
        chamberHygiene: initialData?.chamberHygiene ?? '',
        anyIncidentsOnDive: !!initialData?.anyIncidentsOnDive,
        abortedDive: !!initialData?.abortedDive,
        abortedDescription: initialData?.abortedDescription ?? '',
        traumaInjuryPresent: !!initialData?.traumaInjuryLevel,
        traumaInjuryLevel: initialData?.traumaInjuryLevel ?? null,
        traumaInjuryDescription: initialData?.traumaInjuryDescription ?? '',
        decompressionInjuryPresent: !!initialData?.decompressionIllness,
        decompressionIllness: initialData?.decompressionIllness ?? null,
        decompressionIllnessDescription:
            initialData?.decompressionIllnessDescription ?? '',
        pressureInjuriesPresent: !!initialData?.pressureInjuries,
        pressureInjuries: initialData?.pressureInjuries ?? [],
        pressureInjuryDescription: initialData?.pressureInjuryDescription ?? '',
        equipmentFailuresPresent: !!initialData?.equipmentFailures,
        equipmentFailures: initialData?.equipmentFailures ?? [],
        equipmentFailureDescription:
            initialData?.equipmentFailureDescription ?? '',
        closeCallPresent: !!initialData?.closeCall,
        closeCall: initialData?.closeCall ?? null,
        closeCallPresentDescription:
            initialData?.closeCallPresentDescription ?? '',
        hideIncident: !!initialData?.hideIncident,
        notes: initialData?.notes ?? '',
        files: initialData?.files ?? [],
        hideFeedback: !!initialData?.hideFeedback,
    }

    const requiredMessage = t('form.error.required')
    const maximumCharacters = `${t('form.error.maximumAllowed')} 100 ${t(
        'form.error.characters'
    )}`

    const validateDependentStringWithMaxCharacters = (relatedField) =>
        Yup.string().when(relatedField, {
            is: (val) => val,
            then: () =>
                Yup.string()
                    .max(100, maximumCharacters)
                    .required(requiredMessage),
            otherwise: () => Yup.string().max(100, maximumCharacters),
        })

    const validateDependentArray = (relatedField) =>
        Yup.array().when(relatedField, {
            is: (val) => val,
            then: () =>
                Yup.array().min(1, requiredMessage).required(requiredMessage),
            otherwise: () => Yup.array(),
        })

    const validateDependentObject = (relatedField) =>
        Yup.object().when(relatedField, {
            is: (val) => val,
            then: () => Yup.object().required(requiredMessage),
            otherwise: () => Yup.object().nullable(),
        })

    const supervisorDiveRecordValidation = {
        anyIncidentsOnDive: Yup.boolean()
            .test('anyIncidentsOnDive', (value, { parent, createError }) => {
                if (
                    (value &&
                        (parent.abortedDive ||
                            parent.traumaInjuryPresent ||
                            parent.decompressionInjuryPresent ||
                            parent.pressureInjuriesPresent ||
                            parent.equipmentFailuresPresent ||
                            parent.closeCallPresent)) ||
                    !value
                ) {
                    return true
                } else {
                    return createError({
                        message: t('form.error.youNeedToAddSomeIncident'),
                        path: 'anyIncidentsOnDive',
                    })
                }
            })
            .required(requiredMessage),
        abortedDescription: validateDependentStringWithMaxCharacters(
            'abortedDive'
        ),
        traumaInjuryLevel: validateDependentObject('traumaInjuryPresent'),
        traumaInjuryDescription: validateDependentStringWithMaxCharacters(
            'traumaInjuryPresent'
        ),
        decompressionIllness: validateDependentObject(
            'decompressionInjuryPresent'
        ),
        decompressionIllnessDescription: validateDependentStringWithMaxCharacters(
            'decompressionInjuryPresent'
        ),
        pressureInjuries: validateDependentArray('pressureInjuriesPresent'),
        pressureInjuryDescription: validateDependentStringWithMaxCharacters(
            'pressureInjuriesPresent'
        ),
        equipmentFailures: validateDependentArray('equipmentFailuresPresent'),
        equipmentFailureDescription: validateDependentStringWithMaxCharacters(
            'equipmentFailuresPresent'
        ),
        closeCall: validateDependentObject('closeCallPresent'),
        closeCallPresentDescription: validateDependentStringWithMaxCharacters(
            'closeCallPresent'
        ),
        hideFeedback: Yup.string().required(requiredMessage),
    }

    const diverDiveRecordValidation = {
        rest: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .notRequired(),
        numberOfMeals: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .notRequired(),
        typeOfFood: Yup.string()
            .trim()
            .max(100, maximumCharacters)
            .notRequired(),
        calorieIntake: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .notRequired(),
        litersOfWater: Yup.number()
            .min(0, t('form.error.invalidNumber'))
            .notRequired(),
        litersOfOtherLiquids: Yup.number()
            .min(0, t('form.error.invalidNumber'))
            .notRequired(),
        prescribedMedication: Yup.string()
            .trim()
            .max(100, maximumCharacters)
            .notRequired(),
        nonPrescribedMedication: Yup.string()
            .max(100, maximumCharacters)
            .trim()
            .notRequired(),
        supplementsConsumed: Yup.string()
            .max(100, maximumCharacters)
            .trim()
            .notRequired(),
        physicalActivityAnomalies: Yup.string().max(100, maximumCharacters),
        lightCardio: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .notRequired(),
        moderateCardio: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .notRequired(),
        strenuousCardio: Yup.number()
            .integer(t('form.error.invalidNumber'))
            .min(0, t('form.error.invalidNumber'))
            .notRequired(),
        mentalWellnessInfluenced: Yup.boolean().required(requiredMessage),
        mentalWellnessDescription: Yup.string().max(100, maximumCharacters),
        mentalWellnessElements: Yup.array().notRequired(),
        personalHygiene: Yup.string().trim().max(100, maximumCharacters),
        chamberHygiene: Yup.string().trim().max(100, maximumCharacters),
        ...supervisorDiveRecordValidation,
    }

    const validation = Yup.object().shape(
        isSupervisorDiveRecord
            ? supervisorDiveRecordValidation
            : diverDiveRecordValidation
    )

    const hideIncidentLabel =
        diveRecord.verifiedLevel > 0
            ? 'form.label.hideIncident'
            : 'form.label.hideIncidentAfterApproval'

    const hideFeedbackLabel =
        diveRecord.verifiedLevel > 0
            ? 'form.label.hideFeedback'
            : 'form.label.hideFeedbackAfterApproval'

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSave}
            innerRef={formRef}
        >
            {({ handleSubmit, isSubmitting, values, errors }) => {
                const {
                    mentalWellnessInfluenced,
                    anyIncidentsOnDive,
                    abortedDive,
                    traumaInjuryPresent,
                    decompressionInjuryPresent,
                    pressureInjuriesPresent,
                    equipmentFailuresPresent,
                    closeCallPresent,
                } = values
                return (
                    <Form>
                        <FocusError />
                        <DiveSummaryFormContext />
                        <div className="_wr -contentElements -diveRecordContent">
                            {!isSupervisorDiveRecord && (
                                <Fragment>
                                    <div className="a-lightText a-mediumText -mb20">
                                        <span className="redStar">*</span>
                                        <span>{t('general.listRestDiet')}</span>
                                    </div>
                                    <div className="_w">
                                        <span className="-mb20 -mt5 _12 a-bodyTextRegular">
                                            {t('general.healthHygieneRoutine')}
                                        </span>
                                    </div>

                                    {/* --- Rest ----------------------------------------------------------------------- */}

                                    <div className="_w">
                                        <span className="-mb20 _12 a-bodyTextRegular">
                                            {t('general.rest')}
                                        </span>
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <InputField
                                                name="rest"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                    </div>

                                    {/* --- Diet ----------------------------------------------------------------------- */}

                                    <div className="_w">
                                        <span className="-mb20 _12 -mt5 a-bodyTextRegular">
                                            {t('general.diet')}
                                        </span>
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <InputField
                                                name="numberOfMeals"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                        <div className="_12 _m4">
                                            <InputField name="typeOfFood" />
                                        </div>
                                        <div className="_12 _m4">
                                            <InputField
                                                name="calorieIntake"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                    </div>

                                    {/* --- Hydratation ---------------------------------------------------------------- */}

                                    <div className="_w">
                                        <span className="-mb20 _12 -mt5 a-bodyTextRegular">
                                            {t('general.hydratation')}
                                        </span>
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <InputField
                                                name="litersOfWater"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                        <div className="_12 _m4">
                                            <InputField
                                                name="litersOfOtherLiquids"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                    </div>

                                    {/* --- Medication/Supplements ----------------------------------------------------- */}

                                    <div className="_w">
                                        <span className="-mb20 _12 -mt5 a-bodyTextRegular">
                                            {t('general.medicationSupplements')}
                                        </span>
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <InputField name="prescribedMedication" />
                                        </div>
                                        <div className="_12 _m4">
                                            <InputField name="nonPrescribedMedication" />
                                        </div>
                                        <div className="_12 _m4">
                                            <InputField name="supplementsConsumed" />
                                        </div>
                                    </div>

                                    {/* --- Physical activity during dive ---------------------------------------------- */}
                                    <div className="_w">
                                        <span className="-mb20 _12 -mt5 a-bodyTextRegular">
                                            {t('general.physicalActivity')}
                                        </span>
                                    </div>
                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <InputField name="physicalActivityAnomalies" />
                                        </div>
                                        <div className="_12 _m4">
                                            <InputField
                                                name="lightCardio"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                        <div className="_12 _m4">
                                            <InputField
                                                name="moderateCardio"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <InputField
                                                name="strenuousCardio"
                                                type={INPUT_FILED_TYPE.NUMBER}
                                            />
                                        </div>
                                    </div>

                                    {/* --- Mental wellness ------------------------------------------------------------ */}
                                    <div className="_w">
                                        <span className="-mb20 _12 a-bodyTextRegular">
                                            {t('general.mentalWellness')}
                                        </span>
                                    </div>
                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <RadioGroupField
                                                name="mentalWellnessInfluenced"
                                                defaultOptions={
                                                    YES_NO_BOOLEAN_OPTIONS
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                            />
                                        </div>
                                        {mentalWellnessInfluenced && (
                                            <>
                                                <div className="_12 _m4">
                                                    <InputField name="mentalWellnessDescription" />
                                                </div>
                                                <div className="_12 _m4">
                                                    <MultiselectField
                                                        name="mentalWellnessElements"
                                                        entityType={
                                                            ENTITIES.MENTAL_WELLNESS_ELEMENTS
                                                        }
                                                        searchable
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    {/* --- Hygiene -------------------------------------------------------------------- */}

                                    <div className="_w">
                                        <span className="-mb20 -mt5 _12 a-bodyTextRegular">
                                            {t('general.hygiene')}
                                        </span>
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <InputField name="personalHygiene" />
                                        </div>
                                        <div className="_12 _m4">
                                            <InputField name="chamberHygiene" />
                                        </div>

                                        <Separator />
                                    </div>
                                </Fragment>
                            )}

                            {/* --- Incidents on dive ---------------------------------------------------------- */}
                            <div className="_w">
                                <span className="-mb20 _12 a-bodyTextRegular">
                                    {t('general.incidentsOnDive')}
                                </span>
                            </div>

                            <div className="_w">
                                <div className="_12">
                                    <RadioGroupField
                                        name="anyIncidentsOnDive"
                                        defaultOptions={YES_NO_BOOLEAN_OPTIONS}
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                        required
                                    />
                                </div>
                            </div>

                            {anyIncidentsOnDive && (
                                <>
                                    <div className="_w">
                                        <div className="_12 aligned-start">
                                            <RadioGroupField
                                                name="hideIncident"
                                                label={hideIncidentLabel}
                                                defaultOptions={
                                                    YES_NO_BOOLEAN_OPTIONS
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                tooltip="form.label.hiddenIncidentText"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <RadioGroupField
                                                name="abortedDive"
                                                defaultOptions={
                                                    YES_NO_BOOLEAN_OPTIONS
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                required
                                            />
                                        </div>
                                        {abortedDive && (
                                            <div className="_12 _m4">
                                                <InputField
                                                    name="abortedDescription"
                                                    required
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <RadioGroupField
                                                name="traumaInjuryPresent"
                                                label="form.label.traumaInjuryLevel"
                                                defaultOptions={
                                                    YES_NO_BOOLEAN_OPTIONS
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                required
                                            />
                                        </div>
                                        {traumaInjuryPresent && (
                                            <>
                                                <div className="_12 _m4">
                                                    <SelectField
                                                        name="traumaInjuryLevel"
                                                        label="form.label.traumaInjury"
                                                        entityType={
                                                            ENTITIES.TRAUMA_INJURY_LEVEL
                                                        }
                                                        searchable
                                                        required
                                                    />
                                                </div>
                                                <div className="_12 _m4">
                                                    <InputField
                                                        name="traumaInjuryDescription"
                                                        required
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <RadioGroupField
                                                name="decompressionInjuryPresent"
                                                label="form.label.decompressionIllness"
                                                defaultOptions={
                                                    YES_NO_BOOLEAN_OPTIONS
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                required
                                            />
                                        </div>
                                        {decompressionInjuryPresent && (
                                            <>
                                                <div className="_12 _m4">
                                                    <SelectField
                                                        name="decompressionIllness"
                                                        label="form.label.decompressionIllnessPresent"
                                                        entityType={
                                                            ENTITIES.DECOMPRESSION_ILLNESS
                                                        }
                                                        searchable
                                                        required
                                                    />
                                                </div>
                                                <div className="_12 _m4">
                                                    <InputField
                                                        name="decompressionIllnessDescription"
                                                        required
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <RadioGroupField
                                                name="pressureInjuriesPresent"
                                                label="form.label.pressureInjuries"
                                                defaultOptions={
                                                    YES_NO_BOOLEAN_OPTIONS
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                required
                                            />
                                        </div>
                                        {pressureInjuriesPresent && (
                                            <>
                                                <div className="_12 _m4">
                                                    <MultiselectField
                                                        name="pressureInjuries"
                                                        label="form.label.pressureInjuriesPresent"
                                                        entityType={
                                                            ENTITIES.PRESSURE_INJURY_TYPE
                                                        }
                                                        searchable
                                                        required
                                                    />
                                                </div>
                                                <div className="_12 _m4">
                                                    <InputField
                                                        name="pressureInjuryDescription"
                                                        required
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <RadioGroupField
                                                name="equipmentFailuresPresent"
                                                label="form.label.equipmentFailures"
                                                defaultOptions={
                                                    YES_NO_BOOLEAN_OPTIONS
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                required
                                            />
                                        </div>
                                        {equipmentFailuresPresent && (
                                            <>
                                                <div className="_12 _m4">
                                                    <MultiselectField
                                                        name="equipmentFailures"
                                                        label="form.label.equipmentFailuresPresent"
                                                        entityType={
                                                            ENTITIES.EQUIPMENT_FAILURE_TYPE
                                                        }
                                                        searchable
                                                        required
                                                    />
                                                </div>
                                                <div className="_12 _m4">
                                                    <InputField
                                                        name="equipmentFailureDescription"
                                                        required
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="_w">
                                        <div className="_12 _m4">
                                            <RadioGroupField
                                                name="closeCallPresent"
                                                label="form.label.closeCall"
                                                defaultOptions={
                                                    YES_NO_BOOLEAN_OPTIONS
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.STRING
                                                }
                                                required
                                            />
                                        </div>
                                        {closeCallPresent && (
                                            <>
                                                <div className="_12 _m4">
                                                    <SelectField
                                                        name="closeCall"
                                                        label="form.label.closeCallPresent"
                                                        entityType={
                                                            ENTITIES.CLOSE_CALL_TYPE
                                                        }
                                                        searchable
                                                        required
                                                    />
                                                </div>
                                                <div className="_12 _m4">
                                                    <InputField
                                                        name="closeCallPresentDescription"
                                                        required
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}
                            <Separator />

                            <div className="_w">
                                <span className="-mb20 _12 a-bodyTextRegular">
                                    {t('general.feedbackAndRating')}
                                </span>
                                <div className="_12">
                                    <RadioGroupField
                                        name="hideFeedback"
                                        label={hideFeedbackLabel}
                                        defaultOptions={YES_NO_BOOLEAN_OPTIONS}
                                        valueType={SELECT_VALUE_TYPE.STRING}
                                        tooltip="general.hideFeedbackTooltip"
                                        required
                                    />
                                </div>
                            </div>

                            <Separator />

                            {/* --- Notes ---------------------------------------------------------------------- */}

                            <div className="_w">
                                <span className="-mb20 _12 a-bodyTextRegular">
                                    {t('general.notesAndAttachments')}
                                </span>
                            </div>

                            <div className="_w">
                                <div className="_12">
                                    <TextAreaField name="notes" largeTextarea />
                                </div>
                            </div>
                            <div className="_w file-upload">
                                <FileUploadField
                                    name="files"
                                    label="form.label.diveSummaryFile"
                                    fileCategory={
                                        FILE_CATEGORIES.DIVE_RECORD_DIVE_SUMMARY
                                    }
                                    canDelete={true}
                                />
                            </div>
                        </div>
                        <StepButtons
                            nextlabel={
                                isRejected
                                    ? 'button.reSubmit'
                                    : projectId
                                    ? 'button.submit'
                                    : 'button.save'
                            }
                            backlabel="button.back"
                            skipLabel="button.saveAndContinueLater"
                            handleNext={handleSubmit}
                            handleSkip={() => handleSave(values, null, false)}
                            handleBack={() =>
                                handleSave(values, null, false, true)
                            }
                            canSkip
                            disableNext={isSubmitting}
                        />
                        {(isSubmitting || isLoading) && <Loader />}
                    </Form>
                )
            }}
        </Formik>
    )
}

export default DiveSummaryRecord
