import ENTITIES from 'constants/entities'

import LanguageForm from '../components/LanguageForm'
import DiverPositionForm from '../components/DiverPositionForm'
import PrimaryTrainingTypeCategoryForm from '../components/PrimaryTrainingTypeCategoryForm'
import TrainingTypeForm from '../components/TrainingTypeForm'
import SkillForm from '../components/SkillForm'
import CertificateIssuerForm from '../components/CertificateIssuerForm'

const CUSTOM_FORMS = {
    [ENTITIES.LANGUAGE]: { component: LanguageForm },
    [ENTITIES.DIVER_POSITION]: {
        component: DiverPositionForm,
        include: 'diverPositionCategory',
    },
    [ENTITIES.CERTIFICATE_ISSUER]: {
        component: CertificateIssuerForm,
    },
    [ENTITIES.PRIMARY_TRAINING_TYPE_CATEGORY]: {
        component: PrimaryTrainingTypeCategoryForm,
        include: 'primaryTrainingType',
    },
    [ENTITIES.TRAINING_TYPE]: {
        component: TrainingTypeForm,
        include: 'primaryTrainingType,primaryTrainingTypeCategory',
    },
    [ENTITIES.SKILL]: {
        component: SkillForm,
        include: 'skillCategories',
    },
}

export { CUSTOM_FORMS }
