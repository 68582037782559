import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'

import { deleteEntityService } from 'services/entity.service'

import {
    formatEntityCreator,
    formatEntitySourceText,
} from 'utils/tableCellFormatters'
import { formatEntitySource } from 'utils/filterButtonFormatters'

import { ALERT_TYPES, BUTTON_STATUS } from 'constants/enums'
import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'

import ManagementLayout from '../components/ManagementLayout'
import { Table } from 'components/table'
import EntityBreadcrumbs from './components/EntityBreadcrumbs'
import EntityFilters from './components/EntityFilters'

const TABLE_HEADER = [
    {
        key: 'name',
        title: 'table.header.name',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        smallScreenHeader: true,
        sortable: true,
    },
    {
        key: 'source',
        title: 'table.header.source',
        sortKey: 'creator.id',
        showOnSmallScreens: true,
        sortable: true,
        formatter: formatEntitySourceText,
    },
    {
        key: 'creator',
        title: 'table.header.creator',
        sortKey: 'creator.firstName',
        showOnSmallScreens: true,
        sortable: true,
        formatter: formatEntityCreator,
    },
]

const filters = {
    name: { type: 'text' },
    source: { type: 'text', formatter: formatEntitySource },
}

const EntityManagement = ({ entityType, create }) => {
    const t = useTranslate()
    const { pathname } = useLocation()

    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)

    const {
        data,
        meta,
        isLoading,
        fetchDataAfterDeletion,
    } = useFetchDataByQueryParams(entityType, {
        include: 'creator',
    })

    const handleCreate = () => {
        navigate(`${pathname}${ROUTES.CREATE}`)
    }

    const handleEdit = ({ id }) => {
        navigate(`${pathname}${ROUTES.EDIT}/${id}`)
    }

    const handleDelete = async ({ id }) => {
        try {
            await deleteEntityService(entityType, id)
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            fetchDataAfterDeletion()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const entityName = t(
        `entityManagement.${entityType}_singular`
    ).toLowerCase()

    return (
        <ManagementLayout
            BreadcrumbsComponent={EntityBreadcrumbs}
            entityType={entityType}
        >
            <Table
                title={`entityManagement.${entityType}`}
                fullWidthTitle
                headerItems={TABLE_HEADER}
                data={data}
                totalItems={meta?.totalItems}
                isLoading={isLoading}
                searchable={true}
                searchKey="name"
                entityType={entityType}
                actions={
                    create
                        ? [
                              {
                                  handleAction: handleCreate,
                                  icon: ICONS.PLUS,
                                  iconColor: '#ffffff',
                                  label: 'button.createNew',
                              },
                          ]
                        : []
                }
                rowDropdownActions={[
                    {
                        handleAction: handleEdit,
                        icon: ICONS.EDIT,
                        label: 'button.edit',
                        btnClass: BUTTON_STATUS.TERTIARY,
                    },
                    create && {
                        handleAction: handleDelete,
                        icon: ICONS.DELETE,
                        iconColor: 'red',
                        label: 'button.delete',
                        title: `${t('general.deleteTitle')} ${entityName}`,
                        confirmationMessage: `${t(
                            'message.areYouSureDelete'
                        )} ${entityName}?`,
                        translateTitle: false,
                        translateMessage: false,
                        btnClass: BUTTON_STATUS.TERTIARY,
                        textColor: '-redText',
                    },
                ].filter((item) => !!item)}
                FilterComponent={EntityFilters}
                filters={filters}
                filtersKey={`${entityType}_management_filters`}
            />
        </ManagementLayout>
    )
}

export default EntityManagement
