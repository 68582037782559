import React from 'react'
import PropTypes from 'prop-types'
import { Form, Formik } from 'formik'

import useQueryParams from 'hooks/useQueryParams'

import { translateRoleOptions } from 'utils/translateOptionsHelper'

import ENTITIES from 'constants/entities'

import Modal from 'components/Modal'
import { DateTimeField, InputField, SelectField } from 'components/formFields'
import { getSelectedDate } from '../../../../services/moment.service'
import { useTranslate } from 'react-polyglot'
import Separator from '../../../../components/Separator'
import { SELECT_VALUE_TYPE } from '../../../../constants/enums'

const IndividualAccountFilters = ({ open, setOpen, activeFilters }) => {
    const { addFilterParams } = useQueryParams()
    const t = useTranslate()

    const USER_SUBSCRIPTION_TYPES = [
        {
            id: 1,
            name: t('billing.subscriptionType.1'),
        },
        {
            id: 2,
            name: t('billing.subscriptionType.2'),
        },
    ]

    const emptyValues = {
        firstName: '',
        lastName: '',
        currentTitle: '',
        role: '',
        email: '',
        userSubscription: null,
        registeredAt: { after: null, before: null },
    }

    const initialValues = {
        firstName: activeFilters.firstName ?? emptyValues.firstName,
        lastName: activeFilters.lastName ?? emptyValues.lastName,
        currentTitle: activeFilters.currentTitle ?? emptyValues.currentTitle,
        role: activeFilters.role ?? emptyValues.role,
        email: activeFilters.email ?? emptyValues.email,
        registeredAt: activeFilters.registeredAt ?? emptyValues.registeredAt,
        userSubscription:
            activeFilters.userSubscription ?? emptyValues.userSubscription,
    }

    const handleFilter = (filters) => {
        addFilterParams(filters)
        setOpen(false)
    }

    const handleReset = (formFields, setFieldValue, setTouched) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, emptyValues[key])
        })
        setTouched({})
    }

    return (
        <Formik initialValues={initialValues} onSubmit={handleFilter}>
            {({ values, isSubmitting, setFieldValue, setTouched }) => (
                <Form>
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="general.filterUsers"
                        isSubmitting={isSubmitting}
                        values={values}
                        smallModalWithDropdowns
                        buttons={{
                            middleBtn: {
                                handleClick: () =>
                                    handleReset(
                                        values,
                                        setFieldValue,
                                        setTouched
                                    ),
                            },
                            nextBtn: {
                                label: 'button.filter',
                            },
                        }}
                    >
                        <div className="_wr">
                            <div className="_w">
                                <div className="_12 _m6">
                                    <InputField name="firstName" />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="lastName" />
                                </div>
                                <div className="_12 _m6">
                                    <InputField
                                        name="currentTitle"
                                        label="form.label.title"
                                        placeholder="form.placeholder.title"
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <InputField name="email" />
                                </div>
                                <div className="_12 _m6">
                                    <SelectField
                                        name="role"
                                        entityType={ENTITIES.ROLE}
                                        translateItem={translateRoleOptions}
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <SelectField
                                        name="userSubscription"
                                        defaultOptions={USER_SUBSCRIPTION_TYPES}
                                        valueType={SELECT_VALUE_TYPE.OBJECT}
                                        translateItem={(data) =>
                                            data &&
                                            t(
                                                'billing.subscriptionType.' +
                                                    data.id
                                            )
                                        }
                                    />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('general.userRegistered')}
                                </span>
                                <div className="_12 _m6">
                                    <DateTimeField
                                        name="registeredAt.after"
                                        label="form.label.from"
                                        maxDate={
                                            values.registeredAt.before
                                                ? new Date(
                                                      values.registeredAt.before
                                                  )
                                                : null
                                        }
                                    />
                                </div>
                                <div className="_12 _m6 ">
                                    <DateTimeField
                                        name="registeredAt.before"
                                        label="form.label.to"
                                        minDate={
                                            values.registeredAt.after
                                                ? getSelectedDate(
                                                      values.registeredAt.after
                                                  )
                                                : null
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

IndividualAccountFilters.propTypes = {
    open: PropTypes.bool,
    setOpen: PropTypes.func,
}

export default IndividualAccountFilters
