import React, { useContext } from 'react'
import { useNavigate } from 'react-router'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import { getEntityFilters } from 'services/localStorage.service'

import { AlertContext } from 'contexts/AlertContext'

import { ALERT_TYPES, BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'

import { InputField } from 'components/formFields'
import Button from 'components/Button'
import Loader from 'components/Loader'
import Separator from 'components/Separator'

const EntityForm = ({
    initialData,
    title,
    handleSubmit,
    path,
    entityType,
    customInitialValues,
    customValidation,
    CustomFormFields,
    requestDataFormatter,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)

    const initialValues = customInitialValues || {
        name: initialData?.name ?? '',
    }

    const requiredMessage = t('form.error.required')

    const validation =
        customValidation ||
        Yup.object().shape({
            name: Yup.string().required(requiredMessage),
        })

    const onSubmit = async (formData, { setSubmitting }) => {
        try {
            setSubmitting(true)
            await handleSubmit(
                requestDataFormatter ? requestDataFormatter(formData) : formData
            )
            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
            handleBack()
        } catch (error) {
            setAlert(
                error,
                ALERT_TYPES.ERROR,
                error.response?.data?.message ? t : null
            )
        } finally {
            setSubmitting(false)
        }
    }

    const handleBack = () => {
        navigate(
            `/${path}${
                getEntityFilters(`${entityType}_management_filters`) || ''
            }`
        )
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validation}
        >
            {({ values, isSubmitting }) => (
                <Form>
                    <div className="_w">
                        <div className="_12 -mb20">
                            <span className="a-bodyTextMedium">{title}</span>
                        </div>
                        {CustomFormFields ? (
                            <CustomFormFields values={values} />
                        ) : (
                            <div className="_12 _l6">
                                <InputField
                                    name="name"
                                    label="form.label.entityName"
                                    placeholder="form.placeholder.entityName"
                                    required
                                />
                            </div>
                        )}
                        <Separator />
                    </div>
                    <div className="_w spaceBetweenTwoButtons">
                        <div className="_12 _s6 _m5 -backButton">
                            <Button
                                label="button.cancel"
                                onClick={handleBack}
                                btnClass={BUTTON_STATUS.SECONDARY}
                            />
                        </div>
                        <div className="_12 _s6 _m5 -nextButton">
                            <Button
                                label="button.save"
                                type={BUTTON_TYPE.SUBMIT}
                            />
                        </div>
                    </div>
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    )
}

export default EntityForm
