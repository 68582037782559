import React, { useContext, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ICONS from 'constants/icons'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_STATUS,
    COMPANY_USER_STATUS,
} from 'constants/enums'
import FILTERS from 'constants/filters'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import useQueryParams from 'hooks/useQueryParams'

import { MOMENT_FORMATS } from 'services/moment.service'
import { deleteEntityService, editEntityService } from 'services/entity.service'
import { getEntityFilters } from 'services/localStorage.service'

import { Table } from 'components/table'
import EmailCell from 'components/table/tableCells/EmailCell'
import DateCell from 'components/table/tableCells/DateCell'
import StatusCell from 'components/table/tableCells/StatusCell'
import TabNavigation from 'components/tabs/TabNavigation'
import InviteEmployeeForm from './InviteEmployeeForm'

const TABLE_COLUMNS = [
    {
        key: 'firstName',
        title: 'table.header.firstName',
        showOnSmallScreens: true,
        smallScreenFirstItem: true,
        sortable: false,
    },
    {
        key: 'lastName',
        title: 'table.header.lastName',
        showOnSmallScreens: true,
        sortable: false,
    },
    {
        key: 'email',
        title: 'table.header.emailAddress',
        showOnSmallScreens: true,
        smallScreenHeader: true,
        sortable: false,
        CellComponent: EmailCell,
    },
    {
        key: 'status',
        title: 'table.header.status',
        showOnSmallScreens: true,
        sortable: false,
        statuses: COMPANY_USER_STATUS,
        CellComponent: StatusCell,
    },
    {
        key: 'lastLogin',
        title: 'table.header.lastLogin',
        showOnSmallScreens: true,
        sortable: false,
        dateFormat: MOMENT_FORMATS.DATE,
        CellComponent: DateCell,
    },
]

const TAB = {
    EMPLOYEES: 'employees',
    INVITED: 'invited',
}

const UserManagement = () => {
    const t = useTranslate()

    const { tab, subTab } = useQueryParams()

    const { setAlert } = useContext(AlertContext)
    const { currentUser, isVerified } = useContext(CurrentUserContext)

    const [addModalOpened, setAddModalOpened] = useState(false)

    const {
        data: companyUsers,
        meta,
        isLoading,
        fetchData: fetchCompanyUsers,
        fetchDataAfterDeletion,
    } = useFetchDataByQueryParams(ENTITIES.COMPANY_USERS, {
        status:
            subTab === TAB.INVITED
                ? [
                      COMPANY_USER_STATUS.NEW.value,
                      COMPANY_USER_STATUS.REJECTED.value,
                  ]
                : [
                      COMPANY_USER_STATUS.ACTIVE.value,
                      COMPANY_USER_STATUS.BLOCKED.value,
                  ],
    })

    const handleActivate = async (item) => {
        await editStatus(item, COMPANY_USER_STATUS.ACTIVE.value)
    }

    const handleDeactivate = async (item) => {
        await editStatus(item, COMPANY_USER_STATUS.BLOCKED.value)
    }

    const handleDelete = async (item) => {
        try {
            await deleteEntityService(ENTITIES.COMPANY_USERS, item.id, {})
            fetchDataAfterDeletion()
        } catch (error) {
            setAlert(error.response.data.message, ALERT_TYPES.ERROR)
        }
    }

    const editStatus = async (item, status) => {
        try {
            await editEntityService(
                ENTITIES.COMPANY_USERS,
                item.id,
                {
                    status: status,
                },
                true
            )

            await fetchCompanyUsers()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const getUserName = ({ firstName, lastName, email }) => {
        return firstName && lastName ? `${firstName} ${lastName}` : email
    }

    const getActivateOrDeactivateAction = (item) => {
        const name = getUserName(item)

        return item.status === COMPANY_USER_STATUS.ACTIVE.value
            ? {
                  handleAction: handleDeactivate,
                  icon: ICONS.BLOCK,
                  label: 'button.block',
                  title: 'general.blockUser',
                  confirmationMessage: `${t(
                      'message.areYouSureBlock'
                  )} ${name}?`,
              }
            : {
                  handleAction: handleActivate,
                  icon: ICONS.CHECKMARK_ICON_ROUNDED,
                  label: 'button.activate',
                  title: 'general.activateUser',
                  confirmationMessage: `${t(
                      'message.areYouSureActivate'
                  )} ${name}?`,
              }
    }

    const getDeleteConfirmationMessage = (item) => {
        const name = getUserName(item)
        const listName =
            subTab === TAB.INVITED
                ? t('message.fromInvitedUsers')
                : t('message.fromEmployees')

        return `${t('message.areYouSureRemove')} ${name} ${listName}`
    }

    return (
        <>
            <h6 className="a-bodyTextMedium -mb20">
                {t('general.userManagement')}
            </h6>
            <TabNavigation
                tabs={[
                    {
                        key: TAB.EMPLOYEES,
                        title: 'general.employees',
                        queryParams: getEntityFilters(
                            FILTERS.COMPANY_EMPLOYEES
                        ),
                    },
                    {
                        key: TAB.INVITED,
                        title: 'general.invitedUsers',
                        queryParams:
                            getEntityFilters(FILTERS.COMPANY_INVITED_USERS) ||
                            `?tab=${tab}`,
                    },
                ]}
                tabQueryParam="subTab"
            />
            <Table
                headerItems={TABLE_COLUMNS}
                data={companyUsers}
                totalItems={meta?.totalItems}
                isLoading={isLoading}
                searchable={true}
                searchPlaceholder="general.searchForUser"
                fullWidthTitle
                actions={
                    isVerified
                        ? [
                              {
                                  handleAction: () => {
                                      setAddModalOpened(true)
                                  },
                                  icon: ICONS.PLUS,
                                  iconColor: '#ffffff',
                                  label: 'button.addUser',
                              },
                          ]
                        : []
                }
                rowDropdownActions={[
                    {
                        switchableAction: getActivateOrDeactivateAction,
                        btnClass: BUTTON_STATUS.TERTIARY,
                        isDisabled: (item) =>
                            [
                                COMPANY_USER_STATUS.NEW.value,
                                COMPANY_USER_STATUS.REJECTED.value,
                            ].includes(item.status) ||
                            item.user?.id === currentUser.id,
                        translateMessage: false,
                    },
                    {
                        handleAction: handleDelete,
                        icon: ICONS.DELETE,
                        label: 'button.delete',
                        title:
                            subTab === TAB.INVITED
                                ? 'general.removeInvitedUser'
                                : 'general.removeEmployee',
                        customConfirmationMessage: getDeleteConfirmationMessage,
                        btnClass: BUTTON_STATUS.TERTIARY,
                        iconColor: 'red',
                        textColor: '-redText',
                        translateMessage: false,
                        isDisabled: (item) => item.user?.id === currentUser.id,
                    },
                ]}
                filtersKey={
                    subTab === TAB.INVITED
                        ? FILTERS.COMPANY_INVITED_USERS
                        : FILTERS.COMPANY_EMPLOYEES
                }
            />
            {addModalOpened && (
                <InviteEmployeeForm
                    saveRoute={ENTITIES.COMPANY_USERS + '/bulk'}
                    setModalOpened={setAddModalOpened}
                    refetchData={async () => {
                        await fetchCompanyUsers()
                    }}
                />
            )}
        </>
    )
}

export default UserManagement
