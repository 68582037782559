import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import React, { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { createEntityService } from 'services/entity.service'

import isEmail from 'validator/lib/isEmail'

import { AlertContext } from 'contexts/AlertContext'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'

import Loader from 'components/Loader'
import { MultiselectField } from 'components/formFields'
import FocusError from 'components/FocusError'
import Modal from 'components/Modal'
import CustomEmailListItem from 'components/customMultiSelectComponents/CustomEmailListItem'
import CustomUserOption from 'components/customMultiSelectComponents/CustomUserOption'
import CustomUserItem from 'components/customMultiSelectComponents/CustomUserItem'
import Note from 'components/Note'

const InviteEmployeeForm = ({ saveRoute, setModalOpened, refetchData }) => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        divers: [],
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        invitedUsers: Yup.array()
            .min(1, requiredMessage)
            .required(requiredMessage),
    })

    const onSubmit = async ({ invitedUsers }, { setSubmitting, resetForm }) => {
        try {
            setSubmitting(true)
            const { detail } = await createEntityService(
                saveRoute,
                {
                    invitedUsers: invitedUsers.map((user) => {
                        if (user.id) {
                            return {
                                id: user.id,
                            }
                        }
                        return {
                            email: user,
                        }
                    }),
                },
                false
            )
            setAlert(t(detail), ALERT_TYPES.SUCCESS)
            resetForm({})
            handleClose()
            if (refetchData) refetchData()
        } catch (error) {
            setAlert(
                error,
                ALERT_TYPES.ERROR,
                error.response?.data?.message ? t : null
            )
        } finally {
            setSubmitting(false)
        }
    }

    const handleClose = () => {
        setModalOpened(false)
    }

    const renderCustomOption = (item) => {
        return <CustomUserOption item={item} />
    }

    const renderCustomItem = (item) => {
        return <CustomUserItem item={item} />
    }

    const renderCustomListItem = (search, data, handleSelect, setOpen) => {
        const isValidEmail = isEmail(search)

        const noResultText = `${t(
            'general.noResultForInviteByName'
        )} “${search}”. ${t('general.inviteUserByEmail')}`

        if (data.length === 0) {
            return (
                <CustomEmailListItem
                    title={!!isValidEmail ? search : noResultText}
                    email={!!isValidEmail ? search : ''}
                    handleSelect={handleSelect}
                    setOpen={setOpen}
                />
            )
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validation}
        >
            {({ isSubmitting }) => (
                <Form>
                    <FocusError />
                    <Modal
                        open={true}
                        setOpen={setModalOpened}
                        closeOnClickOutside={false}
                        title={t('general.invite')}
                        isSubmitting={isSubmitting}
                        smallModal
                        buttons={{
                            prevBtn: {
                                handleClick: handleClose,
                            },
                            nextBtn: {
                                label: 'button.sendInvitation',
                            },
                        }}
                        smallModalWithDropdowns
                        noTranslateTitle
                    >
                        <Note note="features.newUserInviteNote" />

                        <div className="_12">
                            <MultiselectField
                                name="invitedUsers"
                                label={'form.label.divers'}
                                placeholder={'form.placeholder.divers'}
                                entityType={ENTITIES.DIVERS_ELASTIC}
                                displayAttribute="fullName"
                                required
                                searchable
                                renderCustomOption={renderCustomOption}
                                renderCustomItem={renderCustomItem}
                                renderCustomListItem={renderCustomListItem}
                            />
                        </div>
                        {isSubmitting && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

InviteEmployeeForm.propTypes = {
    saveRoute: PropTypes.string,
    setModalOpened: PropTypes.func,
    refetchData: PropTypes.func,
}

export default InviteEmployeeForm
