import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import COLORS from 'constants/colors'
import ICONS from 'constants/icons'
import { COMPANY_TYPE, ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'
import { Link } from 'react-router'

const AboutCompany = ({ company }) => {
    const t = useTranslate()

    const {
        hqAddress,
        email,
        size,
        industries,
        services,
        vessels,
        clients,
        about,
        locationOfServices,
        organizationTypes,
        hqCountry,
        tinVat,
        fullPhoneNumber,
        website,
        companyType,
        assetsAndEquipment,
    } = company

    const isClientOrganization = companyType === COMPANY_TYPE.CLIENT_COMPANY

    return (
        <div className="m-userMainInfo">
            <div className="m-userMainInfo__about">
                <div className="aligned-center  -mb20">
                    <span className="a-bodyTextMedium ">
                        {t('general.aboutCompany')}
                    </span>
                </div>
                <div className="-aboutMaininfo -diverProfile">
                    <div className="aligned-center -gap10 fullWidth flex-wrap">
                        <span className="aligned-center -gap5 a-mediumText a-lightText">
                            <Icon
                                name={ICONS.LOCATION}
                                size={ICON_SIZE.SIZE20}
                                color={COLORS.DARK_BLUE_20}
                            />
                            {hqCountry
                                ? hqCountry?.name + ', ' + hqAddress
                                : hqAddress}
                        </span>
                        {/* NOTE: This id temporarily removed */}
                        {/* <div className="aligned-center -gap5">
                            <Icon
                                name={ICONS.MAIL}
                                size={ICON_SIZE.SIZE20}
                                color={COLORS.DARK_BLUE_20}
                            />
                            <Link
                                className="a-mediumText a-lightText -opacity-60"
                                to=""
                                onClick={() =>
                                    (window.location = `mailto:${email}`)
                                }
                                title={email}
                            >
                                {email}
                            </Link>
                        </div> */}
                        {/* {fullPhoneNumber && (
                            <div className="aligned-center -gap5 -phoneNumberWithTooltip">
                                <Icon
                                    name={ICONS.PHONE}
                                    size={ICON_SIZE.SIZE20}
                                    color={COLORS.DARK_BLUE_20}
                                />
                                <Link
                                    className="a-mediumText a-lightText -opacity-60"
                                    title={fullPhoneNumber}
                                    onClick={() =>
                                        (window.location = `tel:${email}`)
                                    }
                                >
                                    {fullPhoneNumber}
                                </Link>
                                <span className="-tooltip a-captionsTextRegular">
                                    {t('general.corporatePhoneNumberTooltip')}
                                </span>
                            </div>
                        )} */}
                        {website && (
                            <div className="aligned-center -gap5">
                                <Icon
                                    name={ICONS.WEBSITE}
                                    size={ICON_SIZE.SIZE20}
                                    color={COLORS.DARK_BLUE_20}
                                />
                                <Link
                                    className="a-mediumText a-lightText -opacity-60"
                                    to={
                                        website.startsWith('http')
                                            ? website
                                            : '//' + website
                                    }
                                    target="blank"
                                    title={website}
                                >
                                    {website}
                                </Link>
                            </div>
                        )}
                    </div>
                    {/* <div className="column">
                        <span className="a-mediumText a-lightText -opacity-60">
                            {t('general.hqLocation')}
                        </span>
                        <span className="a-mediumText aligned-start -mt10">
                            <Icon
                                name={ICONS.LOCATION}
                                color={COLORS.DARK_BLUE_20}
                                size={ICON_SIZE.SIZE20}
                            />
                            {hqCountry
                                ? hqCountry?.name + ', ' + hqAddress
                                : hqAddress}
                        </span>
                    </div> */}
                    {/* <div className="column">
                        <span className="a-mediumText a-lightText -opacity-60">
                            {t('general.contactPerson')}
                        </span>
                        <span className="a-mediumText aligned-start -mt10">
                            <Icon
                                name={ICONS.USER}
                                color={COLORS.DARK_BLUE_20}
                            />
                            {user.fullName}
                        </span>
                    </div> */}
                </div>

                {about && (
                    <div className="-mt20">
                        <span className="a-mediumText a-lightText -opacity-60">
                            {t('general.about')}
                        </span>
                        <p className="a-mediumText -mt10 a-lightText -about">
                            {about}
                        </p>
                    </div>
                )}
                {assetsAndEquipment && (
                    <div className="-mt20">
                        <span className="a-mediumText a-lightText -opacity-60">
                            {t('general.assetsAndEquipment')}
                        </span>
                        <p className="a-mediumText -mt10 a-lightText -about">
                            {assetsAndEquipment}
                        </p>
                    </div>
                )}
                <div className="-rowInformation -mt20 aligned-center -mt20 -mb20">
                    {size && (
                        <div className="aligned-center -companySize -rowElement -gap5">
                            <span className="a-mediumText a-lightText -opacity-60">
                                {t('general.companySize')}:
                            </span>
                            <span className="a-mediumTextSemiBold">
                                {size?.name}
                            </span>
                        </div>
                    )}
                    {/* {tinVat && (
                        <div className="aligned-center -rowElement -gap5 -tinVatWithTooltip">
                            <span className="a-mediumText a-lightText -opacity-60">
                                {t('general.tinVat')}:
                            </span>
                            <span className="a-mediumTextSemiBold">
                                {tinVat}
                            </span>
                            <span className="-tooltip a-captionsTextRegular">
                                {t('general.tinVatTooltip')}
                            </span>
                        </div>
                    )} */}
                </div>

                {industries?.length > 0 && (
                    <div className="column -industry -mb20">
                        <span className="a-mediumText a-lightText -opacity-60 -mb10">
                            {t('general.industryYourOrganizationServes')}
                        </span>
                        <div className="listOfStatuses">
                            {industries?.map((item, index) => {
                                return (
                                    <span
                                        key={index}
                                        className="a-status -darkYellow a-captionsTextRegular"
                                    >
                                        {item.name}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                )}
                {organizationTypes?.length > 0 && (
                    <div className="column">
                        <span className="a-mediumText a-lightText -opacity-60 -mb10">
                            {t('general.categories')}
                        </span>
                        <div className="listOfStatuses">
                            {organizationTypes?.map((category, index) => {
                                return (
                                    <span
                                        key={index}
                                        className="a-status -green a-captionsTextRegular"
                                    >
                                        {category.name}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                )}

                {services && (
                    <div className="column">
                        <span className="a-mediumText a-lightText -opacity-60 -mt20 -mb10">
                            {t('general.familyOfProvidedServices')}
                        </span>
                        <div className="listOfStatuses">
                            {services?.map((item, index) => {
                                return (
                                    <span
                                        key={index}
                                        className="a-status -purple a-captionsTextRegular"
                                    >
                                        {item.name}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                )}

                {locationOfServices?.length > 0 && (
                    <div className="column">
                        <span className="a-mediumText a-lightText -opacity-60 -mt20 -mb10">
                            {t(
                                isClientOrganization
                                    ? 'form.label.locationsOfOperations'
                                    : 'form.label.locationsOfServices'
                            )}
                        </span>
                        <div className="listOfStatuses">
                            {locationOfServices?.map(
                                (locationOfService, index) => {
                                    return (
                                        <span
                                            key={index}
                                            className="a-status -green a-captionsTextRegular"
                                        >
                                            {locationOfService.name}
                                        </span>
                                    )
                                }
                            )}
                        </div>
                    </div>
                )}

                {vessels?.length > 0 && (
                    <div className="column">
                        <span className="a-mediumText a-lightText -opacity-60 -mt20 -mb10">
                            {t('general.vessels')}
                        </span>
                        <div className="listOfStatuses">
                            {vessels?.map((vessel, index) => {
                                return (
                                    <span
                                        key={index}
                                        className="a-status -gray a-captionsTextRegular"
                                    >
                                        {vessel.name}
                                    </span>
                                )
                            })}
                        </div>
                    </div>
                )}

                {clients?.length > 0 && (
                    <div className="m-userMainInfo__about -mt15">
                        <div className="column -gap10">
                            <span className="a-bodyTextMedium">
                                {t('general.clients')}
                            </span>
                            <div className="listOfStatuses">
                                {clients?.map((client, index) => {
                                    return (
                                        <span
                                            key={index}
                                            className="aligned-center -gap5 a-status -grayWithBcg -clients a-captionsTextRegular"
                                        >
                                            <div className="-clientsBcg">
                                                <Icon
                                                    name={ICONS.BUILDING}
                                                    size={ICON_SIZE.SIZE12}
                                                    color={COLORS.DARK_BLUE_60}
                                                />
                                            </div>
                                            {client.name}
                                        </span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

AboutCompany.propTypes = {
    company: PropTypes.object,
}

export default AboutCompany
