import PropTypes from 'prop-types'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const ProfileImageGuidelines = ({ profileImageGuidelinesObject }) => {
    return (
        <div className="fullWidth column">
            <span className="a-bodyTextMedium">
                {profileImageGuidelinesObject?.mainTitle}
            </span>
            <span className="a-mediumText a-lightText -mt5">
                {profileImageGuidelinesObject.mainDescription}
            </span>
            <div className="column -gap20 -mt20">
                {profileImageGuidelinesObject.sections.map((section, index) => (
                    <div key={index} className="column">
                        <span className="a-mediumTextSemiBold">
                            {section.sectionTitle}{' '}
                        </span>
                        <span className="a-mediumText -mt5">
                            <span className="a-lightText">
                                {section.description}{' '}
                            </span>
                        </span>
                        {section.sectionList?.map((list, index) => (
                            <li className="a-mediumText -liDisc " key={index}>
                                <span className="a-mediumTextSemiBold">
                                    {list.boldText}{' '}
                                </span>
                                <span className="a-lightText">{list.text}</span>
                            </li>
                        ))}
                        {section.subSections?.map((subSection, index) => (
                            <div
                                className="column a-lightText -mt10"
                                key={index}
                            >
                                <span className="a-mediumTextSemiBold -mb5">
                                    {subSection.title}
                                </span>
                                {subSection.sectionList?.map((list, index) => {
                                    return (
                                        <li
                                            className={`a-mediumText -liDisc ${
                                                list.icon
                                                    ? 'aligned-start -gap5'
                                                    : ''
                                            }`}
                                            key={index}
                                        >
                                            {list.icon && (
                                                <div className="min-content-width">
                                                    <Icon
                                                        name={list.icon}
                                                        color={
                                                            list.icon ===
                                                            ICONS.CHECKMARK_ICON_ROUNDED_FILL
                                                                ? COLORS.GREEN_80
                                                                : COLORS.RED_80
                                                        }
                                                        size={ICON_SIZE.SIZE20}
                                                    />
                                                </div>
                                            )}
                                            <span>
                                                <span className="a-mediumTextSemiBold">
                                                    {list.boldText}{' '}
                                                </span>
                                                <span className="a-lightText">
                                                    {list.text}
                                                </span>
                                            </span>
                                        </li>
                                    )
                                })}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

ProfileImageGuidelines.propTypes = {
    profileImageGuidelinesObject: PropTypes.object,
}

export default ProfileImageGuidelines
