import React, { useState } from 'react'
import { useParams } from 'react-router'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import ENTITIES from 'constants/entities'

import useFetchData from 'hooks/useFetchData'

import AddNew from '../../../DiverOnboarding/AddNew'
import BellRunEventList from './BellRunEventList'
import BellRunEventFormModal from './BellRunEventFormModal'

const BellRunEventWrapper = ({
    diveRecordDiveDataId,
    hideActions,
    unitImperial,
    fetchDiveRecord,
    diveRecordDiveData,
}) => {
    const t = useTranslate()
    const { diveRecordId } = useParams()

    const [create, setCreate] = useState(false)
    const [editItem, setEditItem] = useState(null)

    const {
        data: bellRunEventData,
        isLoading,
        fetchData: fetchDiveData,
    } = useFetchData(
        ENTITIES.BELL_RUN_EVENT,
        {
            'diveRecordDiveData.id': diveRecordDiveDataId,
            include: 'bellRunDivePosition',
        },
        !!diveRecordId,
        false
    )

    const data = diveRecordId
        ? bellRunEventData
        : diveRecordDiveData.bellRunEvents

    const fetchData = () => {
        fetchDiveData()
        fetchDiveRecord()
    }

    if (hideActions)
        return (
            <BellRunEventList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                setEditItem={setEditItem}
                hideActions={hideActions}
                unitImperial={unitImperial}
            />
        )

    return (
        <div className="-contentElements">
            <h2 className="-mt35 _12 a-centeredText">
                {t('general.bellRunEvent')}
            </h2>

            <p className="a-bodyTextRegular -mt20 _12 a-centeredText">
                {t('general.pleaseAddBellRunEvent')}
            </p>

            <div className="_12">
                <AddNew
                    label="general.addBellRunEvent"
                    handleAction={() => setCreate(true)}
                />
            </div>

            <BellRunEventList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                setEditItem={setEditItem}
                unitImperial={unitImperial}
            />

            {create && (
                <BellRunEventFormModal
                    closeModal={() => setCreate(false)}
                    fetchData={fetchData}
                />
            )}

            {!!editItem && (
                <BellRunEventFormModal
                    closeModal={() => setEditItem(null)}
                    initialData={editItem}
                    fetchData={fetchData}
                />
            )}
        </div>
    )
}

BellRunEventWrapper.propTypes = {
    diveRecordDiveDataId: PropTypes.number.isRequired,
    hideActions: PropTypes.bool,
    unitImperial: PropTypes.bool,
    fetchDiveRecord: PropTypes.func,
}

export default BellRunEventWrapper
