import React, { Fragment, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import { getEntityService } from 'services/entity.service'

import renderLoader from '../../utils/loaderHelper'

import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'

import AddNew from '../../AddNew'
import DivingModeExperienceList from './DivingModeExperienceList'
import AddDivingModeModal from './AddDivingModeModal'
import NoData from 'components/NoData'

const DivingModeExperienceWrapper = ({
    data,
    isLoading,
    fetchData,
    showActions,
    refetchData,
}) => {
    const t = useTranslate()

    const [addDiveMode, setAddDiveMode] = useState(false)
    const [divingModes, setDivingModes] = useState([])

    useEffect(() => {
        getEntityService(ENTITIES.DIVING_MODE, { pagination: false }).then(
            (response) => {
                setDivingModes(response.data)
            }
        )
    }, [])

    const enabledDivingModes = divingModes.filter(
        (mode) => !data.some((item) => item?.divingMode?.id === mode.id)
    )

    return (
        <Fragment>
            {showActions && (
                <AddNew
                    handleAction={() => {
                        setAddDiveMode(true)
                    }}
                    label="general.addDivingModeExperience"
                />
            )}
            {data.length === 0 && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noDataYet')}
                    description={t(
                        showActions
                            ? 'general.clickThePlusButton'
                            : 'general.nothingHereRightNow'
                    )}
                />
            )}
            {addDiveMode && (
                <AddDivingModeModal
                    open={addDiveMode}
                    setOpen={setAddDiveMode}
                    fetchData={fetchData}
                    refetchData={refetchData}
                    divingModes={enabledDivingModes}
                    showActions={showActions}
                />
            )}
            <DivingModeExperienceList
                data={data}
                isLoading={isLoading}
                fetchData={fetchData}
                showActions={showActions}
                refetchData={refetchData}
            />
            {renderLoader(isLoading, true)}
        </Fragment>
    )
}

DivingModeExperienceWrapper.propTypes = {
    data: PropTypes.array,
    isLoading: PropTypes.bool,
    fetchData: PropTypes.func,
    showActions: PropTypes.bool,
    refetchData: PropTypes.func,
}

DivingModeExperienceWrapper.defaultProps = {
    showActions: false,
}

export default DivingModeExperienceWrapper
