import useWindowDimensions from 'hooks/useWindowDimension'
import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Outlet } from 'react-router'

const NoAuthLayout = ({ children }) => {
    const t = useTranslate()
    const { isSmallScreen } = useWindowDimensions()

    return (
        <div className="m-noAuth">
            <div className="m-noAuth__image">
                <div className="m-noAuth__image--title">
                    <div className="m-noAuth__image--icon"></div>
                    <div>
                        <p className="a-bodyTextMedium -welcome">
                            {t('noAuth.welcome')}
                        </p>
                        <h1>{t('noAuth.welcomeDescription')}</h1>
                    </div>
                    {isSmallScreen && (
                        <div>
                            <p className="a-bodyTextMedium -mt15 -description">
                                {t('noAuth.welcomeDescription')}
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className="m-noAuth__form">
                <div className="-container">
                    <div></div>
                    <div className="m-noAuth__formField">
                        {children || <Outlet />}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NoAuthLayout
