import PropTypes from 'prop-types'
import {
    Fragment,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useField } from 'formik'
import { useTranslate } from 'react-polyglot'
import { useDropzone } from 'react-dropzone'
import heic2any from 'heic2any'

import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { AlertContext } from 'contexts/AlertContext'

import useFetchFile from 'hooks/useFetchFile'

import axios, { POST } from 'utils/axiosClient'
import { formatResponseData } from 'utils/jsonApiFormatters'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { ALERT_TYPES, AVAILABILITY, ICON_SIZE } from 'constants/enums'

import FieldError from 'components/FieldError'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import PlaceholderImageIcon from 'components/PlaceholderImageIcon'

const ProfilePictureUploadField = ({
    name,
    isJSONAPI,
    apiUrl,
    reload,
    disabled,
    signUp,
    profilePage,
    availability,
    companyProfile,
    fileCategory,
}) => {
    const t = useTranslate()

    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )

    const [openProfileImageDropdown, setOpenProfileImageDropdown] = useState(
        false
    )

    const hamBtnRef = useRef(null)

    const [field, meta, helpers] = useField(name)

    const { value } = field
    const { setValue } = helpers
    const [uploaded, setUploaded] = useState(value)

    const { fileUrl } = useFetchFile(uploaded?.path)

    const error = meta.touched && meta.error
    const canDelete = !!uploaded

    const handleClickOutside = (event) => {
        if (!hamBtnRef.current?.contains(event.target)) {
            setOpenProfileImageDropdown(false)
        }
    }

    const renderStatusClassName = (userStatus) => {
        switch (userStatus) {
            case AVAILABILITY.AVAILABLE_FOR_HIRE:
                return '-available'
            case AVAILABILITY.UNAVAILABLE:
                return '-unavailable'
            case AVAILABILITY.EMPLOYED_FULL_TIME:
                return '-employed'
            case AVAILABILITY.CONTRACTED_FREELANCER:
                return '-freelancer'
            default:
                return '-noStatus'
        }
    }

    const handleDeleteModal = (e) => {
        showConfirmationModal({
            title: 'general.removeProfilePhoto',
            message: 'general.areYouSureRemoveProfilePhoto',
            handleConfirm: () => handleDeleteSingleItem(e),
            handleCancel: () => closeConfirmationModal(),
        })
    }

    const handleDeleteSingleItem = (e) => {
        e.stopPropagation()
        setUploaded(null)
        closeConfirmationModal()
    }

    const onDrop = useCallback(
        async (acceptedFiles) => {
            for (const element of acceptedFiles) {
                if (element.type.startsWith('image/')) {
                    const formData = new FormData()
                    let acceptedData = element
                    let fileName = element.name

                    if (['image/heic', 'image/heif'].includes(element.type)) {
                        // Convert .heic image to .jpg format
                        const convertedImage = await heic2any({
                            blob: element,
                            toType: 'image/jpeg', // Convert to JPEG format
                        })
                        acceptedData = convertedImage
                        fileName = element.name.replace('.heic', '.jpeg')
                    }

                    formData.append('file', acceptedData, fileName)
                    formData.append('category', fileCategory)
                    axios(
                        POST,
                        `/${ENTITIES.FILE}/upload`,
                        formData,
                        isJSONAPI,
                        apiUrl
                    )
                        .then(async ({ data }) => {
                            let file = data

                            if (isJSONAPI) {
                                const {
                                    data: uploadedFile,
                                } = formatResponseData(data)
                                file = uploadedFile
                            }

                            setUploaded(file)
                        })
                        .catch((error) => {
                            setAlert(error, ALERT_TYPES.ERROR, t)
                        })
                } else {
                    setAlert(t('message.imageFiles'), ALERT_TYPES.ERROR)
                }
            }
        },
        [setValue]
    )

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        disabled,
    })

    const displayEditButton = () => (
        <Fragment>
            <div className="-imageButton">
                <IconButton
                    icon={ICONS.EDIT_USER_PROFILE}
                    onClick={(e) => {
                        e.preventDefault()
                        setOpenProfileImageDropdown(!openProfileImageDropdown)
                    }}
                />
                <div className="-tooltip a-captionsTextRegular">
                    {t('general.editPhoto')}
                </div>
            </div>
            <ul
                ref={hamBtnRef}
                className={`m-avatar__popup ${
                    openProfileImageDropdown ? '-open' : ''
                } `}
            >
                <li {...getRootProps()} className="m-avatar__item a-mediumText">
                    <input {...getInputProps()} />
                    <Icon
                        name={ICONS.UPLOAD}
                        color={COLORS.LIGHT_BLUE}
                        size={ICON_SIZE.SIZE22}
                    />
                    {t('button.uploadNewPhoto')}
                </li>
                <li
                    className={`${
                        !canDelete ? '-disabled' : ''
                    } m-avatar__item a-mediumText`}
                    onClick={(e) =>
                        uploaded && canDelete ? handleDeleteModal(e) : ''
                    }
                >
                    <Icon
                        name={ICONS.DELETE}
                        color={COLORS.LIGHT_BLUE}
                        size={ICON_SIZE.SIZE22}
                    />
                    {t('button.removeCurrentPhoto')}
                </li>
            </ul>
        </Fragment>
    )

    const displayAddButton = () => (
        <div className="-imageButton">
            <input {...getInputProps()} />
            <div {...getRootProps()} className="-plus">
                <Icon
                    name={ICONS.PLUS}
                    color={COLORS.WHITE}
                    size={ICON_SIZE.SIZE20}
                />
            </div>
            <div className="-tooltip a-captionsTextRegular">
                {t('button.addPhoto')}
            </div>
        </div>
    )

    const displayProfileImage = () => (
        <Fragment>
            <div className="m-profilePictureUploader--placeholder">
                <div className="-placeholderImage">
                    <PlaceholderImageIcon companyProfile={companyProfile} />
                </div>
            </div>
            <div
                className={`m-profilePictureUploader--image ${
                    uploaded?.path ? '-hasImage' : ''
                }`}
                {...(uploaded?.path && {
                    style: {
                        backgroundImage: `url(${fileUrl})`,
                    },
                })}
            >
                {availability && (
                    <div
                        className={`-status ${renderStatusClassName(
                            availability
                        )}`}
                    ></div>
                )}
                {!uploaded ? displayAddButton() : displayEditButton()}
            </div>
        </Fragment>
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    useEffect(() => {
        setValue(uploaded)
        setOpenProfileImageDropdown(false)
    }, [uploaded])

    useEffect(() => {
        setUploaded(value)
    }, [reload])

    return (
        <div className={`${profilePage ? '_12 _m3 column' : 'fullWidth'}`}>
            <div
                className={`m-addAndUpload m-profilePictureUploader ${
                    disabled ? '-disabled' : ''
                } ${signUp ? '-signUp' : ''}`}
            >
                {displayProfileImage()}
                {error && (
                    <FieldError
                        className="errorMessage _12 -mt10"
                        error={error}
                    />
                )}
            </div>
        </div>
    )
}

ProfilePictureUploadField.propTypes = {
    name: PropTypes.string.isRequired,
    isJSONAPI: PropTypes.bool,
    apiUrl: PropTypes.string,
    reload: PropTypes.bool,
    disabled: PropTypes.bool,
    signUp: PropTypes.bool,
    profilePage: PropTypes.bool,
    availability: PropTypes.string,
    companyProfile: PropTypes.bool,
    fileCategory: PropTypes.string.isRequired,
}

ProfilePictureUploadField.defaultProps = {
    isJSONAPI: true,
    disabled: false,
    profilePage: false,
    companyProfile: false,
}

export default ProfilePictureUploadField
