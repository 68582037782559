import React, { useContext } from 'react'
import { useParams } from 'react-router'
import { useTranslate } from 'react-polyglot'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES, DIVE_RECORD_STATUS } from 'constants/enums'

import { DiveRecordContext } from '../contexts/DiveRecordContext'
import { AlertContext } from 'contexts/AlertContext'

import { persistEntityService } from 'services/entity.service'

import TextAreaField from 'components/formFields/TextAreaField'
import Modal from 'components/Modal'
import FocusError from '../../../../components/FocusError'
import Loader from 'components/Loader'

const RecordVerificationRejectModal = ({
    setOpen,
    setSubmitted,
    submitted,
}) => {
    const t = useTranslate()
    const { diveRecordId } = useParams()

    const { setAlert } = useContext(AlertContext)
    const { goToProjectPage } = useContext(DiveRecordContext)

    const initialValues = {
        rejectComment: '',
    }

    const validation = Yup.object().shape({
        rejectComment: Yup.string().required(t('form.error.required')),
    })

    const handleSubmit = async (formData) => {
        try {
            setSubmitted(true)
            await persistEntityService(
                ENTITIES.DIVE_RECORD,
                {
                    ...formData,
                    status: DIVE_RECORD_STATUS.REJECTED.id,
                },
                diveRecordId
            )
            goToProjectPage()
            setAlert(t('message.diveRecordRejected'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitted(false)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit, values, isSubmitting }) => (
                <Form>
                    <Modal
                        open={true}
                        setOpen={setOpen}
                        closeOnClickOutside={false}
                        title="message.rejectDiveRecordTitle"
                        isSubmitting={isSubmitting || submitted}
                        smallModal
                        buttons={{
                            nextBtn: {
                                label: 'button.rejectDiveRecord',
                                handleClick: () => handleSubmit(values),
                            },
                        }}
                    >
                        <FocusError />
                        <span className="_12">
                            {t('message.rejectDiveRecordDescription')}
                        </span>

                        <div className="_12 -mt30">
                            <TextAreaField
                                name="rejectComment"
                                label="form.label.comment"
                                placeholder="form.placeholder.comment"
                                required
                            />
                        </div>
                        {(isSubmitting || submitted) && <Loader />}
                    </Modal>
                </Form>
            )}
        </Formik>
    )
}

export default RecordVerificationRejectModal
