const FILE_CATEGORIES = {
    GALLERY_IMAGE: 'gallery_image',
    UPLOADED_DIVE_RECORD: 'uploaded_dive_record',
    FORMAL_EDUCATION: 'formal_education',
    PERSONAL_EQUIPMENT: 'personal_equipment',
    PAST_EMPLOYMENT: 'past_employment',
    COMPANY_HERO_IMAGE: 'company_hero_image',
    COMPANY_LOGO: 'company_logo',
    TRAVEL_DOC_RECORD_INFORMATION: 'travel_doc_record_information',
    USER_AVATAR: 'user_avatar',
    USER_HERO_IMAGE: 'user_hero_image',
    TRAINING_CERTIFICATE: 'training_certificate',
    MEDICAL_RECORD: 'medical_record',
    FEEDBACK_ATTACHMENTS: 'feedback_attachments',
    VISA: 'visa',
    TRAVEL_DOCUMENTATION: 'travel_documentation',
    DIVE_RECORD_DIVE_SUMMARY: 'dive_record_dive_summary',
    DIVE_RECORD_FIRST_LEVEL_VERIFICATION:
        'dive_record_first_level_verification',
    TRAINING_COURSE: 'training_course',
}

const FILE_VISIBILITY = {
    PUBLIC: 1,
    PRIVATE: 2,
    AUTHORIZED: 3,
}

export { FILE_CATEGORIES, FILE_VISIBILITY }
