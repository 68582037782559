import moment from 'moment'

export const MOMENT_FORMATS = {
    DATE: 'D/MMM/YYYY',
    DATETIME: 'D/MMM/YYYY HH:mm',
    DATE_API: 'yyyy-MM-DD',
    DATE_MONTHYEAR: 'MMMM YYYY',
    TIME: 'HH:mm',
    DATE_FULL_TIMEZONE: 'YYYY-MM-DDTHH:mm:ssZ',
}

export const EXPIRY_DATE = {
    EXPIRED: { status: 'expired', color: 'red' },
    WILL_EXPIRE: { status: 'will expire', color: 'orange' },
    NOT_EXPIRED: { status: 'not expired', color: 'green' },
}

const EXPIRING_DAYS = 60

export const formatDate = (date, format = MOMENT_FORMATS.DATE) => {
    return moment(date).format(format)
}

export const formatMonthYearDate = (date) => {
    return moment(date).format(MOMENT_FORMATS.DATE_MONTHYEAR)
}

export const formatDateTime = (dateTime) => {
    return moment(dateTime).format(MOMENT_FORMATS.DATETIME)
}

export const getDateTimeAgo = (date, format) => {
    return moment(date, format).fromNow()
}

export const getIsDateSameOrBefore = (date, targetDate, format) => {
    return moment(date, format).isSameOrBefore(moment(targetDate, format))
}

export const getIsDateSameAsCurrentDate = (date, format) => {
    return moment(date, format).isSame(moment(getTodaysDate(), format))
}

export const getIsDateSameOrAfter = (date, targetDate, format) => {
    return moment(date, format).isSameOrAfter(moment(targetDate, format))
}

export const getIsMonthSameOrAfter = (date, targetDate, format) => {
    const dateMonth = moment(date, format).startOf('month')
    const targetDateMonth = moment(targetDate, format).startOf('month')
    return dateMonth.isSameOrAfter(targetDateMonth)
}

export const getIsDateSameOrBeforeCurrentDate = (
    value,
    format = MOMENT_FORMATS.DATE
) => {
    return getIsDateSameOrBefore(
        moment(value).format(format),
        moment(getTodaysDate()).format(format),
        format
    )
}

export const formatDateApi = (date) => {
    return moment(date, MOMENT_FORMATS.DATE_API).format(MOMENT_FORMATS.DATE)
}

export const checkExpiryDate = (value, numberOfDays = EXPIRING_DAYS) => {
    const valueToDate = new Date(value)
    valueToDate.setHours(0, 0, 0, 0)

    const daysDifference = moment(valueToDate).diff(
        moment(getTodaysDate()),
        'days'
    )

    if (daysDifference < 0) {
        return EXPIRY_DATE.EXPIRED.color
    } else if (daysDifference >= 0 && daysDifference < numberOfDays) {
        return EXPIRY_DATE.WILL_EXPIRE.color
    }

    return EXPIRY_DATE.NOT_EXPIRED.color
}

export const checkIfExpired = (value, numberOfDays = EXPIRING_DAYS) => {
    const valueToDate = new Date(value)
    valueToDate.setHours(0, 0, 0, 0)

    const daysDifference = moment(valueToDate).diff(
        moment(getTodaysDate()),
        'days'
    )

    return daysDifference < 0
}

export const getTomorrowDate = (value) => {
    let inputDate = value ? moment(value) : moment()
    const tomorrowDate = inputDate.add(1, 'days')._d

    return tomorrowDate
}

export const getSelectedDate = (value) => {
    let selectedDate = value ? moment(value) : moment()
    return selectedDate._d
}

export const getTodaysDate = (isEndOfTheDay = false) => {
    const today = new Date()
    if (isEndOfTheDay) {
        today.setHours(23, 59, 59, 0)
    } else {
        today.setHours(0, 0, 0, 0)
    }

    return new Date(today)
}

export const isDateBefore = (date1, date2, format) => {
    return moment(date1, format).isBefore(moment(date2, format))
}

export const isDateAfter = (date1, date2, format) => {
    return moment(date1, format).isAfter(moment(date2, format))
}

export const isMonthBefore = (date1, date2, format) => {
    const date1Month = moment(date1, format).startOf('month')
    const date2Month = moment(date2, format).startOf('month')
    return date1Month.isBefore(date2Month)
}

export const isMonthAfter = (date1, date2, format) => {
    const date1Month = moment(date1, format).startOf('month')
    const date2Month = moment(date2, format).startOf('month')
    return date1Month.isAfter(date2Month)
}
