import React from 'react'
import PropTypes from 'prop-types'

import { ICON_SIZE } from 'constants/enums'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const FileCell = ({
    value,
    value: {
        file: { originalName },
    },
    handleView,
}) => {
    return (
        <div
            className="aligned-center -gap5 -fileCell pointer"
            onClick={() => handleView(value)}
        >
            <Icon
                name={ICONS.DIVE_RECORDS}
                size={ICON_SIZE.SIZE20}
                color={COLORS.DARK_BLUE_60}
            />
            <span className="-cellValue">{originalName}</span>
        </div>
    )
}

FileCell.propTypes = {
    value: PropTypes.shape({
        file: PropTypes.shape({
            originalName: PropTypes.string,
        }),
    }),
    handleView: PropTypes.func,
}

export default FileCell
