import PropTypes from 'prop-types'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { useLocation, useNavigate } from 'react-router'

import useFetchData from 'hooks/useFetchData'

import ENTITIES from 'constants/entities'
import { MEDICAL_EXAM_TYPE, MEDICAL_RECORD_TYPE } from '../constants'
import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import Loader from 'components/Loader'
import StepButtons from 'components/StepButtons'
import AddNew from '../AddNew'
import CreateMedicalRecord from './CreateMedicalRecord'
import MedicalRecords from './MedicalRecords'
import RequestAccess from 'screens/diver/profile/RequestAccess'
import NoData from 'components/NoData'
import MedicalInfoTermsAndConditions from './MedicalInfoTermsAndConditions'
import TabHeading from 'screens/diver/profile/diverInformation/components/TabHeading'

const MedicalInfoOnboarding = ({
    goToNextStep,
    goPreviousStep,
    userID,
    myProfile,
    profilePage,
    refetchData,
    user,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()

    const [create, setCreate] = useState(location.state?.medicalInfo || false)

    //NOTE: When navigated from profile page update button to the same tab you are already
    useEffect(() => {
        location.state?.medicalInfo && setCreate(location.state?.medicalInfo)
    }, [location.state?.medicalInfo])

    const showActions = myProfile || !profilePage

    const { data, meta, isLoading, fetchData } = useFetchData(
        ENTITIES.MEDICAL_RECORD,
        {
            include: 'medicalRecordType,files,medicalExam,medicalExamStandard',
            'user.id': userID,
        },
        true,
        false
    )

    const status = meta?.dataAccessStatus
    const hasAccess =
        myProfile ||
        [
            ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id,
            ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id,
        ].includes(status)

    const hideFiles =
        status === ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id
    // It is necessary to clear the location state
    // (that is sent from the homepage due to completing the profile) after the component is rendered
    useEffect(() => {
        navigate(`${location.pathname}${location.search}`, { replace: true })
    }, [])

    const dataLength = data.length

    const dataToRefetch = data.filter(
        (item) =>
            item.medicalRecordType?.id ===
                MEDICAL_RECORD_TYPE.CLINICAL_EXAM_FORM.id &&
            (item.medicalExam?.id === MEDICAL_EXAM_TYPE.ANNUAL.id ||
                item.medicalExam?.id === MEDICAL_EXAM_TYPE.SEMI_ANNUAL.id)
    )

    const handleOpenModal = () => setCreate(true)

    if (!hasAccess) {
        return (
            <RequestAccess
                module={ACCESS_CONTROL_MODULES.MEDICAL_INFORMATION}
                title="general.medicalInformation"
                status={status}
                canRequestAccess={
                    status === ACCESS_CONTROL_STATUSES.NOT_FOUND.id
                }
                profile={user}
                checkAccessControl={fetchData}
                requestTime={meta.dataAccessRequestTime}
            />
        )
    }

    if (!myProfile && !user.agreedMedicalInfoTermsAndConditions) {
        return (
            <Fragment>
                <div className="m-profileTabs">
                    <div className="aligned-center ">
                        <span className="a-bodyTextMedium ">
                            {t('general.medicalInformation')}
                        </span>
                    </div>
                </div>
                <div className="a-note a-mediumText -mt20">
                    {t('medicalInfoTerms.notAgreedNote')}
                </div>
            </Fragment>
        )
    }

    if (myProfile && !user.agreedMedicalInfoTermsAndConditions) {
        return <MedicalInfoTermsAndConditions />
    }

    return (
        <Fragment>
            <div className={profilePage ? 'm-profileTabs' : '-contentElements'}>
                <TabHeading
                    tabTitle="general.medicalInformation"
                    tab={ROUTES.MEDICAL_INFO}
                    myProfile={myProfile}
                />
                {hideFiles && (
                    <p className="a-mediumText a-lightText -mt20">
                        {t('general.noAccessToFiles')}
                    </p>
                )}
                {showActions && (
                    <div className={!profilePage ? '_12' : ''}>
                        <AddNew
                            handleAction={handleOpenModal}
                            label="general.addNewMedicalRecord"
                        />
                    </div>
                )}
                {create && (
                    <CreateMedicalRecord
                        open={create}
                        setOpen={setCreate}
                        fetchMedicalRecords={fetchData}
                        filteredData={dataToRefetch}
                        refetchData={refetchData}
                    />
                )}
                <MedicalRecords
                    data={data}
                    isLoading={isLoading}
                    fetchMedicalRecords={fetchData}
                    showActions={showActions}
                    profilePage={profilePage}
                    refetchData={refetchData}
                    filteredData={dataToRefetch}
                />
            </div>

            {!profilePage && (
                <StepButtons
                    handleBack={goPreviousStep}
                    handleNext={goToNextStep}
                    handleSkip={goToNextStep}
                    canSkip={!dataLength}
                    noBorder
                />
            )}

            {data?.length === 0 && !isLoading && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noDataYet')}
                    description={t(
                        showActions
                            ? 'general.clickThePlusButton'
                            : 'general.nothingHereRightNow'
                    )}
                    isSmallWindow
                />
            )}

            {isLoading && dataLength > 0 && <Loader />}
        </Fragment>
    )
}

MedicalInfoOnboarding.propTypes = {
    goToNextStep: PropTypes.func,
    goPreviousStep: PropTypes.func,
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
    refetchData: PropTypes.func,
}

MedicalInfoOnboarding.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default MedicalInfoOnboarding
