import PropTypes from 'prop-types'

import { useTranslate } from 'react-polyglot'

import useFetchData from 'hooks/useFetchData'

import ENTITIES from 'constants/entities'

import SkillRecordWrapper from './components/SkillRecordWrapper'
import TabNavigation from 'components/tabs/TabNavigation'
import { useLocation } from 'react-router'
import useQueryParams from 'hooks/useQueryParams'
import DivingModeExperienceWrapper from './components/DivingModeExperienceWrapper'
import ExperienceWrapper from './components/ExperienceWrapper'

const EXPERIENCE_AND_SKILLS_OPTIONS = {
    EXPERIENCES: 'experiences',
    DIVE_MODE_EXPERIENCES: 'diveModeExperiences',
    SKILLS: 'skills',
}

const SkillsOnboarding = ({ userID, myProfile, refetchData }) => {
    const t = useTranslate()

    const searchParams = useLocation().search
    const { subTab } = useQueryParams()

    const {
        data: experience,
        isLoading: isLoadingExperience,
        fetchData: fetchExperience,
    } = useFetchData(
        ENTITIES.EXPERIENCE,
        {
            include: 'industry',
            'user.id': userID,
            'exists[divingMode]': false,
        },
        true,
        false
    )

    const {
        data: divingModeExperience,
        isLoading: isLoadingDivingModeExperience,
        fetchData: fetchDivingModeExperience,
    } = useFetchData(
        ENTITIES.EXPERIENCE,
        {
            include: 'divingMode',
            'user.id': userID,
            'exists[divingMode]': true,
        },
        true,
        false
    )

    const {
        data: skills,
        isLoading: isLoadingSkills,
        fetchData: fetchSkills,
    } = useFetchData(
        ENTITIES.SKILL_RECORD,
        { include: 'skillCategory,skill', user: userID },
        true,
        false
    )

    const renderTabContent = () => {
        switch (subTab) {
            case EXPERIENCE_AND_SKILLS_OPTIONS.EXPERIENCES:
                return (
                    <ExperienceWrapper
                        data={experience}
                        fetchData={fetchExperience}
                        isLoading={isLoadingExperience}
                        showActions={myProfile}
                        refetchData={refetchData}
                    />
                )
            case EXPERIENCE_AND_SKILLS_OPTIONS.DIVE_MODE_EXPERIENCES:
                return (
                    <DivingModeExperienceWrapper
                        data={divingModeExperience}
                        isLoading={isLoadingDivingModeExperience}
                        fetchData={fetchDivingModeExperience}
                        refetchData={refetchData}
                        showActions={myProfile}
                    />
                )
            case EXPERIENCE_AND_SKILLS_OPTIONS.SKILLS:
            default:
                return (
                    <SkillRecordWrapper
                        data={skills}
                        isLoading={isLoadingSkills}
                        fetchData={fetchSkills}
                        refetchData={refetchData}
                        showActions={myProfile}
                    />
                )
        }
    }

    return (
        <div className="m-profileTabs -skills">
            <div>
                <div>
                    <div className="-mb20">
                        <div className="aligned-center a-tabName">
                            <span className="a-bodyTextMedium ">
                                {t('general.experienceAndSkills')}
                            </span>
                        </div>
                    </div>
                    <TabNavigation
                        tabs={[
                            {
                                key: EXPERIENCE_AND_SKILLS_OPTIONS.SKILLS,
                                title: 'general.skills',
                                queryParams: searchParams,
                            },
                            {
                                key: EXPERIENCE_AND_SKILLS_OPTIONS.EXPERIENCES,
                                title: 'general.experience',
                                queryParams: searchParams,
                            },
                            {
                                key:
                                    EXPERIENCE_AND_SKILLS_OPTIONS.DIVE_MODE_EXPERIENCES,
                                title: 'general.diveModes',
                                queryParams: searchParams,
                            },
                        ]}
                        tabQueryParam="subTab"
                    />
                    {renderTabContent()}
                </div>
            </div>
        </div>
    )
}

SkillsOnboarding.propTypes = {
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
    refetchData: PropTypes.func,
    hideFiles: PropTypes.bool,
}

SkillsOnboarding.defaultProps = {
    myProfile: false,
}

export default SkillsOnboarding
