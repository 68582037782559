import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import Modal from 'components/Modal'
import Separator from 'components/Separator'
import ProfileImageExamples from './ProfileImageExamples'

const ProfileImageGuidelinesModal = ({ setOpenModal }) => {
    const t = useTranslate()

    return (
        <Modal
            open={true}
            setOpen={setOpenModal}
            title="general.profileImageGuidelines"
        >
            <div className="_w">
                <p className="a-mediumText a-lightText a-centeredText _12">
                    {t('general.profileImageGuidelinesDescription')}
                </p>
                <Separator />
                <div className="_12 column -gap15 -pb20">
                    <ProfileImageExamples />
                </div>
            </div>
        </Modal>
    )
}

ProfileImageGuidelinesModal.propTypes = {
    setOpenModal: PropTypes.func,
}

export default ProfileImageGuidelinesModal
