import { useTranslate } from 'react-polyglot'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const CertificateMismatchedInfo = ({
    label,
    verificationData,
    fileComparison,
}) => {
    const t = useTranslate()

    if (!verificationData && !fileComparison) return null

    /** If compare object is not returned invalid image is provided */
    if(!verificationData && fileComparison) return (
        <div className={`_12 aligned-center -gap10 -mt10 flex-wrap ${
            fileComparison ? '-mb15' : ''
        }`}>
            <Icon
                name={ICONS.CLOSE_CIRCLE}
                size={ICON_SIZE.SIZE20}
                color={COLORS.RED}
            />
            <span className="a-mediumText">{t('general.invalidCertificateImage')}</span>
        </div>
    )

    const mismatchedData = Object.keys(verificationData).filter(
        (key) => !verificationData[key]
    )


    if (!mismatchedData.length) return null

    return (
        <div
            className={`_12 aligned-center -gap10 -mt10 flex-wrap ${
                fileComparison ? '-mb15' : ''
            }`}
        >
            <span className="a-mediumText">{`${t(label)}: `}</span>
            {mismatchedData.map((item, index) => (
                <div
                    key={index}
                    className="a-captionsTextRegular
aligned-center -gap5"
                >
                    <Icon
                        name={ICONS.CLOSE_CIRCLE}
                        size={ICON_SIZE.SIZE20}
                        color={COLORS.RED}
                    />

                    <span>{t(`general.${item}`)}</span>
                </div>
            ))}{' '}
        </div>
    )
}

export default CertificateMismatchedInfo
