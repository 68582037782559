import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { useTranslate } from 'react-polyglot'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'

import {
    MOMENT_FORMATS,
    formatDate,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
} from 'services/moment.service'
import { getEntityFilters } from 'services/localStorage.service'

import { positionCategoryIds } from 'utils/positionHelper'
import { translatePackagesOptions } from 'utils/translateOptionsHelper'
import { validateEmail } from 'utils/yupValidations'

import { AlertContext } from 'contexts/AlertContext'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_STATUS,
    BUTTON_TYPE,
    DIVER_POSITION_CATEGORIES,
    ICON_SIZE,
    INPUT_FILED_TYPE,
    PACKAGE_INFO_DATA,
    ROLES,
    SELECT_VALUE_TYPE,
    TOOLTIP_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import FILTERS from 'constants/filters'
import { FILE_CATEGORIES } from 'constants/fileCategories'

import Separator from 'components/Separator'
import {
    CheckboxField,
    MultiselectField,
    ProfilePictureUploadField,
    TextAreaField,
} from 'components/formFields'
import DateTimeField from 'components/formFields/DateTimeField'
import InputField from 'components/formFields/InputField'
import SelectField from 'components/formFields/SelectField'
import SelectGroupField from 'components/formFields/SelectGroupField'
import FocusError from 'components/FocusError'
import Loader from 'components/Loader'
import Button from 'components/Button'

const PersonalInfoModalContext = ({ setAvailability }) => {
    const formikContext = useFormikContext()

    const { values } = formikContext

    const { userStatus } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }

        if (!firstRender.current) {
            setAvailability(userStatus?.code)
        }
    }, [userStatus])
}

const IndividualAccountForm = ({ initialData, handleSubmit }) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { setAlert } = useContext(AlertContext)

    const [availability, setAvailability] = useState(
        initialData?.userStatus.code
    )
    const [errorMessage, setErrorMessage] = useState(false)

    const diver =
        !initialData || initialData?.role?.name === ROLES.ROLE_DIVER.name

    const initialPositionValues = Object.fromEntries(
        Object.entries(positionCategoryIds).map(([key, id]) => [
            key,
            initialData?.diverPositions?.filter(
                (item) =>
                    item.relationships?.diverPositionCategory?.data?.id === id
            ) ?? [],
        ])
    )

    const initialSubscriptionType = PACKAGE_INFO_DATA.find(
        (item) =>
            item.id === initialData?.userSubscription?.userSubscriptionType
    )

    const initialValues = {
        firstName: initialData?.firstName ?? '',
        middleName: initialData?.middleName ?? '',
        lastName: initialData?.lastName ?? '',
        currentTitle: initialData?.currentTitle ?? '',
        dateOfBirth: initialData?.dateOfBirth ?? '',
        country: initialData?.country ?? null,
        nationality: initialData?.nationality ?? '',
        diverLanguages: initialData?.diverLanguages ?? [],
        email: initialData?.email ?? '',
        countryPhoneCode: initialData?.countryPhoneCode ?? null,
        phoneNumber: initialData?.phoneNumber ?? '',
        about: initialData?.about ?? '',
        avatar: initialData?.avatar ?? null,
        occupationalDivingPersonnel:
            initialPositionValues.occupationalDivingPersonnel ?? [],
        marineMobileOffshoreUnitPersonnel:
            initialPositionValues.marineMobileOffshoreUnitPersonnel ?? [],
        rovPersonnel: initialPositionValues.rovPersonnel ?? [],
        offshoreSurveyPersonnel:
            initialPositionValues.offshoreSurveyPersonnel ?? [],
        projectMissionPersonnel:
            initialPositionValues.projectMissionPersonnel ?? [],
        projectOperationsManagementPersonnel:
            initialPositionValues.projectOperationsManagementPersonnel ?? [],
        hidden: initialData?.hidden ?? false,
        userStatus: initialData?.userStatus ?? null,
        regions: initialData?.regions ?? [],
        professionalSummary: initialData?.professionalSummary ?? '',
        identityVerified: initialData?.identityVerified ?? false,
        active: initialData?.active ?? false,
        userSubscription: initialSubscriptionType ?? null,
    }

    const requiredMessage = t('form.error.required')
    const maximumCharacters = `${t('form.error.maximumAllowed')} 500 ${t(
        'form.error.characters'
    )}`

    const birthdayValidation = Yup.date().test(
        'dateOfBirth',
        (value, { parent, createError }) => {
            if (getIsDateSameOrBeforeCurrentDate(value, MOMENT_FORMATS.DATE)) {
                return true
            }
            return createError({
                message: t('form.error.birthDate'),
                path: 'dateOfBirth',
            })
        }
    )

    const validation = Yup.object({
        firstName: Yup.string().trim().required(requiredMessage),
        middleName: Yup.string().trim(),
        lastName: Yup.string().trim().required(requiredMessage),
        dateOfBirth: diver
            ? birthdayValidation.required(requiredMessage)
            : birthdayValidation.notRequired(),
        country: diver
            ? Yup.object().required(requiredMessage)
            : Yup.object().notRequired(),
        nationality: Yup.string().trim(),
        positions: Yup.object()
            .shape({
                occupationalDivingPersonnel: Yup.array().notRequired(),
                marineMobileOffshoreUnitPersonnel: Yup.array().notRequired(),
                rovPersonnel: Yup.array().notRequired(),
                offshoreSurveyPersonnel: Yup.array().notRequired(),
                projectMissionPersonnel: Yup.array().notRequired(),
                projectOperationsManagementPersonnel: Yup.array().notRequired(),
            })
            .test('positions', (value, { parent }) => {
                if (
                    parent.occupationalDivingPersonnel.length === 0 &&
                    parent.marineMobileOffshoreUnitPersonnel.length === 0 &&
                    parent.rovPersonnel.length === 0 &&
                    parent.offshoreSurveyPersonnel.length === 0 &&
                    parent.projectMissionPersonnel.length === 0 &&
                    parent.projectOperationsManagementPersonnel.length === 0
                ) {
                    return setErrorMessage(true)
                }
                setErrorMessage(false)
                return true
            }),
        diverLanguages: diver
            ? Yup.array()
                  .min(1, t('form.error.atLeastOneLanguage'))
                  .required(requiredMessage)
            : Yup.array().notRequired(),
        email: validateEmail(t).required(requiredMessage),
        regions: diver
            ? Yup.array()
                  .min(1, t('form.error.addAtLeastOne'))
                  .required(requiredMessage)
            : Yup.array().notRequired(),
        userStatus: diver
            ? Yup.object().required(requiredMessage)
            : Yup.object().nullable(),
        about: Yup.string().max(500, maximumCharacters),
        userSubscription: diver
            ? Yup.object().required(requiredMessage)
            : Yup.object().nullable(),
    })

    const onSubmit = async (
        {
            diverLanguages,
            diverPositions,
            marineMobileOffshoreUnitPersonnel,
            offshoreSurveyPersonnel,
            occupationalDivingPersonnel,
            rovPersonnel,
            projectMissionPersonnel,
            projectOperationsManagementPersonnel,
            userSubscription,
            ...formData
        },
        { setSubmitting }
    ) => {
        try {
            setSubmitting(true)

            const positionList = [
                marineMobileOffshoreUnitPersonnel,
                offshoreSurveyPersonnel,
                occupationalDivingPersonnel,
                rovPersonnel,
                projectMissionPersonnel,
                projectOperationsManagementPersonnel,
            ]
            const diverPositionList = Object.values(positionList)
                .flat()
                .map((role) => role)

            await handleSubmit({
                ...formData,
                diverPositions: diverPositionList,
                diverLanguages: diverLanguages.map((item) => ({
                    ...item,
                    user: {
                        id: initialData?.id,
                        entityType: ENTITIES.USER,
                    },
                })),
            })
            handleBack()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        } finally {
            setSubmitting(false)
        }
    }

    const handleBack = () =>
        navigate(
            `/${ROUTES.INDIVIDUAL_ACCOUNT_MANAGEMENT}${getEntityFilters(
                FILTERS.SUPER_ADMIN_INDIVIDUAL_FILTERS
            )}`
        )

    const showErrorMessage = (touched) =>
        [
            'occupationalDivingPersonnel',
            'marineMobileOffshoreUnitPersonnel',
            'rovPersonnel',
            'offshoreSurveyPersonnel',
            'projectMissionPersonnel',
            'projectOperationsManagementPersonnel',
        ].some((field) => touched[field])

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validation}
            onSubmit={onSubmit}
        >
            {({ isSubmitting, touched }) => (
                <Form className="personalInfoModal">
                    <FocusError />
                    <PersonalInfoModalContext
                        setAvailability={setAvailability}
                    />
                    <div className="-contentElements -individualsManagement">
                        <div className={`_w ${!diver ? '-mb15' : ''}`}>
                            <ProfilePictureUploadField
                                name="avatar"
                                profilePage={true}
                                availability={availability}
                                fileCategory={FILE_CATEGORIES.USER_AVATAR}
                            />
                            {diver && (
                                <div className="_12 _xl9 -mainInfo">
                                    <div className="_w">
                                        <div className="_12 _m6 -mb5">
                                            <SelectField
                                                name="userStatus"
                                                entityType={
                                                    ENTITIES.USER_STATUSES
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.OBJECT
                                                }
                                                required
                                            />
                                        </div>
                                        <div className="_12 _m6 -mb5">
                                            <SelectField
                                                name="userSubscription"
                                                label="form.label.subscriptionType"
                                                placeholder="form.placeholder.subscriptionType"
                                                defaultOptions={
                                                    PACKAGE_INFO_DATA
                                                }
                                                valueType={
                                                    SELECT_VALUE_TYPE.OBJECT
                                                }
                                                translateItem={
                                                    translatePackagesOptions
                                                }
                                                disabled
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="_w">
                                        <div className="_12 _m3 centered-checkbox -topLevel -mb20">
                                            <CheckboxField
                                                name="hidden"
                                                label="form.label.hidden"
                                                title="general.profileVisibility"
                                                tooltip="form.label.hiddenUserText"
                                                icon={ICONS.HELP_AND_INFO}
                                                translate
                                            />
                                        </div>
                                        <div className="_12 _m3 centered-checkbox -mb20">
                                            <CheckboxField
                                                name="identityVerified"
                                                label="form.label.verified"
                                                title="general.personaVerification"
                                                translate
                                                disabled={
                                                    initialValues.identityVerified
                                                }
                                            />
                                        </div>
                                        <div className="_12 _m3 centered-checkbox -mb20">
                                            <CheckboxField
                                                name="active"
                                                label="form.label.activeAccount"
                                                title="general.accountActivity"
                                                translate
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="_w">
                            <Separator />
                            <div className="_12 _m6">
                                <InputField name="firstName" required />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="middleName" />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="lastName" required />
                            </div>
                            <div className="_12 _m6">
                                <InputField
                                    name="currentTitle"
                                    label="form.label.title"
                                    placeholder="form.placeholder.title"
                                />
                            </div>
                            <div className="_12 _m6 calendarMovedToRight">
                                <DateTimeField
                                    name="dateOfBirth"
                                    icon={true}
                                    required={!!diver}
                                    maxDate={getTodaysDate()}
                                />
                            </div>
                        </div>
                        <div className="_w -selectRolesFields">
                            <Separator />
                            <div className="_12">
                                <div className="column -gap5">
                                    <span className="a-bodyTextMedium">
                                        {t('general.selectYourRoles')}
                                    </span>
                                    <span className="a-mediumText a-lightText">
                                        {t(
                                            'general.selectYourMostRecentPosition'
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="_12 _m6 -mt20">
                                <MultiselectField
                                    name="occupationalDivingPersonnel"
                                    label="form.label.occupationalDivingPersonnel"
                                    placeholder="form.placeholder.selectRoles"
                                    entityType={ENTITIES.DIVER_POSITION}
                                    params={{
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .DIVING_PERSONNEL.id,
                                    }}
                                    searchable
                                    tooltip="form.label.occupationalDivingPersonnelTooltip"
                                />
                            </div>
                            <div className="_12 _m6 -mt20">
                                <MultiselectField
                                    name="marineMobileOffshoreUnitPersonnel"
                                    label="form.label.marineMobileOffshoreUnitPersonnel"
                                    placeholder="form.placeholder.selectRoles"
                                    entityType={ENTITIES.DIVER_POSITION}
                                    params={{
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .MARINE_MOU_PERSONNEL.id,
                                    }}
                                    searchable
                                    tooltip="form.label.marineMobileOffshoreUnitPersonnelTooltip"
                                    tooltipPosition={TOOLTIP_POSITION.LEFT}
                                />
                            </div>
                            <div className="_12 _m6 -mt20">
                                <MultiselectField
                                    name="rovPersonnel"
                                    label="form.label.rovPersonnel"
                                    placeholder="form.placeholder.selectRoles"
                                    entityType={ENTITIES.DIVER_POSITION}
                                    params={{
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .ROV_PERSONNEL.id,
                                    }}
                                    searchable
                                    tooltip="form.label.rovPersonnelTooltip"
                                />
                            </div>
                            <div className="_12 _m6 -mt20">
                                <MultiselectField
                                    name="offshoreSurveyPersonnel"
                                    label="form.label.offshoreSurveyPersonnel"
                                    placeholder="form.placeholder.selectRoles"
                                    entityType={ENTITIES.DIVER_POSITION}
                                    params={{
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .SURVEY_PERSONNEL.id,
                                    }}
                                    searchable
                                    tooltip="form.label.offshoreSurveyPersonnelTooltip"
                                    tooltipPosition={TOOLTIP_POSITION.LEFT}
                                />
                            </div>
                            <div className="_12 _m6 -mt20">
                                <MultiselectField
                                    name="projectMissionPersonnel"
                                    label="form.label.projectMissionPersonnel"
                                    placeholder="form.placeholder.selectRoles"
                                    entityType={ENTITIES.DIVER_POSITION}
                                    params={{
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .PROJECT_MISSION_PERSONNEL.id,
                                    }}
                                    searchable
                                    tooltip="form.label.projectMissionPersonnelTooltip"
                                />
                            </div>
                            <div className="_12 _m6 -mt20">
                                <MultiselectField
                                    name="projectOperationsManagementPersonnel"
                                    label="form.label.projectOperationsManagementPersonnel"
                                    placeholder="form.placeholder.selectRoles"
                                    entityType={ENTITIES.DIVER_POSITION}
                                    params={{
                                        'diverPositionCategory.id':
                                            DIVER_POSITION_CATEGORIES
                                                .PROJECT_OPERATIONS_MANAGEMENT_PERSONNEL
                                                .id,
                                    }}
                                    searchable
                                    tooltip="form.label.projectOperationsManagementPersonnelTooltip"
                                    tooltipPosition={TOOLTIP_POSITION.LEFT}
                                />
                            </div>
                            {showErrorMessage(touched) && errorMessage && (
                                <div className="_12">
                                    <span className="errorMessage  -active">
                                        {t('form.error.selectAtLeastOneRole')}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div className="_w ">
                            <Separator />
                            <div className="_12 _m6">
                                <SelectField
                                    name="country"
                                    label="form.label.countryOfResidence"
                                    placeholder="form.placeholder.countryOfResidence"
                                    entityType={ENTITIES.COUNTRY}
                                    searchable
                                    required={!!diver}
                                />
                            </div>
                            <div className="_12 _m6">
                                <InputField name="nationality" />
                            </div>
                        </div>
                        <div className="_w ">
                            <Separator />
                            <div className="_12 a-oneLineSelectGroup">
                                <SelectGroupField
                                    name="diverLanguages"
                                    title="general.addLanguages"
                                    description="general.pleaseAddAllLanguages"
                                    fieldsName={[
                                        'language',
                                        'languageProficiency',
                                    ]}
                                    fieldsPlaceholder={[
                                        'form.placeholder.selectLanguage',
                                        'form.placeholder.selectProficiency',
                                    ]}
                                    fieldsEntityType={[
                                        ENTITIES.LANGUAGE,
                                        ENTITIES.LANGUAGE_PROFICIENCY,
                                    ]}
                                    fieldsRequired={[true, true]}
                                    fieldsShowLabel={[false, false]}
                                    required={!!diver}
                                    fieldsSearchable={[true, true]}
                                />
                            </div>
                        </div>
                        <div className="_w">
                            <Separator />
                            <div className="_12 _l6 -inputWithIcon">
                                <InputField
                                    name="email"
                                    label="form.label.profileEmail"
                                    placeholder="form.placeholder.profileEmail"
                                    type={INPUT_FILED_TYPE.EMAIL}
                                    icon={ICONS.MAIL}
                                    size={ICON_SIZE.SIZE20}
                                    iconColor={COLORS.DARK_BLUE_60}
                                    disabled
                                    required
                                />
                            </div>
                            <div className="_12 _l6 -inputWithIcon">
                                <div className="a-phoneNumberField aligned-baseline fakeLabel -notFirst">
                                    <SelectField
                                        name="countryPhoneCode"
                                        label="form.label.phone"
                                        entityType={ENTITIES.COUNTRY}
                                        displayAttribute="callingCode"
                                        searchAttribute="callingCode"
                                        iconAttribute="flag"
                                        showPlaceholder={false}
                                        params={{ sort: 'callingCode' }}
                                        searchable
                                        required
                                        disabled
                                    />
                                    <InputField
                                        name="phoneNumber"
                                        label="general.fakeLabel"
                                        placeholder="form.placeholder.phone"
                                        icon={ICONS.PHONE}
                                        iconColor={COLORS.DARK_BLUE_60}
                                        size={ICON_SIZE.SIZE20}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="_w">
                            <Separator />
                            <div className="_12">
                                <TextAreaField
                                    name="about"
                                    label="form.label.aboutMe"
                                    placeholder="form.placeholder.aboutMe"
                                    largeTextarea
                                />
                            </div>
                        </div>
                        {diver && (
                            <div className="_w">
                                <Separator />
                                <div className="_12">
                                    <TextAreaField
                                        name="professionalSummary"
                                        label="form.label.professionalSummary"
                                        placeholder="form.placeholder.professionalSummary"
                                        largeTextarea
                                    />
                                </div>
                            </div>
                        )}
                        {diver && (
                            <div className="_w">
                                <Separator />
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="regions"
                                        label="form.label.regions"
                                        placeholder="form.placeholder.regions"
                                        entityType={ENTITIES.REGIONS}
                                        searchable
                                        required
                                        dropup
                                    />
                                </div>
                            </div>
                        )}
                        <div className="_w">
                            <Separator />
                            {!!initialData && (
                                <div className="_12 space-between flex-wrap -gap10">
                                    <p className="a-mediumText a-lightText">
                                        <span className="a-mediumTextSemiBold">
                                            {t('general.lastLogin')}:
                                        </span>
                                        {` ${formatDate(
                                            initialData?.lastLogin
                                        )} ${t('general.at')} ${formatDate(
                                            initialData?.lastLogin,
                                            MOMENT_FORMATS.TIME
                                        )}.`}
                                    </p>
                                    <p className="a-mediumText a-lightText">
                                        <span className="a-mediumTextSemiBold">
                                            {t('general.registered')}:
                                        </span>
                                        {` ${formatDate(
                                            initialData?.registeredAt
                                        )} ${t('general.at')} ${formatDate(
                                            initialData?.registeredAt,
                                            MOMENT_FORMATS.TIME
                                        )}.`}
                                    </p>
                                </div>
                            )}
                            {!!initialData && <Separator />}
                        </div>
                        <div className="_w spaceBetweenTwoButtons">
                            <div className="_12 _s6 _m5 -backButton">
                                <Button
                                    label="button.cancel"
                                    onClick={handleBack}
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                />
                            </div>
                            <div className="_12 _s6 _m5 -nextButton">
                                <Button
                                    label="button.saveChanges"
                                    type={BUTTON_TYPE.SUBMIT}
                                />
                            </div>
                        </div>
                    </div>
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    )
}

export default IndividualAccountForm
