import React, { Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'

import useQueryParams from 'hooks/useQueryParams'
import useFetchData from 'hooks/useFetchData'

import { formatDivingModeExperience } from 'utils/filterButtonFormatters'

import { INPUT_FILED_TYPE } from 'constants/enums'

import Modal from 'components/Modal'
import {
    CheckboxField,
    InputField,
    MultiselectField,
    ArrayCustomGroupField,
    SelectGroupField,
    SelectField,
} from 'components/formFields'
import ENTITIES from 'constants/entities'
import Separator from 'components/Separator'
import DiversFilterFormikContext from './DiversFilterFormikContext'
import Loader from 'components/Loader'
import { DIVING_MODES } from 'screens/diver/diveRecord/constants/divingModes'
import FilterIconButton from 'components/FilterIconButton'
import SelectMultiselectGroupField from '../formFields/SelectMultiselectGroupField'

const DiverFilterFormContext = () => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext

    const { experienceDivingModeItem } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false
            return
        }

        if (!firstRender.current) {
            resetField('experienceDivingModeItem.fromDives', '')
            resetField('experienceDivingModeItem.toDives', '')
            resetField('experienceDivingModeItem.fromDays', '')
            resetField('experienceDivingModeItem.toDays', '')
            resetField('experienceDivingModeItem.fromBellRuns', '')
            resetField('experienceDivingModeItem.toBellRuns', '')
        }
    }, [experienceDivingModeItem.divingMode])

    const resetField = (field, defaultValue) => {
        setFieldValue(field, defaultValue)
        setFieldTouched(field, false)
    }
}

const DiverFilters = ({
    activeFilters,
    showStatusUpdate,
    ignoreFilters = [],
    title = 'general.filterUsers',
    disabled,
}) => {
    const t = useTranslate()

    const { addFilterParams } = useQueryParams()

    const [open, setOpen] = useState(false)

    const { data, isLoading } = useFetchData(ENTITIES.DIVING_MODE)

    const enabledDivingModes = () => {
        return data.filter(
            (mode) => mode.id !== DIVING_MODES.CHAMBER_RECOMPRESSION_ONLY.id
        )
    }

    const emptyValues = {
        userStatus: [],
        projectPosition: null,
        previouslyEmployed: false,
        yearsOfExperience: { from: '', to: '' },
        experienceDivingModeItem: {
            divingMode: null,
            fromDives: '',
            toDives: '',
            fromDays: '',
            toDays: '',
            fromBellRuns: '',
            toBellRuns: '',
        },
        experienceDivingMode: [],
        country: [],
        region: [],
        role: [],
        skillCategory: [],
        skill: [],
        skillRecord: [],
        language: [],
        age: { from: '', to: '' },
    }

    const initialValues = {
        userStatus: activeFilters.userStatus ?? emptyValues.userStatus,
        projectPosition:
            activeFilters.projectPosition ?? emptyValues.projectPosition,
        previouslyEmployed: Boolean(activeFilters.previouslyEmployed),
        yearsOfExperience:
            activeFilters.yearsOfExperience ?? emptyValues.yearsOfExperience,
        experienceDivingModeItem: emptyValues.experienceDivingModeItem,
        experienceDivingMode:
            activeFilters.experienceDivingMode ??
            emptyValues.experienceDivingMode,
        country: activeFilters.country ?? emptyValues.country,
        region: activeFilters.region ?? emptyValues.region,
        role: activeFilters.role ?? emptyValues.role,
        skillCategory: activeFilters.skillCategory ?? emptyValues.skillCategory,
        skill: activeFilters.skill ?? emptyValues.skill,
        skillRecord: activeFilters.skillRecord ?? emptyValues.skillRecord,
        language: activeFilters.language ?? emptyValues.language,
        age: activeFilters.age ?? emptyValues.age,
    }

    const validation = Yup.object({
        experienceDivingModeItem: Yup.object().shape({
            fromDives: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber')),
            toDives: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .test('toDives', (value, { parent, createError }) => {
                    if (Number(value) <= Number(parent.fromDives)) {
                        return createError({
                            message: t('form.error.mustBeMoreThanFrom'),
                            path: 'experienceDivingModeItem.toDives',
                        })
                    }
                    return true
                }),
            fromDays: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber')),
            toDays: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .test('toDays', (value, { parent, createError }) => {
                    if (Number(value) <= Number(parent.fromDays)) {
                        return createError({
                            message: t('form.error.mustBeMoreThanFrom'),
                            path: 'experienceDivingModeItem.toDays',
                        })
                    }
                    return true
                }),
            fromBellRuns: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber')),
            toBellRuns: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .test('toBellRuns', (value, { parent, createError }) => {
                    if (Number(value) <= Number(parent.fromBellRuns)) {
                        return createError({
                            message: t('form.error.mustBeMoreThanFrom'),
                            path: 'experienceDivingModeItem.toBellRuns',
                        })
                    }
                    return true
                }),
        }),
        yearsOfExperience: Yup.object().shape({
            from: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber')),
            to: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .test('to', (value, { parent, createError }) => {
                    if (Number(value) <= Number(parent.from)) {
                        return createError({
                            message: t('form.error.mustBeMoreThanFrom'),
                            path: 'yearsOfExperience.to',
                        })
                    }
                    return true
                }),
        }),
        age: Yup.object().shape({
            from: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .max(200, t('form.error.numberMustBeLessThan')),
            to: Yup.number()
                .integer(t('form.error.onlyIntegerNumber'))
                .min(0, t('form.error.invalidNumber'))
                .max(200, t('form.error.numberMustBeLessThan'))
                .test('to', (value, { parent, createError }) => {
                    if (Number(value) <= Number(parent.from)) {
                        return createError({
                            message: t('form.error.mustBeMoreThanFrom'),
                            path: 'age.to',
                        })
                    }
                    return true
                }),
        }),
    })

    const handleFilter = (
        { previouslyEmployed, experienceDivingModeItem, ...filters },
        { setSubmitting }
    ) => {
        setSubmitting(true)
        addFilterParams({
            ...filters,
            previouslyEmployed: previouslyEmployed ? 1 : null,
        })
        setSubmitting(false)
        setOpen(false)
    }

    const handleReset = (formFields, setFieldValue) => {
        Object.keys(formFields).forEach((key) => {
            setFieldValue(key, emptyValues[key])
        })
    }

    const hasValue = (value) => value || value === 0

    const canAddSkill = (item, experienceDivingMode) =>
        item.divingMode &&
        !experienceDivingMode.some(
            (el) => el.divingMode.id === item.divingMode.id
        ) &&
        (hasValue(item.fromDives) ||
            hasValue(item.toDives) ||
            hasValue(item.fromDays) ||
            hasValue(item.toDays) ||
            hasValue(item.fromBellRuns) ||
            hasValue(item.toBellRuns) ||
            item.divingMode.id === 8)

    const showFilter = (fieldName) => {
        return ignoreFilters.indexOf(fieldName) === -1
    }

    if (isLoading) return <Loader />

    return (
        <Fragment>
            <FilterIconButton setOpen={setOpen} disabled={disabled} />
            {open && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={handleFilter}
                >
                    {({ values, setFieldValue, isSubmitting }) => (
                        <Form>
                            <Modal
                                open={open}
                                setOpen={setOpen}
                                closeOnClickOutside={false}
                                title={title}
                                isSubmitting={isSubmitting}
                                values={values}
                                buttons={{
                                    prevBtn: {},
                                    middleBtn: {
                                        handleClick: () =>
                                            handleReset(values, setFieldValue),
                                    },
                                    nextBtn: {
                                        label: 'button.filter',
                                    },
                                }}
                            >
                                <DiverFilterFormContext />
                                <DiversFilterFormikContext
                                    emptyValues={emptyValues}
                                />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('general.availability')}
                                </span>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="userStatus"
                                        label="form.label.userStatus"
                                        placeholder="form.placeholder.userStatus"
                                        entityType={ENTITIES.USER_STATUSES}
                                        searchable
                                    />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t(
                                        'general.yearsOfExperienceInDivingIndustry'
                                    )}
                                </span>
                                <div className="_12 _m6 toInput">
                                    <InputField
                                        name="yearsOfExperience.from"
                                        label="form.label.from"
                                        placeholder="form.placeholder.durationFrom"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                    />
                                </div>
                                <div className="_12 _m6 toInput">
                                    <InputField
                                        name="yearsOfExperience.to"
                                        label="form.label.to"
                                        placeholder="form.placeholder.durationTo"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                    />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t(
                                        'general.experienceInSpecificDivingMode'
                                    )}
                                </span>
                                <div className="_12">
                                    <ArrayCustomGroupField
                                        name="experienceDivingMode"
                                        itemName="experienceDivingModeItem"
                                        itemInitialValue={
                                            emptyValues.experienceDivingModeItem
                                        }
                                        canAddItem={canAddSkill}
                                        renderItem={formatDivingModeExperience}
                                    >
                                        <SelectField
                                            name="experienceDivingModeItem.divingMode"
                                            label="form.label.divingMode"
                                            placeholder="form.placeholder.divingMode"
                                            defaultOptions={enabledDivingModes()}
                                        />
                                        {[1, 2, 4, 5, 7, 6].includes(
                                            values.experienceDivingModeItem
                                                .divingMode?.id
                                        ) && (
                                            <Fragment>
                                                <InputField
                                                    name="experienceDivingModeItem.fromDives"
                                                    label="form.label.from"
                                                    placeholder="form.placeholder.fromDives"
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    units="form.placeholder.dives"
                                                />
                                                <InputField
                                                    name="experienceDivingModeItem.toDives"
                                                    label="form.label.to"
                                                    placeholder="form.placeholder.toDives"
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    units="form.placeholder.dives"
                                                />
                                            </Fragment>
                                        )}
                                        {values.experienceDivingModeItem
                                            .divingMode?.id === 3 && (
                                            <Fragment>
                                                <InputField
                                                    name="experienceDivingModeItem.fromDays"
                                                    label="form.label.from"
                                                    placeholder="form.placeholder.fromDays"
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    units="general.days"
                                                />
                                                <InputField
                                                    name="experienceDivingModeItem.toDays"
                                                    label="form.label.to"
                                                    placeholder="form.placeholder.toDays"
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    units="general.days"
                                                />
                                            </Fragment>
                                        )}
                                        {values.experienceDivingModeItem
                                            .divingMode?.id === 3 && (
                                            <Fragment>
                                                <InputField
                                                    name="experienceDivingModeItem.fromBellRuns"
                                                    label="form.label.from"
                                                    placeholder="form.placeholder.fromDays"
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    units="form.placeholder.bellRunsUnit"
                                                />
                                                <InputField
                                                    name="experienceDivingModeItem.toBellRuns"
                                                    label="form.label.to"
                                                    placeholder="form.placeholder.toDays"
                                                    type={
                                                        INPUT_FILED_TYPE.NUMBER
                                                    }
                                                    units="form.placeholder.bellRunsUnit"
                                                />
                                            </Fragment>
                                        )}
                                    </ArrayCustomGroupField>
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('general.location')}
                                </span>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="country"
                                        label="form.label.country"
                                        placeholder="form.placeholder.selectCountry"
                                        entityType={ENTITIES.COUNTRY}
                                        searchable
                                    />
                                </div>
                                <div className="_12 _m6">
                                    <MultiselectField
                                        name="region"
                                        label="form.label.region"
                                        placeholder="form.placeholder.selectRegion"
                                        entityType={ENTITIES.REGION}
                                        searchable
                                    />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('general.workInformation')}
                                </span>
                                {showFilter('previouslyEmployed') && (
                                    <div className="_12 -mb20">
                                        <CheckboxField
                                            name="previouslyEmployed"
                                            label="form.label.previouslyEmployed"
                                            translate
                                        />
                                    </div>
                                )}

                                <div className="_12 _l6">
                                    <MultiselectField
                                        name="role"
                                        label="form.label.roles"
                                        placeholder="form.placeholder.selectRoles"
                                        entityType={ENTITIES.DIVER_POSITION}
                                        searchable
                                    />
                                </div>
                                <div className="a-oneLineSelectGroup _12 -mt20">
                                    <SelectMultiselectGroupField
                                        name="skillRecord"
                                        title="general.addAllSkills"
                                        fieldsName={['skillCategory', 'skill']}
                                        fieldsPlaceholder={[
                                            'form.placeholder.skillCategory',
                                            'form.placeholder.skill',
                                        ]}
                                        fieldsEntityType={[
                                            ENTITIES.SKILL_CATEGORY,
                                            ENTITIES.SKILL,
                                        ]}
                                        fieldsShowLabel={[false, false]}
                                        fieldsRequired={[true, true]}
                                        disableAddOnlyForBoth={true}
                                        buttonLabel="button.add"
                                        secondFieldRelation="skillCategories.id"
                                        fieldsSearchable={[true, true]}
                                        listingTitle={t(
                                            'general.selectedSkills'
                                        )}
                                        disableOneField={[false, true]}
                                    />
                                </div>
                                <Separator />
                                <span className=" _12 a-bodyTextMedium">
                                    {t('general.language')}
                                </span>
                                <div className="_12 a-oneLineSelectGroup -mt20">
                                    <SelectGroupField
                                        name="language"
                                        fieldsName={[
                                            'language',
                                            'languageProficiency',
                                        ]}
                                        fieldsPlaceholder={[
                                            'form.placeholder.selectLanguage',
                                            'form.placeholder.selectProficiency',
                                        ]}
                                        fieldsEntityType={[
                                            ENTITIES.LANGUAGE,
                                            ENTITIES.LANGUAGE_PROFICIENCY,
                                        ]}
                                        fieldsRequired={[false, false]}
                                        fieldsShowLabel={[true, true]}
                                        required
                                        fieldsSearchable={[true, true]}
                                        dropup
                                    />
                                </div>
                                <Separator />
                                <span className="-mb20 _12 a-bodyTextMedium">
                                    {t('general.age')}
                                </span>
                                <div className="_12 _m6 toInput">
                                    <InputField
                                        name="age.from"
                                        label="form.label.from"
                                        placeholder="form.placeholder.durationFrom"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                    />
                                </div>
                                <div className="_12 _m6 toInput">
                                    <InputField
                                        name="age.to"
                                        label="form.label.to"
                                        placeholder="form.placeholder.durationTo"
                                        type={INPUT_FILED_TYPE.NUMBER}
                                    />
                                </div>
                                {isSubmitting && <Loader />}
                            </Modal>
                        </Form>
                    )}
                </Formik>
            )}
        </Fragment>
    )
}

DiverFilters.propTypes = {
    activeFilters: PropTypes.object,
    ignoreFilters: PropTypes.array,
}

export default DiverFilters
