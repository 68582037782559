import { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { Formik } from 'formik'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { ProfileContext } from './context/ProfileContext'
import { AlertContext } from 'contexts/AlertContext'

import { editEntityService } from 'services/entity.service'

import useQueryParams from 'hooks/useQueryParams'
import useFetchFile from 'hooks/useFetchFile'

import ENTITIES from 'constants/entities'
import ICONS from 'constants/icons'
import { getProfileTabs } from './diverInformation/constants/tabs'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_SIZE,
} from 'constants/enums'
import { FILE_CATEGORIES } from 'constants/fileCategories'

import useWindowDimensions from 'hooks/useWindowDimension'

import { DiverInformation } from './diverInformation'
import Button from 'components/Button'
import Loader from 'components/Loader'
import PersonalInfoCard from './diverInformation/components/PersonalInfoCard'
import ProfileCompletion from './diverInformation/components/ProfileCompletion'
import PersonalInfoModal from './diverInformation/modals/PersonalInfoModal'
import CoverImageUploadFields from 'components/formFields/CoverImageUploadFields'
import IdentityVerification from '../Homepage/components/IdentityVerification'

const Profile = () => {
    const { profileHash } = useParams()
    const { tab } = useQueryParams()
    const { isSmallScreen } = useWindowDimensions()

    const location = useLocation()

    const {
        currentUser,
        userId,
        isClientOrganization,
        fetchCurrentUser,
    } = useContext(CurrentUserContext)
    const { profile, isLoadingProfile, fetchProfile } = useContext(
        ProfileContext
    )
    const { setAlert } = useContext(AlertContext)

    const isMyProfile = currentUser?.id === profile?.id

    const { fileUrl: coverImageUrl } = useFetchFile(
        isMyProfile ? currentUser?.heroImagePath : profile?.heroImagePath
    )

    const coverPhotoButtonTitle = !!currentUser?.heroImagePath
        ? 'general.editCoverPhoto'
        : 'general.addCoverPhoto'

    const [open, setOpen] = useState(location.state?.personalInfo || false)
    const [openCoverImageModal, setOpenCoverImageModal] = useState('')

    const initialValues = {
        heroImage: currentUser?.heroImage ?? null,
    }

    const handleSubmit = async (formData, { setSubmitting }, customData) => {
        try {
            setSubmitting(true)

            const data = { ...formData, heroImage: customData }
            await editEntityService(ENTITIES.USER, userId, data)
            fetchCurrentUser()

            setOpenCoverImageModal(false)
            setSubmitting(false)
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleChildSubmit = async (customData, setSubmitting) => {
        handleSubmit(initialValues, { setSubmitting }, customData)
    }

    // NOTE: When navigated from profile page update button to the same tab you are already
    useEffect(() => {
        location.state?.personalInfo && setOpen(location.state?.personalInfo)
    }, [location.state?.personalInfo])

    const renderTabContent = () => {
        const TabComponent =
            getProfileTabs(profile).find(({ key }) => key === tab)?.component ||
            PersonalInfoCard

        return (
            <TabComponent
                myProfile={isMyProfile}
                userID={Number(profile.id)}
                profilePage
                refetchData={fetchProfile}
                user={profile}
                setOpen={setOpen}
            />
        )
    }

    if (isLoadingProfile) return <Loader />

    if (!currentUser || !profile) return null

    const renderCoverImageBox = () => (
        <div className="-coverImageBox -mb20">
            {isMyProfile && (
                <Button
                    onClick={() => setOpenCoverImageModal(true)}
                    icon={ICONS.EDIT}
                    iconSize={ICON_SIZE.SIZE20}
                    btnClass={BUTTON_STATUS.COVER_BUTTON}
                    buttonSize={BUTTON_SIZE.COVER}
                />
            )}
            <div
                className="m-coverImage"
                {...{
                    style: {
                        backgroundImage: `url(${coverImageUrl})`,
                    },
                }}
            />
        </div>
    )

    return (
        <div>
            <div className="_wr">
                <div className="_w">
                    <div className="m-boxes fullWidth -mt20">
                        {isSmallScreen && renderCoverImageBox()}
                        {isSmallScreen && (
                            <ProfileCompletion
                                user={profile}
                                userID={Number(profile.id)}
                                myProfile={isMyProfile}
                                isClientOrganization={isClientOrganization}
                                profileHash={profileHash}
                            />
                        )}
                        {isMyProfile && isSmallScreen && (
                            <IdentityVerification profilePage={true} />
                        )}
                        <div className="m-boxes__side -left -smallSidebar -noBackground">
                            <DiverInformation
                                user={profile}
                                myProfile={isMyProfile}
                                userID={Number(profile.id)}
                                setOpen={setOpen}
                            />
                        </div>
                        <div className="m-boxes__main -fullHeight -noBackground -tabContentBox">
                            {!isSmallScreen && renderCoverImageBox()}
                            {!isSmallScreen && (
                                <ProfileCompletion
                                    user={profile}
                                    userID={Number(profile.id)}
                                    myProfile={isMyProfile}
                                    isClientOrganization={isClientOrganization}
                                    profileHash={profileHash}
                                />
                            )}
                            {isMyProfile && !isSmallScreen && (
                                <IdentityVerification profilePage={true} />
                            )}
                            <div
                                className={`m-boxes__white fullHeight ${
                                    isSmallScreen ? '-mt20' : ''
                                }`}
                            >
                                {renderTabContent()}
                            </div>
                        </div>
                    </div>
                </div>
                {open && (
                    <PersonalInfoModal
                        open={open}
                        setOpen={setOpen}
                        fetchProfileData={fetchProfile}
                    />
                )}

                {openCoverImageModal && (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ setSubmitting }) => (
                            <CoverImageUploadFields
                                name="heroImage"
                                open={openCoverImageModal}
                                setOpen={setOpenCoverImageModal}
                                title={coverPhotoButtonTitle}
                                handleChildSubmit={handleChildSubmit}
                                setSubmitting={setSubmitting}
                                image={initialValues.heroImage}
                                fileCategory={FILE_CATEGORIES.USER_HERO_IMAGE}
                            />
                        )}
                    </Formik>
                )}
            </div>
        </div>
    )
}

export default Profile
