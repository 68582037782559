import { useTranslate } from 'react-polyglot'
import { useRef } from 'react'

import { VERIFICATION_LEVEL_COLORS } from 'constants/constants'

import VerificationLevelShowcase from 'components/VerificationLevelShowcase'
import useWindowDimensions from 'hooks/useWindowDimension'
import ROUTES from 'constants/routes'
import { useNavigate } from 'react-router'

const VerificationLevels = ({ user }) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const badgesRef = useRef(null)

    const { isTablet } = useWindowDimensions()

    const handleFindOutMore = () => {
        navigate(
            `/${ROUTES.HELP_AND_INFO}/${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`
        )
    }

    const handleMissingLevels = (data, totalLevels) => {
        const result = Array.from({ length: totalLevels }, (_, i) => ({
            level: i,
            num_records: 0,
            color: VERIFICATION_LEVEL_COLORS[i],
        }))

        data.forEach((item) => {
            result[item.level].num_records = item.num_records
        })

        return result
    }

    const totalLevels = 6
    const data = handleMissingLevels(
        user?.diveRecordsVerifiedLevels,
        totalLevels
    )

    return (
        <div className="-diveRecordsLevels column">
            <span className="a-mediumText a-lightText -opacity-60 -mt20">
                {t('general.diveRecords')}
            </span>
            {data && (
                <div
                    className="aligned-center -gap20 -mt10 flex-wrap"
                    ref={badgesRef}
                >
                    {data.map((item, level) => {
                        return (
                            <div key={level}>
                                <VerificationLevelShowcase
                                    badgesRef={badgesRef}
                                    levelOfVerification={level}
                                >
                                    <div
                                        className="-levels aligned-center -gap10"
                                        onClick={
                                            isTablet
                                                ? handleFindOutMore
                                                : undefined
                                        }
                                    >
                                        <span
                                            className={`a-status a-bodyTextMedium -${
                                                item.color || 'greenWithBcg'
                                            }`}
                                        >
                                            {item.num_records}
                                        </span>
                                        <span className="a-captionsTextRegular">
                                            {t('general.level')} {item.level}
                                        </span>
                                    </div>
                                </VerificationLevelShowcase>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default VerificationLevels
