import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { Fragment } from 'react'

import useWindowDimensions from 'hooks/useWindowDimension'

import { profileImageGuidelines } from 'data/profileImageGuidelines'

import ProfileImageSingleExample from './ProfileImageSingleExample'

const ProfileImageExamples = ({ page }) => {
    const t = useTranslate()
    const { isSmallScreen } = useWindowDimensions()

    return (
        <Fragment>
            {profileImageGuidelines.map((guideline, index) => (
                <div
                    className={`column -gap20 ${
                        page && !isSmallScreen
                            ? 'aligned-start'
                            : 'aligned-center'
                    } fullWidth ${isSmallScreen ? 'aligned-center' : ''}`}
                    key={index}
                >
                    <span className="a-mediumTextSemiBold a-centeredText">
                        {t(guideline.title)}
                    </span>
                    <div
                        className={`aligned-start ${
                            page && !isSmallScreen
                                ? 'space-between'
                                : 'space-evenly'
                        } fullWidth flex-wrap -gap10 ${
                            isSmallScreen ? 'space-evenly' : ''
                        }`}
                    >
                        {guideline.examples.map((example, index) => (
                            <ProfileImageSingleExample
                                key={index}
                                example={example}
                                correct={guideline.correct}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </Fragment>
    )
}

ProfileImageExamples.propTypes = {
    page: PropTypes.bool,
}

export default ProfileImageExamples
