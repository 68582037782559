import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router'

import useProjectAccess from 'hooks/useProjectAccess'
import useWindowDimensions from 'hooks/useWindowDimension'

import { getPositionImage } from 'utils/positionImage'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_POSITION,
    ICON_SIZE,
} from 'constants/enums'
import ROUTES from 'constants/routes'
import COLORS from 'constants/colors'
import ICONS from 'constants/icons'

import Button from 'components/Button'
import Icon from 'components/Icon'
import CardFooter from 'components/card/CardFooter'

const ContractorPositionCard = ({
    data,
    handleAction,
    isSideCard,
    customActions = null,
}) => {
    const t = useTranslate()
    const navigate = useNavigate()

    const { isSmallerTablet } = useWindowDimensions()

    const { isDiver } = useContext(CurrentUserContext)

    const {
        diverPosition,
        numOfOpenPositions,
        description,
        skillsPack,
        project,
        fromPayRate,
        toPayRate,
    } = data

    const { isProjectOwner } = useProjectAccess(project)

    const positionCategory = diverPosition?.diverPositionCategory?.code
    const position = diverPosition?.code

    const renderImage = () => (
        <div className="a-positionCard__image">
            <Icon
                name={getPositionImage(positionCategory, position)}
                color={COLORS.LIGHT_BLUE}
                size={ICON_SIZE.SIZE36}
            />
        </div>
    )

    const handleViewApplicants = () => {
        navigate(
            `${ROUTES.PROJECT}/${project.id}${ROUTES.DIVE_TEAM}?projectPosition=${data.id}&status=2`
        )
    }

    const renderButton = () => {
        if (customActions) return customActions

        if (isProjectOwner || isDiver) {
            return (
                <div
                    className={`${
                        !isSideCard ? 'fullWidth space-between -gap10' : ''
                    } ${isSmallerTablet ? 'column' : ''}`}
                >
                    {!isSideCard && (
                        <div className="a-positionCard__button -firstButton">
                            <Button
                                btnClass={BUTTON_STATUS.SECONDARY}
                                buttonSize={BUTTON_SIZE.MEDIUM}
                                label={'button.viewApplicants'}
                                onClick={handleViewApplicants}
                            />
                        </div>
                    )}
                    <div className="a-positionCard__button ">
                        <Button
                            btnClass={
                                !isSideCard
                                    ? BUTTON_STATUS.PRIMARY
                                    : BUTTON_STATUS.TERTIARY
                            }
                            buttonSize={
                                !isSideCard
                                    ? BUTTON_SIZE.MEDIUM
                                    : BUTTON_SIZE.SMALL
                            }
                            label={'button.invite'}
                            icon={isSideCard ? ICONS.ARROW_RIGHT : ''}
                            iconPosition={
                                isSideCard
                                    ? ICON_POSITION.RIGHT
                                    : ICON_POSITION.LEFT
                            }
                            iconColor={isSideCard ? COLORS.LIGHT_BLUE : ''}
                            onClick={handleAction}
                        />
                    </div>
                </div>
            )
        }
        return null
    }

    const handleViewTeamMembers = () => {
        navigate(
            `${ROUTES.PROJECT}/${project.id}${ROUTES.DIVE_TEAM}?projectPosition=${data.id}&status=1`
        )
    }

    return (
        <div
            className={`a-positionCard -mb10 ${
                isSideCard ? '-isSideCard' : ''
            }`}
        >
            {!isSideCard && renderImage()}
            <div className="a-positionCard__content">
                <div className="-titleAndImage">
                    <span className="a-bodyTextMedium -title">
                        {diverPosition.name + ' (' + numOfOpenPositions + ')'}
                    </span>
                </div>

                {!isSideCard &&
                    (fromPayRate !== undefined || toPayRate !== undefined) && (
                        <div className="-mt10 -payRate aligned-center">
                            <Icon
                                name={ICONS.WALLET}
                                size={ICON_SIZE.SIZE20}
                                color={COLORS.DARK_BLUE_60}
                            />
                            <span className="a-mediumText -infoValue">
                                {(fromPayRate && !toPayRate ? 'from ' : '') +
                                    (fromPayRate || 0) +
                                    (toPayRate ? '-' : '') +
                                    (toPayRate || '') +
                                    '$'}
                            </span>
                            <span className="a-mediumText -infoLabel">
                                {t('general.perDay')}
                            </span>
                        </div>
                    )}
                {description && (
                    <span className="a-mediumText -infoValue -mt10 a-lightText -opacity-60 -descriptionText">
                        {description}
                    </span>
                )}
                <div className="-values -mt5">
                    {!isSideCard &&
                        skillsPack?.map((value, index) => {
                            return (
                                <div
                                    key={index}
                                    className="-mt5 a-status -blue a-captionsTextRegular"
                                >
                                    {value}
                                </div>
                            )
                        })}
                </div>
            </div>
            {numOfOpenPositions > 0 ? (
                <CardFooter withBorder={!!renderButton()}>
                    {renderButton()}
                </CardFooter>
            ) : (
                isProjectOwner && (
                    <CardFooter withBorder>
                        <Button
                            btnClass={BUTTON_STATUS.SECONDARY}
                            buttonSize={BUTTON_SIZE.SMALL}
                            label={'button.viewTeamMembers'}
                            onClick={handleViewTeamMembers}
                        />
                    </CardFooter>
                )
            )}
        </div>
    )
}

ContractorPositionCard.propTypes = {
    data: PropTypes.object,
    handleAction: PropTypes.func,
    isSideCard: PropTypes.bool,
}

ContractorPositionCard.defaultProps = {
    isSideCard: false,
}

export default ContractorPositionCard
