import { Elements, PaymentElement } from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from 'components/stripe/CheckoutForm'
import React, { useEffect, useState } from 'react'
import useFetchData from 'hooks/useFetchData'
import ENTITIES from 'constants/entities'
import { getEntityService } from 'services/entity.service'
import { POST, RESPONSE_TYPE } from 'utils/axiosClient'
import ROUTES from 'constants/routes'
import { useNavigate, useParams } from 'react-router'
import Icon from 'components/Icon'
import ICONS from 'constants/icons'
import { ICON_SIZE, SELECT_VALUE_TYPE } from 'constants/enums'
import COLORS from 'constants/colors'
import getCurrencySymbolByCode from 'utils/currencyUtil'
import RadioGroup from 'components/RadioGroup'
import { useTranslate } from 'react-polyglot'
import PayPalForm from 'components/stripe/PayPalForm'
import Loader from 'components/Loader'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const Stripe = () => {
    const t = useTranslate();

    const ALLOWED_PLANS = ['pro', 'enterprise'];
    const ALLOWED_PAYMENT_TYPES = [
        {
            id: 'card',
            name: t('billing.card'),
            icon: <Icon
                name={ICONS.CARD}
                size={ICON_SIZE.SIZE24}
            />
        },
        // {
        //     id: 'paypal',
        //     name: t('billing.paypal'),
        //     icon: <Icon
        //         name={ICONS.PAY_PAL}
        //     />
        // },
    ]

    const { plan } = useParams()
    const navigate = useNavigate()

    const [clientSecret, setClientSecret] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedInterval, setSelectedInterval] = useState(null)
    const [selectedPaymentType, setSelectedPaymentType] = useState(ALLOWED_PAYMENT_TYPES[0].id)
    const [chosenPlansList, setChosenPlansList] = useState([])

    const { data: packagesList } = useFetchData(
        ENTITIES.LIST_PACKAGES,
        {},
        true,
        false,
        false,
        [],
        false
    )

    useEffect(() => {
        if(!packagesList || !packagesList.length) return;
        if(!ALLOWED_PLANS.includes(plan)) {
            navigate(ROUTES.PACKAGES)
            return;
        }

        const filteredData = packagesList.filter(p => p.plan === plan);
        setChosenPlansList(filteredData);
        setSelectedInterval(filteredData[1])
    }, [packagesList])


    useEffect(() => {
        if(!selectedInterval) {
            return;
        }
        setShowLoader(true)
        getEntityService(
            ENTITIES.CREATE_PAYMENT_INTENT,
            {
                plan: plan,
                interval: selectedInterval.interval,
            },
            false,
            false,
            process.env.REACT_APP_API_HOST,
            RESPONSE_TYPE.JSON,
            POST
        ).then(({ data }) => {
            setClientSecret(data.client_secret)
            setShowLoader(false)
        }).catch((err) => {
            setShowLoader(false)
        })
    }, [selectedInterval])

    const getPaymentComponent = () => {
        if(showLoader) {
            return <Loader />
        }

        switch (selectedPaymentType) {
            case 'card':
                return <CheckoutForm {...{ selectedInterval, setSelectedInterval, chosenPlansList, packagesList }} />
            // case 'paypal':
            //     return <PayPalForm />
        }
    }

    if(!clientSecret) return null;

    if(!chosenPlansList || !chosenPlansList.length) return null;

    return (
        <div className={'subscription-page'}>
        <Elements stripe={stripePromise} options={{ clientSecret }} key={clientSecret}>
            <div className="_wr">
                <div className="_w">
                    <div className="_12 -gap20">
                        <div className="plan-description-wrapper">
                            <span className="a-mediumTextSemiBold -mr10">{t('billing.selectedPlan')}:</span>
                            <div className="justify-center -gap5 plan-wrapper">
                                <Icon
                                    name={ICONS.PRO}
                                    size={ICON_SIZE.SIZE20}
                                    color={COLORS.YELLOW}
                                />

                                <span
                                    className="a-mediumTextSemiBold a-lightText -opacity-60 -about">{selectedInterval.plan} {selectedInterval.interval}</span>
                            </div>
                        </div>
                        <div className="plan-description-wrapper">
                            <span className="a-mediumTextSemiBold -mr10">{t('billing.cost')}:</span>
                            <p className="a-mediumText a-lightText -opacity-60 -about a-capitalize">{getCurrencySymbolByCode(selectedInterval.currency)}{selectedInterval.price}</p>
                        </div>
                        <div className="plan-description-wrapper">
                            <span className="a-mediumTextSemiBold -mr10">{t('billing.billingFrequency')}:</span>
                            <RadioGroup
                                name="interval"
                                label={'billing.billingFrequency'}
                                defaultOptions={chosenPlansList.map((item) => {
                                    return {
                                        ...item,
                                        name: t(`billing.interval.${item.interval}`)
                                    }
                                })}
                                value={selectedInterval}
                                valueType={SELECT_VALUE_TYPE.OBJECT}
                                setValue={(val) => setSelectedInterval(val)}
                                showLabel={false}
                            />
                        </div>
                        <div className="plan-description-wrapper payment-type-selection">
                            <span className="a-mediumTextSemiBold -mr10">{t('billing.paymentMethod')}:</span>
                            <RadioGroup
                                name="interval"
                                label={'billing.paymentMethod'}
                                defaultOptions={ALLOWED_PAYMENT_TYPES}
                                value={selectedPaymentType}
                                valueType={SELECT_VALUE_TYPE.STRING}
                                setValue={(val) => setSelectedPaymentType(val)}
                                showLabel={false}
                                displayAttribute={'icon'}
                                descriptionAttribute={'name'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {getPaymentComponent()}
        </Elements>
        </div>
    )
}

export default Stripe