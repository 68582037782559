import ROUTES from 'constants/routes'

import { diveRecordFirstLevelVerificationRoute } from 'routes/AuthRoutes/AuthRoutes'

import { FullScreenLayout, NoAuthLayout } from 'components/layouts'
import { NotFoundPage } from 'screens/common'
import ForgotPassword from 'screens/noAuth/ForgotPassword'
import Login from 'screens/noAuth/Login'
import Register from 'screens/noAuth/Register'
import RegisterPassword from 'screens/noAuth/RegisterPassword'
import ResetPassword from 'screens/noAuth/ResetPassword'
import { DeclineEmailChange } from 'screens/diver/settings'
import CertificateQrDetails from 'screens/diver/DiverOnboarding/TrainingRecordsOnboarding/components/CertificateQrDetails'
import AdciSearch from '../../screens/common/adci/components/AdciSearch'
import AdciSearchPublic from '../../screens/common/adci/components/AdciPublic'

const noAuthRoutes = [
    {
        element: <NoAuthLayout />,
        layout: true,
        routes: [
            {
                path: ROUTES.HOME,
                element: <Login />,
            },
            {
                path: ROUTES.LOGIN,
                element: <Login />,
            },
            {
                path: ROUTES.REGISTER,
                element: <Register />,
            },
            {
                path: `${ROUTES.REGISTER_PASSWORD}/:token`,
                element: <RegisterPassword />,
            },
            {
                path: ROUTES.FORGOT_PASSWORD,
                element: <ForgotPassword />,
            },
            {
                path: `${ROUTES.RESET_PASSWORD}/:token`,
                element: <ResetPassword />,
            },
            {
                path: ROUTES.NOT_FOUND_PAGE,
                element: <NotFoundPage />,
            },
        ],
    },
    {
        element: <FullScreenLayout />,
        layout: true,
        routes: [
            {
                ...diveRecordFirstLevelVerificationRoute,
            },
            {
                path: `${ROUTES.DECLINE_EMAIL_CHANGE}/:hash`,
                element: <DeclineEmailChange />,
            },
            {
                path: `${ROUTES.CERTIFICATE_QR}/:hash`,
                element: <CertificateQrDetails />,
            },
            {
                path: ROUTES.CERTIFICATE_PUBLIC,
                element:  <AdciSearchPublic/>,
            },
        ],
    },
]

export { noAuthRoutes }
