import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Link } from 'react-router'

import { SUPPORT } from 'constants/constants'

import Note from 'components/Note'
import DownloadMobileAppBoxes from './downloadMobileApp/DownloadMobileAppBoxes'

const DownloadMobileApp = () => {
    const t = useTranslate()
    return (
        <div className="column -gap20">
            <div className="column -gap10">
                <span className="a-bodyTextMedium">
                    {t('general.downloadMobileAppTitle')}
                </span>
                <span className="a-mediumText a-lightText">
                    {t('general.downloadMobileAppDescription')}
                </span>
            </div>
            <DownloadMobileAppBoxes />
            <Note>
                {t('general.needHelp')} {t('general.contactUsAt')}{' '}
                <Link
                    onClick={() =>
                        (window.location = `mailto:${SUPPORT.EMAIL}`)
                    }
                    title={SUPPORT.EMAIL}
                >
                    {SUPPORT.EMAIL}
                </Link>
                . {t('general.startExploringSNDToday')}
            </Note>
        </div>
    )
}

export default DownloadMobileApp
