import React, { Fragment } from 'react'

import ICONS from 'constants/icons'

import ProfileImageGuidelines from './ProfileImageGuidelines'
import ProfileImageExamples from 'screens/noAuth/Register/diver/ProfileGuidelinesModal/ProfileImageExamples'

const profileImageGuidelinesObject = {
    mainTitle: 'Profile image guidelines',
    mainDescription:
        'Your profile image is an essential part of your account as it helps represent you in a professional and identifiable manner. Please follow these detailed guidelines to ensure your photo meets the required standards.',
    sections: [
        {
            sectionTitle: 'General requirements',
            sectionList: [
                {
                    boldText: 'Accepted file types:',
                    text: 'JPEG (or JPG), WEBP, PNG, and HEIC (or HEIF).',
                },
                {
                    boldText: 'Maximum file size:',
                    text: '5 MB.',
                },
                {
                    boldText: 'Recommended resolution:',
                    text: 'At least 500 x 500 pixels for clarity.',
                },
                {
                    boldText: 'Aspect ratio:',
                    text: 'Maintain a square aspect ratio (1:1).',
                },
                {
                    boldText: 'Background:',
                    text: 'Plain and neutral backgrounds are preferred for a professional look.',
                },
            ],
        },
        {
            sectionTitle: 'Proper composition of your photo',
            description:
                'To ensure your profile photo looks professional, it must follow these composition guidelines:',
            subSections: [
                {
                    title: 'Face position and size:',
                    sectionList: [
                        {
                            boldText: 'Center your face:',
                            text: 'Your face should be centrally aligned in the photo.',
                        },
                        {
                            boldText: 'Head size:',
                            text: 'The head must occupy 70-80% of the photo’s height. Avoid images where your face is too small or too large in the frame.',
                        },
                    ],
                },
                {
                    title: 'Do not:',
                    sectionList: [
                        {
                            boldText: 'Too close:',
                            text: 'Avoid photos where your face fills the entire frame, leaving no visible background.',
                        },
                        {
                            boldText: 'Too far away:',
                            text: 'Photos with your face appearing too small or distant are not acceptable.',
                        },
                        {
                            boldText: 'Not centered:',
                            text: 'Ensure your face is not off-center; it should be in the middle of the image.',
                        },
                    ],
                },
                {
                    title: 'Examples:',
                    sectionList: [
                        {
                            icon: ICONS.CHECKMARK_ICON_ROUNDED_FILL,
                            boldText: 'Correct:',
                            text: 'Your head is centered, occupying the appropriate size in the photo.',
                        },
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: ' Too close:',
                            text: 'The head is overly zoomed in, cutting off parts of the face.',
                        },
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Too far away:',
                            text: 'The face appears distant, and details are not clear.',
                        },
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Not centered:',
                            text: 'The face is off-center, causing imbalance in the composition.',
                        },
                    ],
                },
            ],
        },
        {
            sectionTitle: 'Lighting and quality',
            sectionList: [
                {
                    boldText: 'Good lighting:',
                    text: 'The photo should be well-lit with even lighting across your face. Avoid shadows or overly bright spots.',
                },
                {
                    boldText: 'Sharpness:',
                    text: 'The image should be sharp and not blurry.',
                },
                {
                    boldText: 'Contrast:',
                    text: 'Ensure there is enough contrast between your face and the background.',
                },
                {
                    boldText: 'Background quality:',
                    text: 'A plain white or light background is highly recommended. Avoid busy or distracting patterns.',
                },
            ],
            subSections: [
                {
                    title: 'Inappropriate examples:',
                    sectionList: [
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Dark or overexposed:',
                            text: 'Avoid photos with poor lighting where the face is too dark or bright.',
                        },
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Blurry:',
                            text: 'Ensure the image is sharp and clear.',
                        },
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Distracting backgrounds:',
                            text: 'Photos with patterned or colorful backgrounds are not suitable.',
                        },
                    ],
                },
            ],
        },

        {
            sectionTitle: 'Appearance and professionalism',
            sectionList: [
                {
                    boldText: 'Neutral expressions:',
                    text: 'Keep a neutral expression or a slight, natural smile.',
                },
                {
                    boldText: 'Eye contact:',
                    text: 'Look directly into the camera.',
                },
                {
                    boldText: 'Clothing:',
                    text: 'Wear professional or casual clothing suitable for your role. Avoid hats, sunglasses, or distracting accessories.',
                },
            ],
            subSections: [
                {
                    title: 'Inappropriate examples:',
                    sectionList: [
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Tilted or angled:',
                            text: 'The photo should not be taken at an angle; the face should be straight and level with the camera.',
                        },
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Blocked face:',
                            text: 'Do not wear sunglasses, hats, or other accessories that block your face.',
                        },
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Text or watermarks:',
                            text: 'Avoid any text, watermarks, or logos on the image.',
                        },
                    ],
                },
            ],
        },
        {
            sectionTitle: 'Examples of acceptable and unacceptable Images',
            subSections: [
                {
                    title: 'Acceptable photo example (good):',
                    sectionList: [
                        {
                            icon: ICONS.CHECKMARK_ICON_ROUNDED_FILL,
                            text: 'Your head is properly centered and proportioned.',
                        },
                        {
                            icon: ICONS.CHECKMARK_ICON_ROUNDED_FILL,
                            text: 'The image is clear, with proper lighting and a neutral background.',
                        },
                        {
                            icon: ICONS.CHECKMARK_ICON_ROUNDED_FILL,
                            text: 'The face is sharp and visible with no distractions.',
                        },
                    ],
                },
                {
                    title: 'Inappropriate examples:',
                    sectionList: [
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Too close:',
                            text: 'The head fills the entire frame, cutting off parts of the face.',
                        },
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Too far away:',
                            text: 'The face is too distant to be clearly visible.',
                        },
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Not centered:',
                            text: 'The head is positioned too far to the side.',
                        },
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Poor quality:',
                            text: 'Blurry or pixelated images.',
                        },
                        {
                            icon: ICONS.CLOSE_CIRCLE,
                            boldText: 'Blocked face:',
                            text: 'Obstructions like hats or sunglasses.',
                        },
                    ],
                },
            ],
        },
    ],
}

const ProfileImageGuidelinesPage = () => {
    return (
        <Fragment>
            <ProfileImageGuidelines
                profileImageGuidelinesObject={profileImageGuidelinesObject}
            />
            <div className="column -gap15 -mt20 -pt20 a-separator -dashedtop">
                <ProfileImageExamples page={true} />
            </div>
        </Fragment>
    )
}

export default ProfileImageGuidelinesPage
