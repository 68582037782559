import PropTypes from 'prop-types'

import renderNoteAndAlertColor from 'utils/notesAndAlertsColorHelper'

import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

const NotesAndAlertsIcon = ({ type, note }) => {
    return (
        <Icon
            name={`alert_${type}`}
            color={renderNoteAndAlertColor(type)}
            size={note ? ICON_SIZE.SIZE20 : ICON_SIZE.SIZE32}
        />
    )
}

NotesAndAlertsIcon.propTypes = {
    type: PropTypes.string,
    note: PropTypes.bool,
}

NotesAndAlertsIcon.defaultProps = {
    note: false,
}

export default NotesAndAlertsIcon
