import PropTypes from 'prop-types'
import { useMemo, useRef } from 'react'

import useWindowDimensions from 'hooks/useWindowDimension'

import { TOOLTIP_POSITION } from 'constants/enums'

const IconShowcase = ({ children, tooltip, position, badgesRef }) => {
    const { isTablet } = useWindowDimensions()
    const singleBadgeRef = useRef(null)

    const contentPosition = useMemo(() => {
        if (badgesRef.current && singleBadgeRef.current) {
            const badgesRect = badgesRef.current.getBoundingClientRect()
            const singleBadgeRect =
                singleBadgeRef.current.getBoundingClientRect()

                const viewportWidth = window.innerWidth;

            const singleBadgeRight = singleBadgeRect.right
            const badgeLeft = badgesRect.left
            const singleBadgeLeft = singleBadgeRect.left

            const fitsRight = viewportWidth - singleBadgeRight >= 400
            const fitsLeft = singleBadgeLeft - 400 < viewportWidth - badgeLeft && singleBadgeLeft - 400 > 0
            
            if(fitsRight){
                return '-right'
            }

            if(fitsLeft){
                return '-left'
            }
            
            return '-middle'
        }
    }, [badgesRef.current, singleBadgeRef.current])
    
    const renderClassName = () => {
        return isTablet ? '-bottom' : `-${position || ''}`
    }

    return (
        <div
            className={`a-iconShowcase ${renderClassName()}`}
            ref={singleBadgeRef}
        >
            {children}
            {!isTablet && (
                <div
                    className={`iconShowcase-content hidden ${contentPosition}`}
                >
                    {tooltip}
                </div>
            )}
        </div>
    )
}

IconShowcase.propTypes = {
    position: PropTypes.oneOf([
        TOOLTIP_POSITION.RIGHT,
        TOOLTIP_POSITION.LEFT,
        TOOLTIP_POSITION.TOP,
        TOOLTIP_POSITION.BOTTOM,
    ]),
    tooltip: PropTypes.object,
    badgesRef: PropTypes.object,
}

IconShowcase.defaultProps = {
    position: TOOLTIP_POSITION.RIGHT,
}

export default IconShowcase
