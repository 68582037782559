import COLORS from 'constants/colors'
import { ALERT_TYPES } from 'constants/enums'

const renderNoteAndAlertColor = (type) => {
    const colorMap = {
        [ALERT_TYPES.WARNING]: COLORS.ORANGE_TEXT,
        [ALERT_TYPES.SUCCESS]: COLORS.GREEN_TEXT,
        [ALERT_TYPES.NOTIFICATION]: COLORS.LIGHT_BLUE,
        [ALERT_TYPES.ERROR]: COLORS.RED_TEXT,
        [ALERT_TYPES.INFO]: COLORS.DARK_BLUE,
    }

    return colorMap[type] || COLORS.LIGHT_BLUE
}

export default renderNoteAndAlertColor
