/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import useWindowDimensions from 'hooks/useWindowDimension'

import Loader from 'components/Loader'
import Pagination from './Pagination'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import TableActions from './TableActions'
import RowActions from './RowActions'
import { TableCards } from './tableCards'
import TableFilters from './TableFilters'

const PAGINATION_OPTIONS = [10, 20, 30]

const Table = ({
    title,
    headerItems,
    data,
    isLoading,
    totalItems,
    actions,
    rowActions,
    selectedRowActions,
    paginate,
    searchable,
    onClickRow,
    paginationOptions,
    idKey,
    FilterComponent,
    fullWidthTitle,
    rowDropdownActions,
    searchKey,
    searchPlaceholder,
    searchDisabled,
    filters,
    filtersKey,
    defaultSort,
    sortCallback,
    entityType,
    showDefaultKey,
}) => {
    const t = useTranslate()

    const { width, isSmallScreen, isTablet } = useWindowDimensions()

    const defaultItemsPerPage = paginationOptions[0]

    const [selectedRow, setSelectedRow] = useState(null)

    useEffect(() => {
        setSelectedRow(null)
    }, [isLoading])

    const tableHeaderItems =
        width < 768
            ? headerItems.filter((item) => item.showOnSmallScreens)
            : headerItems

    return (
        <div className="m-table">
            <div
                className={`m-table__header  ${
                    fullWidthTitle ? '-fullWidthTitle' : ''
                }`}
            >
                {title && (
                    <span className="a-bodyTextMedium -title">{t(title)}</span>
                )}
                <div className="m-table__actions">
                    <div className="-wrapper">
                        {searchable && (
                            <TableFilters
                                FilterComponent={FilterComponent}
                                filters={filters}
                                filtersKey={filtersKey}
                                searchKey={searchKey}
                                searchPlaceholder={searchPlaceholder}
                                disabled={searchDisabled}
                                entityType={entityType}
                                showDefaultKey={showDefaultKey}
                            />
                        )}
                    </div>
                    {actions.length > 0 && (
                        <TableActions actions={actions} isTablet={isTablet} />
                    )}
                </div>
            </div>
            {selectedRowActions.length !== 0 && (
                <div className="m-table__actionsContainer">
                    <RowActions
                        actions={selectedRowActions}
                        item={selectedRow}
                    />
                </div>
            )}

            {isLoading && <Loader />}

            {!isSmallScreen ? (
                <div className={`m-table__container`}>
                    <table>
                        <TableHeader
                            headerItems={tableHeaderItems}
                            rowActions={[
                                ...(rowDropdownActions || []),
                                ...rowActions,
                            ]}
                            sortCallback={sortCallback}
                            defaultSort={defaultSort}
                        />
                        <TableBody
                            headerItems={tableHeaderItems}
                            data={data}
                            isLoading={isLoading}
                            rowActions={rowActions}
                            selectedRowActions={selectedRowActions}
                            onClickRow={onClickRow}
                            selectedRow={selectedRow}
                            setSelectedRow={setSelectedRow}
                            defaultItemsPerPage={defaultItemsPerPage}
                            idKey={idKey}
                            rowDropdownActions={rowDropdownActions}
                        />
                    </table>
                </div>
            ) : (
                <TableCards
                    headerItems={tableHeaderItems}
                    data={data}
                    isLoading={isLoading}
                    rowActions={[...(rowDropdownActions || []), ...rowActions]}
                    selectedRowActions={selectedRowActions}
                    defaultItemsPerPage={defaultItemsPerPage}
                />
            )}
            {data.length > 0 && paginate && (
                <Pagination
                    totalItems={totalItems}
                    paginationOptions={paginationOptions}
                    defaultItemsPerPage={defaultItemsPerPage}
                    forSmallScreens={true}
                />
            )}
        </div>
    )
}

Table.propTypes = {
    title: PropTypes.string,
    headerItems: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            title: PropTypes.string,
            sortable: PropTypes.bool,
        })
    ).isRequired,
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
    totalItems: PropTypes.number,
    actions: PropTypes.array,
    rowActions: PropTypes.array,
    selectedRowActions: PropTypes.array,
    paginate: PropTypes.bool,
    searchable: PropTypes.bool,
    onClickRow: PropTypes.func,
    sortCallback: PropTypes.func,
    paginationOptions: PropTypes.array,
    idKey: PropTypes.string,
    FilterComponent: PropTypes.elementType,
    fullWidthTitle: PropTypes.bool,
    filters: PropTypes.object,
    filtersKey: PropTypes.string,
    defaultSort: PropTypes.object,
    searchKey: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    searchDisabled: PropTypes.bool,
    showDefaultKey: PropTypes.bool,
    entityType: PropTypes.string,
}

Table.defaultProps = {
    actions: [],
    rowActions: [],
    selectedRowActions: [],
    paginate: true,
    searchable: false,
    idKey: 'id',
    paginationOptions: PAGINATION_OPTIONS,
    fullWidthTitle: false,
    sortCallback: null,
    defaultSort: null,
    showDefaultKey: true,
}

export default Table
