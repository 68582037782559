import React from 'react'
import { useTranslate } from 'react-polyglot'

import { createEntityService } from 'services/entity.service'

import EntityForm from './components/EntityForm'
import ManagementLayout from '../components/ManagementLayout'
import EntityBreadcrumbs from './components/EntityBreadcrumbs'
import { CUSTOM_FORMS } from './utils/customForms'

const CreateEntity = ({ entityType, path }) => {
    const t = useTranslate()

    const handleSubmit = async (formData) => {
        await createEntityService(entityType, formData, true)
    }

    const FormComponent = CUSTOM_FORMS[entityType]?.component || EntityForm

    return (
        <ManagementLayout
            BreadcrumbsComponent={EntityBreadcrumbs}
            entityType={entityType}
            create
        >
            <FormComponent
                handleSubmit={handleSubmit}
                title={`${t('general.create')} ${t(
                    `entityManagement.${entityType}_singular`
                ).toLowerCase()}`}
                entityType={entityType}
                path={path}
            />
        </ManagementLayout>
    )
}

export default CreateEntity
