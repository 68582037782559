import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { useLocation } from 'react-router'

import { AlertContext } from 'contexts/AlertContext'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { editEntityService } from 'services/entity.service'
import useFetchData from 'hooks/useFetchData'

import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    PROJECT_WORKER_STATUS,
} from 'constants/enums'
import ICONS from 'constants/icons'
import ENTITIES from 'constants/entities'
import Button from 'components/Button'
import ROUTES from 'constants/routes'
import COLORS from 'constants/colors'

import SearchAndFilter from 'components/SearchAndFilter'
import DiverFilters from '../../../../components/diverList/DiverFilters'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import DiveTeamStatusUpdate from './DiveTeamStatusUpdate'
import RejectionModal from 'components/rejectionModal/RejectionModal'
import CardHeader from 'components/card/CardHeader'
import DiveTeamListItemFeedback from './DiveTeamListItemFeedback'
import DiveTeamListItemComments from './DiveTeamListItemComments'
import InfoRow from 'components/InfoRow'
import CardFooter from 'components/card/CardFooter'
import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'
import Pagination from 'components/table/Pagination'
import EmptyList from 'components/EmptyList'

const DiveTeamList = ({
    projectDiveTeam,
    activeFilters,
    isLoadingFilters,
    refetchData,
    defaultProjectPosition,
    isProjectOwner,
    isProjectClient,
    filters,
}) => {
    const t = useTranslate()
    const { search } = useLocation()

    const { data, isLoading, meta } = projectDiveTeam
    const { status, ...searchFilters } = activeFilters
    const projectPosition =
        activeFilters.projectPosition || defaultProjectPosition
    const [rejectModalOpen, setRejectModalOpen] = useState(false)
    const [rejectingItem, setRejectingItem] = useState(null)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const { setAlert } = useContext(AlertContext)
    const ignoreFilters = ['availability', 'previouslyEmployed']

    const {
        data: diveInviteStatuses,
    } = useFetchData(ENTITIES.DIVER_INVITE_STATUS, { pagination: false })

    const formatWorkerLanguages = (workerLanguages) => {
        if (!workerLanguages?.length) return 'N\\A'

        return workerLanguages?.map(
            ({ languageName, languageProficiencyName }) =>
                `${languageName} (${languageProficiencyName.toLowerCase()})`
        )
    }

    const formatExperiences = (experiences) => {
        if (!experiences || !experiences.length) {
            return 'N\\A'
        }

        return experiences
            .filter(({ diveModeName }) => diveModeName && diveModeName !== '/')
            .map(
                ({ dives, days, bellRuns, diveModeName }) =>
                    `${diveModeName} ${
                        dives && dives !== '/'
                            ? dives + ' ' + t('general.dives')
                            : ''
                    } ${
                        days && days !== '/'
                            ? days + ' ' + t('general.days')
                            : ''
                    } ${
                        bellRuns && bellRuns !== '/'
                            ? bellRuns + ' ' + t('general.bellRuns')
                            : ''
                    }`
            )
    }

    const closeConfirmationModalAndRefreshPositions = () => {
        closeConfirmationModal()
        refetchData()
    }

    const handleConfirmationModal = (message) => {
        showConfirmationModal({
            title: 'general.success',
            message: `${t(message)}`,
            confirmLabel: 'button.ok',
            handleConfirm: () => closeConfirmationModalAndRefreshPositions(),
            handleCancel: () => closeConfirmationModalAndRefreshPositions(),
            translateMessage: false,
            hideCancel: true,
        })
    }

    const handleEmploy = async (item) => {
        await handleApplicationChange(
            item.id,
            {
                personEmployed: true,
            },
            'general.successfullyEmployed',
            'form.error.canNotAcceptApplication'
        )
    }

    const handleAcceptApplication = async (item) => {
        await handleApplicationChange(
            item.id,
            {
                inviteAccepted: true,
            },
            'general.successfullyAccepted',
            'form.error.canNotAcceptApplication'
        )
    }

    const handleRejectApplication = async (item) => {
        setRejectingItem(item)
        setRejectModalOpen(true)
    }

    const handleInviteAgain = async (item) => {
        await handleApplicationChange(
            item.id,
            {
                invitedAgain: true,
            },
            'general.successfullyInvitedAgain',
            'form.error.canNotInviteAgain'
        )
    }

    const handleRemoveDiver = async (item) => {
        await handleApplicationChange(
            item.id,
            {
                inviteRejected: true,
            },
            'general.successfullyRemoved',
            'form.error.canNotRemoveFromPosition'
        )
    }

    const handleApplicationChange = async (
        itemId,
        formData,
        successMessage,
        errorMessage
    ) => {
        try {
            await editEntityService(ENTITIES.INVITE, itemId, formData, true, [
                'positionRejections',
            ])
            setRejectModalOpen(false)
            handleConfirmationModal(successMessage)
        } catch (error) {
            if (error.response.data.message === 'Not Found') {
                setAlert(t(errorMessage), ALERT_TYPES.ERROR)
            } else {
                setAlert(error, ALERT_TYPES.ERROR)
            }
            refetchData()
        }
    }

    const handleCancelInvitation = async (item) => {
        await editEntityService(ENTITIES.INVITE, item.id, {
            canceled: true,
        })
        handleConfirmationModal('general.successfullyCanceled')
    }

    const getItemStatuses = (item) => {
        if (item.diverInviteStatus) {
            return [
                {
                    type: 'status',
                    name: [[item.diverInviteStatus.diverInviteStatusName]],
                    color: 'green',
                },
            ]
        }
        const statuses = []

        if (!item?.fullName) {
            statuses.push({
                type: 'status',
                name: [[t('general.unregisteredDiver')]],
                color: 'gray',
            })
        }

        if (item.systemStatus === 1) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusNewApplicant')]],
                color: 'purple',
            })
        }

        if (item.systemStatus === 2) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusNewInvite')]],
                color: 'purple',
            })
        }

        if (item.systemStatus === 3) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusReapplied')]],
                color: 'orange',
            })
        }

        if (item.systemStatus === 4) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusRejected')]],
                color: 'red',
            })
        }

        if (item.systemStatus === 5) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusAccepted')]],
                color: 'green',
            })
        }

        if (item.systemStatus === 6) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusEmployed')]],
                color: 'green',
            })
        }

        if (item.systemStatus === 7) {
            statuses.push({
                type: 'status',
                name: [[t('button.inviteStatusCanceled')]],
                color: 'red',
            })
        }

        return statuses
    }

    const isApplicant = (item) => {
        return item.systemStatus === 5
    }

    const updateInviteStatus = async (item, status) => {
        try {
            await editEntityService(ENTITIES.INVITE, item.id, {
                status,
            })
            setAlert(t('general.statusUpdated'), ALERT_TYPES.SUCCESS)
        } catch (error) {
            setAlert(t('general.unableToUpdateStatus'), ALERT_TYPES.ERROR)
        }
    }

    const getItemActions = (item) => {
        if (!diveInviteStatuses || !diveInviteStatuses.length) {
            return []
        }

        return diveInviteStatuses.map((status) => {
            return {
                handleAction: () => {
                    updateInviteStatus(item, status).then(() => {
                        refetchData()
                    })
                },
                label: status.name,
            }
        })
    }

    const showStatusUpdate =
        parseInt(activeFilters?.status) === PROJECT_WORKER_STATUS.CANDIDATES

    const isFilterEmpty = Object.keys(activeFilters).every(
        (f) => ['status', 'projectPosition'].indexOf(f) > -1
    )

    if (!data) return null

    return (
        <div>
            <SearchAndFilter
                searchPlaceholder="general.searchForUser"
                activeFilters={searchFilters}
                filters={filters}
                showDefaultKey={false}
                hiddenFilterKeys={['diverPosition', 'projectPosition']}
                withAdditionalButton={isProjectOwner && showStatusUpdate}
            >
                <DiverFilters
                    activeFilters={activeFilters}
                    showStatusUpdate={isProjectOwner && showStatusUpdate}
                    ignoreFilters={ignoreFilters}
                    title={'general.filterTeamMembers'}
                />
                {isProjectOwner && showStatusUpdate && (
                    <DiveTeamStatusUpdate
                        projectPositionId={parseInt(projectPosition)}
                        updateListFunc={() => {
                            refetchData()
                        }}
                        disabled={!data || !data.length}
                        diveInviteStatuses={diveInviteStatuses}
                    />
                )}
            </SearchAndFilter>

            {data.map((item, index) => (
                <div key={index} className="-mb10">
                    <CardContainer
                        withAvatar
                        link={
                            item?.workerId
                                ? `${ROUTES.DIVER_PROFILE}/${item?.profileHash}`
                                : ''
                        }
                    >
                        <CardHeader
                            avatar={item.avatarPath}
                            title={item.fullName || item.workerEmail}
                            subtitle={item.positionTitle}
                            item={item}
                            verifiedBadge={!!item.identityVerified}
                            statuses={getItemStatuses(item)}
                            actions={
                                isProjectOwner && isApplicant(item)
                                    ? getItemActions(item)
                                    : null
                            }
                            statusActionsCombined={
                                isProjectOwner && isApplicant(item)
                            }
                            placeholderIcon={ICONS.USER}
                        />
                        <InfoRow withButtons alwaysSeparated>
                            <div className="aligned-center -gap20">
                                <Info
                                    value={item.numberOfApprovalsByClients}
                                    iconName={ICONS.LIKE}
                                    iconColor={COLORS.GREEN}
                                />
                                <Info
                                    value={item.numberOfDisapprovalsByClients}
                                    iconName={ICONS.DISLIKE}
                                    iconColor={COLORS.RED}
                                />
                            </div>
                            <DiveTeamListItemComments
                                diveTeamItem={item}
                                isProjectClient={isProjectClient}
                                refetchData={refetchData}
                            />
                        </InfoRow>
                        {!!item.numberOfApprovalsByClients && (
                            <Info
                                label="general.endorsedBy"
                                value={item.namesOfApprovals}
                                color="green"
                            />
                        )}

                        {!!item.numberOfDisapprovalsByClients && (
                            <Info
                                label="general.disapprovedBy"
                                value={item.namesOfDisapprovals}
                                color="red"
                            />
                        )}

                        {item.positionDescription && (
                            <Info
                                label="form.label.aboutPosition"
                                value={item.positionDescription}
                            />
                        )}
                        {item.countryName && (
                            <Info
                                label="form.label.country"
                                value={item.countryName}
                            />
                        )}
                        {item.language && (
                            <Info
                                label="form.label.languages"
                                value={formatWorkerLanguages(item.language)}
                                color="pink"
                            />
                        )}
                        {item.experiences.length !== 0 && (
                            <Info
                                label="form.label.experiencedInModes"
                                value={formatExperiences(item.experiences)}
                                color="orange"
                            />
                        )}
                        {item?.fullName && item.totalExperienceYears && (
                            <Info
                                label="form.label.totalYearsOfExperience"
                                value={item.totalExperienceYears}
                                color="grayLight"
                            />
                        )}

                        {isProjectOwner &&
                            (item.canBeAccepted ||
                                item.canBeEmployed ||
                                item.canBeRejected ||
                                item.canInviteAgain ||
                                item.canBeCanceled ||
                                item.canBeRemoved) && (
                                <CardFooter withBorder>
                                    {item.canBeRejected && (
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            buttonSize={BUTTON_SIZE.MEDIUM}
                                            label="button.rejectApplication"
                                            onClick={() =>
                                                handleRejectApplication(item)
                                            }
                                        />
                                    )}

                                    {item.canInviteAgain && (
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            buttonSize={BUTTON_SIZE.MEDIUM}
                                            label="button.inviteAgain"
                                            onClick={() =>
                                                handleInviteAgain(item)
                                            }
                                        />
                                    )}

                                    {item.canBeAccepted && (
                                        <Button
                                            btnClass={BUTTON_STATUS.PRIMARY}
                                            buttonSize={BUTTON_SIZE.MEDIUM}
                                            label="button.acceptApplication"
                                            onClick={() =>
                                                handleAcceptApplication(item)
                                            }
                                        />
                                    )}

                                    {item.canBeEmployed && (
                                        <Button
                                            btnClass={BUTTON_STATUS.PRIMARY}
                                            buttonSize={BUTTON_SIZE.MEDIUM}
                                            label="button.employApplication"
                                            onClick={() => handleEmploy(item)}
                                        />
                                    )}

                                    {item.canBeCanceled && (
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            buttonSize={BUTTON_SIZE.MEDIUM}
                                            label="button.cancelInvitation"
                                            onClick={() =>
                                                handleCancelInvitation(item)
                                            }
                                        />
                                    )}

                                    {item.canBeRemoved && (
                                        <Button
                                            btnClass={BUTTON_STATUS.SECONDARY}
                                            buttonSize={BUTTON_SIZE.MEDIUM}
                                            label="button.removeFromPosition"
                                            onClick={() =>
                                                handleRemoveDiver(item)
                                            }
                                        />
                                    )}
                                </CardFooter>
                            )}
                        {isProjectClient && (
                            <DiveTeamListItemFeedback
                                diveTeamItem={item}
                                isProjectClient={isProjectClient}
                                refetchData={refetchData}
                            />
                        )}
                    </CardContainer>
                </div>
            ))}
            {data.length === 0 && !isLoading && (
                <EmptyList
                    icon={ICONS.USERS}
                    title={
                        meta?.totalItems
                            ? t('general.noTeamMembersOnThisPage')
                            : t('general.noTeamMembersYet')
                    }
                    description={
                        meta?.totalItems
                            ? t('general.noTeamMembersOnThisPageDesc')
                            : t('general.noTeamMembersDesc')
                    }
                    showSearchResult={
                        (search.includes('name') || !isFilterEmpty) &&
                        !meta?.totalItems
                    }
                />
            )}
            <Pagination totalItems={meta?.totalItems} meta={meta} scrollToTop />
            {rejectModalOpen && (
                <RejectionModal
                    diverInvite={rejectingItem}
                    setOpen={setRejectModalOpen}
                    handleReject={handleApplicationChange}
                />
            )}
            {renderLoader(isLoading, null, isLoadingFilters)}
        </div>
    )
}

DiveTeamList.propTypes = {
    projectDiveTeam: PropTypes.object,
    activeFilters: PropTypes.object,
    refetchData: PropTypes.func,
    defaultProjectPosition: PropTypes.number,
    filters: PropTypes.object,
}

export default DiveTeamList
