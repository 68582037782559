import React from 'react'
import AdciSearch from './AdciSearch'

const AdciSearchPublic = () => {
    return (
        <div className="m-emailLink">
            <div className="m-emailLink__background -checkCert">
                <AdciSearch publicView={true}></AdciSearch>
            </div>
        </div>
    )
}

export default AdciSearchPublic
