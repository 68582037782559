import React from 'react'
import { useTranslate } from 'react-polyglot'

import { ICON_SIZE } from 'constants/enums'

import Icon from 'components/Icon'

import { getCertificateStatusInfo } from '../utils/certificateHelper'

const CertificateStatus = ({ verifiedStatus }) => {
    const t = useTranslate()

    const status = getCertificateStatusInfo(verifiedStatus, t)

    if (!status) return null

    const { name, icon, color, iconColor } = status

    return (
        <div
            className={`a-status 
                a-captionsTextRegular
            }  ${`-${color}`} aligned-center -gap5`}
        >
            <Icon name={icon} size={ICON_SIZE.SIZE16} color={iconColor} />
            <span>{name}</span>
        </div>
    )
}

export default CertificateStatus
