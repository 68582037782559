import React, { Fragment, useEffect } from 'react'
import { useTranslate } from 'react-polyglot'

import useQueryParams from 'hooks/useQueryParams'

import { getVerificationLevels } from 'data/diveRecordVerificationLevels'

import Icon from 'components/Icon'

const DiveRecordVerificationLevels = () => {
    const t = useTranslate()
    const {
        filterParams: { levelId },
    } = useQueryParams()

    useEffect(() => {
        if (levelId) {
            const element = document.getElementById(levelId)
            if (element) {
                const top = element.offsetTop
                window.scrollTo({
                    top,
                    behavior: 'smooth',
                })
            }
        }
    }, [levelId])

    const renderProcess = (title, processItems) => (
        <div>
            <div className="-mt10">
                <span className="a-mediumText a-lightText">{t(title)}:</span>
            </div>
            <div>
                <ul>
                    {processItems.map((process, index) => {
                        return (
                            <li
                                key={index}
                                className="a-mediumText a-lightText -liDisc"
                            >
                                {process}
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )

    return (
        <div>
            <div className="-mb20">
                <span className=" a-bodyTextMedium">
                    {t('general.diveRecordVerificationLevels')}
                </span>
            </div>
            {getVerificationLevels(t).map((level) => {
                return (
                    <Fragment key={level.id}>
                        <div className="-mb20" id={`level-${level.id}`}>
                            <div className="aligned-center -gap10">
                                <Icon name={level.icon} />
                                <span className=" a-mediumTextSemiBold">
                                    {level.title}
                                </span>
                            </div>
                            <div className=" -mt10">
                                <span className="a-mediumText a-lightText">
                                    {level.description}
                                </span>
                            </div>
                            {level?.process &&
                                renderProcess('general.process', level.process)}
                            {level?.processForSupervisor &&
                                renderProcess(
                                    'general.processForSupervisor',
                                    level.processForSupervisor
                                )}
                            <div className="-mt10">
                                <span className="a-mediumText a-lightText">
                                    {t('general.riskLevel')}{' '}
                                </span>
                                <span
                                    className={`a-mediumTextSemiBold ${level.color}`}
                                >
                                    {level.riskLevel}
                                </span>
                            </div>
                        </div>
                    </Fragment>
                )
            })}
        </div>
    )
}

export default DiveRecordVerificationLevels
